import { faTowerCell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import configApp from "../../config/config";

export default function SettingsInfo({ version, name, clock, networkImage, rfid }) {
  const devMode = configApp.device_settings?.devMode ? true : false;
  return <div className="DeviceInfo">
          {devMode && <a>devMode</a>}
          <FontAwesomeIcon icon={faTowerCell} style={{ color: networkImage }} />
          {rfid && <b style={{ color: rfid }} >Rfid</b>}
          {configApp.device_settings?.hasOwnProperty("version") && <a>{version}: {configApp.device_settings.version}</a>}
          {configApp.device_settings?.hasOwnProperty("name") && <a>{name}: {configApp.device_settings.name}</a>}
          {clock}
         </div>
};