import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import Cabinet from "../screens/cabinet/Cabinet";

class CabinetContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Cabinet {...this.state} {...this.props}/>
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        app: store.appState,
    };
};

export default connect(mapStateToProps)(withTranslation()(CabinetContainer));