import React, { useEffect } from 'react';
import Content from "../../blocks/Content";

export default function HighQuestAnswer(props) {

    useEffect(() => {
        let nodeVideo = document.querySelector('#high_quest_answer');
        nodeVideo.autoplay = 'autoplay';
        nodeVideo.addEventListener('ended', () => {
            nodeVideo.pause();
            props?.hasOwnProperty("goBackStep") && props.goBackStep();
            props.toNextChildQuest();
            props?.hasOwnProperty("renderAdditionalButton") && props.renderAdditionalButton(null);
        });
        return () => nodeVideo.removeEventListener('ended', () => {
                        props.toNextChildQuest();
                        props.renderAdditionalButton(null);
                    });
    }, []);

    return <div className={"Screen quest container"}>
            <div className="h-100 d-flex justify-content-center align-items-center">
                <Content>
                    <div className={"m-auto d-flex justify-content-center"}>
                        <video className={"mw-100 mh-100"} id="high_quest_answer" src={props.file} autoPlay={true}>
                            <source src={props.file}/>
                        </video>
                    </div>
                </Content>
            </div>
           </div>
}