import React, { useEffect } from "react";
import Content from "../blocks/Content";

export default function Siz(props) {
    let res;
    let element;
    let newEntries = [];

    let options = {
        root: document.querySelector('.scroll-block'),
        rootMargin: '0px',
        threshold: 1
    };

    const callback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !newEntries.includes(entry.target.id)) newEntries.push(entry.target.id);
        });
    };

    let observer = new IntersectionObserver(callback, options);

    useEffect(() =>{
        return () => {
            if (newEntries.length > 0) {
                newEntries.forEach(el => props.viewedSizs(el));
                observer.disconnect();
            };
        }
    },[])
    

    if (props.ipms && props.ipms.length > 0) {
        res = <div className="d-flex align-items-center flex-column justify-content-center h-100" >
                <h1 className="Text_success preshift__text">{props.t('List of PPE to receive')}</h1>
                <table className="table_siz display-9">
                    <thead>
                        <tr>
                            <th className="text-center" scope="col">{props.t('Siz_Title')}</th>
                            <th className="text-center" scope="col">{props.t('Warehouse')}</th>
                            <th className="text-center" scope="col">{props.t('Get (pcs.)')}</th>
                            <th className="text-center" scope="col">{props.t('Use (days)')}</th>
                            <th className="text-center" scope="col">{props.t('Stock balance (pcs.)')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ipms.map(el =>
                            <tr className="table_siz_tr" key={el.uuid} id={el.uuid} >
                                <td className="text-center table_siz_text">{el.object.title}</td>
                                <td className="text-center table_siz_text">{el.stock.title}</td>
                                <td><div className="m-auto table_siz_num">{el.count}</div></td>
                                <td><div className="m-auto table_siz_num">{el.use_time}</div></td>
                                <td><div className="m-auto table_siz_num">{el.count_in_stock}</div></td>
                            </tr>)}
                    </tbody>
                </table>
              </div>;
        setTimeout(() => props.ipms.map(el => {
                            element = document.getElementById(`${el.uuid}`);
                            observer.observe(element);
                        }), 500);
    } else {
        res = <div className="d-flex align-items-center flex-column justify-content-center h-100">
                <h4 className={`preshift__text text-center Text_success`}>{props.t('No siz')}</h4>
              </div>
    }

    return <Content>{res}</Content>
}