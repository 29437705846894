import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Keyboard from "react-simple-keyboard";

function SetDevicesSettings(props) {
    const [showRFID, setShowRFID] = useState(false);
    const [showBreathalyzer, setShowBreathalyzer] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const rfid_port = props.device_settings.rfid.port;
    const rfid_baudRate = props.device_settings.rfid.baudRate;
    const breathalyzer_port = props.device_settings.breathalyzer.port;
    const breathalyzer_baudRate = props.device_settings.breathalyzer.baudRate;
    const breathalyzer_delimiter = props.device_settings.breathalyzer.delimiter;


    return <div className="d-flex flex-column w-75">
            <div className={`d-flex justify-content-start align-items-center ${!showBreathalyzer && "mb-5"}`}>
                <span className="set_settings_label mr-4">{props.t("Parameters of the breathalyzer")}</span>
                <button type="button" className="keyboardShowButtton" onClick={() => setShowBreathalyzer(!showBreathalyzer)}>
                    <FontAwesomeIcon icon={showBreathalyzer ? faChevronUp : faChevronDown} />
                </button>
            </div>
            {showBreathalyzer &&
                <div className="set_devices_inputs m-5">
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="set_settings_label mr-2" htmlFor="breathalyzer_port">Port</label>
                        <FormControl
                            type="text"
                            minLength="1"
                            required
                            name="breathalyzer_port"
                            id="breathalyzer_port"
                            value={breathalyzer_port}
                            placeholder="Port"
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field mr-2`} />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="set_settings_label mr-2" htmlFor="breathalyzer_baudRate">baudRate</label>
                        <FormControl
                            type="text"
                            minLength="1"
                            required
                            name="breathalyzer_baudRate"
                            id="breathalyzer_baudRate"
                            value={breathalyzer_baudRate}
                            placeholder="baudRate"
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field mr-2`} />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="set_settings_label mr-2" htmlFor="breathalyzer_delimiter">delimiter</label>
                        <FormControl
                            type="text"
                            minLength="1"
                            required
                            name="breathalyzer_delimiter"
                            id="breathalyzer_delimiter"
                            value={breathalyzer_delimiter}
                            placeholder="delimiter"
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field`} />
                    </div>
                </div>}
            <div className={`d-flex justify-content-start align-items-center ${!showRFID && "mb-5"}`}>
                <span className="set_settings_label mr-4">{props.t("RFID Reader Parameters")}</span>
                <button type="button" className="keyboardShowButtton" onClick={() => setShowRFID(!showRFID)}>
                    <FontAwesomeIcon icon={showRFID ? faChevronUp : faChevronDown} />
                </button>
            </div>
            {showRFID &&
                <div className="set_devices_inputs m-5">
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="set_settings_label mr-2" htmlFor="rfid_port">Port</label>
                        <FormControl
                            type="text"
                            minLength="1"
                            required
                            name="rfid_port"
                            id="rfid_port"
                            value={rfid_port}
                            placeholder="port"
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field mr-2`} />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="set_settings_label mr-2" htmlFor="rfid_baudRate">baudRate</label>
                        <FormControl
                            type="text"
                            minLength="1"
                            required
                            name="rfid_baudRate"
                            id="rfid_baudRate"
                            value={rfid_baudRate}
                            placeholder="baudrate"
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field mr-2`} />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <input type="checkbox"
                            defaultChecked={props.device_settings.rfid.isHid}
                            name="isHid"
                            id="isHid"
                            onChange={props.onInputChange}
                            className="set_settings_input mr-4" />
                        <label className="set_settings_label" htmlFor="isHid">isHid</label>
                    </div>
                </div>}
                {(showBreathalyzer || showRFID) &&
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="keyboardShowBtn">
                            <button type="button" className="keyboardShowButtton" onClick={() => setShowKeyboard(!showKeyboard)}>
                                <FontAwesomeIcon icon={showKeyboard ? faChevronUp : faChevronDown} />
                            </button>
                        </div>
                        <div className={`mx-4 keywoard-container search-emp_keywoard-container ${showKeyboard ? null : "unShowKeyboardClass"}`}>
                            <Keyboard className="px-4" {...props.keyboardConfig} />
                        </div>
                    </div>}
           </div>
}

export default withTranslation()(SetDevicesSettings);