import React from 'react';
import {Container, FormGroup} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleInfo, faCircleCheck, faCircleExclamation, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import NoticeListItem from './NoticeListItem';
import Breams from "../../blocks/Breams";
import MainNav from "../../blocks/MainNav";
import Loader from "../../blocks/Loader";
import Content from "../../blocks/Content";
import Select from '../../blocks/Select';
import { contains } from '../../../helpers/functions';

export default function NoticeList(props) {
    const sort_types = ["Descending order", "Ascending"];
    const types = ["New notifications", "Unread notifications", "warning", "info", "danger", "success", "primary"];
    const sort_type = props.sort_by_date;
    const selected_types = props.selected_types;
    const notifications = [];
    selected_types.forEach(type => {
        switch (type) {
            case "New notifications":
                props.notifications.forEach(el => (new Date() < new Date(Date.parse(el.end_dt))) && notifications.push(el));
                break;
            case "Unread notifications":
                props.notifications.forEach(el => !el.read && notifications.push(el));
                break;
            default:
                props.notifications.forEach(el => (el.type === type) && notifications.push(el));
                break;
          }
    });
    const filter_notifications = selected_types.length === 0 ? props.notifications : notifications;
    filter_notifications.sort((a, b) =>
        (sort_type === "Descending order")
            ? b.start_dt.localeCompare(a.start_dt)
            : a.start_dt.localeCompare(b.start_dt));

    const handleSetNodeIcon = (type) => {
        switch (type) {
            case "success": return <FontAwesomeIcon icon={faCircleCheck} className="display-7 text-success mr-1"/>
            case "danger": return <FontAwesomeIcon icon={faCircleExclamation} className="display-7 text-danger mr-1"/>
            case "warning": return <FontAwesomeIcon icon={faCircleExclamation} className="display-7 text-warning mr-1"/>
            case "primary": return <FontAwesomeIcon icon={faCircleInfo} className="display-7 text-primary mr-1"/>
            default: return <FontAwesomeIcon icon={faCircleInfo} className="display-7 text-info mr-1"/>
          }
    };

    const handleBtnClass = (type) => {
        switch (type) {
            case "New notifications": return "secondary"
            case "Unread notifications": return "dark"
            default: return type
          }
    };

    return <>
            <Breams/>
            <div className={`Screen container ${!props.load.isLoading && "justify-content-start"}`}>
                <Loader isLoading={props.load.isLoading} loadId={"notice-list"} justifyContent="center">
                    <div className={"Screen-Panel p-2"} >
                        <div className="d-flex flex-row justify-content-between">
                            <div className=' w-75'>
                                <span className={`Select-Text mr-3`}>{`${props.t('Show only')}:`}</span>
                                {types.map(el => {
                                    const btn_class = handleBtnClass(el);
                                    return <div onClick={props.onSelectTypes} key={el} id={el}
                                                className={`border p-3 m-1 btn max-content display-9 ${contains(selected_types, el) && `btn-${btn_class}`}`}>
                                            {props.t(el)}
                                           </div> })}
                            </div>
                            <FormGroup className="d-flex align-items-start justify-content-between">
                                <Select onSelectCallback={props.onSelectSortByDate} className="FeedbackAdd-SelectType max-content flex-column"
                                        name="theme_id" textLabel={`${props.t('Sort by date')}:`}>
                                    {sort_types.map(key => <option value={key} key={key} selected={(key === sort_type)}>{props.t(key)}</option>)}
                                </Select>
                            </FormGroup>
                        </div>
                    </div>
                    {filter_notifications.length > 0
                     ? <Content>
                        <Container className="d-flex flex-column align-items-center w-100">
                            {filter_notifications.map((notify) =>
                                <NoticeListItem key={notify.uuid} notify={notify} handleSetNodeIcon={handleSetNodeIcon} navLink={props.navLink}/>)}
                        </Container>
                       </Content>
                     : <h4 className="Text_success font-weight-medium display-8 text-center">{props.t('You have no notifications')}.</h4>}
                </Loader>
            </div>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Personal Area')}</span>
                    </NavLink>
                </Container>
            </MainNav>
        </>
}