import {isElectron} from "react-device-detect";
import axios from "axios";
import configApp from "../config/config";
import {API_DEFAULT_CONFIG, API_ROOT} from "./api-config";
import {makeStrForGetReqFromObj, setLog} from "../helpers/functions";
import store from "../localStorage";
import networkAnswerManager from "../reducers/network-answer-manager";
import { notify } from "../components/blocks/Notify";
import { settingsSource } from '../helpers/source'

let logger;

try {
    if (isElectron) {
      logger = require('electron-log');
    }
} catch (e) {
    console.log(e.message);
}

const NETWORK_ERROR = 'NETWORK_ERROR';

class apiHandling {
  props = {
    name: "apiHandling",
  };

  static handleFailReq = (error) => {
    let fieldValidationErrors = {};
    let err = {};

    if (error.response) {
      err.code = error.response.status;
      err.data = error.response.data;
      err.headers = error.response.config.headers;
      err.url = error.response.config.url;
      if (error.response.config.data) {
        let data = [];
        for(var pair of error.response.config.data.entries()) {
          data.push(pair);
        }
        err.post_text = data;
      }
      if (error.response?.hasOwnProperty("data")) {
        for (let key in error.response.data) {
          if (error.response.data?.hasOwnProperty(key)) {
            fieldValidationErrors[key] = [];
            fieldValidationErrors[key].push(error.response.data[key]);
          }
        }
      }
    } else {
      fieldValidationErrors.error = [];
      fieldValidationErrors.error[0] = error.message;
      err.message = error.message;
      err.headers = (error.config && error.config.headers) ? error.config.headers : {};
      err.url = (error.config && error.config.url) ? error.config.url : {};
      if (error.config && error.config.data) {
        let data = [];
        for(var pair of error.config.data.entries()) {
          data.push(pair);
        }
        err.post_text = data;
      }
    }
    setLog('Ошибка при отправке запроса на сервер', err);

    fieldValidationErrors.typeError = NETWORK_ERROR;
    this.ttfb(error);

    return fieldValidationErrors;
  };

  static ttfb = (response) => {
    const fetchTiming = performance
      .getEntriesByType("resource")
      .find((n) => n.initiatorType === "xmlhttprequest");
    const speed = fetchTiming ? fetchTiming.duration : 0;
    let code = 500;
    if (response.status) {
      code = response.status;
    } else if (!response.status && fetchTiming) {
      if (response.message) {
        const mes_code = response.message.split(" ").map(el => Number(el));
        mes_code.forEach(el => { if (!isNaN(el)) code = el });
      }
    } else {
      code = 0;
    };
    networkAnswerManager.setSpeedAndCode(speed, code);
    //из-за перевода такой костыль
    if (code === 503) {
      const lang = store.getState().localeState.locale;
      let error = '';
      switch (lang) {
        case 'en':
          error = 'Work is underway on the server, the application functions are temporarily disabled';
          break;
        case 'ky':
          error = 'Сервер иштеп жатат, колдонмонун функциялары убактылуу иштебейт';
          break;
        default:
          error = 'На сервере ведутся работы, функции приложения временно не работают';
          break;
      };
      notify(error, 'error');
    };
  };

  static stopSettingsReq = () => {
    if (settingsSource.source) {
      settingsSource.source.cancel('Остановлено руками')
      settingsSource.setSource(null)
    }
  };

  static req = (options) => {
    let handleFailReq = this.handleFailReq;
    let ttfb = this.ttfb;
    let agent = null;

    try {
      const https = require("https");
      agent = new https.Agent({
        rejectUnauthorized: false, // отключим требование https
      });
    } catch (e) {
      // console.log(e.message);
    }

    return new Promise((resolve, reject) => {
      let _options = { ...options };

      if (!_options?.hasOwnProperty("action")) throw new NotHasOption("Required option action");
      if (!_options?.hasOwnProperty("data")) _options.data = {};

      if (configApp.mode_response === "json_local") {
        try {
          const data = require(`./../data/response/public/${_options.action}.json`);
          const response = {
            data: data,
          };
          ttfb(response);
          resolve(response);
        } catch (e) {
          reject(handleFailReq(e));
        }
      } else {
        let config = {
          responseType: "application/json",
          httpsAgent: agent,
          cancelToken: _options.cancelToken || '',
          ...API_DEFAULT_CONFIG,
        };
        let url = ''
        let longRequestTimer;

        if (_options?.hasOwnProperty("method") && _options.method === "post") {
          url = `${API_ROOT}/public/${_options.action}`;
          longRequestTimer = setTimeout(() => setLog('Долгий ответ от сервера', url), 10000);
          axios.post(url, _options.data, config)
            .then((response) => {
              ttfb(response);
              resolve(response);
            })
            .catch((e) => {
              let error = handleFailReq(e)
              if (axios.isCancel(e)) {
                e.handleStop = true
                error = e
              }
              reject(error)
            })
            .finally(() => clearTimeout(longRequestTimer));
        } else {
          const data = makeStrForGetReqFromObj(_options.data);
          url = `${API_ROOT}/public/${_options.action}${data}`;
          longRequestTimer = setTimeout(() => setLog('Долгий ответ от сервера', url), 10000);
          axios.get(url, config)
            .then((response) => {
              ttfb(response);
              resolve(response);
            })
            .catch((e) => {
              let error = handleFailReq(e)
              if (axios.isCancel(e)) {
                e.handleStop = true
                error = e
              }
              reject(error)
            })
            .finally(() => clearTimeout(longRequestTimer));
        }
      }
    });
  };

  /**
   * Выполняет запрос по ссылке, подходит для "чужих" серверов
   * @param link - http://hyperoff.ru
   * @param method - get|post
   * @param data - {}
   * @returns {Promise<unknown>}
   */
  static reqByLink = (link, method = "get", data = {}) => {
    let handleFailReq = this.handleFailReq;
    let ttfb = this.ttfb;

    return new Promise((resolve, reject) => {
      if (!link) throw new NotHasOption("Required option link");

      if (configApp.mode_response !== "json_local") {
        let token = store.getState().userState.token;

        if (token) apiDefaultConfig.headers.Authorization = "JWT " + token;

        let config = {
          responseType: "application/json",
          ...API_DEFAULT_CONFIG,
        };
        let longRequestTimer;

        if (method === "post") {
          longRequestTimer = setTimeout(() => setLog('Долгий ответ от сервера', link), 10000);
          axios.post(link, data, config)
            .then((response) => {
              ttfb(response);
              resolve(response);
            })
            .catch((e) => reject(handleFailReq(e)))
            .finally(() => clearTimeout(longRequestTimer));
        } else {
          const str_data = makeStrForGetReqFromObj(data);
          const url = `${link}${str_data}`;
          longRequestTimer = setTimeout(() => setLog('Долгий ответ от сервера', url), 10000);
          axios.get(url, config)
            .then((response) => {
              ttfb(response);
              resolve(response);
            })
            .catch((e) => reject(handleFailReq(e)))
            .finally(() => clearTimeout(longRequestTimer));
        }
      }
    });
  };
}

/**
 * @param message
 * @constructor
 */
function NotHasOption(message) {
    this.message = message;
    this.name = 'Required option';
}

export default apiHandling;