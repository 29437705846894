import React from "react";
import { isElectron } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import networkAnswerManager from "../../reducers/network-answer-manager";
import SettingsInfo from "../blocks/SettingsInfo";

class SettingsInfoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clock: new Date().toLocaleString(),
      image: this.networkImage(),
      rfid: this.rfid(),
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(this.tick, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  networkImage = () => {
    let speed = networkAnswerManager.getNetworkSpeed();
    let code = networkAnswerManager.getResponseCode();
    if (((code >= 500 && code <= 599) || !code) && Math.ceil(speed) > 0) {
      return "red";
    } else {
      if (
        Math.ceil(speed) === 0 ||
        Math.ceil(speed) === undefined ||
        Math.ceil(speed) === null ||
        code === 0
      ) {
        return "black";
      } else if (Math.ceil(speed) > 500 && code) {
        return "orange";
      } else {
        return "green";
      }
    }
  };

  rfid = () => {
    let color = '';
    if (isElectron && this.props.app.is_rfid) {
      color = this.props.app.isRfidWork ? 'green' : 'red';
    };
    return color;
  };

  tick = () => this.setState({
    clock: new Date().toLocaleString(),
    image: this.networkImage(),
    rfid: this.rfid(),
  });

  render() {
    return <SettingsInfo version={this.props.t("Version")} name={this.props.t("Name")} clock={this.state.clock} networkImage={this.state.image} rfid={this.state.rfid} />
  }
}

const mapStateToProps = (store) => {
  return {
    app: store.appState,
  };
};

export default connect(mapStateToProps)(withTranslation()(SettingsInfoContainer));