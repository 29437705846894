import { faArrowLeft, faCheck, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ExitTimer from "../../blocks/ExitTimer";
import MainNav from "../../blocks/MainNav";

export default function EndTheme(props) {
  let topics_count = 0;
  let complete_topics = 0;

  if (props.activeLessons[0]) {
    if (props.activeLessons[0].topics) topics_count = props.activeLessons[0].topics.length;
    if (props.activeLessons[0].result) complete_topics = props.activeLessons[0].result.count_complete_topics;
  };

  const exitCourse = () =>
    props.location.pathname === "/electronic-courses-class"
    ? props.handleChangeContent("Course", props.activeCourse.appoint.uuid)
    : props.handleChangeContent("AllCourses");
  
  return <>
      <div className="Screen container d-flex flex-column justify-content-center align-items-center">
        <h1 className="preshift__text mb-4">{props.t("Theme completed")}</h1>
        <h1>{complete_topics + props.t('of') + topics_count}</h1>
        <FontAwesomeIcon icon={faCheck} className="Success-Ok mb-5"/>
        <Container className={`d-flex justify-content-center`}>
          <Button onClick={() => {if (props.is_exit_timer) props.handleChangeContent("QuestionStepper")}} className={`Btn Btn_success MuiButton mr-4`}>
            <span>{props.t('Next theme')}</span>
            <ExitTimer theme_name={props.app.theme_name} goOut={() => props.handleChangeContent("QuestionStepper")} handleExitTimer={props.handleExitTimer}/>
          </Button>
          <Button onClick={() => {if (props.is_exit_timer) exitCourse()}} className={`Btn Btn_success MuiButton`}>
            <span>{props.t("Exit the course")}</span>
          </Button>
        </Container>
      </div>
      {props.location.pathname !== "/electronic-courses-class" && props.is_exit_timer &&
        <MainNav className={`main-navi`}>
          <Container className={`d-flex justify-content-end`}>
            <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton mr-4`}>
              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
              <span>{props.t('Personal area')}</span>
            </NavLink>
            <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
              <span>{props.t('Exit')}</span>
            </NavLink>
          </Container>
        </MainNav>}
    </>
}