import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Notices from "../screens/notices/Notices";
import Loader from "../blocks/Loader";
import Breams from "../blocks/Breams";
import MainNav from "../blocks/MainNav";
import { setLog } from "../../helpers/functions";
import { pageLoadManageActions as loader } from "../../reducers/load";
import { addBreamb, resetBreamb } from "../../reducers/breams-manage";

class DemoNoticeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      notice: {},
      colorActions: {
        put_aside: "warning",
        close: "secondary",
        set_read: "primary",
        agree: "success",
        refused: "danger",
      },
    };

    this.getNotice()
    document.title = props.t("Demo Notification");
    resetBreamb();
    addBreamb({ title: this.props.t("Demo Notification") });
  };

  getJson = () => {
    let url = window.location.hash;
    let json = decodeURI(url.replace("#/demo-notification/", ""));
    let obj = {};
    let actions_from_obj = {};
    try {
      obj = JSON.parse(json);
      actions_from_obj = obj.actions;
    } catch (e) {
      setLog('Ошибка парсинга JSON на демо уведомлении', e);
    }

    let notice = {
      title: obj.title ? obj.title : "Заголовок отсутствует",
      description: obj.description ? obj.description : "Описание отсутствует",
      content: {
        content: obj.content?.content ? obj.content.content : "Фото/видео/документ",
        "content-type": (obj.content && obj.content['content-type']) ? obj.content['content-type'] : "video"
      },
      actions: actions_from_obj.length > 0
      ? actions_from_obj
      : [
          {
            name: "agree",
            title: "Действие отсутствует",
            callbacks: ["/notifications/5fd04017-ede8-4844-ad47-5400e031a263/agree/3828"]
          }
        ]
    };
    return new Promise((resolve, reject) => resolve(notice))
  };

  getNotice = () => {
    loader.start("demo-notice");
    return new Promise((resolve, reject) => {
      this.getJson()
      .then(notice => {
        this.setState({notice: notice});
        resolve();
      }).finally(() => setTimeout(() => {
            this.setState({load: false});
            loader.stop("demo-notice");
          }, 1000))
    })
  };

  onSelectAction = (action_id) => console.log("Выполнение действия: " + (action_id + 1));

  render() {
    return <Loader
              isLoading={this.state.load}
              loadId={"demo-notice"}
              justifyContent="center"
              className="h-100" >
              <Breams />
              <Notices {...this.state} {...this.props}
                onSelectAction={this.onSelectAction}
                notice={this.state.notice} />
              <MainNav>
                <Container className={`d-flex justify-content-end`}>
                  <NavLink to={"/"} className={`Btn Btn_danger MuiButton`} >
                    <FontAwesomeIcon className="mr-4" icon={faSignOutAlt} />
                    <span>{this.props.t("Exit")}</span>
                  </NavLink>
                </Container>
              </MainNav>
            </Loader>
  };
};

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps)(withRouter(withTranslation()(DemoNoticeContainer)));