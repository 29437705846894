import React from 'react';
import Lottie from "lottie-react";
import Loader from "../../blocks/Loader";
import animationData from "../../../data/icons/unlock.json";

export default function Rfid(props) {
    const message = props.message;
    const textStatus = message ? message : props.t('To enter, bring the RFID key to the reader');
    const classStatus = message ? 'RfidScreen-Text_danger' : 'RfidScreen-Text-success';
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className={"screen"}>
            <Loader isLoading={props.isLoading} loadId={"item"} justifyContent="center">
                <div className="RfidScreen px-4 d-flex align-items-center flex-column justify-content-center h-100">
                    <div className="preshift__lottie mb-5">
                        <Lottie className="preshift__lottie mb-3" {...defaultOptions} style={{height: '20rem', width: '20rem'}} />
                    </div>
                    <h4 className={`RfidScreen-Text text-cot-green text-center ${classStatus}`}>{textStatus}</h4>
                </div>
            </Loader>
           </div>
};