import store from "../localStorage";

const initialState = {
    isLoading: false,
    idsLoad: []
}

export default function loadManage(state = initialState, action) {
    switch (action.type) {
        case 'PAGE_LOADING':
            return {...state, idsLoad: state.idsLoad.concat(action.loadId), isLoading: true};
        case 'PAGE_LOADED':
            let idsLoad = state.idsLoad.filter(obj => action.loadId !== obj);
            let isLoading = true;
            if (!idsLoad.length) isLoading = false;
            return {...state, idsLoad: idsLoad, isLoading: isLoading};
        default:
            return state;
    }
}

export class pageLoadManageActions {
    // snow the spinner
    static start = (loadId) => store.dispatch({type: 'PAGE_LOADING', loadId: loadId});
    // hide the spinner
    static stop = (loadId) => store.dispatch({type: 'PAGE_LOADED', loadId: loadId});
}
