import React, {Component} from 'react';
import QuestionNotifyScreen from '../../screens/questions/QuestionNotifyScreen';

export default class QuestionNotifyContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            simple_quest_num: 0,
            extra_quest_num: 0,
            time: 5
        };

        this.intervalID = setInterval(() => {
            this.setState({time: this.state.time - 1});
            if (this.state.time === 0) this.props.handleCloseQuestionNotify();
        }, 1000);
    };

    componentDidMount() {
        this.prepareData();
    };

    componentWillUnmount() {
        clearInterval(this.intervalID);
    };

    prepareData = () => {
        let simpleQuestNum = 0;
        let extraQuestNum = 0;
        this.props.educations.forEach(el => {
            if (el.type === "simpleCheck") {
                el.listStep.forEach(step => {
                    if (step.name === "question") simpleQuestNum += 1;
                });
            };
            if (el.type === "extra_education_flow") {
                el.listStep.forEach(step => {
                    if (step.name === "question") extraQuestNum += 1;
                });
            }
        });

        this.setState({
            simple_quest_num: simpleQuestNum,
            extra_quest_num: extraQuestNum,
        });
    };

    render() {
        return <QuestionNotifyScreen {...this.state} t={this.props.t} handleCloseQuestionNotify={this.props.handleCloseQuestionNotify} />
    };
};