import React from "react";
import {NavLink} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileAlt, faFilePdf, faFileVideo, faPen } from "@fortawesome/free-solid-svg-icons";

function LibraryItem(props) {
    const selectIcon = (type) => {
        switch (type) {
            case "video_tutorial": return faFileVideo;
            case "simulated_situation": return faFileVideo;
            case "text_document": return faFilePdf;
            default: return faFileAlt;
        }
    };
    const text_status = props.important ? props.t('Important') : '';
    const font_icon = selectIcon(props.type);

    return <div>
            <NavLink to={`/library/material/${props.uuid}`}
                className="LibraryItem d-flex flex-column justify-content-around align-items-center w-100"
                style={{color: "#212529", textDecoration: "none"}} >
                <div className=" d-flex justify-content-center align-items-center" style={{height: 150}}>
                    <FontAwesomeIcon icon={font_icon} className="text-center display-1 mb-2"/>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100" style={{backgroundColor: "white"}}>
                    <div className="d-flex justify-content-between align-items-center">
                        {!props.unread && <FontAwesomeIcon icon={faEye} className="display-9 mr-2"/>}
                        {props.hasOwnProperty('signed') &&
                            <FontAwesomeIcon icon={faPen} className={`display-9 mr-2 ${props.signed ? 'text-success' : 'text-danger'}`}/>}
                        <div className={"display-9 LibraryText"} title={props.title}>{props.title}</div>
                    </div>
                    {text_status && <div className={"display-9 LibraryItem-Status LibraryItem-Status_success"}>{text_status}</div>}
                </div>
            </NavLink>
           </div>
}

export default withTranslation()(LibraryItem);