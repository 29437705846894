import React from "react";
import { connect } from "react-redux";
import apiScreensavers from "../../api/apiScreensavers";
import userManage from "../../reducers/user-manage";
import ScreensaverModal from "../blocks/Screensaver/ScreensaverModal";
import { setLog } from "../../helpers/functions";

class ScreensaverContainer extends React.Component {
  inititialState = {
    screensaver: false,
    time: 300,
    type: null,
    file: null,
    params: null,
    localeId: null,
}
  constructor(props) {
    super(props);

    this.state = {
      ...this.inititialState,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.app.screensaver &&
        (prevProps.app.screensaver_uuid !== this.props.app.screensaver_uuid ||
        prevProps.app.screensaver_last_update_time !== this.props.app.screensaver_last_update_time)) {
      this.getScreensaversData(this.props.app.screensaver_uuid);
    };
    if (this.props.app.screensaver &&
        prevProps.app.show_the_screensaver_later !== this.props.app.show_the_screensaver_later) {
      const interval = this.props.app.show_the_screensaver_later;
      interval && this.setState({time: interval});
    };
  };

  componentWillUnmount() {
    this.setState(this.inititialState);
  };

  getScreensaversData(uuid) {
    apiScreensavers.getScreensavers(uuid)
    .then(response => {
      this.setState({
        screensaver: this.props.app.screensaver,
        type: response.data.type,
        file: response.data.file,
        params: response.data.params
      })
    })
    .catch(e => setLog('Ошибка загрузки заставки', e))
  };

  handleTimeExpired = () => userManage.logout();

  render() {
    if (!this.props.isLoading) {
      return <ScreensaverModal {...this.state} theme_name={this.props.app.theme_name} handleTimeExpired={this.handleTimeExpired} />
    } else {
      return <></>;
    }
  };
};

const mapStateToProps = (store) => {
  return {
    app: store.appState,
    isLoading: store.loadState.isLoading,
  };
};

export default connect(mapStateToProps)(ScreensaverContainer);