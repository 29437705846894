import React from 'react';
import { useTranslation } from 'react-i18next';
import survey_img from '../../../../assets/survey.png';

export default function QuestionnaireNotify(props) {
    const { t } = useTranslation();
    return (
        <div className={"Screen container"}>
            <div className='d-flex flex-column justify-content-center align-items-center h-100 text-muted text-center py-5'>
                <h1 className='display-3 font-weight-bold Text_success'>{t('Attention').toUpperCase()}</h1>
                <h1 className='display-3 font-weight-bold Text_success'>{t('to improve the quality of express training')}</h1>
                <h1 className='display-3 font-weight-bold Text_success' >{t('Ask you').toUpperCase()}</h1>
                <h1 className='display-3 font-weight-bold Text_success'>{t('answer a few questions')}</h1>
                <span className='display-8 mt-4'>{t('Survey will start')}: <b className='text-success'>{props.time}</b></span>
            </div>
        </div>
    )
};