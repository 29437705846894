import React from "react";

export default function Person(props) {
      let damageBodyParts = [];
      damageBodyParts = props.body_parts.map(part => part.name);

      return <svg className={"failVisual__person"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.04 583.68">
                  <g id="Слой_2" data-name="Слой 2">
                    <g id="Слой_1-2" data-name="Слой 1">
                        <path className="HIP_JOINT" fill={damageBodyParts.indexOf( 'hip-joint' ) !== -1 ? 'red' : 'grey'}
                              d="M93.17,418.38C80.94,394,77.21,392.54,59.4,403.7a1.38,1.38,0,0,1-2.11-1c-.93-7.24-2-14.26-2.55-21.33-2.66-33.7-3.13-67.34,3.56-100.74,1.16-5.77,2.94-6.71,8.24-3.94C99.7,294,132.78,294,165.45,275.29c3.41-2,4.62-1.24,5.28,2.37,4,22.12,7,44.28,5.86,66.92-.86,17.16-1.62,34.3-3.21,51.41-.23,2.52.08,5.18-1.27,7.82a1.39,1.39,0,0,1-2.17.4c-6.42-5.87-13.1-11.07-22.25-4-5.83,4.49-8.92,10.34-10.93,16.92a1.38,1.38,0,0,1-2.69-.17c-1.83-11.86-3.59-23.73-5.62-35.55-3-17.36-6.17-34.69-9.45-52-.59-3.08-.57-8.07-4-8.19-3.82-.13-3.69,4.93-4.11,7.91-4,28.35-11.54,56.12-14.35,84.68a33.72,33.72,0,0,1-.74,4.24A1.38,1.38,0,0,1,93.17,418.38Z"/>
                        <path className="CHEST" fill={damageBodyParts.indexOf( 'chest' ) !== -1 ? 'red' : 'grey'}
                              d="M99.2,101.88c5.8,10.84,22.56,13.59,28.65,1.79,2.74-5.3,4.93-5.88,9.61-1,6.4,6.62,13.39,12.62,18.86,20.17a16.44,16.44,0,0,0,13,6.91c3.35.13,4,1.72,3.71,4.65-.91,8.31-1.66,16.65-2.53,25-.46,4.42-2.45,7.75-6.66,9.75-9.2,4.38-18.52,7.94-29,7.74-11.66-.23-23.34-.06-35,.09-10.07.14-19.62-2.2-29.06-5.39-7.61-2.57-12-7.48-12.45-15.69-.29-5.05-.84-10.09-1.26-15.13C56.55,134.6,55.54,129,65,128.24c6.26-.52,9.28-7.57,13.72-11.72,1.74-1.63,3.2-3.57,4.79-5.38a64,64,0,0,0,8.18-7.51C93.92,101.37,96.19,100.29,99.2,101.88Z"/>
                        <path className="BELLY_UP" fill={damageBodyParts.indexOf( 'belly-up' ) !== -1 ? 'red' : 'grey'}
                              d="M166.92,171.54a.71.71,0,0,1,1,.76c-2.08,12.14-6.19,23.24-5.07,35,.18,2,.08,4,.37,5.93,1.62,11.16,1.51,10.76-8.89,14.14-25.48,8.27-51.21,6.86-77,2.65-10.1-1.65-11.92-3.64-11-13.73a111.4,111.4,0,0,0-4.82-43.47c2-1.88,3.69,0,5.34.71,9.38,3.95,19,6.74,29.3,6.6,14.53-.2,29.07.08,43.6-.19C149.19,179.82,157.66,176,166.92,171.54Z"/>
                        <path className="RIGHT_SHIN" fill={damageBodyParts.indexOf( 'right-shin' ) !== -1 ? 'red' : 'grey'}
                              d="M74,537.34a1.1,1.1,0,0,1-1.73.68c-5-3.56-9.62-4.85-14.83-2.61-2.9,1.25-3.34-.2-3.54-2.62-1.5-17.57-3.24-35.11-4.59-52.69a108.82,108.82,0,0,1,3.73-37.49c2.21-7.93,4.11-15.82,4.21-24.2.1-8,4.11-12.12,11.3-14.39s12,1.08,15.81,6.41c6.58,9.09,9.21,18.87,4.44,29.73-1.33,3-2.94,6.15-2.61,9.47,2.52,26-5.64,50.52-9.86,75.58C75.72,529.05,74.92,532.85,74,537.34Z"/>
                        <path className="LEFT_SHIN" fill={damageBodyParts.indexOf( 'left-shin' ) !== -1 ? 'red' : 'grey'}
                              d="M180,472.41c.81,20.66-3.7,40.41-4,60.45,0,2.17-.66,3.59-3.16,2.19-4.75-2.68-9.3-1.88-13.78.71-2.31,1.34-3.75.72-4.08-2-2.38-19.91-8.78-39.06-11.13-59a140.76,140.76,0,0,1-.64-18.46,58.69,58.69,0,0,0-3.1-19.36c-3.62-11.14.85-21.28,7.15-30.28a9,9,0,0,1,11.87-3c5.49,2.91,12.61,5,12.84,12.72.27,8.95,2.43,17.42,4.85,25.88A87.51,87.51,0,0,1,180,472.41Z"/>
                        <path className="HEAD" fill={damageBodyParts.indexOf( 'head' ) !== -1 ? 'red' : 'grey'}
                              d="M120.46,77.25a10.23,10.23,0,0,1-11,.42A56.17,56.17,0,0,1,104.27,74C98.37,69.65,92.06,64.9,92.7,56.1c.18-2.53-1.72-3.22-3.16-4.51-5.59-5-5.77-11.68-.62-17.26,1.44-1.55.42-3.37.44-5.06.23-17.71,11.49-30.18,27-29.22,5.63.35,10,2.77,14.37,7.46,6.81,7.33,10.63,14.79,9.69,24.69a2.6,2.6,0,0,0,.72,2.3,11.4,11.4,0,0,1-.73,16.88,9.64,9.64,0,0,0-3.59,5.93C135.24,65.75,125.91,73.49,120.46,77.25Z"/>
                        <path className="BELLY_DOWN" fill={damageBodyParts.indexOf( 'belly-down' ) !== -1 ? 'red' : 'grey'}
                              d="M164.26,252.78a1.75,1.75,0,0,1,2.37,1.41c.13,1.06.25,2,.44,2.89,2.59,12.63,2.8,13.22-8.75,18.59-30.64,14.25-61,13.88-90.68-2a13.28,13.28,0,0,1-5.44-17.79c2.39-4.45,4.34-2.2,6.2-1.62,17.32,5.38,35,8.44,53.14,7.63A134.67,134.67,0,0,0,164.26,252.78Z"/>
                        <path className="LEFT_FOREARM" fill={damageBodyParts.indexOf( 'left-forearm' ) !== -1 ? 'red' : 'grey'}
                              d="M173.42,167.78a402.48,402.48,0,0,0,3.92-60.58c0-3.93,1.79-3.91,4.51-2.53,6.71,3.41,10.51,9.11,12.76,16.1,3.41,10.58,3.93,21.61,5,32.53a207.77,207.77,0,0,0,5,29.26c.88,3.59.63,5.79-3.48,6.89-6.7,1.79-9.19,7.72-12.12,13.18-.9,1.66-1.73,4.16-4,3.81-1.81-.27-2-2.68-2.38-4.32C180.3,190.47,173.88,179.92,173.42,167.78Z"/>
                        <path className="RIGHT_FOREARM" fill={damageBodyParts.indexOf( 'right-forearm' ) !== -1 ? 'red' : 'grey'}
                              d="M56.33,167.78c-1.2,13.28-7.21,24.37-9.84,36.41-.86,3.95-3.31,2.93-4.93.35s-2.78-5.5-4.24-8.21c-1.61-3-3.82-5.81-7.15-6.49-5.7-1.17-6.11-4.33-4.71-9,5.06-17,4-34.92,7.76-52.11,1.76-8,4-15.88,10.56-21.5,1.84-1.57,4-3.64,6.4-2.81,2.9,1,1.42,4.2,1.57,6.43C52.91,129,52.89,147.32,56,165.38,56.14,166.46,56.29,167.54,56.33,167.78Z"/>
                        <path className="BELLY" fill={damageBodyParts.indexOf( 'belly' ) !== -1 ? 'red' : 'grey'}
                              d="M117.16,258.33c-14.71-1.09-29.39-2.2-43.8-5.81-6.72-1.69-9.21-5.14-8.6-12.17.87-10.12.31-9.53,9.95-7.53C96,237.21,117.43,238.57,139,235c6.26-1.05,12.49-2.29,18.72-3.48,4.54-.87,6.61.19,6.7,5.56.18,10.77.64,11-9.75,14.68C142.57,256,130.19,259.11,117.16,258.33Z"/>
                        <path className="RIGHT_UPPER_ARM" fill={damageBodyParts.indexOf( 'right-upper-arm' ) !== -1 ? 'red' : 'grey'}
                              d="M23.4,263a2.08,2.08,0,0,1-3.57.24c-2.67-3.89-5.33-7.28-10.42-8-2.42-.34-1.8-2.81-1.54-4.42,2.88-18.27,5.5-36.63,13.34-53.66,2.8-6.08,6.28-7,10.41-2.06,4.67,5.66,9.29,11.92,11.7,18.71,2.34,6.61-1.75,13.17-5,19.32C33.32,242.82,28.51,252.67,23.4,263Z"/>
                        <path className="LEFT_UPPER_ARM" fill={damageBodyParts.indexOf( 'left-upper-arm' ) !== -1 ? 'red' : 'grey'}
                              d="M209.54,263a1.93,1.93,0,0,1-3.23-.32c-6.09-12.08-11.88-23.35-17.41-34.75-3.6-7.44-5.34-14.78,1.65-21.82,3-3,3.83-7.94,7.22-10.95,4.81-4.26,8.22-3.81,10.8,2,7.6,17.06,10.37,35.4,13.56,53.61.3,1.7.4,3.95-1.86,4.36C215.48,256,212.56,259.17,209.54,263Z"/>
                        <path className="RIGHT_FOOT" fill={damageBodyParts.indexOf( 'right-foot' ) !== -1 ? 'red' : 'grey'}
                              d="M74.09,560.7c0,4.63.15,9.26,0,13.88-.24,5.93-2.84,9.43-9.47,9.08-6.11-.33-12.2-1.62-18.38-.39a4,4,0,0,1-4.73-2.75c-.63-1.81-.52-3.88,1.05-4.84,9.13-5.57,8.89-15,10.07-23.82.31-2.4.76-4.79,1.29-7.15,1.17-5.34,4.12-8.85,10-8.46,5,.33,9.82,5.41,10.08,10.58.24,4.61,0,9.25,0,13.87Z"/>
                        <path className="LEFT_FOOT" fill={damageBodyParts.indexOf( 'left-foot' ) !== -1 ? 'red' : 'grey'}
                              d="M155.38,568.74c-1-7.46.11-14.84.79-22.25.56-6.23,4.44-9.23,9.85-9.57s9.21,2.78,9.95,8.47,2.51,11,2.32,16.88c-.15,4.8,4.05,8,7.14,11.43,1.72,1.9,4.31,3.59,2.92,6.71s-4.56,3.42-7.37,2.82c-4.45-.94-8.73.19-13.09.35-9.8.38-11.61-1.24-12.26-10.9C155.54,571.37,155.46,570.05,155.38,568.74Z"/>
                        <path className="LEFT_HAND" fill={damageBodyParts.indexOf( 'left-hand' ) !== -1 ? 'red' : 'grey'}
                              d="M211.38,292.62c-.61,2.85-.82,4.34-1.26,5.77-.6,2-.26,5.21-3.1,5-2.59-.18-2.57-3.39-2.76-5.39A50.49,50.49,0,0,1,213,264.3c1.72-2.57,4.3-4.86,7.66-4.31,3.82.63,3,4.46,3.59,7,1.82,7.69,2.78,15.62,5.06,23.16,3.08,10.22-4.44,21-12.62,26.9-2.85,2.05-4.24.19-4.12-2.47.15-3,1.12-6,1.53-9.08C214.67,301.48,215.56,297.32,211.38,292.62Z"/>
                        <path className="RIGHT_HAND" fill={damageBodyParts.indexOf( 'right-hand' ) !== -1 ? 'red' : 'grey'}
                              d="M0,291.91c1.76-8,3.93-18.29,6.33-28.54,1.16-5,4.52-4.85,7.13-1.67,8.78,10.73,13.92,22.83,11.9,37.08-.25,1.82-.12,4.54-2.74,4.58s-2.4-2.57-2.75-4.48.45-4.81-2.18-4.7-3.06,3.16-2.84,5.25c.45,4.34,1.58,8.61,2.19,12.95.27,1.84.94,4.22-1.4,5.25-1.89.82-3.27-.77-4.59-1.9C4.47,310.09-.29,303.5,0,291.91Z"/>
                        <path className="RIGHT_SHOULDER" fill={damageBodyParts.indexOf( 'right-shoulder' ) !== -1 ? 'red' : 'grey'}
                              d="M87.52,85.1a2.49,2.49,0,0,1,3.56,1c4.24,8.23,3.57,8.81-3.47,16-6.31,6.44-12.46,13-18.63,19.59a12,12,0,0,1-5.45,3.52c-6.16,1.86-7.35,1-7.57-5.37C55.26,99,55.26,99,74.67,90.56,78.87,88.74,83.43,87.7,87.52,85.1Z"/>
                        <path className="LEFT_SHOULDER" fill={damageBodyParts.indexOf( 'left-shoulder' ) !== -1 ? 'red' : 'grey'}
                              d="M173,119.06c-.1,1.44,1.07,4.93-1.64,6.66-2.92,1.86-5.91-.48-8.57-1.82a12,12,0,0,1-4.43-3.86c-5.63-8.6-12.9-15.64-20.9-21.87-5.89-4.59-.72-8.1.72-11.73,2-4.94,4.62-.58,6.71.25,6.9,2.75,13.61,6,20.52,8.72,4.89,2,7.28,5,6.92,10.44C172.06,109.78,172.68,113.76,173,119.06Z"/>
                        <path className="NECK" fill={damageBodyParts.indexOf( 'neck' ) !== -1 ? 'red' : 'grey'}
                              d="M131.73,73.12a1.21,1.21,0,0,1,2,.52c1.55,5.53,2.18,10.46-1.36,15.42-2.67,3.76-4.69,8-7.15,11.89-3,4.76-7.4,7-12.93,5.88-10-2-20.36-21.34-16.7-30.81.9-2.34,1.64-3.09,4.07-1.06C114.3,87.1,117.52,87.2,131.73,73.12Z"/>
                        <path className="CHEST" fill={damageBodyParts.indexOf( 'chest' ) !== -1 ? 'red' : 'grey'}
                              d="M99.2,101.88c-6.91.24-9.76,7.38-15.7,9.26,3.77-3.76,7.48-7.6,11.34-11.28C97.74,97.1,98.81,98.9,99.2,101.88Z"/>
                    </g>
                </g>
            </svg>
}