import React from "react";
import {Animated} from "react-animated-css";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {addBreamb, resetBreamb} from "../../reducers/breams-manage";
import apiEmployee from "../../api/apiEmployee";
import NoticesContainer from "../containers/NoticesContainer";
import MainNav from "../blocks/MainNav";
import Loader from "../blocks/Loader";
import {pageLoadManageActions as loader} from "../../reducers/load";
import { errorNotify } from "../../helpers/functions";
import {clearNotifications} from "../../reducers/user";
import ManageStepper from "./ManageStepper";
import Breams from "../blocks/Breams";
import StepperNav from "../blocks/StepperNav";

class NoticesStepper extends ManageStepper {
    constructor(props) {
        super(props);

        props.history.push("/notices");

        this.state = {
            activeStep: 0,
            notifications: [],
            currentTitle: '',
            listStep: [],
            nextTitle: '',
            stepContent: null,
            listContent: {
                notice: NoticesContainer
            },
            isVisible: true,
        };

        document.title = props.t('Notices');
        resetBreamb();
    }

    async componentDidMount() {
        let user_uuid = this.props.user.data.uuid;
        let notifications = [];

        if (this.props.user?.data?.hasOwnProperty('block_notifications') &&
            this.props.user.data.block_notifications.length > 0) {
            notifications = this.props.user.data.block_notifications;
        } else {
            loader.start('stepper');
            try {
                let response = await apiEmployee.getNotifications(user_uuid);
                notifications = response.data.notifications;
            } catch (e) {
                errorNotify(this.props.t('Error loading important notifications'), e, this.props.history.push, this.props.user.isAuth);
            } finally {
                loader.stop('stepper');
            }
        }

        if (notifications.length > 0) this.prepareToRender(notifications).then(this.renderBreamb);
    }

    /**
     * Подготавливает рендер к текущему шагу: устанавливает хлеб.крошки, заголовки, контент...
     */
    prepareToRender = (data) => {
        return new Promise((resolve, reject) => {
            let listStep = this.listStep(data),
                notifications = this.sortNotices(data),
                stepContent = this.stepContent(listStep);

            this.setState({
                listStep: listStep,
                notifications: notifications,
                stepContent: stepContent,
            }, resolve);
        })
    }

    renderBreamb = () => addBreamb({title: this.props.t("Important Notice")});

    sortNotices = (notifications) => {
        let tempListStep = [];

        for (let item in notifications) {
            let step = notifications[item];
            tempListStep.push(step);
        }

        notifications = tempListStep.slice(0);
        notifications.sort((a, b) => a.priority - b.priority);

        return notifications;
    }

    /**
     * Возвращает шаги в одном массиве
     * @returns {*[]}
     */
    listStep = (notifications) => {
        let listStep = [];

        notifications.forEach((notification) => {
            listStep = listStep.concat([{
                title: notification.title,
                container: this.state.listContent['notice'],
                blocked: notification.blocked,
            }]);
        });

        return listStep;
    }

    /**
     * Возвращает заголовок следующего шага
     * @returns {string}
     */
    nextTitle = () => {
        return '';
    }

    /**
     * Возвращает контент текущего шага
     * @param listStep
     * @returns {null}
     */
    stepContent = (listStep) => {
        let stepContent = null;

        if (listStep.length > 0) {
            listStep.forEach((step, indexStep) => {
                if (indexStep === this.state.activeStep) {
                    stepContent = listStep[indexStep].container;
                    return true;
                }
            });
        }

        return stepContent;
    }

    /**
     * Обработчик перехода на следующий шаг
     */
    handleNextStep = () => {
        let activeStep = this.getActiveStep();

        if (activeStep < this.state.notifications.length - 1) {
            this.handleNext();
            let activeStep = this.state.activeStep;
            activeStep++;

            this.setState({
                isVisible: false,
            }, () => {
                setTimeout(() => {
                    resetBreamb();
                    this.setState({
                        activeStep: activeStep,
                        isVisible: true,
                    }, () => this.renderBreamb());
                }, 500);
            });
        } else {
            this.setState({
                isVisible: false,
            }, () => {
                setTimeout(() => {
                    resetBreamb();
                    this.setState({
                        isVisible: true,
                    }, () => this.handleEndStepper());
                }, 500);
            });
        }
    }

    handleEndStepper = () => {
        clearNotifications();
        this.toBackScreen();
    }

    renderAdditionalButton = (button) => this.setState({additionalButton: button});

    toBackScreen = () => this.props.history.goBack();

    render() {
        let StepContent = this.state.stepContent,
            Content = null;

        if (this.state.notifications.length > 0) {
            Content = <div className="ModalBlockNotices_div" >
                <Breams/>
                <Animated
                    className="d-flex flex-column h-100 w-100"
                    animationInDuration={500}
                    animationOutDuration={500}
                    animationIn="slideInRight"
                    animationOut="slideOutLeft"
                    isVisible={this.state.isVisible}>
                    <StepContent
                        key={this.state.activeStep}
                        notifications={this.state.notifications}
                        listStep={this.state.listStep}
                        activeStep={this.state.activeStep}
                        renderAdditionalButton={this.renderAdditionalButton}
                        handleEndStepper={this.handleEndStepper}
                        handleNextStep={this.handleNextStep}
                    />
                </Animated>
                {this.state.listStep.length > 1 &&
                    <StepperNav
                        titleNextStep={this.state.nextTitle}
                        activeStep={this.state.activeStep}
                        listStep={this.state.listStep}
                        additionalButton={this.state.additionalButton} />}
            </div>

        } else {
            Content = <>
                <div className={"Screen container"}>
                    <div className="preshift d-flex align-items-center flex-column justify-content-center h-100">
                        <h4 className={`preshift__text text-center Text_success`}>{this.props.t('No notifications')}</h4>
                    </div>
                </div>
                <MainNav>
                    <Container className={`d-flex justify-content-between`}>
                        <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                            <span>{this.props.t('Personal Area')}</span>
                        </NavLink>
                    </Container>
                </MainNav>
            </>
        }

        return <Loader className="h-100" isLoading={'stepper'} loadId={"stepper"} justifyContent="center">
                {Content}
               </Loader>
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(NoticesStepper)));