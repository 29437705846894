import store from "../localStorage";
import {SAVE_USER_DATA, CLEAR_USER_DATA, PIN_CODE_SUCCESS, SET_PIN_CODE, SET_USER_INTERVAL, CLEAR_USER_INTERVAL} from "./user";
import apiEmployee from "../api/apiEmployee";

let timeOutGetNotify;

export default class userManage {
    static logout = () => {
        store.dispatch({type: CLEAR_USER_DATA});
        clearInterval(timeOutGetNotify);
    }
    static clearIntervalGetExtData = () => {
        store.dispatch({type: CLEAR_USER_INTERVAL});
        clearInterval(timeOutGetNotify);
    };
    static getData = () => store.getState().userState.data;
    static getUuid = () => userManage.getData().uuid;
    static authorized = (data) => new Promise((resolve, reject) => {
            store.dispatch({type: SAVE_USER_DATA, data: data});
            resolve(true);
        });
    static pinCodeAuthorized = () => store.dispatch({type: PIN_CODE_SUCCESS});
    static setPinCode = (data) => store.dispatch({type: SET_PIN_CODE, data: data});
    static setIntervalGetExtData = () => {
        const userData = this.getData();
        const isInterval = store.getState().userState.isInterval;
        if (!isInterval) {
            store.dispatch({type: SET_USER_INTERVAL});
            timeOutGetNotify = setInterval(() =>
                apiEmployee.auth(userData.uuid, true)
                .then(response => {
                    const res_number = response.data[0].number;
                    const userState = store.getState().userState;
                    const isAuthNow = userState.isAuth;
                    const number = userState.data.number;
                    if (isAuthNow && number === res_number) {
                        this.authorized(response.data[0]);
                    } else {
                        clearInterval(timeOutGetNotify);
                    };
                })
                .catch(()=>{}), 120000);
        }
    }
}