import React, {Component} from "react";
import {isElectron} from "react-device-detect";
import {Animated} from "react-animated-css";
import {connect} from "react-redux";
import Keyboard from "react-simple-keyboard";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faChevronDown, faChevronUp, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import QuestionnaireQuestion from "../screens/questions/questionnaires/QuestionnaireQuestion";
import Content from "../blocks/Content";
import {notify} from "../blocks/Notify";
import Modal from "../modals/Modal";
import BootstrapModal from "../modals/BootstrapModal";
import modalManage from "../../reducers/modal-manage";
import {addBreamb} from "../../reducers/breams-manage";

class QuestionnairesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showKeyboard: false,
            uuid: props.survey.uuid,
            title: props.survey.title,
            description: props.survey.description,
            questionnaires: props.survey.elements.sort((a, b) => +a.position - +b.position),
            isVisible: true,
            results: [],
            input: '',
            importants: [],
            localeKeyboard: props.locale.locale,
            keyboardConfig: {
                layoutName: props.locale.locale,
                layout: {
                    'ru_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'ru': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'en': [
                        'Q W E R T Y U I O P {backspace}',
                        '{lock} A S D F G H J K L -',
                        '{lang} {numbers} Z X C V B N M',
                        '{space}'
                    ],
                    'en_lower': [
                        'q w e r t y u i o p {backspace}',
                        '{lock} a s d f g h j k l -',
                        '{lang} {numbers} z x c v b n m',
                        '{space}'
                    ],
                    'ky_lower': [
                        'ё й ц у к е н г ш щ з х ъ ө {backspace}',
                        '{lock} ф ы в а п р о л д ж э ү -',
                        '{lang} {numbers} я ч с м и т ь б ю ң',
                        '{space}'
                    ],
                    'ky': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ Ө {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э Ү -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю Ң',
                        '{space}'
                    ],
                    'symbols': [
                        '[ ] { } # % ^ * - + =',
                        '_ \/ | ~ < > $ € •',
                        '{lang} {abc} {numbers} . , ? ! \'',
                        '{space}'
                    ],
                    'numbers': [
                        '1 2 3 4 5 6 7 8 9 0',
                        '- \/ : ; ( ) ₽ & @ "',
                        '{lang} {abc} {symbols} . , ? ! \'',
                        '{space}'
                    ]
                },
                display: {
                    "{backspace}": '<',
                    "{enter}": props.t('Search'),
                    "{space}": props.t('Space'),
                    "{lock}":  "A->a", // String.fromCharCode(55358, 56389), // "U+1F845", // "&#129093", // 🡅,
                    "{lang}": props.locale.locale.toUpperCase(),
                    "{symbols}": '#+=',
                    "{abc}": props.t('ABC'),
                    "{numbers}": '123'
                },
                theme: 'theme_cot theme_cot_search-emp',
                onChange: this.onChangeKeyboard,
                onKeyPress: this.onKeyPress,
                keyboardRef: r => (this.keyboard = r),
            },
        };
        addBreamb({title: props.survey.title});
    }

    onChangeKeyboard = (value) => this.handleChangeText(this.state.input, value);

    handleChangeInput = (question_uuid, value) => {
        this.setState({input: question_uuid}, () => this.keyboard && this.keyboard.setInput(value));
        isElectron && !this.state.showKeyboard && this.setState({showKeyboard: true});
    };

    onKeyPress = (button) => {
        let keyboardConfig = this.state.keyboardConfig;
        if (button === "{lock}") {
            keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard
                ? `${this.state.localeKeyboard}_lower`
                : this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{lang}") {
            modalManage.show('selectLangKeyboard');
        } else if (button === "{symbols}") {
            keyboardConfig.layoutName = 'symbols';
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{abc}") {
            keyboardConfig.layoutName = this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{numbers}") {
            keyboardConfig.layoutName = 'numbers';
            this.setState({keyboardConfig: keyboardConfig});
        }
    };

    handleSelectLang = (lang) => {
        let keyboardConfig = this.state.keyboardConfig;
        keyboardConfig.display = {...keyboardConfig.display, "{lang}": lang.toUpperCase()}
        keyboardConfig.layoutName = lang;
        this.setState({
            localeKeyboard: lang,
            keyboardConfig: keyboardConfig,
        });
        modalManage.close('selectLangKeyboard');
    };

    handleSendResults = (results) => this.props.sendResults(results);

    handleSearchResultsAnswer = (uuid) => {
        let answer = '';
        this.state.results.forEach(el => {
            if (el.question === uuid) answer = el.answer;
        });
        return answer;
    };

    handleSubmit = () => {
        const importants = [];
        const results = this.state.results;
        const results_uuids = results.length > 0 ? results.map(el => el.question) : [];
        this.state.questionnaires.forEach(quest => {
            if (quest.important) {
                const quest_uuid = quest.uuid;
                const isAnswered = results_uuids.includes(quest_uuid);
                const type = quest.answers ? quest.answers[0].type : '';
                if (!isAnswered && type !== 'switch') { // type === 'switch' всегда должен быть не важным
                    importants.push(quest_uuid);
                } else {
                    const answer = this.handleSearchResultsAnswer(quest_uuid);
                    if ((type === 'scale' && answer.length !== quest.answers[0].params.criteria.length) ||
                        ((type === 'radio' || type === 'text' || type === 'textarea' || type === 'checkbox') && answer.length < 1)) {
                        importants.push(quest_uuid);
                    };
                };
            }
        });

        const survey_result = {
            start_dt: this.props.startSurvey,
            end_dt: new Date().getTime(),
            survey_uuid: this.props.survey.uuid,
            assignment_id: this.props.id,
            answers: results,
        };

        this.setState({importants: importants});
        if (importants.length === 0) {
            this.handleSendResults(survey_result);
        } else {
            notify(this.props.t("You haven't answered all the questions"), 'error');
            document.getElementById(`${importants[0]}`)?.scrollIntoView({ behavior: 'smooth' });
        };
    };

    handleChangeText = (question_uuid, value) => {
        if (question_uuid) {
            const input = document.getElementById(question_uuid);
            input.value = value;
            if (this.keyboard) this.keyboard.setInput(value);
            const new_answers = [];
            this.state.results.forEach(el => el.question !== question_uuid && new_answers.push(el));
            const answer = {
                question: question_uuid,
                answer: value,
            };
            const result = [...new_answers, answer];
            this.setState({results: result});
        };
    };

    handleSelectRadio = (question_uuid, answer_uuid) => {
        const new_answers = [];
        this.state.results.forEach(el => el.question !== question_uuid && new_answers.push(el));
        const answer = {
            question: question_uuid,
            answer: answer_uuid,
        };
        const result = [...new_answers, answer];
        this.setState({results: result});
    };

    handleSelectScale = (question_uuid, criteria, variant) => {
        const old_answer = [];
        const new_answers = [];
        this.state.results.forEach(el => el.question === question_uuid ? old_answer.push(el) : new_answers.push(el));
        const old_criterias = old_answer.length > 0
            ? old_answer[0].answer.filter(el => el.criteria !== criteria && el)
            : [];
        const new_criteria = {
            criteria: criteria,
            variant: variant,
        };
        const new_answer = {
            question: question_uuid,
            answer: [...old_criterias, new_criteria],
        };
        const result = [...new_answers, new_answer];
        this.setState({results: result});
    };

    handleSelectCheckbox = (question_uuid, answer_uuid) => {
        const old_answer = [];
        const new_answers = [];
        this.state.results.forEach(el => el.question === question_uuid ? old_answer.push(el) : new_answers.push(el));
        const answers = old_answer[0] ? old_answer[0].answer : [];
        const res = answers.includes(answer_uuid) ? answers.filter(el => el !== answer_uuid && el) : [...answers, answer_uuid];
        const new_answer = {
            question: question_uuid,
            answer: res
        };
        const result = [...new_answers, new_answer];
        this.setState({results: result});
    };

    render() {
        return (
            <>
                <div className='ModalBlockNotices_div' >
                    <Animated
                        className="d-flex flex-column h-100 w-100"
                        animationInDuration={500}
                        animationOutDuration={500}
                        animationIn="slideInRight"
                        animationOut="slideOutLeft"
                        isVisible={this.state.isVisible}>
                        <div className={"Screen quest container"}>
                            <Content>
                                <div className={"notice_block display-9"}>
                                    {/* <h1>{this.state.title}</h1>
                                    <h2>{this.state.description}</h2> */}
                                    {this.state.questionnaires.map(el =>
                                        <QuestionnaireQuestion
                                            key={el.uuid}
                                            question={el}
                                            results={this.state.results}
                                            importants={this.state.importants}
                                            t={this.props.t}
                                            lang={this.props.locale.locale}
                                            theme_name={this.props.app.theme_name}
                                            handleSelectRadio={this.handleSelectRadio}
                                            handleSelectCheckbox={this.handleSelectCheckbox}
                                            handleChangeText={this.handleChangeText}
                                            handleChangeInput={this.handleChangeInput}
                                            handleSelectScale={this.handleSelectScale}
                                        /> )}
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    {(!this.props.user?.data?.hasOwnProperty('important_survey') || this.props.user.data.important_survey === null) &&
                                        <Button onClick={() => this.props.history.push('/cabinet')} className={`Btn MuiButton btn Btn_success`} >
                                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                            <span>{this.props.t('Personal Area')}</span>
                                        </Button>}
                                    <Button onClick={this.handleSubmit} className={`Btn MuiButton btn Btn_success d-flex justify-content-center w-25`}>
                                        <span>{this.props.t('Done')}</span>
                                    </Button>
                                    <Button onClick={() => this.props.history.push('/')} className={`Btn MuiButton btn Btn_danger`} >
                                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                                        <span>{this.props.t('Exit')}</span>
                                    </Button>
                                </div>
                            </Content>
                            {isElectron &&
                                <>
                                    <div className="keyboardShowBtn">
                                        <button type="button" className="keyboardShowButtton" onClick={() => this.setState({showKeyboard: !this.state.showKeyboard})}>
                                            <FontAwesomeIcon icon={this.state.showKeyboard ? faChevronDown : faChevronUp} />
                                        </button>
                                    </div>
                                    <div className={`mx-4 keywoard-container search-emp_keywoard-container ${this.state.showKeyboard ? null : "unShowKeyboardClass"}`}>
                                        <Keyboard className="px-4" {...this.state.keyboardConfig} />
                                    </div>
                                </>}
                        </div>
                    </Animated>
                </div>
                <Modal>
                    <BootstrapModal modalId="selectLangKeyboard" dialogClassName={"startup__modal-lang"} btns={[]}>
                        <div className="ModalLang list-group d-flex flex-row justify-content-between">
                        {this.props.locale.languages &&
                            this.props.locale.languages.map(lang => 
                                <button key={lang.id} className="ModalLang-Btn d-flex flex-column text-center" onClick={() => this.handleSelectLang(lang.id)}>
                                    <Image width="64" className="mx-auto mb-4" src={lang.icon}/>
                                    <span className="display-6 text-uppercase font-weight-bold">{lang.title}</span>
                                </button>)}
                        </div>
                    </BootstrapModal>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState,
        locale: store.localeState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(QuestionnairesContainer)));