import {isElectron} from "react-device-detect";
import isCordova from "is-cordova";
import {
    CLEAR_ERRORS, DEFAULT_STATE_APP,
    FATAL_ERROR, NOT_FATAL_ERROR, INITIALIZED_SUCCESS,
    SAVE_SETTINGS,
    SET_FAVICON, SET_LOGO, SET_LOGO_NEGATIVE, SET_MODULES, SET_THEME,
    SET_ELECTRON_START_APP_ERROR, RFID_WORK, IS_CAN_UPDATE, UPDATE_STATUS
} from "./application";
import userManage from "./user-manage";
import store from "../localStorage";
import { setInfoLog, setLog } from "../helpers/functions";
import { BACKEND_HOST } from "../api/api-config";
import { autoUpdate } from "../update/autoUpdate";
import networkAnswerManager from "./network-answer-manager";

let serverSettings = {};
export default class appManage {
    static errorApp = (e, info) => {
        setLog(info, e);
        store.dispatch({type: FATAL_ERROR, errors: e});
        store.dispatch({type: NOT_FATAL_ERROR, errors: []});
    }

    static notFatalError = (e) => store.dispatch({type: NOT_FATAL_ERROR, errors: [e]});

    static getLocaleId = () => store.getState().localeState.locale;

    static getThemeCode = () => {
        const state = store.getState();
        return (state.appState.theme_name) ? state.appState.theme_name : 'default_theme';
    }

    static getLogo = () => store.getState().appState.logo;

    static getViewCamera = () => store.getState().appState.view_camera;

    static getLogoNegative = () => store.getState().appState.logo_negative;

    static getFavicon = () => store.getState().appState.favicon;

    static setDefaultStateApp = () => store.dispatch({type: DEFAULT_STATE_APP});

    static setThemeCode = (theme) => store.dispatch({type: SET_THEME, theme_name: theme});

    static setLogo = (logo) => store.dispatch({type: SET_LOGO, logo: logo});

    static setLogoNegative = (logo) => store.dispatch({type: SET_LOGO_NEGATIVE, logo_negative: logo});

    static setFavicon = (favicon) => store.dispatch({type: SET_FAVICON, favicon: favicon});

    static setModules = (modules) => store.dispatch({type: SET_MODULES, modules: modules});

    static clearErrors = () => store.dispatch({type: CLEAR_ERRORS});

    static isRfidWork = (isWork) => store.dispatch({type: RFID_WORK, isRfidWork: isWork});

    static setIsCanUpdated = (isCanUpdate) => store.dispatch({type: IS_CAN_UPDATE, isCanUpdate: isCanUpdate});

    static setUpdateStatus = (update_status) => store.dispatch({type: UPDATE_STATUS, update_status: update_status});

    static saveSettings = (settings, isRerender = true) => {
        if (isRerender) {
            store.dispatch({type: SAVE_SETTINGS, settings: settings});
            serverSettings = settings;
            setInfoLog('Настройки сохранены');
        } else if (!isRerender &&
            JSON.stringify(serverSettings) !== JSON.stringify(settings) &&
            (window.location.hash === "#/" || window.location.hash === "#/startup") &&
            !store.getState().loadState.isLoading) {
             store.dispatch({type: SAVE_SETTINGS, settings: settings});
             serverSettings = settings;
             setInfoLog('Настройки обновлены');
        } else {
            let storage = window.localStorage;
            let state = JSON.parse(storage.getItem('ComplexAppStorage'));
            state.appState = {...state.appState, ...settings};
            storage.setItem('ComplexAppStorage', JSON.stringify(state));
        }
    };

    /**
     * Подготавливет настройки
     * @returns {Promise<unknown>}
     */
    static prepareSettings = (isRerender) => {
        networkAnswerManager.setNetworkError('');
        return new Promise((resolve, reject) => {
            const apiApp = require("../api/apiApp").default;
            apiApp.getSettings()
            .then(response => {
                if (isElectron) {
                    try {
                        const electron = require('electron');
                        electron.ipcRenderer.send('save_server_settings', {...response.data});
                    } catch (e) {
                        console.error(e.message);
                    }
                    if (response.data.updateState && response.data.updateState.can_update &&
                        (window.location.hash === "#/" || window.location.hash === "#/startup")) {
                        autoUpdate(response.data.updateState);
                    };
                };
                this.saveSettings(response.data, isRerender);
                resolve(response);
            }).catch(e => {
                if (!e.handleStop) {
                    if (isElectron && BACKEND_HOST.includes("http://localhost")) store.dispatch({type: SET_ELECTRON_START_APP_ERROR});
                    let error = 'Network Error';
                    if (e.error?.length && e.error[0].includes('Invalid URL')) error = 'Invalid URL';
                    if (networkAnswerManager.getResponseCode() === 500) error = 'Server Error';
                    networkAnswerManager.setNetworkError(error);
                    setLog('Ошибка сохранения настроек с сервера', e);
                }
                reject(e);
            });
        });
    }

    static initAppSuccess = () => store.dispatch({type: INITIALIZED_SUCCESS});

    /**
     * @returns {function(): *}
     */
    static initializeApp = () => async () => {
        let promise = this.prepareSettings(true)
        .then(response => {
            require('./locale').setLanguages(response.data.languages);
            let htmlNode = document.querySelector('html'),
                faviconNode = document.getElementById('favicon'),
                body = document.querySelector('body');
            htmlNode.lang = this.getLocaleId();
            faviconNode.href = this.getFavicon();

            if (isCordova) body.classList.add('is_cordova');

            Promise.all([promise])
            .then(this.initAppSuccess)
            .catch(e => setLog('Ошибка инициализации приложения', e))
            .finally(() => setInterval(() =>
                    this.prepareSettings(false)
                    .catch(e => setLog('Ошибка обновления настроек с сервера', e)), 600000));
        })
        .catch(() => setTimeout(() => !BACKEND_HOST.includes("http://localhost") && window.location.reload(), 60000));

        if (store.getState().userState.isAuth) userManage.setIntervalGetExtData();

        return promise;
    }
}