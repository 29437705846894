import React, {Component} from 'react';

class ManageStepper extends Component {
    stepsSet = new Set();
    activeStep = 0;
    timeAnimation = 500;
    stepsOptionals = new Set();
    stepsSkipped = new Set();

    addStep = (step) => this.stepsSet.add(step);

    unsetStepByKey = (key) => this.stepsSet.forEach((value, valueAgain, set) => {
            if (value.key === key) this.stepsSet.delete(value);
        });

    getSteps = () => this.stepsSet.entries();

    setActiveStep = (step) => this.activeStep = step;

    getNextStep = () => {}

    setActiveStepByKey = (step) => {}

    getActiveStep = () => this.activeStep;

    isStepOptional = (step) => {
        //return this.stepsOptionals.has(step);
    };

    isStepSkipped = (step) => {
        //return this.stepsSkipped.has(step);
    };

    handleNext = () => {
        /*let newSkipped = this.stepsSkipped;

        if (this.isStepSkipped(this.activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(this.activeStep);
        }

        this.setActiveStep((prevActiveStep) => prevActiveStep + 1);
        this.setSkipped(newSkipped);*/
        this.setActiveStep(this.getActiveStep() + 1);
    };

    handleBack = () => this.setActiveStep(this.getActiveStep() - 1);

    handleSkip = () => {
         /*if (!this.isStepOptional(this.activeStep)) {
             // You probably want to guard against something like this,
             // it should never occur unless someone's actively trying to break something.
             throw new Error("You can't skip a step that isn't optional.");
         }

         this.setActiveStep((prevActiveStep) => prevActiveStep + 1);

         this.setSkipped((prevSkipped) => {
             const newSkipped = new Set(prevSkipped.values());
             newSkipped.add(this.activeStep);

             return newSkipped;
         });*/
    };

    handleReset = () => this.setActiveStep(0);
}

export default ManageStepper;