import React from 'react';
import { Row, Col } from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Content from "../../blocks/Content";

export default function DemoQuestionModule(props) {
    let questions = null;
    if (props.module_questions) questions = props.module_questions.map(el => el);

    return <div className={"Screen ScreenCabinet container"}>
            <Content>
                <Row className={"ScreenCabinet-Row"}>
                    {questions.map(question =>
                        <Col xs={"12"}  className={"ScreenCabinet-Col cursor-pointer"} key={question.uuid}>
                            <div onClick={() => props.getDemoQuest(question.uuid)}
                                    className={"d-flex align-items-center justify-content-start Btn Btn_success text-white p-5"}>
                                <div className="demoQuest__text d-flex">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-4"/>
                                    {question.text}
                                </div>
                            </div>
                        </Col>)}
                </Row>
            </Content>
           </div>
}