import React, { Fragment } from 'react';
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Person from "../../blocks/Person";
import Content from "../../blocks/Content";

function SuccessVisual(props) {
    let acorrentAnswers = [];
    props.answers.forEach(answer => {
        if (answer.is_right === false && answer?.hasOwnProperty('visualisation') && answer.visualisation) acorrentAnswers.push(answer);
    });

    let nodeAcorrentAnswers = acorrentAnswers.map(answer => {
        let bodyParts = answer.visualisation.body_part;
        return <Fragment key={answer.id} >
                <Col xs={"8"} lg={"4"} className={"mb-5 mb-lg-0"}>
                    <div>
                        <video loop="loop" className="visual-success__video" preload="auto"
                            autoPlay={true} muted="" data-setup="{
                                    &quot;children&quot;: { &quot;controlBar&quot;: { &quot;children&quot;: { &quot;muteToggle&quot;: false, &quot;fullscreenToggle&quot;:false, &quot;volumeControl&quot;:false, &quot;playToggle&quot;:false } } }
                                }" src={answer.visualisation.file}>
                        </video>
                        <div className='visual-success__video_x'></div>
                    </div>
                </Col>
                <Col xs={"4"} lg={"2"} className={"mb-5 mb-lg-0"}>
                    <div className="visual-success__person">
                        <Person body_parts={bodyParts}/>
                    </div>
                </Col>
               </Fragment>
    });

    return <div className="w-100 h-100 visual-success Screen container">
            <Content>
                <div className={"d-flex flex-column align-items-center h-100"}>
                    <div className={"visual-success__answer answer text-center mb-0"}>
                        <FontAwesomeIcon icon={faCheck} className="Success-Ok mb-5"/>
                        <div className={"answer__text answer__text_right"}>
                            {props.t('You answered correctly')}.<br/>
                            {props.t('You have avoided injury through competence')}!
                        </div>
                    </div>
                    <Row className={"align-items-center mt-2"}>
                        {nodeAcorrentAnswers}
                    </Row>
                </div>
            </Content>
           </div>
}

const mapStateToProps = (store) => {
    return {};
};

export default connect(mapStateToProps)(withTranslation()(SuccessVisual));