import {CLEAR_STATE_PRESHIFT, SAVE_FLOW, TAKE_FRONT_PHOTO, TAKE_OVERVIEW_PHOTO, SET_CAME_FROM} from "./preshift";
import store from "../localStorage";

export default class preshiftManage {
    static setIsTakeFrontPhoto = (isTakePhoto) => store.dispatch({type: TAKE_FRONT_PHOTO, isTakeFrontPhoto: isTakePhoto});
    static setIsTakeOverviewPhoto = (isTakePhoto) => store.dispatch({type: TAKE_OVERVIEW_PHOTO, isTakeOverviewPhoto: isTakePhoto});
    static getIsTakeFrontPhoto = () => store.getState().preshiftState.isTakeFrontPhoto;
    static getIsTakeOverviewPhoto = () => store.getState().preshiftState.isTakeOverviewPhoto;
    /**
     * @returns {{history: []}|(*&{history: []})|{}|*}
     */
    static getFlow = () => store.getState().preshiftState.flow;
    static saveFlow = (data) => store.dispatch({type: SAVE_FLOW, data: data});
    static clear = () => store.dispatch({type: CLEAR_STATE_PRESHIFT});
    static getCameFrom = () => store.getState().preshiftState.came_from;
    static setCameFrom = (came_from) => store.dispatch({type: SET_CAME_FROM, came_from: came_from});
}