import {Image} from "react-bootstrap";
import React from "react";
import {connect} from "react-redux";
import {setLocaleState} from "../../reducers/locale";
import modalManage from "../../reducers/modal-manage";
import i18n from "../../externalHardware/i18next/i18next";
import BootstrapModal from "./BootstrapModal";

class SelectLang extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSelect = (locale) => {
        i18n.changeLanguage(locale);
        setLocaleState(locale);
        modalManage.close('selectLang');
    }

    render() {
        return <BootstrapModal modalId="selectLang" dialogClassName={"startup__modal-lang"} btns={[]}>
                <div className="ModalLang list-group d-flex flex-row justify-content-between">
                    {this.props.locale.languages &&
                     this.props.locale.languages.map(lang =>
                        <button key={lang.id} className="ModalLang-Btn d-flex flex-column text-center" onClick={() => this.handleSelect(lang.id)}>
                            <Image width="64" className="mx-auto mb-4" src={lang.icon}/>
                            <span className="display-6 text-uppercase font-weight-bold">{lang.title}</span>
                        </button>)}
                </div>
               </BootstrapModal>

    }
}

const mapStateToProps = (store) => {
    return {
        locale: store.localeState,
    };
};

export default connect(mapStateToProps)(SelectLang);