import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import {isElectron} from "react-device-detect";
import {withRouter} from "react-router-dom";
import Startup from "../screens/startup/Startup";
import withFormHanlding from "../../hoc/FormHandling";
import modalManage from "../../reducers/modal-manage";
import apiEmployee from "../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../reducers/load";
import configApp from "../../config/config";
import {listenerRfid} from "../../index";
import userManage from "../../reducers/user-manage";
import { reloadElectron } from '../../helpers/functions';

class StartupContainer extends Component {
  constructor(props) {
    super(props);
    userManage.logout();

    this.state = {
      rfidWord: '',
      keyboardConfig: {
        layoutName: "default",
        layout: {
          default: ["1 2 3", "4 5 6", "7 8 9", "- 0 {backspace}"],
        },
        display: {
          "{backspace}": "<",
        },
        theme: "theme_cot",
        onChange: this.onChange,
      },
      to_link: "/cabinet",
      clock: new Date().toLocaleString(),
      employes: [],
    };
    let formState = {
      formErrors: {
        tabNum: [],
      },
      formValues: { tabNum: "" },
      formValid: false,
      onSubmit: this.handleSubmit,
    };

    props.prepareFormState(formState);

    this.initRfid();
  }

  onInputTabelNum = (event) => this.onChange(event.target.value);

  handleKeyUp = (e) => this.setState({rfidWord: this.state.rfidWord += e.key});

  componentDidMount() {
    loader.stop("screenStartup");
    this.intervalID = setInterval(() => this.tick(), 1000);
    this.handleCheckLocationSearchTabelNumber();
    if (isElectron &&
    this.props.app.is_rfid &&
    configApp.device_settings.rfid.isHid) {
      document.body.addEventListener('keyup', this.handleKeyUp);
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
    if (isElectron &&
    this.props.app.is_rfid &&
    configApp.device_settings.rfid.isHid) {
      document.body.removeEventListener('keyup', this.handleKeyUp);
    }
  };

  tick = () => this.setState({clock: new Date().toLocaleString()});

  handleClearStateEmployes = () => this.setState({employes: []});

  handleCheckLocationSearchTabelNumber = () => {
    const search = this.props.location.search;
    if (search && search.includes('tabel_namber')) {
      const tabel_number = search.split('=')[1];
      loader.start("screenStartup");
      this.setState({to_link: this.props.location.pathname}, () => this.checkTabel(tabel_number));
    };
  };

  /**
   * @param input
   */
  onChange = (input) => {
    let tabNum = document.querySelector(".startup__tabel-field");
    tabNum.value = input;
    this.props.validateField(tabNum);
  };

  /**
   * @param event
   * @returns {boolean}
   */
  routeGuider = (event) => {
    event.preventDefault();
    event.persist();

    this.setState({ to_link: event.target.getAttribute("linkto") },
      () => this.state.to_link === "/demo"
          ? this.props.history.push(this.state.to_link)
          : this.handleSubmit(this.props.formValues));
        
    return false;
  };

  /**
   * @param employee_id
   */
  handleSelectEmployee = (employee_id) => {
    modalManage.close("selectEmployees");
    loader.start("screenStartup");
    this.auth(employee_id);
  };

  handleError = (e) => {
    const errors = e.hasOwnProperty('message') ? [e.message] : [e.error];
    this.props.prepareFormState({
      formErrors: { error: errors },
      formValid: false,
    });
  };

  auth = (id) => new Promise((resolve, reject) => {
    apiEmployee.auth(id)
    .then(res => {
      userManage.authorized(res.data[0])
      .then(isAuth => {
        if (isAuth) this.props.history.push(this.state.to_link);
        userManage.setIntervalGetExtData();
        resolve(isAuth);
      });
    })
    .catch(e => this.handleError(e))
    .finally(() => loader.stop("screenStartup"));
  });

  authByRfid = (result) => {
    return new Promise((resolve, reject) => {
      loader.start("screenStartup");
      let to_link = this.state.to_link;

      apiEmployee.authRfid(result)
      .then(response => {
        userManage.authorized(response.data[0])
        .then(isAuth => {
          if (isAuth) {
            userManage.pinCodeAuthorized();
            this.props.history.push(to_link);
          }
          userManage.setIntervalGetExtData();
          resolve(isAuth);
        });
      })
      .catch(e => {
        setTimeout(this.initRfid, 1000);
        this.handleError(e);
      })
      .finally(() => loader.stop("screenStartup"));
    });
  };

  initRfid = () => {
    if (isElectron && !configApp.server_settings) {
      reloadElectron();
    } else if (isElectron &&
        configApp.server_settings.is_rfid &&
        !configApp.device_settings.rfid.isHid) {
      listenerRfid.start()
      .then(result => {
        this.props.clearErrors()
        .then(() => this.authByRfid(result))
        .catch(e => this.handleError(e));
      })
      .catch(()=>{});
    }
  };

  /**
   * @param formValues
   * @returns {Promise<unknown>}
   */
  handleSubmit = (formValues) => {
    if (this.props.formValid) {
      loader.start("screenStartup");
      const tab_num = formValues?.tabNum || null;
      this.checkTabel(tab_num);
    }
  };

  checkTabel = (tab_num) => {
    apiEmployee.checkTabel(tab_num)
      .then(response => {
        if (response.data.length > 1) {
          response.data.forEach(emp => this.state.employes.push(emp));
          loader.stop("screenStartup");
          modalManage.show("selectEmployees");
        } else if (response.data.length === 1) {
          this.auth(response.data[0].uuid);
        }
      })
      .catch(e => {
        this.handleError(e);
        loader.stop("screenStartup");
      });
  };

  componentWillUpdate(nextProps, nextState) {
    // TODO: fix double keyboards
    let keyboards = $(".startup__keyboard .hg-rows");
    if (keyboards.length > 1) keyboards.last().remove();
    if (isElectron && nextState.rfidWord.toLowerCase().includes('enter')) {
      const word = nextState.rfidWord.slice(0, -5);
      if (word) this.authByRfid(word);
      this.setState({rfidWord: ''});
    }
  }

  render() {
    return <Startup {...this.props} {...this.state}
            routeGuider={this.routeGuider}
            onInputTabelNum={this.onInputTabelNum}
            handleSelectEmployee={this.handleSelectEmployee}
            errorClass={this.props.errorClass}
            handleClearStateEmployes={this.handleClearStateEmployes} />
  };
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState
    };
};

/**
 * @param message
 * @constructor
 */
function NotHasTabNum(message) {
    this.message = message;
    this.name = 'Not has tab num';
}

export default withRouter(connect(mapStateToProps)(withFormHanlding(StartupContainer)));