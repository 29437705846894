import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ExitTimer({ theme_name, goOut, handleExitTimer, sec = 5 }) {
    const [seconds, setSeconds] = useState(sec);
    const [persent, setPersent] = useState(100);
    const [out, setOut] = useState(false);
    const timer = useRef();
    const pers = Math.floor(100 / sec);

    useEffect(() => {
        if (seconds > 0) {
            timer.current = setTimeout(() => {
                const cur_persent = persent - pers < 0 ? 0 : persent - pers;
                setSeconds(seconds - 1);
                setPersent(cur_persent);
            }, 1000);
        } else {
            setSeconds(0);
            handleExitTimer && handleExitTimer();
            setOut(true);
        };
        return () => clearTimeout(timer.current);
    }, [seconds, persent]);

    useEffect(() => {seconds === 0 && goOut()}, [out]);

    let theme_color;

    if (theme_name === "default_theme") theme_color="#1E874A";
    if (theme_name === "evraz_theme") theme_color="#ED7817";
    if (theme_name === "danger") theme_color="#DC3545";

    return  <div className={"exit__timer mb-4"}>
                <CircularProgressbar
                    value={`${persent}`}
                    text={`${seconds}`}
                    styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: 'butt',
                        pathTransitionDuration: 0.8,
                        pathColor: `#ffff`,
                        textColor: `#ffff`,
                        textSize: '50px',
                        trailColor: theme_color,
                    })} />
            </div>
}