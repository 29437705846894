import React from 'react';
import { Container } from 'react-bootstrap';

export default function Prolog(props) {
    return <div className={"Screen quest container"}>
            <Container className="h-100 d-flex justify-content-center align-items-center">
                <div>
                    <div className={"w-100 m-auto d-flex justify-content-center align-items-center"}>
                        <video className={"mw-100 mh-100"} id="prolog_video" src={props.question?.prolog} autoPlay={true}>
                            <source src={props.question?.prolog}/>
                        </video>
                    </div>
                    <div className={"quest__quest"}>
                        <div className={"quest__container text-center"}>
                            <h2 className={"quest__title justify-content-center"}>{props.question?.text}</h2>
                        </div>
                    </div>
                </div>
            </Container>
           </div>
}