import React from "react";
import Content from "./Content";

export default function List(props) {
    const Item = props.templateItem;
    const listItems = props.listItems;

    return <Content className={props.library && "LibraryGrid h-100"}>
            {listItems.map(item => <Item {...item} {...props} key={item.uuid} />)}
           </Content>
}