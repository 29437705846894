import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import {withRouter} from "react-router-dom";
import {isElectron} from "react-device-detect";
import withFormHanlding from "../../../hoc/FormHandling";
import modalManage from "../../../reducers/modal-manage";
import apiEmployee from "../../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import configApp from "../../../config/config";
import {listenerRfid} from "../../../index";
import userManage from "../../../reducers/user-manage";
import LoginCourses from '../../screens/electronicCourses/LogInCourses';
import {reloadElectron} from '../../../helpers/functions';

class LoginCoursesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rfidWord: '',
            to_link: "/electronic-courses",
            employes: [],
            active_input: 'login',
            localeKeyboard: "en",
            keyboardConfig: {
                layoutName: "en_lower",
                layout: {
                    'en_lower': [
                        'q w e r t y u i o p {backspace}',
                        '{lock} a s d f g h j k l',
                        '{numbers} z x c v b n m',
                        '{space}'
                    ],
                    'en': [
                        'Q W E R T Y U I O P {backspace}',
                        '{lock} A S D F G H J K L',
                        '{numbers} Z X C V B N M',
                        '{space}'
                    ],
                    'symbols': [
                        '[ ] { } # % ^ * - + = {backspace}',
                        '_ \/ | ~ < > $ € •',
                        '{abc} {numbers} . , ? ! \'',
                        '{space}'
                    ],
                    'numbers': [
                        '1 2 3 4 5 6 7 8 9 0 {backspace}',
                        '- \/ : ; ( ) ₽ & @ "',
                        '{abc} {symbols} . , ? ! \'',
                        '{space}'
                    ]
                },
                display: {
                    "{backspace}": '<',
                    "{space}": props.t('Space'),
                    "{lock}":  "A->a", // String.fromCharCode(55358, 56389), // "U+1F845", // "&#129093", // 🡅,
                    "{symbols}": '#+=',
                    "{abc}": props.t('ABC'),
                    "{numbers}": '123'
                },
                theme: 'theme_cot theme_cot_search-emp',
                onChangeAll: this.onChangeInputs,
                onKeyPress: this.onKeyPress,
                keyboardRef: r => (this.keyboard = r),
            },
            data: [],
            input: ""
        };

        let formState = {
            formErrors: {
                login: [],
                password: [],
            },
            formValues: {login: '', password: ''},
            formValid: false,
            onSubmit: this.handleSubmit
        };
        props.prepareFormState(formState);
        document.title = this.props.t('Electronic courses');
        this.initRfid();
    };

    handleChangeActiveInput = (input) => this.setState({active_input: input});

    handleKeyUp = (e) => this.setState({rfidWord: this.state.rfidWord += e.key});

    componentDidMount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.addEventListener('keyup', this.handleKeyUp);
        }
    };

    componentWillUnmount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.removeEventListener('keyup', this.handleKeyUp);
        }
    };

    onKeyPress = (button) => {
        let keyboardConfig = this.state.keyboardConfig;
        if (button === "{lock}") {
            keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard ? `${this.state.localeKeyboard}_lower` : this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{symbols}") {
            keyboardConfig.layoutName = 'symbols';
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{abc}") {
            keyboardConfig.layoutName = this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{numbers}") {
            keyboardConfig.layoutName = 'numbers';
            this.setState({keyboardConfig: keyboardConfig});
        }
    };

    onChangeInputs = (inputs) => {
        if (inputs.login || inputs.login === '') this.onInputLogin(inputs.login);
        if (inputs.password || inputs.password === '') this.onInputPassword(inputs.password);
    };

    onInputLogin = (val) => {
        let login = document.querySelector('[name=login]');
        if (val || val === '') login.value = val;
        this.onChange(login);
    };

    onInputPassword = (val) => {
        let password = document.querySelector('[name=password]');
        if (val || val === '') password.value = val;
        this.onChange(password);
    };

    onChange = (input) => this.props.validateField(input);

    /**
     * @param event
     * @returns {boolean}
     */
    routeGuider = (event) => {
        event.preventDefault();
        event.persist();
        this.setState({to_link: event.target.getAttribute('linkto')},
                      () => this.handleSubmit());
        return false;
    };

    handleError = (e) => {
        const errors = e.hasOwnProperty('message') ? [e.message] : [e.error];
        this.props.prepareFormState({
            formValues: {...this.props.formValues, login: '', password: ''},
            formErrors: {...this.props.formErrors, error: errors},
            formValid: false
        });
    };

    authByRfid = (result) => {
        return new Promise((resolve, reject) => {
            loader.start('item');
            let to_link = this.state.to_link;

            apiEmployee.authRfid(result)
            .then(response => {
                userManage.authorized(response.data[0])
                .then(isAuth => {
                    if (isAuth) {
                        userManage.pinCodeAuthorized();
                        this.props.history.push(to_link);
                    }
                    userManage.setIntervalGetExtData();
                    resolve(isAuth);
                });
            }).catch(e => {
                setTimeout(this.initRfid, 1000);
                this.handleError(e);
            }).finally(() => loader.stop('item'));
        });
    };

    initRfid = () => {
        if (isElectron && !configApp.server_settings) {
            reloadElectron();
        } else if (isElectron &&
                configApp.server_settings.is_rfid &&
                !configApp.device_settings.rfid.isHid) {
            listenerRfid.start()
            .then(result => this.authByRfid(result))
            .catch(()=>{});
        }
    };

    /**
     * @param formValues
     * @returns {Promise<unknown>}
     */
    handleSubmit = () => {
        return new Promise((resolve, reject) => {
            loader.start('item');
            let login = this.props.formValues.login;
            let password = this.props.formValues.password;
            apiEmployee.authElCourses(login, password)
            .then(response => {
                if (response.data.length > 1) {
                    response.data.forEach(emp => this.state.employes.push(emp));
                    loader.stop('item');
                    modalManage.show('selectEmployees');
                } else if (response.data.length === 1) {
                    userManage.authorized(response.data[0])
                    .then(isAuth => {
                        if (isAuth) {
                            userManage.pinCodeAuthorized();
                            this.props.history.push(this.state.to_link);
                        }
                        userManage.setIntervalGetExtData();
                        resolve(isAuth);
                    })
                    .catch(()=>{})
                    .finally(() => loader.stop('item'));
                };
                resolve(response);
            }).catch(e => {
                this.handleError(e);
                loader.stop('item');
            });
        });
    };

    componentWillUpdate(nextProps, nextState) {
        // TODO: fix double keyboards
        let keyboards = $('.startup__keyboard .hg-rows');
        keyboards.length > 1 && keyboards.last().remove();
        if (isElectron && nextState.rfidWord.toLowerCase().includes('enter')) {
            const word = nextState.rfidWord.slice(0, -5);
            if (word) this.authByRfid(word);
            this.setState({rfidWord: ''});
          }
    };

    render() {
        return <LoginCourses {...this.props} {...this.state}
                keyboard={this.keyboard}
                routeGuider={this.routeGuider}
                onInputLogin={this.onInputLogin}
                onInputPassword={this.onInputPassword}
                errorClass={this.props.errorClass}
                handleClearStateEmployes={this.handleClearStateEmployes}
                handleChangeActiveInput={this.handleChangeActiveInput} />
    };
}

const configHocForm = {};

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        app: store.appState,
        locale: store.localeState,
    };
};

/**
 * @param message
 * @constructor
 */
function NotHasTabNum(message) {
    this.message = message;
    this.name = 'Not has tab num';
}

export default withRouter(connect(mapStateToProps)(withFormHanlding(LoginCoursesContainer, configHocForm)));

