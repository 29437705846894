import React, { useEffect, useState } from "react";
import { isElectron } from "react-device-detect";
import Keyboard from "react-simple-keyboard";
import {Button, Container, FormGroup, } from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faArrowLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import Breams from "../../blocks/Breams";
import Content from "../../blocks/Content";
import MainNav from "../../blocks/MainNav";
import Select from "../../blocks/Select";
import Loader from "../../blocks/Loader";
import {isWebApp, isTrueProp} from "../../../helpers/functions";
import configApp from "../../../config/config";
import Modal from "../../modals/Modal";
import BootstrapModal from "../../modals/BootstrapModal";


export default function FeedbackAnswer(props) {
    const [showSelect, setShowSelect] = useState(true);
    const classDisable = props.formValid ? '' :' disabled';
    const keys = Object.keys(props.themes);
    const isRec = props.recipient === 'user' ? true : false;
    const users = props.users;
    const structures = props.structures;
    const array = isRec ? users : structures;
    const recipient_id = isRec ? props.selected_user_id : props.selected_structure_id;
    const structure_id = props.user.data.structure_id;
    const auto_focus = isWebApp();

    useEffect(()=> {
        setShowSelect(false);
        setTimeout(()=>setShowSelect(true), 1);
    }, [props.recipient]);

    return <>
            <Breams/>
            <div className="Screen FeedbackAdd container">
                <Content>
                    <Loader isLoading={props.load.isLoading} loadId={"content"} justifyContent="center">
                        <FormGroup className="d-flex align-items-center justify-content-between">
                            <Select onSelectCallback={props.onSelectTheme} className="FeedbackAdd-SelectType"
                                    name="theme_id" textLabel={props.t('Message subject')}>
                                {keys.map(key => <option value={key} key={key}>{props.themes[key]}</option>)}
                            </Select>
                            <Button onClick={props.showSelectRecipientModal} className={`Btn Btn_success ${classDisable} MuiButton ml-3`}>
                                {props.t('Recipient')}
                            </Button>
                            <Button onClick={(event) => props.handleSubmit(event, 'structure', structure_id)} className={`Btn Btn_success ${classDisable} MuiButton ml-3`}>
                                <FontAwesomeIcon icon={faPaperPlane} className="mr-4"/>
                                {props.t('Send')}
                            </Button>
                        </FormGroup>
                        <div className={"mb-4"}>
                            <FormGroup className="mb-3" controlId="formBasicEmail">
                                <textarea
                                    type="text"
                                    minLength="1"
                                    autoFocus={auto_focus}
                                    required
                                    rows="8"
                                    onChange={(event) => props.onInputMessage(event.currentTarget.value)}
                                    name="message"
                                    className={`Textarea MessageField mx-auto form-control display-7 p-4`}
                                    placeholder={props.t('Your message')}
                                    value={props.formValues.message ? props.formValues.message : ''} />
                            </FormGroup>
                            {(isTrueProp(configApp.device_settings, 'keyboard') || isElectron) &&
                                <div className={"keywoard-container"}>
                                    <Keyboard className="px-4" {...props.keyboardConfig} />
                                </div>}
                        </div>
                    </Loader>
                </Content>
            </div>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <NavLink to={"/feedback"} className={`Btn Btn_boring MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Back')}</span>
                    </NavLink>
                    <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                        <span>{props.t('Exit')}</span>
                    </NavLink>
                </Container>
            </MainNav>
            <Modal>
                <BootstrapModal modalId="selectRecipient" dialogClassName={"startup__modal-lang"} btns={[]} >
                    <div className="d-flex flex-column justify-content-between" style={{width: '50vw'}}>
                        <h1 className="text-center">{props.t('Select the recipient')}</h1>
                        <div className="d-flex justify-content-around mt-3 mb-3">
                            <div className={`cursor-pointer Btn MuiButton ${!isRec && 'Btn_success'}`} onClick={() => props.handleRecipient('structure')}>
                                {props.t('Division')}
                            </div>
                            <div className={`cursor-pointer Btn MuiButton ${isRec && 'Btn_success'}`} onClick={() => props.handleRecipient('user')}>
                                {props.t('Recipient')}
                            </div>
                        </div>
                        <FormGroup className="d-flex flex-column align-items-center justify-content-center mt-4">
                            {showSelect &&
                                <Select onSelectCallback={props.onSelectRecipient} className={`FeedbackAdd-SelectType`} name="recipient_id" >
                                    {array.map(el => <option value={el.id.toString()} key={el.id} selected={el.id == recipient_id}>{el.title}</option>)}
                                </Select>}
                            <Button onClick={props.handleSubmit} className={`Btn Btn_success ${classDisable} MuiButton mt-4`}>
                                <FontAwesomeIcon icon={faPaperPlane} className="mr-4"/>
                                {props.t('Send')}
                            </Button>
                        </FormGroup>
                    </div>
                </BootstrapModal>
            </Modal>
           </>
};