import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Content from '../../blocks/Content';
import BootstrapModal from '../../modals/BootstrapModal';
import modalManage from '../../../reducers/modal-manage';

export default function Fail(props) {
    const quote_from_document = props.question?.quote_from_document;
    const quote_from_document_max_length = 1500;
    const modal_id = 'rulesModal';
    const select_answer = props.select_answer instanceof Array ? props.select_answer[0] : props.select_answer;

    let titleFactors = null;
    if (select_answer?.hasOwnProperty('factor_risk') &&
        select_answer.factor_risk) titleFactors = select_answer.factor_risk.map(factor => factor.title).join();

    let comment = null;
    if (select_answer?.hasOwnProperty('comment') && select_answer.comment) comment = select_answer.comment;

    let correctAnswer = [];
    props.answers.forEach((answer) => {
        if (answer.is_right === true) correctAnswer.push(answer);
    });

    return <div className={"Screen container"}>
            <Content>
                <div className="fail-answer">
                    <div className={"fail-answer__comment fail-answer__comment_grid px-3"}>
                        {comment &&
                            <div>
                                <h3 className={"fail-answer__text_wrong"}>{props.t('Consequences of incompetent actions')}</h3>
                                <p className={"fail-answer__text mb-4"}>{comment}</p>
                            </div>}
                        {titleFactors &&
                            <div>
                                <h3 className={"fail-answer__text_wrong"}>{props.t('Striking facts')}</h3>
                                <p className={"fail-answer__text"}>{titleFactors}</p>
                            </div>}
                    </div>
                    <div className={"fail-answer__fail mb-4"}>{props.t('You answered wrong')}!</div>
                    {correctAnswer.length > 0 &&
                        <div className={"fail-answer__comment py-4 px-3"}>
                            <div className='d-flex flex-column'>
                                <h3 className={"fail-answer__text_success"}>{props.t('Correct answer')}:</h3>
                                {correctAnswer.map(el => <p key={el.id} className={"fail-answer__text fail-answer__text_success_correct w-100 mb-1"}>{el.text}</p>)}
                            </div>
                        </div>}
                </div>
                {quote_from_document &&
                    <div className='d-flex flex-column mb-5'>
                        <span className='display-6 mb-2'>{props.t('Violation of the rule')}</span>
                        <span className='display-7 text-justify' style={{textIndent: '2rem', lineHeight: 0.99}}>
                            <b>{quote_from_document?.slice(0, quote_from_document_max_length)}{quote_from_document?.length > quote_from_document_max_length && "..."}</b>
                        </span>
                        {quote_from_document?.length > quote_from_document_max_length &&
                            <div className='d-flex w-100 justify-content-end'>
                                <FontAwesomeIcon
                                    className={'cursor-pointer'}
                                    onClick={() => modalManage.show(modal_id)}
                                    style={{height: '3.5rem'}}
                                    icon={faSearch}/>
                            </div>}
                    </div>}
            </Content>
            <BootstrapModal modalId={modal_id} dialogClassName={'w-75'} title={props.t('Violation of the rule')}>
                <div className='display-9 text-justify max-vh-75' style={{overflowY: 'auto'}}>
                    {quote_from_document}
                </div>
            </BootstrapModal>
        </div>
}