import React, { useLayoutEffect } from "react";
import FileViewer from 'react-file-viewer';
import ReactPlayer from "react-player";
import Presentation from '../../../blocks/Presentation';
import Content from "../../../blocks/Content";
import Loader from "../../../blocks/Loader";
import FatalError from "../../FatalError";
import configApp from "../../../../config/config";
import { getFilePath } from "../../../../helpers/cacheFiles";


export default function DocumentAndVideo(props) {
    let fileType, url, type, viewType;
    let devMode = configApp.device_settings?.devMode ? true : false;

    if (props.stepData) {
        fileType = props.stepData.data?.file_type?.split('/')[1];
        url = props.stepData.data?.url || ''; // props.stepData.data?.url ? getFilePath(props.stepData.data?.url) : '';
        type = props.stepData.name;
        viewType = props.stepData.params?.view_type;
    };

    const handleCheckUrl = (url) => 
        new Promise(() => {
            fetch(url).catch(props.onError)
        });

    useLayoutEffect(() => {
        if (!url && (type === 'video' || type === 'document')) props.onError('Отсутствует ссылка на файл');
        if (type === 'document' && viewType !== 'presentation' && url) handleCheckUrl(url);
    }, []);

    return <div className="Screen Material container">
            <Content className="Material-Content">
                <Loader isLoading={props.load.isLoading} loadId={"item"} justifyContent="center">
                    <div className="Material-FileViewer text-center">
                        {type === "document"
                            ? viewType === "presentation"
                                ? <Presentation url={url} t={props.t} handleWatched={props.handleWatched} onError={props.onError} />
                                : <div id="FileViewer">
                                    <FileViewer
                                        autoPlay={true}
                                        fileType={fileType}
                                        filePath={url}
                                        errorComponent={FatalError}
                                        onError={props.onError} />
                                </div>
                            : <ReactPlayer
                                id="FileViewer"
                                playing={true}
                                url={url}
                                width="100%"
                                height="100%"
                                onError={props.onError}
                                controls //={devMode}
                                config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } } }} />}
                    </div>
                </Loader>
            </Content>
        </div>
};