import React from 'react';
import { Container } from 'react-bootstrap';

export default function QuestionNotifyScreen(props) {
    const simple_quest_num = props.simple_quest_num;
    const extra_quest_num = props.extra_quest_num;
    const all_questions = simple_quest_num + extra_quest_num;

    return <Container className="d-flex flex-column justify-content-center text-center align-items-center h-100 w-100">
            <h1 className='display-1 my-4'><b>{props.t('Pay attention')}</b></h1>
            <h1 className='display-4 my-4'><b>{props.t('The number of questions in the pre-shift testing has changed')}!</b></h1>
            <h1 className='display-8 my-4'>{props.t('Number of questions')}<b className='text-danger'>{all_questions}</b></h1>
            {simple_quest_num > 0 && <span className='display-7 my-1 text-muted'>{props.t('Pre-shift testing issues')}{simple_quest_num}</span>}
            {extra_quest_num > 0 && <span className='display-7 my-1 text-muted'>{props.t('Additional training issues')}{extra_quest_num}</span>}
            <h1 className='display-8 my-4'>{props.t('Testing will start')}: <b className='text-success'>{props.time}</b></h1>
           </Container>
};