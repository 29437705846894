import React, {Component} from "react";
import List from "../blocks/List";

class ListContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <List {...this.props} {...this.state}/>
    }
}

export default ListContainer;