import React from "react";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {isElectron} from "react-device-detect";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Breams from "../blocks/Breams";
import StepperNav from "../blocks/StepperNav";
import {addBreamb, resetBreamb} from "../../reducers/breams-manage";
import LoginCoursesContainer from "../containers/ElectronicCourses/LoginCoursesContainer";
import ElectronicClassContainer from "../containers/ElectronicCourses/ElectronicClassContainer";
import ManageStepper from "./ManageStepper";
import StartupContainer from "../containers/StartupContainer";
import SearchEmpContainer from "../containers/SearchEmpContainer";
import LdapContainer from "../containers/LdapContainer";
import RfidContainer from "../containers/RfidContainer";
import TechWork from "../screens/TechWork";

class StartupStepper extends ManageStepper {
    listStep = [];

    constructor(props) {
        super(props);

        this.props.history.push('/');

        if (isElectron && props.app.is_rfid && props.app.is_auth_only_rfid && props.app.isRfidWork) {
            this.listStep = [{
                key: 'rfid',
                title: this.props.t('Rfid login'),
                priority: 10,
                content: RfidContainer
            }];
        } else if (props.app.use_only_auth_with_student_login_and_password) {
            this.listStep = [{
                key: 'elcourses',
                title: this.props.t('Electronic courses'),
                priority: 10,
                content: LoginCoursesContainer
            }];
        } else if (props.app.modules.includes('learning_class') && props.app.use_to_learning_class) {
            this.listStep = [{
                key: 'elclass',
                title: this.props.t('Electronic courses'),
                priority: 10,
                content: ElectronicClassContainer
            }];
        } else {
            this.listStep = [{
                key: 'startup',
                title: this.props.t('Entry of personnel'),
                priority: 10,
                content: StartupContainer,
            }, {
                key: 'search_employee',
                title: this.props.t('Search employee'),
                priority: 50,
                content: SearchEmpContainer,
            }];
        }

        this.state = {
            activeState: 0,
            isVisible: true,
            isBack: true,
        };

        this._prepareListStep();
        resetBreamb();
    }

    _prepareListStep = () => this.listStep.forEach(item => this.addStep(item));

    toLdapStep = () => {
        this.teleporToStep('startup')
        .then(() => {
            this.unsetStepByKey('startup');
            this.listStep.forEach((item, i) => {
                if (item.key === 'startup') this.listStep.splice(i, 1);
            });
            this.listStep.unshift({
                key: 'ldap',
                title: this.props.t('Local account'),
                priority: 10,
                content: LdapContainer,
            });
            this.teleporToStep('ldap', 0);
            this._prepareListStep();
        });
    }

    toElCoursesStep = () => {
        this.teleporToStep('startup')
        .then(() => {
            this.unsetStepByKey('startup');
            this.listStep.forEach((item, i) => {
                if (item.key === 'startup') this.listStep.splice(i, 1);
            });
            this.listStep.unshift({
                key: 'elcourses',
                title: this.props.t('Electronic courses'),
                priority: 10,
                content: LoginCoursesContainer,
            });
            this.teleporToStep('elcourses', 0);
            this._prepareListStep();
        });
    }

    toStartupStepFromElCourse = () => {
        this.teleporToStep('elcourses')
        .then(() => {
            this.unsetStepByKey('elcourses');
            this.listStep.forEach((item, i) => {
                if (item.key === 'elcourses') this.listStep.splice(i, 1);
            });
            this.listStep.unshift({
                key: 'startup',
                title: this.props.t('Entry of personnel'),
                priority: 10,
                content: StartupContainer,
            });
            this.teleporToStep('startup', 0);
            this._prepareListStep();
        });
    }

    toStartupStep = () => {
        this.teleporToStep('ldap')
        .then(() => {
            this.unsetStepByKey('ldap');
            this.listStep.forEach((item, i) => {
                if (item.key === 'ldap') this.listStep.splice(i, 1);
            });
            this.listStep.unshift({
                key: 'startup',
                title: this.props.t('Entry of personnel'),
                priority: 10,
                content: StartupContainer,
            });
            this.teleporToStep('startup', 0);
            this._prepareListStep();
        });
    }

    teleporToStep = (keyStep, timeout = 500) => {
        resetBreamb();
        return new Promise((resolve, reject) => {
            this.listStep.forEach((step, indexStep) => {
                if (step.key === keyStep) {
                    this.setState({isVisible: false},
                        () => {
                            setTimeout(() => {
                                this.setActiveStep(indexStep);
                                let activeStep = this.getActiveStep();
                                this.setState({activeState: activeStep, isVisible: true});
                                addBreamb({title: this.listStep[activeStep].title});
                                resolve();
                            }, timeout);
                        });
                }
            })
        });
    }

    handleBackStepper = () => this.state.isBack &&
        this.setState({
            isVisible: false,
            isBack: false },
            () => {
                this.handleBack();
                setTimeout(() => this.setState({
                    activeState: this.getActiveStep(),
                    isVisible: true,
                    isBack: true}), 500);
        });

    render() {
        if (this.props.response_code === 503) {
            return <TechWork />
        };
        let activeStep = this.getActiveStep();
        let StepContent = this.listStep[activeStep].content;

        return <>
                {activeStep > 0 && <Breams/>}
                <Animated
                    className="d-flex flex-column h-100 w-100"
                    animationInDuration={500}
                    animationOutDuration={500}
                    animationIn="slideInRight"
                    animationOut="slideOutLeft"
                    isVisible={this.state.isVisible}>
                    <StepContent {...this.props}
                        teleporToStep={this.teleporToStep}
                        toLdapStep={this.toLdapStep}
                        toStartupStep={this.toStartupStep}
                        toElCoursesStep={this.toElCoursesStep}
                        toStartupStepFromElCourse={this.toStartupStepFromElCourse}/>
                </Animated>
                {activeStep > 0 &&
                    <StepperNav
                        activeStep={activeStep}
                        listStep={this.listStep}
                        handleBack={this.handleBackStepper} /> }
               </>
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        response_code: store.networkAnswer.response_code,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(StartupStepper)));