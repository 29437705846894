import React from 'react';

export default React.memo(function QuestionnaireItem(props) {
    const date = `${new Date(props.start_data.slice(0, -6)).toLocaleString().slice(0, -10)}`;

    let bg_color = '';
    let text_status = '';
    if (props.status === 'passed') {
        bg_color = 'success'
        text_status = props.t('Passed one')
    } else if (props.status === 'expired') {
        bg_color = 'danger'
        text_status = props.t('Expired one')
    } else {
        bg_color = 'warning'
        text_status = props.t('Not passed one')
    };

    return (
        <div className={"FeedbackItem"}>
            <div onClick={() => props.getOneSurveys(props.uuid, props.id)} className="FeedbackItem-Link d-flex justify-content-between w-100">
                <div className={`FeedbackItem-Column FeedbackItem-Status bg-${bg_color}`}>{text_status}</div>
                <div className={"FeedbackItem-Column FeedbackItem-Message LibraryText"}>{props.surveys_title}</div>
                <div className={"FeedbackItem-Column FeedbackItem-Date"}>{date}</div>
            </div>
        </div>
    )
});