import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import LibraryList from "../../screens/library/LibraryList";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import apiLibrary from "../../../api/apiLibrary";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import { errorNotify } from '../../../helpers/functions';

class LibraryListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            types: [],
            items: [],
        }

        resetBreamb();
        let types = this.props.match.params.types;
        this.getAllData(types);
    }

    componentDidMount() {
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Library')});
    }

    getMaterials = (types) => {
        loader.start('content_list');
        let employee_id = this.props.user.data.id;
        apiLibrary.getMaterials(employee_id, types)
        .then(response => this.setState({items: response.data.items}))
        .catch(e => errorNotify(this.props.t('Error loading materials by type'), e, this.props.history.push, this.props.user.isAuth, `/library/${this.props.match.params.types}`))
        .finally(() => loader.stop('content_list'));
    }

    getAllData = (types) => {
        loader.start('content_list');
        let employee_id = this.props.user.data.id;
        apiLibrary.getMaterials(employee_id, types)
        .then(response => this.setState({...response.data}))
        .catch(e => {
            const info = types === 'text_document' ? 'Error loading documents' : 'Error loading video instructions';
            errorNotify(this.props.t(info), e, this.props.history.push, this.props.user.isAuth);
        })
        .finally(() => loader.stop('content_list'));
    }

    onSelectType = (value) => {
        let types = value;
        if (value === 'all') types = this.props.match.params.types;
        this.getMaterials(types);
    }

    render() {
        return <LibraryList {...this.state} {...this.props} onSelectType={this.onSelectType}/>;
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(withTranslation()(LibraryListContainer));