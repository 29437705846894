import React from "react";
import {connect} from "react-redux";
import FatalError from "./components/screens/FatalError";
import appManage from "./reducers/app-manager";
import { setLog } from "./helpers/functions";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError = (error) => {
        alert(error.message);
        appManage.errorApp(error, 'Ошибка ErrorBoundary');
        if (this.props?.app.not_fatal_errors.length > 1) {
            return {hasError: false};
        } else {
            return {hasError: true};
        }
    }

    componentDidCatch(error, info) {
        setLog(info, error);
    }

    render() {
        if (this.state.hasError) {
            return <FatalError/>;
        }
        return this.props.children;
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
    };
};

export default connect(mapStateToProps)(ErrorBoundary);