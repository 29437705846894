const initialState = {
    network_speed: 0,
    response_code: 0,
    network_error: '',
}

export const SET_SPEED_AND_CODE = 'SET_SPEED_AND_CODE';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';

export default function networkAnswerReduser(state = initialState, action) {
    switch (action.type) {
        case SET_SPEED_AND_CODE:
            return {
                ...state,
                network_speed: action.network_speed,
                response_code: action.response_code,
            }
        case SET_NETWORK_ERROR:
            return {
                ...state,
                network_error: action.network_error,
            }
        default:
            return state;
    }
}