import React from 'react';
import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import modalManage from "../../reducers/modal-manage";

export default function BootstrapModal(props) {
    const modal = useSelector(store => store.modalState);
    const isShow = modal.idsModal.includes(props.modalId);

    const handleClose = () => {
        modalManage.close(props.modalId);
        props?.hasOwnProperty("handleClearStateEmployes") && props.handleClearStateEmployes();
    };

    React.useEffect(() => {
        return () => modalManage.close(props.modalId)
    }, []);

    return (
        <Modal dialogClassName={props.dialogClassName} show={isShow} onHide={handleClose} {...props.modalSettings} centered >
            {props.title &&
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title_cot">
                        {props.title}
                    </Modal.Title>
                </Modal.Header>}
            {props.children &&
                <Modal.Body>
                    {props.children}
                </Modal.Body>}
            {props.btns?.length > 0 &&
                <Modal.Footer>
                    {props.btns.map(el => el)}
                </Modal.Footer>}
        </Modal>
    )
}