import React from "react";
import { Form } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import RadioCheckboxTable from "./RadioCheckboxTable";
import ScaleTable from "./ScaleTable";

export default React.memo(function QuestionnaireQuestion(props) {
    const question = props.question;
    const important = question.important;
    const isNotAnswer = props.importants.includes(question.uuid);
    const answers = props.question.answers;
    const type = answers ? answers[0].type : '';
    const results = props.results.filter(el => el.question === question.uuid && el);
    const result_answer = results.length > 0 ? results[0].answer : [];
    let Content = <></>;
    if (question.type === 'text') {
        Content = <h2 className='border p-3 mb-0'>{question.text}</h2>
    } else {
        Content = <Form className={`p-3 mb-5 ${important && isNotAnswer && 'border border-danger'}`} id={question.uuid}>
                    <h2 className="display-7 font-weight-bold">
                        {important && type !== 'switch' && <b className='mr-1 text-danger'>*</b>}
                        {question.text}
                    </h2>
                    {type === 'radio' &&
                        <RadioCheckboxTable
                            answers={answers}
                            type={'radio'}
                            question={question}
                            handleClick={props.handleSelectRadio}
                            result_answer={result_answer} />}
                    {type === 'checkbox' &&
                        <RadioCheckboxTable
                            answers={answers}
                            type={'checkbox'}
                            question={question}
                            handleClick={props.handleSelectCheckbox}
                            result_answer={result_answer} />}
                    {type === 'text' &&
                        <Form.Control
                            type='text'
                            autoComplete='off'
                            id={question.uuid}
                            onClick={(e) => props.handleChangeInput(question.uuid, e.target.value)}
                            onChange={(e) => props.handleChangeText(question.uuid, e.target.value)}
                            placeholder={answers[0].params.placeholder}
                            className={`Textarea MessageField mx-auto form-control display-7 p-4`}
                        />}
                    {type === 'textarea' &&
                        <textarea
                            type='text'
                            id={question.uuid}
                            onClick={(e) => props.handleChangeInput(question.uuid, e.target.value)}
                            onChange={(e) => props.handleChangeText(question.uuid, e.target.value)}
                            placeholder={answers[0].params.placeholder}
                            rows="4"
                            className={`Textarea MessageField mx-auto form-control display-7 p-4`}
                        />}
                    {type === 'switch' &&
                        <div className={`d-flex align-items-center`}>
                            <BootstrapSwitchButton
                                checked={result_answer.includes(answers[0].uuid)}
                                onlabel={props.t('Yes')}
                                onstyle={props.theme_name === 'default_theme' ? 'success' : 'warning'}
                                offlabel={props.t('No')}
                                offstyle='dark'
                                style='mx-3'
                                size='lg'
                                onChange={() => props.handleSelectCheckbox(question.uuid, answers[0].uuid)}
                            />
                            <span className='display-7'>{answers[0].params.text}</span>
                        </div>}
                    {type === 'scale' &&
                        <ScaleTable
                            answers={answers[0]}
                            result_answer={result_answer}
                            question={question}
                            handleClick={props.handleSelectScale}
                            lang={props.lang} />}
                </Form>
    }

    return (
        <div className='mt-1 d-flex flex-column justify-content-start'>
            {Content}
        </div>
    );
});