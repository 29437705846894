import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Container} from "react-bootstrap";
import StepLabel from "@material-ui/core/StepLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import {hasIsProp, isTrueProp} from "../../helpers/functions";

class StepperNav extends Component {
    constructor(props) {
        super(props);

        if (!hasIsProp(props, 'activeStep')) throw new Error('Pass the prop activeStep');
        if (!hasIsProp(props, 'listStep')) throw new Error('Pass the prop listStep');
    }

    componentDidUpdate(prevProps) {
        if (this.props.titleNextStep !== prevProps.titleNextStep) this._renderNextTitle();
    }

    handleBack = () => this.props.handleBack && this.props.handleBack();

    _renderAdditionalButton = () => this.props?.hasOwnProperty('additionalButton') ? this.props.additionalButton : null;

    renderBackButton = () => this.props?.hasOwnProperty('handleBack')
                                ? <Button className="MuiButton Btn Btn_boring" onClick={this.handleBack}>
                                    <FontAwesomeIcon className="mr-4" icon={faArrowAltCircleLeft}/>
                                    {this.props.t('Back')}
                                </Button>
                                : null;

    _renderTitle = () => {
        let title = null,
            listStep = this.props.listStep;

        if (isTrueProp(this.props, 'titleStep')) {
            title = this.props.titleStep.slice(0, 50);
            if (this.props.titleStep.length > 50) title += "...";
        } else if (listStep.length > 0 && isTrueProp(listStep[this.props.activeStep], 'title')) {
            title = listStep[this.props.activeStep].title.slice(0, 50);
            if (listStep[this.props.activeStep].title.length > 50) title += "...";
        }

        return title ? <span className="MuiTypography-caption">{title}</span> : null;
    }

    _renderNextTitle = () => {
        let title = null,
            listStep = this.props.listStep,
            activeStep = this.props.activeStep;

        if (hasIsProp(this.props, 'titleNextStep')) {
            title = this.props.titleNextStep.slice(0, 50);
            if (this.props.titleNextStep.length > 50) title += "...";
        } else if (listStep.length > 0 && activeStep !== listStep.length - 1) {
            if (listStep[activeStep + 1]) {
                title = listStep[activeStep + 1].title.slice(0, 50);
                if (listStep[activeStep + 1].title.length > 50) title += "...";
            } else {
                title = listStep[listStep.length - 1].title.slice(0, 50);
                if (listStep[listStep.length - 1].title.length > 50) title += "...";
            }
        }

        return title ? <span className="MuiStepNextStep">{this.props.t('Next')}: {title}</span> : null;
    }

    _renderListStep = () => this.props.listStep.map((label, key) =>
                                <Step key={key} classes={{root: 'MuiStep-root_cot'}}>
                                    <StepLabel completed={false}/>
                                </Step>);

    _renderSteps = (length, activeStep, listStep) => {
        if (length > 10) {
            const count = activeStep + 1;
            const progress = this.props.t('Progress of execution');
            const of = this.props.t('of');
            return <span className="MuiTypography-caption mr-2">{progress} {count} {of} {length} |</span>
        } else {
            return <Stepper activeStep={activeStep}>{listStep}</Stepper>
        };
    };

    render = () => {
        let activeStep = this.props.activeStep;
        let length = this.props.listStep.length;
        let backButton = this.renderBackButton();
        let additionalButton = this._renderAdditionalButton();
        let titleStep = this._renderTitle();
        let titleNextStep = this._renderNextTitle();
        let listStep = this._renderListStep();
        let steps = this._renderSteps(length, activeStep, listStep);

        return <div className='MuiStepContainer'>
                <Container className="pb-1">
                    <div className="w-100">
                        <div className="my-auto pl-0 mx-auto row justify-content-between align-items-center">
                            <div className="mb-4 mb-lg-0">
                                <div className="d-flex flex-row">
                                    {steps}
                                    {titleStep}
                                </div>
                                {titleNextStep}
                            </div>
                            <div className="d-flex justify-content-end">
                                {backButton}
                                {additionalButton}
                            </div>
                        </div>
                    </div>
                </Container>
               </div>
    }
}

const mapStateToProps = (store) => {
    return {
        breamb: store.breambState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(StepperNav)));