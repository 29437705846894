import React from 'react';
import {Container, FormGroup} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import MainNav from "../../blocks/MainNav";
import Breams from "../../blocks/Breams";
import LibraryItem from "../../blocks/Library/LibraryItem";
import ListContainer from "../../containers/ListContainer";
import Loader from "../../blocks/Loader";
import Select from "../../blocks/Select";

function LibraryList(props) {
    document.title = props.t('Library');
    const materialsSort = (items) => {
        let unread_important = [];
        let unread = [];
        let read_important = [];
        let read = [];
        items.forEach(item => {
            if (item.unread && item.important) unread_important.push(item);
            if (item.unread && !item.important) unread.push(item);
            if (!item.unread && item.important) read_important.push(item);
            if (!item.unread && !item.important) read.push(item);
        });
        let allMaterials = [...unread_important, ...unread, ...read_important, ...read];
        return allMaterials;
    };
    const materials = materialsSort(props.items);
    const types = {all: props.t('All'), ...props.types};
    let ListMaterials = null;
    if (materials.length > 0) {
        ListMaterials = <ListContainer listItems={materials} templateItem={LibraryItem} library/>;
    } else {
        ListMaterials =
            <h4 className="Text_success font-weight-medium display-8 text-center">{props.t('You have no materials')}.</h4>;
    };
    const type_keys = Object.keys(types);

    return <>
            <Breams/>
            {type_keys.length > 2 &&
                <div className={"Screen-Panel pt-4"}>
                    <Container>
                        <FormGroup className="d-flex align-items-center justify-content-between">
                            <Select onSelectCallback={props.onSelectType} className="FeedbackAdd-SelectType"
                                    name="theme_id" textLabel={props.t('Type material')}>
                                {type_keys.map(key => <option value={key} key={key}>{types[key]}</option>)}
                            </Select>
                        </FormGroup>
                    </Container>
                </div>}
            <div className="Screen container mx-auto">
                <Loader isLoading={props.load.isLoading} loadId={"content_list"} justifyContent="center">
                    {ListMaterials}
                </Loader>
            </div>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Personal Area')}</span>
                    </NavLink>
                    <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                        <span>{props.t('Exit')}</span>
                    </NavLink>
                </Container>
            </MainNav>
        </>
}

export default withTranslation()(LibraryList);