import apiHandling from "./apiHandling";

class apiLibrary extends apiHandling {
    static getMaterials = (employee_id, types) => this.req({
            method: 'get',
            action: `library/items`,
            data: {
                employee_id: employee_id,
                types: types,
            }
        });

    static getMaterial = (employee_id, uuid) => this.req({
            method: 'get',
            action: `library/items`,
            data: {
                employee_id: employee_id,
                uuid: uuid,
            }
        });

    static setMarkRead = (employee_id, uuid, file, fileName) => {
        const formData = new FormData();
        formData.append('uuid', uuid);
        formData.append('employee_id', employee_id);
        file && formData.append('file', file, `${fileName}.png`);
        fileName && formData.append('fileName', fileName);

        return this.req({
                method: 'post',
                action: 'library/items',
                data: formData,
            });
    }
}

export default apiLibrary;