import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ScrollBlock from "./ScrollBlock";

function Content(props) {
    return <ScrollBlock style={{overflowY: 'auto'}} className="">
            <div style={(props?.hasOwnProperty('style')) ? props.style : null} className={`Content ${props?.hasOwnProperty('className') ? props.className : ''}`}>
                {props.children}
            </div>
           </ScrollBlock>
}

const mapStateToProps = (store) => {
    return {
        breamb: store.breambState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(Content)));