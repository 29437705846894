import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function Timer(props) {
    const [seconds, setSeconds] = useState(60);
    const [persent, setPersent] = useState(100);
    const timer = useRef();

    const handleTimeExpired = () => props?.hasOwnProperty('handleTimeExpired') && props.handleTimeExpired();

    useEffect(() => {
        if (seconds > 0) {
            timer.current = setTimeout(() => {
                setSeconds(seconds - 1);
                setPersent(persent - (persent / seconds));
            }, 1000);
        } else {
            setSeconds(0);
            handleTimeExpired();
        };
        return () => clearTimeout(timer.current);
    });    

    return <div className={"quest__timer mb-0"}>
            <CircularProgressbar
                value={`${persent}`}
                text={`${seconds}`}
                styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'butt',
                    textSize: '45px',
                    pathTransitionDuration: 0.5,
                    pathColor: `#dc3545`,
                    textColor: '#dc3545',
                    trailColor: 'rgb(207, 207, 207)',
                })} />
           </div>
}