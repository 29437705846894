import { faArrowLeft, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isElectron } from "react-device-detect";
import { NavLink } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import configApp from "../../../config/config";
import { focusInput, isTrueProp } from "../../../helpers/functions";
import Content from "../../blocks/Content";
import Loader from "../../blocks/Loader";

export default function LoginCourses(props) {
  const [showKeyboard, setShowKeyboard] = useState(true);
  let messageError = '',
      passwordError = '',
      classDisable = ' disabled',
      formErrors = props.formErrors;
  if (props.formValid) classDisable = '';
  if (formErrors?.hasOwnProperty('error') && formErrors.error.length > 0) {
    let err = formErrors.error[0];
    if (err == 401) {
      messageError = props.t('Wrong login or password');
    } else if (err == 500) {
      messageError = props.t('Network Error');
    } else {
       messageError = err;
    }
  };
  if (formErrors?.hasOwnProperty('login') && formErrors.login.length > 0) messageError = formErrors.login[0];
  if (formErrors?.hasOwnProperty('password') && formErrors.password.length > 0) passwordError = formErrors.password[0];

  useEffect(() => {
    focusInput("elCourseLoginInput");
  }, []);

  return <>
          <div className={"Screen startup"}>
            <Content className="container">
              <div className="overflow-hidden">
                <div className="d-flex flex-column h-100">
                  <Loader isLoading={props.load.isLoading} loadId={"item"} justifyContent="center">
                    <div className={"startup__container startup__container_bn1"}>
                      <div className={"startup__navi"}>
                        <FormGroup className="text-center">
                          <FormLabel className={"startup__tabel-label mb-0"}>
                            {props.t('Electronic courses')}
                            <OverlayTrigger placement="top"
                                            overlay={
                                                <Tooltip id="button-tooltip" className={"tooltip error"}>
                                                  <i className="fas fa-exclamation-triangle mr-3"> </i> {(messageError) ? messageError : null}
                                                </Tooltip>}
                                              show={!!(messageError)} >
                              <FormControl type="text"
                                            minLength="1"
                                            required
                                            name="login"
                                            id="elCourseLoginInput"
                                            placeholder={props.t('Login')}
                                            onInput={(event) => props.onInputLogin(event.currentTarget.value)}
                                            className={`startup__tabel-field mx-auto mt-3`}
                                            onClick={() => props.handleChangeActiveInput('login')} />
                            </OverlayTrigger>
                          </FormLabel>
                        </FormGroup>
                        <FormGroup className="text-center">
                          <FormLabel className={"startup__tabel-label mb-0"}>
                            <OverlayTrigger placement="top"
                                            overlay={
                                                <Tooltip id="button-tooltip" className={"tooltip error"}>
                                                  <i className="fas fa-exclamation-triangle mr-3"> </i> {(passwordError) ? passwordError : null}
                                                </Tooltip>}
                                              show={!!(passwordError)} >
                              <FormControl type="password"
                                            minLength="1"
                                            required
                                            name="password"
                                            placeholder={props.t('Password')}
                                            onInput={(event) => props.onInputPassword(event.currentTarget.value)}
                                            className={`startup__tabel-field mx-auto mt-3`}
                                            onClick={() => props.handleChangeActiveInput('password')} />
                            </OverlayTrigger>
                          </FormLabel>
                        </FormGroup>
                        <FormGroup className="text-center">
                          <NavLink onClick={props.routeGuider}
                                  linkto={`/electronic-courses`}
                                  to={`/electronic-courses`}
                                  className={`startup__submit btn Btn_success ${classDisable} mx-auto`}
                                  style={{display: "block"}} >
                            {props.t('Enter')}
                          </NavLink>
                        </FormGroup>
                        {!props.app.use_only_auth_with_student_login_and_password &&
                          <div className="mb-0 text-center mt-2">
                            <span onClick={props.toStartupStepFromElCourse} className="startup__link">
                              <FontAwesomeIcon className="mr-3" icon={faArrowLeft}/>
                              {props.t('Back')}
                            </span>
                          </div>}
                      </div>
                    </div>
                    {(isTrueProp(configApp.device_settings, 'keyboard') || isElectron) &&
                      <div className="container mw-75 mt-5">
                        <div className="keyboardShowBtn">
                          <button type="button" className="keyboardShowButtton" onClick={() => setShowKeyboard(!showKeyboard)}>
                            <FontAwesomeIcon icon={showKeyboard ? faChevronDown : faChevronUp} />
                          </button>
                        </div>
                        <div className={`mx-4 keywoard-container search-emp_keywoard-container ${showKeyboard ? null : "unShowKeyboardClass"}`}>
                          <Keyboard className="px-4" {...props.keyboardConfig} inputName={props.active_input} />
                        </div>
                      </div>}
                  </Loader>
                </div>
              </div>
            </Content>
          </div>
         </>
};