import apiHandling from "./apiHandling";

class apiRisks extends apiHandling {
    static getStatistic = (uuid, data) => this.req({
            method: 'get',
            action: `risks/${uuid}`,
            data: data
        });

    static reviewedRisks = (uuid) => this.req({
            method: 'post',
            action: `risks/setViewRisks`,
            data: JSON.stringify({uuid: uuid}),
        });
}

export default apiRisks;
