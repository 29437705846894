import React, {Component} from "react";
import {Container, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import QuestionnairesContainer from "./QuestionnairesContainer";
import QuestionnaireList from "../screens/questions/questionnaires/QuestionnaireList";
import QuestionnairePage from "../screens/questions/questionnaires/QuestionnairePage";
import QuestionnaireNotify from "../screens/questions/questionnaires/QuestionnaireNotify";
import Loader from "../blocks/Loader";
import Breams from "../blocks/Breams";
import MainNav from "../blocks/MainNav";
import {notify} from "../blocks/Notify";
import {addBreamb, resetBreamb, goBackStep} from "../../reducers/breams-manage";
import {pageLoadManageActions as loader} from "../../reducers/load";
import apiSurveys from "../../api/apiSurveys";
import { errorNotify } from "../../helpers/functions";
import userManage from "../../reducers/user-manage";

class QuestionnairesListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            surveys: [],
            questionnaires: [],
            selected_survey: null,
            survey: null,
            startSurvey: 0,
            isShowNotify: false,
            time: 5,
        };

        document.title = props.t('Survey');
        resetBreamb();
        addBreamb({title: props.t('Personal Area')});
        addBreamb({title: props.t("Surveys")});
    }

    componentDidMount() {
        if (this.props.user?.data?.hasOwnProperty('important_survey') &&
            this.props.user.data.important_survey !== null &&
            this.props.user.data.important_survey.appointment_id &&
            this.props.user.data.important_survey.survey_uuid) {
            this.getImportantSurvey(this.props.user.data.important_survey.survey_uuid, this.props.user.data.important_survey.appointment_id);
        } else {
            this.getSurveys();
        }
    };

    componentWillUnmount() {
        clearInterval(this.interval)
    };

    getSurveys = () => {
        loader.start('questionnair')
        apiSurveys.getSurveys(this.props.user.data.uuid)
        .then(res => this.setState({surveys: res.data}))
        .catch(e => errorNotify(this.props.t('Surveys loading error'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('questionnair'))
    };

    getOneSurveys = (survey_uuid, id) => {
        loader.start('questionnair')
        apiSurveys.getOneSurvey(survey_uuid, id)
        .then(res => this.setState({selected_survey: res.data}))
        .catch(e => errorNotify(this.props.t('Survey loading error'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('questionnair'))
    };

    goToSurvey = () => this.setState({
        survey: this.state.selected_survey.survey,
        startSurvey: new Date().getTime(),
    });

    getImportantSurvey = (survey_uuid, id) => {
        loader.start('questionnair')
        apiSurveys.getOneSurvey(survey_uuid, id)
        .then(res => this.setState({
            selected_survey: res.data,
            survey: res.data.survey,
            isShowNotify: true,
        }, () => {
            this.interval = setInterval(() => {
                const time = this.state.time;
                if (time > 0) {
                    this.setState({time: time - 1});
                } else {
                    this.setState({
                        time: 5,
                        isShowNotify: false,
                        startSurvey: new Date().getTime(),
                    }, () => clearInterval(this.interval));
                }
            }, 1000);
        }))
        .catch(async (e) => {
            errorNotify(this.props.t('Survey loading error'), e, this.props.history.push, this.props.user.isAuth);
            await this.handleChangeUserData();
        })
        .finally(() => loader.stop('questionnair'))
    };

    sendResults = (results) => {
        loader.start('questionnair')
        apiSurveys.setSurvey(this.props.user.data.uuid, results)
        .then(res => {
            notify(this.props.t('The survey results have been sent'), 'success');
            this.setState({
                surveys: res.data,
                survey: null,
                selected_survey: null,
            })
        })
        .catch(e => errorNotify(this.props.t('Error when sending the survey result'), e, this.props.history.push, this.props.user.isAuth))
        .finally(async () => {
            goBackStep();
            await this.handleChangeUserData();
            loader.stop('questionnair');
        })
    };

    handleChangeUserData = async () => {
        if (this.props.user?.data?.hasOwnProperty('important_survey') &&
            this.props.user.data.important_survey !== null) {
                const user_data = userManage.getData();
                const new_user_data = {
                    ...user_data,
                    important_survey: null,
                };
                await userManage.authorized(new_user_data)
                .then(userManage.setIntervalGetExtData);
            };
    };

    render() {
        let handleBack = () => this.props.history.push('/cabinet');
        let text_back_button = this.props.t('Personal Area');

        let Content = <></>;
        if (this.state.isShowNotify) {
            Content = <QuestionnaireNotify time={this.state.time} />
        } else if (this.state.survey) {
            Content = <QuestionnairesContainer
                        survey={this.state.survey}
                        sendResults={this.sendResults}
                        id={this.state.selected_survey.assignment.id}
                        startSurvey={this.state.startSurvey} />
        } else if (this.state.selected_survey) {
            text_back_button = this.props.t('Back');
            handleBack = () => this.setState({selected_survey: null})
            Content = <QuestionnairePage survey={this.state.selected_survey} goToSurvey={this.goToSurvey} t={this.props.t} />
        } else {
            Content = <QuestionnaireList surveys={this.state.surveys} getOneSurveys={this.getOneSurveys} t={this.props.t} />
        }

        return (
            <Loader className='h-100' isLoading={'questionnair'} loadId={'questionnair'} justifyContent='center'>
                <Breams/>
                {Content}
                {!this.state.survey &&
                    <MainNav>
                        <Container className={`d-flex justify-content-between`}>
                            <Button onClick={handleBack} className={`Btn Btn_success MuiButton`}>
                                <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                <span>{text_back_button}</span>
                            </Button>
                            <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                                <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                                <span>{this.props.t('Exit')}</span>
                            </NavLink>
                        </Container>
                    </MainNav>}
            </Loader>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        locale: store.localeState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(QuestionnairesListContainer)));