import React, { Component } from "react";
import {withTranslation} from "react-i18next";

/**
 *
 * @param WrappedFormComponent
 * @param config
 * @returns {WithFormHanlding}
 */
export default function withFormHanlding(WrappedFormComponent, config = {}) {
    class WithFormHanlding extends Component {
        /**
         * State errors
         * @type {{}}
         * @private
         */
        _formErrors = {
            //exampleKey: ['ExampleError1', 'ExampleError2']
        };

        document = document;

        _fieldsValid = {};

        constructor(props) {
            super(props);

            this.state = {
                formErrors: {},
                formValues: {},
                formValid: false,
                data: [],
                onSubmit: () => {}
            };

            this.onKeyPressed = this.onKeyPressed.bind(this);
        }

        /**
         * Return css class for field has error
         * @param error
         * @returns {string}
         */
        errorClass(error) {
            return (error.length === 0 ? 'border-success' : 'border-danger');
        }

        componentWillMount() {
            window.addEventListener('keydown', this.onKeyPressed);
        }

        componentWillUnmount() {
            window.removeEventListener('keydown', this.onKeyPressed);
        }

        onKeyPressed = (e) => {
            if (e.code === 'Enter') {
                if (this.state?.hasOwnProperty('onSubmit')) this.state.onSubmit(this.state.formValues);
                return false;
            }
        }

        /**
         *
         * @param state
         * @returns {Promise<unknown>}
         */
        prepareFormState = (state) => {
            return new Promise((resolve, reject) => {
                let currentState = this.state;
                this.setState({
                    ...currentState, ...state
                }, () => {
                    this._formErrors = this.state.formErrors;
                    for (let keyError in this._formErrors) {
                        this._fieldsValid[keyError] = false;
                    }
                    resolve();
                });
            });
        }

        /**
         * Check email
         * @param fieldName
         * @param value
         * @private
         */
        _validateFieldEmail = (fieldName, value) => {
            let fieldValidationErrors = this._formErrors;
            let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) fieldValidationErrors[fieldName][0] = this.props.t('Incorrect email');
            this._formErrors = {...this._formErrors};
            this._formErrors[fieldName] = fieldValidationErrors[fieldName];
        }

        /**
         * Check value empty
         * @param fieldName
         * @param value
         * @private
         */
        _validateFieldNotEmpty = (fieldName = '', value = '') => {
            let fieldValidationErrors = this._formErrors;
            let fieldValid = (value.length > 0);
            if (!fieldValid) fieldValidationErrors[fieldName][0] = this.props.t('Required');
            this._formErrors[fieldName] = fieldValidationErrors[fieldName];
        }

        /**
         * Check value length
         * @param fieldName
         * @param value
         * @param requireds
         * @private
         */
        _validateFieldLength = (fieldName, value, requireds = {}) => {
            let fieldValidationErrors = this._formErrors;
            if (requireds?.hasOwnProperty('minLength') && requireds.minLength) {
                let fieldValid = (value.length >= requireds.minLength);
                if (!fieldValid) fieldValidationErrors[fieldName][0] = `${requireds.minLength} ${this.props.t('characters minimum')}`;
            };
            if (requireds?.hasOwnProperty('maxLength') && requireds.maxLength) {
                let fieldValid = (value.length <= requireds.maxLength);
                if (!fieldValid) fieldValidationErrors[fieldName][0] = `${requireds.maxLength} ${this.props.t('characters max')}`;
            };
            this._formErrors[fieldName] = fieldValidationErrors[fieldName];
        }

        // prop for check password and confirm password
        _passwordFieldValue = {};

        /**
         * Check password
         * @param fieldName
         * @param value
         * @private
         */
        _validateFieldPassword = (fieldName, value) => {
            let fieldValidationErrors = this._formErrors;
            // TODO: тут какая-то проверка на сложность пароля...
            let passwordValid = true;
            if (!passwordValid) {
                fieldValidationErrors[fieldName] = [];
                fieldValidationErrors[fieldName][0] = this.props.t('Password is too simple');
            };
            this._formErrors[fieldName] = fieldValidationErrors[fieldName];
            this._passwordFieldValue[fieldName] = value;
        }

        /**
         * Check password2
         * @param fieldName
         * @param value
         * @param keyPassword
         * @private
         */
        _validateFieldConfirmPassword = (fieldName, value, keyPassword) => {
            if (this._passwordFieldValue?.hasOwnProperty(keyPassword)) {
                let fieldValidationErrors = this.state.formErrors;
                let passwordConfirmValid = (value === this._passwordFieldValue);
                if (!passwordConfirmValid) {
                    fieldValidationErrors[fieldName] = [];
                    fieldValidationErrors[fieldName][0] = this.props.t('Password mismatch');
                };
                this._formErrors[fieldName] = fieldValidationErrors[fieldName];
            }
        }

        /**
         * Check all fields and set status valid form
         */
        validateForm = () => {
            return new Promise((resolve, reject) => {
                let formValid = true;
                for (let error in this.state.formErrors) {
                    if (error === 'detail') continue;
                    if (error === 'error') continue;
                    if (this._fieldsValid?.hasOwnProperty(error)) {
                        if (!this._fieldsValid[error]) {
                            formValid = false;
                            break;
                        }
                    };
                }
                this.setState({formValid: formValid}, () => resolve());
            });
        }

        /**
         * @returns {Promise<unknown>}
         */
        clearErrors = () => new Promise((resolve, reject) => this.setState({formErrors: {}}, () => resolve()));

        /**
         * @param target
         */
        validateField = (target) => {
            let formValues = this.state.formValues,
                formErrors = this.state.formErrors,
                attrName = target.getAttribute('name'),
                attrValue = target.value,
                attrType = target.getAttribute('type');
            this._formErrors[attrName] = [];

            if (!attrName) {
                throw new NotHasAttribute('Element does not contain an name attribute');
            };
            if (!attrType) {
                throw new NotHasAttribute('Element does not contain an type attribute');
            };
            if (attrType === 'password') this._validateFieldPassword(attrName, attrValue);
            if (attrType === 'email') this._validateFieldEmail(attrName, attrValue);
            if (target.attributes?.hasOwnProperty('forconfirm')) {
                let keyPassword = target.getAttribute('forconfirm');
                this._validateFieldConfirmPassword(attrName, attrValue, keyPassword);
            };
            if (target.attributes?.hasOwnProperty('minlength') || target.attributes?.hasOwnProperty('maxlength')) {
                let minLength = target.getAttribute('minlength');
                let maxLength = target.getAttribute('maxlength');
                this._validateFieldLength(attrName, attrValue, {
                    minLength: minLength,
                    maxLength: maxLength
                });
            };
            if (target.attributes?.hasOwnProperty('required')) {
                if (attrType === 'checkbox') {
                    let isChecked = target.checked ? 'true' : '';
                    this._validateFieldNotEmpty(attrName, isChecked);
                } else {
                    this._validateFieldNotEmpty(attrName, attrValue);
                }
            };
            formValues[attrName] = attrValue;
            formValues = {...formValues};
            formErrors = {...this._formErrors};
            this._fieldsValid[attrName] = (this._formErrors[attrName].length === 0);
            this.setState({
                formErrors: formErrors,
                formValues: formValues,
            }, () => this.validateForm());
            this.forceUpdate();
        }

        render() {
            return <WrappedFormComponent {...this.props} {...this.state}
                        prepareFormState={this.prepareFormState}
                        errorClass={this.errorClass}
                        validateField={this.validateField}
                        validateForm={this.validateForm}
                        clearErrors={this.clearErrors}
                        onSubmit={this.state.onSubmit} />;
        }
    }

    WithFormHanlding.displayName = `WithSubscription(${getDisplayName(WrappedFormComponent)})`;

    return withTranslation()(WithFormHanlding);
}

/**
 * @param WrappedComponent
 * @returns {*|string}
 */
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * @param message
 * @constructor
 */
const NotHasAttribute = (message) => {
    this.message = message;
    this.name = 'Not has attribute';
}

/**
 * @param message
 * @constructor
 */
const NotHasSubmitHandler = (message) => {
    this.message = message;
    this.name = 'Not has submit handler';
}