import { faArrowLeft, faCheck, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ExitTimer from "../../blocks/ExitTimer";
import MainNav from "../../blocks/MainNav";

export default function EndLesson(props) {
  const [spentTme, setSpentTime] = useState(0);
  const [competence, setCompetence] = useState(0);

  const exitCourse = () =>
    props.location.pathname === "/electronic-courses-class"
    ? props.handleChangeContent("Course", props.activeCourse.appoint.uuid)
    : props.handleChangeContent("AllCourses");

  useEffect(() => {
    let active_lesson = [];
    props.activeCourse.lessons.forEach(el => el.result.complete && active_lesson.push(el));
    let time = active_lesson[active_lesson.length - 1].result.spent_time;
    let comp = active_lesson[active_lesson.length - 1].result.competence;
    setSpentTime(time);
    setCompetence(comp);
  }, []);
  
  return <>
      <div className="Screen container d-flex flex-column justify-content-center align-items-center">
        <h1 className="preshift__text mb-4">{props.t("End of lesson")}</h1>
        <FontAwesomeIcon icon={faCheck} className="Success-Ok mb-5"/>
        <span className="text-center display-8 mb-4">{`${props.t('Time spent')} ${spentTme}`}</span>
        <span className="text-center display-8 mb-4">{`${props.t('Correct answers')} ${competence}%.`}</span>
        <Container className={`d-flex justify-content-center`}>

          <Button onClick={() => {if (props.is_exit_timer) props.handleChangeContent("QuestionStepper")}} className={`Btn Btn_success MuiButton mr-4`}>
            <span>{props.t('Next lesson')}</span>
            <ExitTimer theme_name={props.app.theme_name} goOut={() => props.handleChangeContent("QuestionStepper")} handleExitTimer={props.handleExitTimer}/>
          </Button>
          <Button onClick={() => {if (props.is_exit_timer) exitCourse()}} className={`Btn Btn_success MuiButton`}>
            <span>{props.t("Exit the course")}</span>
          </Button>
        </Container>
      </div>
      {props.location.pathname !== "/electronic-courses-class" && props.is_exit_timer &&
        <MainNav className={`main-navi`}>
          <Container className={`d-flex justify-content-end`}>
            <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton mr-4`}>
              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
              <span>{props.t('Personal area')}</span>
            </NavLink>
            <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
              <span>{props.t('Exit')}</span>
            </NavLink>
          </Container>
        </MainNav>}
    </>
}
