import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * 
 * @param {string} text 
 * @param {'info' | 'success' | 'warning' | 'error' | 'default'} type 
 * @returns 
 */
export const notify = (text, type) => toast(text, {type: type, className: 'display-9'});
 //type = 'info' | 'success' | 'warning' | 'error' | 'default'

export function ShowNotify() {
    return <ToastContainer closeButton={false} autoClose={4000} />
};