import {NavLink} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Container, Button} from "react-bootstrap";
import HighchartsReact from 'highcharts-react-official';
import Screen from "../blocks/Screen";
import Loader from "../blocks/Loader";
import MainNav from "../blocks/MainNav";
import Breams from "../blocks/Breams";
import img4 from "../../assets/risk/4.png";
import img21 from "../../assets/risk/21.png";
import img41 from "../../assets/risk/41.png";
import ExitTimer from "../blocks/ExitTimer";
import userManage from "../../reducers/user-manage";

export default function EmpLvl(props) {
    const [backButton, setBackButton] = useState(<></>);
    const [isDisabled, setIsDisabled] = useState(true);
    const isLoading = props.load.isLoading;
    const viewRisks = props.user.data.viewRisks;
    const target_level = props.data.target_level;
    const last_risk = props.data.last_risk;
    let nodeResult = null,
        nodeResult2 = null,
        nodeComment = null,
        timeout;

    if (props.data.risks.length > 0) {
        if (last_risk === null) {
            nodeResult = <div>{props.t('There is not enough data to compare risks with last month')}</div>
        } else if (last_risk > target_level) {
            let diff = Math.floor(last_risk / target_level);
            nodeResult = <>
                            <div>{props.t('In the past month, your risk compared to your target is worse in')}</div>
                            <div className="display-6 ml-4 mr-2 font-weight-bold text-right ">{diff} {props.t('once')}</div>
                         </>
        } else if (last_risk < target_level) {
            let diff = Math.floor(target_level / last_risk);
            nodeResult = <>
                            <div>{props.t('In the past month, your risk compared to your target is better in')}</div>
                            <div className="display-6 ml-4 mr-2 font-weight-bold text-right ">{diff} {props.t('once')}</div>
                         </>
        } else {
            nodeResult = <div>{props.t('In the past month, your risk is equal to the target')}</div>
        }
        nodeResult2 = <>
                        <div>{props.t('Competence score last month')}:</div>
                        <div className="display-3 ml-4 mr-2 font-weight-bold text-right">{props.data.risks[props.data.risks.length - 1].averageCompetence}</div>
                     </>
        nodeComment = <div className="p-2">{props.data.risks[props.data.risks.length - 1].comment}</div>
    } else {
        nodeResult = <div className="display-6 w-100 text-center py-3">{props.t('You don\'t have statistics')}</div>
        nodeComment = <div className="p-2">{props.t('Due to the insufficient number of pre-shift express training-testing, your level of competence is reduced, which can make your stay at the workplace potentially dangerous')}. {props.t('To reduce the risk of injury, we recommend that you receive additional training in safe methods and techniques for performing work.')}</div>
    };

    const handleReviewedRisks = () => {
        !isDisabled && props.handleReviewedRisks();
        setIsDisabled(true);
    };

    useEffect(() => {
        if (!viewRisks) {
            setBackButton(
                <Container className={`d-flex justify-content-start`}>
                    <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        {props.t('Personal Area')}
                    </NavLink>
                </Container>);
        } else {
            userManage.clearIntervalGetExtData();
            setBackButton(
                <Container className={`d-flex justify-content-end`}>
                    <Button onClick={handleReviewedRisks} className={`Btn Btn_success MuiButton ${isDisabled ? 'disabled' : ''}`}>
                        {props.t('Read')}
                        {!isDisabled &&
                            <ExitTimer theme_name={props.app.theme_name} goOut={props.handleReviewedRisks} handleExitTimer={()=>setIsDisabled(true)} sec={15} />}
                    </Button>
                </Container>);
            if (!isLoading && !timeout) timeout = setTimeout(() => setIsDisabled(false), 3000);
        }
        return () => clearTimeout(timeout);
    }, [isLoading, viewRisks, isDisabled]);

    return <>
            <Breams/>
            <Screen className="container EmpLvl">
                <Loader className="h-100" isLoading={isLoading} loadId={"emplvl_screen"} justifyContent="center">
                    <div id='phantom_content' className="EmpLvl-ChartContainer">
                        <p className="text-center display-7 mb-1 font-weight-bold">{props.t('Personal Risk Report')}</p>
                        <p className="text-center display-7 mb-3">{props.t('Graph of changes in the occupational risk of injury due to incompetent actions')}</p>
                        <div className="mb-3 position-relative">
                            <div className="highchartsReact_not_hover position-absolute"></div> {/* Чтобы избежать ошибку с hover у HighchartsReact */}
                            <HighchartsReact highcharts={props.Highcharts} options={props.options} />
                        </div>
                        <p className="text-center display-9 mb-4">{props.t('Conditional designations')}</p>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="EmpLvl-Label">
                                <span className="EmpLvl-LabelIcon EmpLvl-LabelIcon_cube"></span>
                                <span className="EmpLvl-LabelText">{props.t('Target risk level')}</span>
                            </div>
                            <div className="EmpLvl-Label">
                                <img className="EmpLvl-LabelIcon" src={img21}/>
                                <span className="EmpLvl-LabelText">{props.t('Low Risk')}</span>
                            </div>
                            <div className="EmpLvl-Label">
                                <img className="EmpLvl-LabelIcon" src={img41}/>
                                <span className="EmpLvl-LabelText">{props.t('Medium risk')}</span>
                            </div>
                            <div className="EmpLvl-Label">
                                <img className="EmpLvl-LabelIcon" src={img4}/>
                                <span className="EmpLvl-LabelText">{props.t('High risk')}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between flex-column flex-md-row">
                            <div className="EmpLvl-Result">
                                {nodeResult &&
                                    <div className="EmpLvl-ResultInf EmpLvl-ResultInf_darken">
                                        {nodeResult}
                                    </div>}
                                {nodeResult2 &&
                                    <div className="EmpLvl-ResultInf EmpLvl-ResultInf_lighten">
                                        {nodeResult2}
                                    </div>}
                            </div>
                            <div className="EmpLvl-Comment ml-md-3">
                                <div className="EmpLvl-CommentTitle">{props.t('Comment')}</div>
                                {nodeComment}
                            </div>
                        </div>
                    </div>
                </Loader>
            </Screen>
            <MainNav className={`main-navi`}>
                {backButton}
            </MainNav>
           </>
}