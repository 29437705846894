import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Keyboard from "react-simple-keyboard";

function SetApiAndTerminalName(props) {
    const [showKeyboard, setShowKeyboard] = useState(false);
    const ip_adress = props.ip_adress;
    const pass = props.pass;
    const platform = props.platform;
    const use_terminal_name = props.device_settings.use_terminal_name;
    const app_name = props.device_settings.appName;
    const terminal_name = props.device_settings.name;

    return <div  className="w-75 d-flex flex-column justify-content-center align-items-center">
            <FormGroup className="text-center w-100 mb-5">
                <FormLabel className={"startup__tabel-label mb-0 w-100 d-flex flex-column justify-content-center align-items-center mb-4"}>
                    {props.t('Enter IP address of the server')}
                    <FormControl
                        type="text"
                        minLength="1"
                        required
                        name="ip_adress"
                        value={ip_adress}
                        placeholder={"http://yourlocalhost"}
                        onChange={props.onInputChange}
                        onClick={props.handleActiveInput}
                        className={`startup__tabel-field mt-3 mw-75`} />
                </FormLabel>
                {platform !== 'win32' &&
                    <FormLabel className={"startup__tabel-label mb-0 w-100 d-flex flex-column justify-content-center align-items-center"}>
                        {props.t('Linux password')}
                        <FormControl
                            type="text"
                            minLength="0"
                            name="pass"
                            value={pass}
                            placeholder={"password"}
                            onChange={props.onInputChange}
                            onClick={props.handleActiveInput}
                            className={`startup__tabel-field mt-3 mw-75`} />
                    </FormLabel>}
            </FormGroup>
            {use_terminal_name !== undefined &&
                <div>
                    <div className={`d-flex justify-content-start align-items-center ${!use_terminal_name && "mb-5"}`}>
                        <input
                            type="checkbox"
                            checked={use_terminal_name}
                            name="use_terminal_name"
                            id="use_terminal_name"
                            onChange={props.onInputChange}
                            className="set_settings_input mr-4" />
                        <label className="set_settings_label" htmlFor="use_terminal_name">{props.t("Use terminal name") + terminal_name}</label>
                    </div>
                    {!use_terminal_name &&
                        <div className="set_devices_inputs m-5">
                            <div className="d-flex justify-content-start align-items-center">
                                <label className="set_settings_label mr-2" htmlFor="appName">appName</label>
                                <FormControl
                                    type="text"
                                    minLength="1"
                                    required
                                    name="appName"
                                    id="appName"
                                    value={app_name}
                                    placeholder="appName"
                                    onChange={props.onInputChange}
                                    onClick={props.handleActiveInput}
                                    className={`startup__tabel-field mr-2`} />
                            </div>
                        </div>}
                </div>}
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="keyboardShowBtn">
                    <button type="button" className="keyboardShowButtton" onClick={() => setShowKeyboard(!showKeyboard)}>
                        <FontAwesomeIcon icon={showKeyboard ? faChevronUp : faChevronDown} />
                    </button>
                </div>
                <div className={`mx-4 keywoard-container search-emp_keywoard-container ${showKeyboard ? null : "unShowKeyboardClass"}`}>
                    <Keyboard className="px-4" {...props.keyboardConfig} />
                </div>
            </div>
           </div>
}

export default withTranslation()(SetApiAndTerminalName);