import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {NavLink} from 'react-router-dom';
import {connect} from "react-redux";
import {Container} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {listenerRfid} from "../../index";
import configApp from "../../config/config";
import apiEmployee from "../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../reducers/load";
import { addBreamb, resetBreamb } from "../../reducers/breams-manage";
import SetRfidCard from "../screens/SetRfidCard";
import { notify } from "../blocks/Notify";
import Breams from "../blocks/Breams";
import MainNav from "../blocks/MainNav";
import Loader from "../blocks/Loader";
import { errorNotify } from "../../helpers/functions";

class SetRfidCardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rfid: '',
            rfid_ready: false,
        };

        document.title = props.t('Link RFID card');
        resetBreamb();
        addBreamb({title: props.t('Personal Area')});
        addBreamb({title: props.t('Link RFID card')});
    };

    componentWillUnmount() {
        listenerRfid.close().catch(()=>{});
        document.body.removeEventListener('keyup', this.handleKeyUp);
    };

    handleResult = () => {
        const new_user_rfid = !configApp.device_settings.rfid.isHid
            ? this.state.rfid
            : this.state.rfid.slice(0, -5);
        loader.start('setRfid');
        apiEmployee.setRfidCard(this.props.user.data.uuid, new_user_rfid)
        .then(() => {
            this.props.history.push('/cabinet');
            notify(this.props.t('Card is linked'), 'success');
        })
        .catch((err) => {
            errorNotify(this.props.t('Error linking card'), err, this.props.history.push, this.props.user.isAuth)
        })
        .finally(() => loader.stop('setRfid'));
    };

    handleSetRfidResult = (res) => this.setState({rfid: res});

    handleKeyUp = (e) => this.setState({rfid: this.state.rfid += e.key});

    handleStartRfid = () => {
        if (!configApp.device_settings.rfid.isHid) {
            listenerRfid.start()
            .then(this.handleSetRfidResult)
            .catch(()=>{});
        } else {
            document.body.addEventListener('keyup', this.handleKeyUp);
        };
        this.setState({rfid_ready: true});
    };

    handleStopRfid = () => {
        this.setState({
            rfid_ready: false,
            rfid: '',
        });
        if (!configApp.device_settings.rfid.isHid) {
            listenerRfid.close().catch(()=>{});
        } else {
            document.body.removeEventListener('keyup', this.handleKeyUp);
        };
    };

    render() {
        return (
            <>
                <Breams />
                <Loader className="h-100" isLoading={this.props.load.isLoading} loadId={"setRfid"} justifyContent="center">
                    <SetRfidCard {...this.state} t={this.props.t}
                        handleStartRfid={this.handleStartRfid}
                        handleStopRfid={this.handleStopRfid}
                        handleResult={this.handleResult} />
                </Loader>
                <MainNav className={`main-navi`}>
                    <Container className={`d-flex justify-content-between`}>
                        <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                            <span>{this.props.t('Personal Area')}</span>
                        </NavLink>
                        <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                            <span>{this.props.t('Exit')}</span>
                        </NavLink>
                    </Container>
                </MainNav>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(SetRfidCardContainer)));