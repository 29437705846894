import React from 'react';
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Person from "../../blocks/Person";
import Content from "../../blocks/Content";

function FailVisual(props) {
    const select_answer = props.select_answer instanceof Array ? props.select_answer[0] : props.select_answer;
    let titleFactors = select_answer.visualisation.factor_risk.map(factor => factor.title).join();
    let bodyParts = select_answer.visualisation.body_part;
    let comment = null;
    if (select_answer?.hasOwnProperty('comment') && select_answer.comment) comment = select_answer.comment;
    let correctAnswer = [];
    props.answers.forEach(answer => {
        if (answer.is_right === true) correctAnswer.push(answer);
    });
    return <div className="h-100 failVisual Screen container">
            <Content>
                <Row className={"align-items-center h-100"}>
                    <Col sm={"12"}>
                        <Row>
                            <Col xs={"12"} lg={"6"} className="mb-3">
                                <h2 className={"mb-3"}>{props.t('Development of a dangerous situation')}</h2>
                                <video loop="loop" className="failVisual__video" preload="auto"
                                       autoPlay={true} muted=""
                                       data-setup="{&quot;children&quot;: { &quot;controlBar&quot;: { &quot;children&quot;: { &quot;muteToggle&quot;: false, &quot;fullscreenToggle&quot;:false, &quot;volumeControl&quot;:false, &quot;playToggle&quot;:false } } }}"
                                       src={select_answer.visualisation.file}>
                                </video>
                            </Col>
                            <Col xs={"12"} lg={"6"} className="mb-3">
                                <h2 className={"mb-3"}>{props.t('Possible consequences')}</h2>
                                <Row>
                                    <Col xs={"12"} sm={"8"}>
                                        <h4>{props.t('Exposure to harmful and dangerous factors')}:</h4>
                                        <div className={"failVisual__dangerFactor mb-3"}>
                                            {titleFactors}
                                        </div>
                                        {comment &&
                                            <>
                                                <h4>{props.t('Harm to health')}:</h4>
                                                <div className={"failVisual__comment mb-3"}>
                                                    {comment}
                                                </div>
                                            </>}
                                    </Col>
                                    <Col xs={"12"} md={"4"} className="text-center">
                                        <Person body_parts={bodyParts}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={"12"}>
                                <div className={"failVisual__answer answer"}>
                                    <div
                                        className={"answer__text mb-3 answer__text_danger"}>{props.t('You answered wrong')}</div>
                                    {correctAnswer.length > 0 &&
                                        <div className='d-flex flex-column'>
                                            <div className={"answer__text mb-3 answer__text_correct-danger "}>{props.t('Correct answer')}:</div>
                                            {correctAnswer.map(el => <div key={el.id} className={"answer__text answer__text_right w-100 mb-1"}>{el.text}</div>)}
                                        </div>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
           </div>
}

const mapStateToProps = (store) => {
    return {};
};

export default connect(mapStateToProps)(withTranslation()(FailVisual));