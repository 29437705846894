import React, { useEffect } from "react";
import {isElectron} from "react-device-detect";
import ReactPlayer from "react-player";
import {withTranslation} from "react-i18next";
import FileViewer from 'react-file-viewer';
import {Container, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faForward, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import MainNav from "../../blocks/MainNav";
import Content from "../../blocks/Content";
import Loader from "../../blocks/Loader";
import Breams from "../../blocks/Breams";
import FatalError from "../FatalError";
import Presentation from "../../blocks/Presentation";
import { notify } from '../../blocks/Notify'


function LibraryMaterial(props) {
    document.title = props.t('Library');
    const material = props.material
    const use_signature = material?.metaFields?.use_signature || false
    const defaultBackUrl = '/library/video_tutorial,simulated_situation,text_document';
    let fileType = '';
    if (material?.hasOwnProperty('file_type')) fileType = material.file_type.split('/')[1];
    const historyBack = (defaultUrl) => {
        if (history.length > 2) {
            props.history.goBack();
        } else if (defaultUrl) {
            props.history.push(defaultUrl);
        } else {
            props.history.push('/');
        }
    };
    const onError = (e) => {
        if (isElectron) {
            let logger;
            try {
                logger = require('electron-log');
                logger.error(e);
            } catch (e) {
                console.log(e.message);
            }
        }
    };

    useEffect(() => {
        use_signature && notify(props.t('Need to sign'), 'info')
    }, [use_signature])

    return <>
            <Breams/>
            <div className="Screen Material container">
                <Content className="Material-Content">
                    <Loader isLoading={props.load.isLoading} loadId={"content"} justifyContent="center">
                        {material?.hasOwnProperty('url') &&
                            <div className="Material-FileViewer text-center">
                                {material?.type === "text_document"
                                    ? material.viewType === "presentation"
                                        ? <Presentation url={material.url} t={props.t}/>
                                        : <FileViewer
                                            id="FileViewer"
                                            autoPlay={true}
                                            fileType={fileType}
                                            filePath={material.url}
                                            errorComponent={FatalError}
                                            onError={onError} />
                                    : <ReactPlayer
                                        id="FileViewer"
                                        playing={false}
                                        url={material.url}
                                        width="100%"
                                        height="100%"
                                        controls
                                        config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } } }} />}
                            </div>}
                    </Loader>
                </Content>
            </div>
            <MainNav>
                <Container className={`d-flex justify-content-${!use_signature ? 'between' : 'end'}`}>
                    {!use_signature &&
                        <Button onClick={() => historyBack(defaultBackUrl)} className={`Btn Btn_boring MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                            <span>{props.t('Back')}</span>
                        </Button>}
                    {use_signature
                        ? <Button onClick={props.changeStep} className={`Btn Btn_success MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            <span>{props.t('Signature')}</span>
                        </Button>
                        :<NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                            <span>{props.t('Exit')}</span>
                        </NavLink>}
                </Container>
            </MainNav>
        </>
}

export default withTranslation()(LibraryMaterial);