import React, {Component} from "react";
import { isElectron } from 'react-device-detect'
import {connect} from "react-redux";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import FrontCamera from "../../../screens/questions/flow/FrontCamera";
import withCameraHandling from "../../../../hoc/CameraHandling";
import { setLog } from "../../../../helpers/functions";
import preshiftManage from "../../../../reducers/preshift-manager";
import configApp from "../../../../config/config";

class FrontCameraContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event_dt: new Date(),
            can_skip: true,
            devMode: configApp.device_settings?.devMode ? true : false,
        }

        this.timeoutID = () => {};
    }

    screenshot = async () => {
        preshiftManage.setIsTakeFrontPhoto(true);
        const file_name = `front_${this.props.activeStep}`;
        await this.props.shot(this.props.app.front_camera, file_name)
        .then(file => {
            let flow = {};
            flow[file_name] = file;
            let statePreshift = this.props.preshift;
            let datumUhrzeit = new Date(),
                event_end_dt = "@" + Math.round(datumUhrzeit.getTime() / 1000),
                stepUuid = this.props.stepData.uuid,
                stepName = this.props.stepData.name,
                start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
                data = {
                    uuid: stepUuid,
                    name: stepName,
                    start_date_time: start_time,
                    elapsed_time: event_end_dt,
                    file_name: file.name
                },
                flow_history = statePreshift.flow.history.steps;
                flow_history.push(data);

            preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
            preshiftManage.saveFlow(flow);
        })
        .catch(e => setLog('Ошибка Front камеры во время снимка', e))
        .finally(() => {
            this.props.handleNextStep();
            preshiftManage.setIsTakeFrontPhoto(false);
        });
    }

    skipListener = (event) => {
        if (isElectron && event.ctrlKey && event.key === 'm') {
            this.props.handleNextStep()
        }
    };

    componentDidMount = () => {
        document.addEventListener('keydown', this.skipListener);
        this.timeoutID = setTimeout(() => {
            this.setState({can_skip: false});
            document.removeEventListener('keydown', this.skipListener);
            !preshiftManage.getIsTakeFrontPhoto() && this.screenshot();
        }, 3000);
        if (this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip || this.state.devMode) {
            this.props.renderAdditionalButton(
                <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                    <FontAwesomeIcon className="mr-4" icon={faForward}/>
                    {this.props.t('Skip')}
                </Button>);
        } else {
            this.props.renderAdditionalButton(null);
        };
    }

    componentDidUpdate() {
        if (!this.state.can_skip) this.props.renderAdditionalButton(null);
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeoutID);
        document.removeEventListener('keydown', this.skipListener);
    }

    render() {
        return <FrontCamera/>
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        preshift: store.preshiftState,
    };
};

export default connect(mapStateToProps)(withCameraHandling(FrontCameraContainer));