import React, { useEffect } from 'react';
import {FormControl, FormGroup, FormLabel, Tooltip, OverlayTrigger} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faUser, faSuitcase, faHome, faHelmetSafety, faQuestionCircle, faDesktop} from '@fortawesome/free-solid-svg-icons';
import {faUniversity} from '@fortawesome/free-solid-svg-icons';
import Content from "../../blocks/Content";
import preshiftManage from "../../../reducers/preshift-manager";
import Loader from "../../blocks/Loader";
import BootstrapModal from "../../modals/BootstrapModal";
import { focusInput } from '../../../helpers/functions';

export default function Startup(props) {
    document.title = props.t('Enter personnel number');
    const modules = props.app.modules;
    let hasError = false;
    let errors = [];
    let messageError = '';
    let classDisable = ' disabled';
    let formErrors = props.formErrors;

    if (props.formValid) classDisable = '';
    if (formErrors?.hasOwnProperty('error') && formErrors.error.length > 0) {
        messageError = formErrors.error[0];
        errors = formErrors.error;
        hasError = true;
    } else if (formErrors?.hasOwnProperty('tabNum') && formErrors.tabNum.length > 0) {
        messageError = formErrors.tabNum[0];
        errors = formErrors.tabNum;
        hasError = true;
    }

    useEffect(() => {
        focusInput("tabNumInput");
    }, []);

    return <>
            <div className={"Screen startup"}>
                <Content className="container">
                    <div className="overflow-hidden">
                        <div className="d-flex flex-row h-100">
                            <Loader isLoading={props.load.isLoading} loadId={"screenStartup"} justifyContent="center" className="mt-5">
                                <div className={"startup__container startup__container_bn1 d"}>
                                    <div className={"startup__navi"}>
                                        <FormGroup className="text-center">
                                            <FormLabel className={"startup__tabel-label mb-0"}>
                                                {props.t('Enter personnel number')}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="button-tooltip" className={"tooltip error"}>
                                                                <i className="fas fa-exclamation-triangle mr-3"> </i> {(hasError) ? props.t(messageError) : null}
                                                            </Tooltip>}
                                                    show={hasError} >
                                                    <FormControl
                                                        type="text"
                                                        minLength="1"
                                                        required
                                                        id="tabNumInput"
                                                        name="tabNum"
                                                        onInput={props.onInputTabelNum}
                                                        className={`startup__tabel-field mx-auto mt-3 ${(hasError) ? props.errorClass(errors) : null}`} />
                                                </OverlayTrigger>
                                            </FormLabel>
                                        </FormGroup>
                                        <FormGroup className="text-center">
                                            {modules.includes('study') &&
                                                <NavLink to={`/preshift`}
                                                    onClick={(event) => {
                                                        preshiftManage.setCameFrom('startup');
                                                        props.routeGuider(event); }}
                                                    linkto={`/preshift`}
                                                    className={`startup__submit Btn Btn_success ${classDisable} mx-auto mb-3`} >
                                                    {props.t('Preshift')}
                                                </NavLink>}
                                            {(modules.includes('extra_education') && props.app.extra_education_in_auth_screen) &&
                                                <NavLink to={`/extra-education`}
                                                    onClick={(event) => {
                                                        preshiftManage.setCameFrom('startup');
                                                        props.routeGuider(event); }}
                                                    linkto={`/extra-education`}
                                                    className={`startup__submit Btn Btn_success ${classDisable} mx-auto mb-3`} >
                                                    {props.t('Additional')}
                                                </NavLink>}
                                            {(modules.includes('electronic_courses') &&
                                              props.app.electronic_courses_in_auth_screen) &&
                                                <NavLink to={`/electronic-courses`}
                                                    onClick={(event) => props.routeGuider(event)}
                                                    linkto={`/electronic-courses`}
                                                    className={`startup__submit Btn Btn_success ${classDisable} mx-auto mb-3`} >
                                                    {props.t('Courses')}
                                                </NavLink>}
                                            <NavLink  to={`/cabinet`}
                                                onClick={(event) => {
                                                    preshiftManage.setCameFrom('cabinet');
                                                    props.routeGuider(event); }}
                                                linkto={`/cabinet`}
                                                className={`startup__submit Btn Btn_boring ${classDisable} mx-auto mb-3`} >
                                                {props.t('Cabinet')}
                                            </NavLink>
                                            {(modules.includes('ipm') && props.app.use_ipm_in_start_screen)  &&
                                                <NavLink to={`/siz`}
                                                    onClick={(event) => {
                                                        preshiftManage.setCameFrom('cabinet');
                                                        props.routeGuider(event); }}
                                                    linkto={`/siz`}
                                                    className={`startup__submit Btn Btn_boring ${classDisable} mx-auto mb-3`} >
                                                    {props.t('Ppe')}
                                                </NavLink>}
                                            {(modules.includes('demo') && props.app.demo)  &&
                                                <NavLink to={`/demo`}
                                                    onClick={(event) => {
                                                        preshiftManage.setCameFrom('startup');
                                                        props.routeGuider(event); }}
                                                    linkto={`/demo`}
                                                    className={`startup__submit Btn Btn_success mx-auto`} >
                                                    {props.t('demo')}
                                                </NavLink>}
                                        </FormGroup>
                                        <div className={"startup__keyboard mb-4"}>
                                            <Keyboard {...props.keyboardConfig} />
                                        </div>
                                        <div className="mb-0 text-center">
                                            {modules.includes('ldap') &&
                                                <>
                                                    <span onClick={props.toLdapStep} className="startup__link">
                                                    <FontAwesomeIcon className="mr-3" icon={faHome}/>
                                                        {props.t('Local account')}
                                                    </span><br/>
                                                </>}
                                            {props.app.use_auth_with_student_login_and_password &&
                                                <>
                                                    <span onClick={props.toElCoursesStep} className="startup__link">
                                                        <FontAwesomeIcon className="mr-3" icon={faDesktop}/>
                                                        {props.t('Electronic courses')}
                                                    </span><br/>
                                                </>}
                                            {props.app.search_by_name &&
                                                <span onClick={() => props.teleporToStep('search_employee')} className="startup__link">
                                                    <FontAwesomeIcon className="mr-3" icon={faSearch}/>
                                                    {props.t('Search by name')}
                                                </span>}
                                        </div>
                                    </div>
                                </div>
                                <div className={"startup__container startup__container_bn2"}>
                                    <div className={"startup__navi"}>
                                        {modules.includes('study') &&
                                            <NavLink to={`/preshift`}
                                                onClick={(event) => {
                                                    preshiftManage.setCameFrom('startup');
                                                    props.routeGuider(event); }}
                                                linkto={`/preshift`}
                                                exact
                                                className={`startup__navi-link Btn Btn_success ${classDisable}`} >
                                                <FontAwesomeIcon icon={faSuitcase} className="mr-4"/>
                                                {props.t('Pre-shift express training')}
                                            </NavLink>}
                                        {(modules.includes('extra_education') && props.app.extra_education_in_auth_screen) &&
                                            <NavLink to={`/extra-education`}
                                                onClick={(event) => {
                                                    preshiftManage.setCameFrom('startup');
                                                    props.routeGuider(event); }}
                                                linkto={`/extra-education`}
                                                exact
                                                className={`startup__navi-link Btn Btn_success ${classDisable}`} >
                                                <FontAwesomeIcon icon={faUniversity} className="mr-4"/>
                                                {props.t('Additional training')}
                                            </NavLink>}
                                        {(modules.includes('electronic_courses') &&
                                          props.app.electronic_courses_in_auth_screen) &&
                                            <NavLink to={`/electronic-courses`}
                                                onClick={(event) => props.routeGuider(event)}
                                                linkto={`/electronic-courses`}
                                                exact
                                                className={`startup__navi-link Btn ${classDisable} Btn_success`} >
                                                <FontAwesomeIcon icon={faDesktop} className="mr-4"/>
                                                {props.t('Electronic courses')}
                                            </NavLink>}
                                        <NavLink to={`/cabinet`}
                                            onClick={(event) => {
                                                preshiftManage.setCameFrom('cabinet');
                                                props.routeGuider(event); }}
                                            linkto={`/cabinet`}
                                            exact
                                            className={`startup__navi-link Btn Btn_boring ${classDisable}`} >
                                            <FontAwesomeIcon icon={faUser} className="mr-4"/>
                                            {props.t('Personal Area')}
                                        </NavLink>
                                        {(modules.includes('ipm') && props.app.use_ipm_in_start_screen) &&
                                            <NavLink to={`/siz`}
                                                onClick={(event) => {
                                                    preshiftManage.setCameFrom('cabinet');
                                                    props.routeGuider(event); }}
                                                linkto={`/siz`}
                                                exact
                                                className={`startup__navi-link Btn Btn_boring ${classDisable}`} >
                                                <FontAwesomeIcon icon={faHelmetSafety} className="mr-4"/>
                                                {props.t('Personal protective equipment')}
                                            </NavLink>}
                                        {(modules.includes('demo') && props.app.demo) &&
                                            <NavLink to={`/demo`}
                                                onClick={(event) => {
                                                    preshiftManage.setCameFrom('startup');
                                                    props.routeGuider(event); }}
                                                linkto={`/demo`}
                                                exact
                                                className={`startup__navi-link Btn Btn_success`} >
                                                <FontAwesomeIcon icon={faQuestionCircle} className="mr-4"/>
                                                {props.t('Demo')}
                                            </NavLink>}
                                    </div>
                                </div>
                            </Loader>
                        </div>
                    </div>
                </Content>
            </div>
            <BootstrapModal modalId="selectEmployees" dialogClassName={"startup__modal-emp"} title={props.t("Select yourself")} btns={[]}
                    handleClearStateEmployes={props.handleClearStateEmployes}>
                <div className="list-group startup__emplist">
                    {props.employes.map((emp, i) =>
                        <button key={i} onClick={() => props.handleSelectEmployee(emp.uuid)}
                                className="startup__emplist-item list-group-item list-group-item-action">
                            <div className="startup__emplist-itemname">{emp.title}</div>
                        </button>)}
                </div>
            </BootstrapModal>
           </>
};