import { isElectron } from 'react-device-detect';
import { isCordova } from 'is-cordova';
import { notify } from '../components/blocks/Notify';

/**
 * Преобразует объект в строку с параметрами для GET запроса
 */
const makeStrForGetReqFromObj = (obj) => {
    let str = '';

    for (var key in obj) {
        str += (str) ? `&${key}=${obj[key]}` : `?${key}=${obj[key]}`;
    }

    return str;
}

/**
 * Проверяет является ли строка ссылкой
 * @param {string} string
 * @returns {boolean}
 */
const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const hasIsProp = (ob, prop) => ob && prop && ob.hasOwnProperty(prop);

const isTrueProp = (ob, prop) => ob && prop && !!(ob.hasOwnProperty(prop) && ob[prop]);

const isScrollable = (node) => {
    let overflowY = window.getComputedStyle(node)['overflow-y'];
    let overflowX = window.getComputedStyle(node)['overflow-x'];
    return {
        vertical: (overflowY === 'scroll' || overflowY === 'auto') && node.scrollHeight > node.clientHeight,
        horizontal: (overflowX === 'scroll' || overflowX === 'auto') && node.scrollWidth > node.clientWidth,
    };
}

/**
 * 
 * @param {string} name 
 * @returns 
 */
const ext = (name) => name.match(/\.([^.]+)$|$/)[1];

const html2canvas = (elements, opts) => {
    elements = elements.length ? elements : [elements];
    var queue,
        canvas,
        options = {
            // general
            logging: false,
            elements: elements,
            background: "#fff",

            // preload options
            proxy: null,
            timeout: 0,    // no timeout
            useCORS: false, // try to load images as CORS (where available), before falling back to proxy
            allowTaint: false, // whether to allow images to taint the canvas, won't need proxy if set to true

            // parse options
            svgRendering: false, // use svg powered rendering where available (FF11+)
            ignoreElements: "IFRAME|OBJECT|PARAM",
            useOverflow: true,
            letterRendering: false,
            chinese: false,

            // render options

            width: null,
            height: null,
            taintTest: true, // do a taint test with all images before applying to canvas
            renderer: "Canvas"
        };

    options = _html2canvas.Util.Extend(opts, options);

    _html2canvas.logging = options.logging;
    options.complete = function (images) {

        if (typeof options.onpreloaded === "function") {
            if (options.onpreloaded(images) === false) {
                return;
            }
        }
        queue = _html2canvas.Parse(images, options);

        if (typeof options.onparsed === "function") {
            if (options.onparsed(queue) === false) {
                return;
            }
        }

        canvas = _html2canvas.Renderer(queue, options);

        if (typeof options.onrendered === "function") options.onrendered(canvas);
    };
}

/**
 * 
 * @param {string} info 
 * @param {any} error 
 */
const setLog = (info, error) => {
    if (isElectron) {
        try {
            const logger = require('electron-log');
            if (error.hasOwnProperty('code') && error.code >= 400 && 499 >= error.code) {
                logger.transports.file.fileName = "code400.log";
                logger.silly({info: info, warn: error});
            } else if (error.code >= 500 && 599 >= error.code) {
                logger.transports.file.fileName = "code500.log";
                logger.warn({info: info, warn: error});
            }else {
                logger.transports.file.fileName = "errors.log";
                logger.error({info: info, error: error});
            };
        } catch(e) {

        }
    } else {
        console.error({info: info, error: error});
    };
}

/**
 * 
 * @param {string} info 
 */
const setInfoLog = (info) => {
    if (isElectron) {
        try {
            const logger = require('electron-log');
            logger.transports.file.fileName = "info.log";
            logger.info(info);
        } catch(e) {

        }
    } else {
        let date = new Date().toLocaleString();
        console.info(date, info);
    };
}

const contains = (array, element) => array.includes(element);

const reloadElectron = () => {
    try {
        const electron = require('electron');
        electron.ipcRenderer.send('relaunch');
        electron.ipcRenderer.send('exit');
    } catch (e) {
        console.log(e);
        alert("Application restart error. Restart the app manually.");
    }
};

/**
 * 
 * @param {string} info 
 * @param {any} error 
 * @param {function} navigateFn 
 * @param {boolean} isAuth 
 * @param {string} link 
 */
const errorNotify = (info, error, navigateFn, isAuth, link = '') => {
    let to_link = isAuth ? '/cabinet' : '/';
    if (link) to_link = link;
    navigateFn(to_link);
    notify(info, 'error');
    setLog(info, error);
};

const getIsUpdated = () => {
    let res = false;
    try {
        const electron = require('electron');
        res = electron.ipcRenderer.sendSync('get_isUpdated');
    } catch (e) {
    }
    return res;
};

const startSecondApp = () => {
    try {
        const electron = require('electron');
        const {exec} = require('child_process');
        const conf = electron.ipcRenderer.sendSync('get_conf');
        const platform = conf.platform;
        const command = platform === 'win32'
            ? `powershell -command "& {$Env:COMPLEX_UPDATED=$false; & 'C:/Program Files/Complex/Complex.exe'}"`
            : 'complex';
        exec(command);
    } catch (e) {
    }
};

/**
 * 
 * @param {string} filePath 
 * @returns 
 */
const isFileInstalled = (filePath) => {
    let flag = false;
    try {
        const fs = require('fs');
        fs.accessSync(filePath, fs.constants.F_OK);
        flag = true;
    } catch (e) {
    };
    return flag;
};

const isWebApp = () => (!isElectron && !isCordova) ? true : false;

const focusInput = (id) => {
    const isWeb = isWebApp();
    if (isWeb) {
        const input = document.getElementById(id);
        setTimeout(() => input?.focus(), 500);
    };
};

const updateCssColors = (data) => { // data = { '--my-color': '#49678d', '--my-color2': '#181f39', }
    const root = document.querySelector(':root');
    Object.entries(data).forEach(el => root.style.setProperty(el[0].toString(), el[1].toString()));
};

const isMultipleQuestion = (array) => {
    let answersCount = 0;
    array.forEach(el => el.is_right && answersCount++)
    return answersCount > 1
};

export {
    ext,
    makeStrForGetReqFromObj,
    isValidHttpUrl,
    hasIsProp,
    isScrollable,
    isTrueProp,
    setLog,
    setInfoLog,
    contains,
    reloadElectron,
    errorNotify,
    getIsUpdated,
    startSecondApp,
    isFileInstalled,
    isWebApp,
    focusInput,
    updateCssColors,
    isMultipleQuestion
};