import { faArrowLeft, faForward, faRedo, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import React, {Component} from 'react';
import { Button, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import parse from "html-react-parser";
import apiDemoQuestions from '../../api/apiDemoQuestions';
import { addBreamb, goBackStep, resetBreamb } from '../../reducers/breams-manage';
import {pageLoadManageActions as loader} from "../../reducers/load";
import Breams from '../blocks/Breams';
import Loader from '../blocks/Loader';
import DemoQuestionModule from '../screens/demoQuestions/DemoQuestionModule';
import DemoQuestions from '../screens/demoQuestions/DemoQuestions';
import Fail from '../screens/questions/Fail';
import FailVisual from '../screens/questions/FailVisual';
import FailVisualAndInjurie from '../screens/questions/FailVisualAndInjurie';
import Prolog from '../screens/questions/Prolog';
import Question from '../screens/questions/flow/Question';
import SuccessVisual from '../screens/questions/SuccessVisual';
import Epilog from '../screens/questions/Epilog';
import HighQuestAnswer from '../screens/questions/HighQuestAnswer';
import { errorNotify } from '../../helpers/functions';
import { notify } from '../blocks/Notify';

class DemoQuestionsContainer extends Component {
    constructor(props) {
        super(props);

        this.state ={
          content: DemoQuestions,
          pre_content: null,
          modules: null,
          module_questions: null,
          question: null,
          high_question: null,
          high_question_step: 0,
          file: null,
        }

        document.title = props.t('Demo');
        this.getDemo();
        resetBreamb();
        addBreamb({title: props.t('Demo')});
    }

    getDemo = () => {
      return new Promise((resolve, reject) => {
        loader.start("demo");
        apiDemoQuestions.getDemoQuestions()
        .then(response => {
          this.setState(response.data);
          loader.stop("demo");
          resolve();
        })
        .catch(e => {
          errorNotify(this.props.t('Error loading demo tasks'), e, this.props.history.push, false);
          loader.stop("demo");
        })
      })
    };

    getDemoQuest = (uuid) => {
      return new Promise((resolve, reject) => {
        loader.start("demo");
        apiDemoQuestions.getDemoQuestion(uuid)
        .then(response => {
          if (response.data.question.type === "combine-video") {
            this.setState({
              high_question: response.data,
              question: response.data },
              () => {
                this.setState({ content: Prolog });
                this.endVideo(Prolog);
                addBreamb({title: this.props.t('Prolog')});
            });
          } else {
            this.setState({ question: response.data },
              () => this.setState({ content: Question }));
          }
          resolve();
        })
        .catch(() => notify(this.props.t('Error loading the question'), 'error'))
        .finally(() => loader.stop("demo"))
      })
    };

    toQuestionModule = (id) => {
      let module = [];
      let breams_title;
      this.state.modules.forEach(el => {
        if (el.uuid === id) {
          module = el.questions;
          breams_title = el.title;
        };
      });
      this.setState({
        module_questions: module,
        pre_content: this.state.content,
      },
        () => {
          this.setState({ content: DemoQuestionModule });
          addBreamb({ title: parse(breams_title) });
        });
    };

    goBack = () => {
      goBackStep();
      this.setState({ content: this.state.pre_content });
    };

    handleTimeExpired = () => this.props.history.push('/');

    onSelectAnswer = (answer_id) => {
      let question = this.state.question,
          selected_answer = {},
          is_right = false;
      if (answer_id instanceof Array) {
        const all_answers = question.answers;
        let right_answers_count = 0;
        all_answers.forEach(el => el.is_right && right_answers_count++);
        let selected_right_answers_count = 0;
        const selected_answers = [];
        answer_id.forEach(id =>
          all_answers.forEach(answer => {
            if (answer.id === id) {
              selected_answers.push(answer);
              if (answer.is_right) selected_right_answers_count++;
            }
          })
        );
        is_right = right_answers_count === selected_right_answers_count && right_answers_count === answer_id.length;
        selected_answer = selected_answers[0];
      } else {
        question.answers.forEach(answer => {
          if (answer.id === answer_id) {
            is_right = answer.is_right;
            selected_answer = answer;
          }
        });
      }
      this.setState({
        select_answer: selected_answer,
        file: selected_answer.file
      });

      if (is_right) {
        this.setState({ content: question.question.type === "combine-video" ? HighQuestAnswer : SuccessVisual });
        addBreamb({title: this.props.t('Answered correctly')});
      } else {
        this.setState({ content: question.question.type === "combine-video"
          ? HighQuestAnswer
          : (selected_answer?.hasOwnProperty('visualisation')
            ? (selected_answer?.hasOwnProperty('injurie') ? FailVisualAndInjurie : FailVisual)
            : Fail)
        });
        addBreamb({title: this.props.t('Answered incorrectly')});
      }
  };

  repeatQuestion = () => {
    this.setState({ content: Question });
    goBackStep();
    notify(this.props.t('Attention!! The order of answers to the question has changed!!'), 'warning');
  };

  highQuestionsStep = () => {
    let steps = this.state.high_question.question.child_questions.length;
    let active_step = this.state.high_question_step;
    if (active_step === 0) {
      goBackStep();
      addBreamb({ title: this.props.t("Question") + (active_step + 1) + this.props.t("of") + steps });
      this.setState({
        question: this.state.high_question.question.child_questions[active_step],
        high_question_step: active_step + 1 },
        () => {this.setState({ content: Question })});
    } else if (active_step !== 0 && active_step < steps) {
      goBackStep();
      addBreamb({ title: this.props.t("Question") + (active_step + 1) + this.props.t("of") + steps });
      this.setState({
        question: this.state.high_question.question.child_questions[active_step],
        high_question_step: active_step + 1 },
        () => {this.setState({ content: Question })});
    } else if (active_step === steps) {
      goBackStep();
      addBreamb({ title: this.props.t("Epilog") });
      this.setState({
        question: this.state.high_question,
        high_question_step: active_step + 1 },
        () => {
          this.setState({ content: Epilog });
          this.endVideo(Epilog); });
    } else {
      this.setState({ high_question_step: 0 });
      goBackStep();
      this.goBack();
    };
  };

  endVideo = (content) => {
    let selector;
    if (content === Prolog) selector = "#prolog_video";
    if (content === Epilog) selector = "#epilog_video";
    setTimeout(() => {
          let nodeVideo = document.querySelector(selector);
          nodeVideo.autoplay = 'autoplay';
          nodeVideo.addEventListener('ended', () => {
              nodeVideo.pause();
              this.highQuestionsStep(); });
      }, 1);
  };

    render() {
        let Content = this.state.content;
        return <div className="Screen">
                <Loader isLoading={this.props.load.isLoading} loadId={"demo"} justifyContent="center">
                  <Breams/>
                  <Content {...this.state} {...this.props}
                            toQuestionModule={this.toQuestionModule}
                            goBack={this.goBack}
                            getDemoQuest={this.getDemoQuest}
                            answers={this.state.question?.answers}
                            question={this.state.question?.question}
                            handleTimeExpired={this.handleTimeExpired}
                            onSelectAnswer={this.onSelectAnswer}
                            select_answer={this.state.select_answer}
                            file={this.state.file}
                            toNextChildQuest={this.highQuestionsStep}
                            goBackStep={goBackStep} />
                  {this.state.content === DemoQuestions &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-row">
                              <Stepper activeStep={0}>
                                <Step key="0" connector={null} classes={{ root: 'MuiStep-root_cot' }}>
                                  <StepLabel completed={false}/>
                                </Step>
                              </Stepper>
                              <span className="MuiTypography-caption">{this.props.t('Demo')}</span>
                            </div>
                            <NavLink to="/" className="Btn Btn_danger MuiButton">
                              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                              {this.props.t('Exit')}
                            </NavLink>
                          </div>
                        </div>
                      </Container>
                    </div>}
                  {(this.state.content === DemoQuestionModule || this.state.content === SuccessVisual || this.state.content === Epilog) &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <Button className="Btn Btn_boring MuiButton cursor-pointer"
                                    onClick={() => {{(this.state.content === SuccessVisual || this.state.content === Epilog) && goBackStep()};
                                                    this.goBack()}}>
                              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                              {this.props.t('Back')}
                            </Button>
                            <NavLink to="/" className="Btn Btn_danger MuiButton">
                              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                              {this.props.t('Exit')}
                            </NavLink>
                          </div>
                        </div>
                      </Container>
                    </div>}
                  {(this.state.content === Fail || this.state.content === FailVisual || this.state.content === FailVisualAndInjurie) &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <Button className="Btn Btn_danger MuiButton cursor-pointer" onClick={() => {goBackStep(); this.goBack()}}>
                              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                              {this.props.t('Done')} {this.props.t('Question')}
                            </Button>
                            <Button className="Btn Btn_boring MuiButton cursor-pointer" onClick={this.repeatQuestion}>
                              <FontAwesomeIcon className="mr-4" icon={faRedo}/>
                              {this.props.t('Try again')}
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </div>}
                  {this.state.content === Prolog &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <Button className="Btn Btn_danger MuiButton cursor-pointer"
                                    onClick={() => {this.state.question.question.type === "combine-video" && goBackStep(); this.goBack();}}>
                              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                              {this.props.t('Done')} {this.props.t('Question')}
                            </Button>
                            <Button className="MuiButton Btn Btn_success" onClick={this.highQuestionsStep}>
                              <FontAwesomeIcon className="mr-4" icon={faForward}/>
                              {this.props.t('Proceed')}
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </div>}
                  {this.state.content === HighQuestAnswer &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <Button className="Btn Btn_danger MuiButton cursor-pointer" onClick={() => {goBackStep(); goBackStep(); this.goBack();}}>
                              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                              {this.props.t('Done')} {this.props.t('Question')}
                            </Button>
                            <Button className="MuiButton Btn Btn_success" onClick={() => {goBackStep(); this.highQuestionsStep();}}>
                              <FontAwesomeIcon className="mr-4" icon={faForward}/>
                              {this.state.high_question_step < this.state.high_question.question.child_questions.length
                                ? this.props.t('Next')
                                : this.props.t('Proceed')}
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </div>}
                  {this.state.content === Question &&
                    <div className='MuiStepContainer'>
                      <Container className="pb-1">
                        <div className="w-100">
                          <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <Button className="Btn Btn_danger MuiButton cursor-pointer"
                                    onClick={() => {this.state.question.question.type === "combine-video" && goBackStep(); this.goBack();}}>
                              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                              {this.props.t('Done')} {this.props.t('Question')}
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </div>}
                </Loader>
              </div>
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState
    };
};

export default connect(mapStateToProps)(withTranslation()(DemoQuestionsContainer));