import React from 'react';
import ReactDOM from 'react-dom';
import {isElectron} from "react-device-detect";
import isCordova from "is-cordova";
import {Provider} from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./localStorage";
import configApp from "./config/config";
import { setInfoLog } from './helpers/functions';

const renderReactDom = () => {
    setInfoLog('Приложение запущено');
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>,
        document.getElementById('root')
    );
};

serviceWorker.unregister();

let startRfid = () => {};

if (isElectron) {
    try {
        const moduleRfid = require('./externalHardware/moduleRfid/moduleRfid').default;
        let settingsRfid = {...configApp.device_settings.rfid};
        startRfid = () => new moduleRfid(settingsRfid);
    } catch (e) {
    }
}
if (isCordova) {
    document.addEventListener('deviceready', () => {
        renderReactDom();
    }, false);
} else {
    renderReactDom();
}

export const listenerRfid = startRfid();