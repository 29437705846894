import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Content from "../../blocks/Content";
import MainNav from "../../blocks/MainNav";

export default function AllCourses(props) {
  let content = <></>;
  let courses = props.courses ? props.courses : [];
  if (courses?.length > 0) {
    courses.sort((a, b) => a.appoint.sort - b.appoint.sort);
    content = (
        <div className="d-flex align-items-center flex-column justify-content-center h-100 min-vw-50">
          <h1 className="Text_success preshift__text">{props.t("Available courses")}</h1>
          <table className="table_siz display-9">
            <thead>
              <tr>
                <th className="text-center" scope="col">{props.t("Course name")}</th>
                <th className="text-center" scope="col">{props.t("Number of lessons")}</th>
                <th className="text-center" scope="col">{props.t("Progress")}</th>
                <th className="text-center" scope="col">{props.t("Status")}</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((el) => {
                let progress = el.result.progress;
                let bgColor = "";
                if (progress === 0) {
                  bgColor = "#dc3545"; // красный
                } else if (progress === 100) {
                  bgColor = "#1E874A"; // зеленый
                } else {
                  bgColor = "#ffc107"; // желтый
                }
                return (
                  <tr className="table_siz_tr cursor-pointer" key={el.appoint.uuid} onClick={() => props.handleChangeContent("Course", el.appoint.uuid, el.title)}>
                    <td className="text-center table_siz_text">{el.title}</td>
                    <td className="text-center table_siz_text">{el.lessons}</td>
                    <td>
                      <div className="m-auto table_siz_num" style={{backgroundColor: bgColor}}>{progress}%</div>
                    </td>
                    <td>
                      <div className="m-auto table_siz_num" style={{backgroundColor: bgColor}}>{el.appoint.status}</div>
                    </td>
                  </tr>);
              })}
            </tbody>
          </table>
        </div>);
  } else {
      content = (
        <div className="d-flex align-items-center flex-column justify-content-center h-100">
          <h4 className={`preshift__text text-center ${props.data ? "Text_danger" : "Text_success"}`}>
            {props.t(`${props.data ? props.data : "No courses"}`)}
          </h4>
        </div>);
  };

  return <>
          <div className="Screen container d-flex flex-column justify-content-center align-items-center">
            <Content>
              {content}
            </Content>
          </div>
          <MainNav className={`main-navi`}>
            <Container className={`d-flex justify-content-between`}>
              <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                <span>{props.t('Personal area')}</span>
              </NavLink>
              <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                <span>{props.t('Exit')}</span>
              </NavLink>
            </Container>
          </MainNav>
         </>
};