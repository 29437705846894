import React, { useEffect, useRef } from 'react';
import {Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Content from '../../blocks/Content';
import BootstrapModal from '../../modals/BootstrapModal';
import modalManage from '../../../reducers/modal-manage';

export default function FailVisualAndInjurie(props) {
    const timer = useRef();
    const modal_id = 'rulesModal';
    const quote_from_document = props.question?.quote_from_document;
    const quote_from_document_max_length = 400;
    // Хранилище
    const pageEnums = {};

    /**
     * Подготавливаем переменные
     */
    const handleLoadPage = () => {
        pageEnums.videoInjurie = document.getElementById('video_injurie');
        pageEnums.videoVisual = document.getElementById('video_visual');
        pageEnums.stepFirstBlock = document.getElementById('step1');
        pageEnums.stepSecondBlock = document.getElementById('step2');
        pageEnums.stepThirdBlock = document.getElementById('step3');
    }

    const select_answer = props.select_answer instanceof Array ? props.select_answer[0] : props.select_answer;

    /**
     * Шаг 1: Показываем видео травм
     * @returns {Promise}
     */
    const handleStep1 = () => {
        return new Promise((resolve, reject) => {
            pageEnums.videoInjurie.addEventListener('error', resolve);
            if (!select_answer.visualisation.file) {
                resolve();
            } else {
                timer.current = setTimeout(() => {
                    pageEnums.stepFirstBlock.classList.add('FailAnswer-Step_active');
                    timer.current = setTimeout(() => {
                        pageEnums.videoInjurie.parentNode.parentNode.classList.add('VideoFail_active');
                        pageEnums.videoInjurie.addEventListener('ended', () => {
                            pageEnums.videoInjurie.pause();
                            pageEnums.videoInjurie.currentTime = pageEnums.videoInjurie.duration - 1;
                            resolve();
                        });
                    }, 1000);
                }, 500);
            }
        });
    }

    /**
     * Шаг 2: Видео травмы закончилось...Показываем визуализации
     * @returns {Promise}
     */
    const handleStep2 = () => {
        return new Promise((resolve, reject) => {
            pageEnums.videoVisual.addEventListener('error', resolve);
            if (!select_answer.injurie.file) {
                resolve();
            } else {
                timer.current = setTimeout(() => {
                    pageEnums.stepSecondBlock.classList.add('FailAnswer-Step_active');
                    timer.current = setTimeout(() => {
                        pageEnums.videoVisual.parentNode.parentNode.classList.add('VideoFail_active');
                        pageEnums.videoVisual.play();
                        pageEnums.videoVisual.addEventListener('ended', () => {
                            pageEnums.videoVisual.pause();
                            pageEnums.videoVisual.currentTime = pageEnums.videoVisual.duration - 1;
                            resolve();
                        });
                    }, 1000);
                }, 500);
            }
        });
    }

    /**
     * Шаг 3: Видео визуализации закончилось...Показываем текст
     * @returns {Promise}
     */
    const handleStep3 = () => {
        return new Promise((resolve, reject) => {
            (async () => {
                for (let i = 1; i <= 3; i++) {
                    await (() => {
                        return new Promise((resolve, reject) => {
                            timer.current = setTimeout(() => {
                                if (i === 3) {
                                    document.querySelectorAll('.js-text3').forEach(el => el.classList.remove('invisible'));
                                } else {
                                    document.querySelector('.js-text' + i).classList.remove('invisible');
                                }
                                resolve();
                            }, 1200);
                        });
                    })();
                }
                props.handleShowTimer && props.handleShowTimer();
            })();
        });
    }

    const handleStepShow = () => {
        (async () => {
            try {
                await handleLoadPage();
                await handleStep1();
                await handleStep2();
                await handleStep3();
            } catch (error) {
                throw new Error(error);
            }
        })();
    }

    useEffect(() => {
        handleStepShow();
        return () => clearTimeout(timer.current);
    }, []);

    let correctAnswer = [];
    props.answers.forEach(answer => {
        if (answer.is_right === true) correctAnswer.push(answer);
    });

    return <div className="Screen container">
            <Content>
                <Row className={"FailAnswer"}>
                    <Col sm={"12"}>
                        <Row>
                            <Col xs={"12"} lg={"6"} className="mb-5 FailAnswer-Step" id="step1">
                                <div className="FailAnswer-Top mb-2">
                                    <h2 className="FailAnswer-Title text-uppercase">{props.t('Development of a dangerous situation')}</h2>
                                    <h4 className="FailAnswer-TitleVideo FailAnswer-TitleVideo_injurie">
                                        {select_answer.visualisation.factor_risk.length > 0
                                            ? select_answer.visualisation.factor_risk.length > 1
                                                ? select_answer.visualisation.factor_risk.map(el => `${el.title}, `)
                                                : select_answer.visualisation.factor_risk.map(el => `${el.title}`)
                                            : select_answer.visualisation.title}
                                    </h4>
                                </div>
                                <div className="FailAnswer-Video VideoFail">
                                    <div className="VideoFail-Inner">
                                        <video
                                            id="video_injurie"
                                            preload="auto"
                                            autoPlay={true}
                                            muted
                                            data-setup="{ &quot;children&quot;: { &quot;controlBar&quot;: { &quot;children&quot;: { &quot;muteToggle&quot;: false, &quot;fullscreenToggle&quot;:false, &quot;volumeControl&quot;:false, &quot;playToggle&quot;:false }}}}"
                                            src={select_answer.visualisation.file}>
                                        </video>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={"12"} lg={"6"} className="mb-5 FailAnswer-Step" id="step2">
                                <div className="FailAnswer-Top mb-2">
                                    <h2 className="FailAnswer-Title text-uppercase">{props.t('Possible consequences')}</h2>
                                    <h4 className="FailAnswer-TitleVideo FailAnswer-TitleVideo_visual">
                                        {select_answer.injurie.title}
                                    </h4>
                                </div>
                                <div className="FailAnswer-Video VideoFail">
                                    <div className="VideoFail-Inner">
                                        <video
                                            id="video_visual"
                                            preload="auto"
                                            autoPlay={true}
                                            muted
                                            data-setup="{ &quot;children&quot;: { &quot;controlBar&quot;: { &quot;children&quot;: { &quot;muteToggle&quot;: false, &quot;fullscreenToggle&quot;:false, &quot;volumeControl&quot;:false, &quot;playToggle&quot;:false }}}}"
                                            src={select_answer.injurie.file}>
                                        </video>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={"12"} className="FailAnswer-Step_active" id="step3">
                                {correctAnswer.length > 0 &&
                                    <>
                                        <div className={"FailAnswer-TextFail mb-4 js-text1 invisible"}>{props.t('You answered wrong')}</div>
                                        <div className={"FailAnswer-Comment px-3 d-flex flex-column justify-content-center align-items-center"}>
                                            <h3 className={"FailAnswer-Text FailAnswer-Text_success js-text2 invisible"}>{props.t('Correct answer')}:</h3>
                                            {correctAnswer.map(el =>
                                                <p key={el.id} className={"FailAnswer-Text FailAnswer-Text_success_correct js-text3 invisible w-100 mb-1"}>{el.text}</p>)}
                                        </div>
                                        {quote_from_document &&
                                            <>
                                                <h2 className="FailAnswer-Title text-uppercase mb-2 js-text3 invisible">{props.t('Violation of the rule')}</h2>
                                                <div className='d-flex flex-column mb-5 js-text3 invisible'>
                                                    <span className='display-7 text-justify' style={{textIndent: '2rem', lineHeight: 0.99}}>
                                                        {quote_from_document?.slice(0, quote_from_document_max_length)}
                                                        {quote_from_document?.length > quote_from_document_max_length && "..."}
                                                    </span>
                                                    {quote_from_document?.length > quote_from_document_max_length &&
                                                        <div className='d-flex w-100 justify-content-end'>
                                                            <FontAwesomeIcon
                                                                className={'cursor-pointer'}
                                                                onClick={() => modalManage.show(modal_id)}
                                                                style={{height: '3.5rem'}}
                                                                icon={faSearch}/>
                                                        </div>}
                                                </div>
                                            </>}
                                    </>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
            <BootstrapModal modalId={modal_id} dialogClassName={'w-75'} title={props.t('Violation of the rule')}>
                <div className='display-9 text-justify max-vh-75' style={{overflowY: 'auto'}}>
                    {quote_from_document}
                </div>
            </BootstrapModal>
        </div>
}