import apiHandling from "./apiHandling";

class apiSurveys extends apiHandling {

    static getSurveys = (user_uuid) => this.req({
        method: 'get',
        action: `surveys/assignments/byEmployee/${user_uuid}`
    });

    static getOneSurvey = (survey_uuid, id) => this.req({
        method: 'get',
        action: `surveys/${survey_uuid}?appointment_id=${id}&with_results=true`,
    });

    static setSurvey = (user_uuid, data) => this.req({
        method: 'post',
        action: `surveys/assignments/byEmployee/${user_uuid}`,
        data: JSON.stringify(data)
    });
}

export default apiSurveys;