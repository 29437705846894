import React, { useEffect, useRef, useState } from 'react';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';

export default function Progress(props) {
    const [persent, setPersent] = useState(0);
    const timer = useRef();

    useEffect(() => {
        if (persent < props.persent) {
            timer.current = setTimeout(() => {
                setPersent(persent + 1);
            }, 25);
        };
        return () => clearTimeout(timer.current);
    });

    return <div className={`${props.className ? props.className + ' ' : null}Progress-Bar ml-2`}>
            <CircularProgressbar value={persent} text={persent}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    textSize: '45px',
                    pathColor: props.color,
                    textColor: '#fff',
                    pathTransitionDuration: .25,
                })} />
           </div>
}