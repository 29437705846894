import apiHandling from "./apiHandling";

class apiPreshift extends apiHandling {
    /**
     * Получить шаги прохождения обучения
     * @returns {Promise<unknown>}
     */

    static getAllFlow = (flow_uuid, uuid, appointment) => {
        let action_string = appointment
            ? `flows/${flow_uuid}?appointment=${appointment}&employee_uuid=${uuid}`
            : `flows/${flow_uuid}?employee_uuid=${uuid}`;
        return this.req({
            method: 'get',
            action: action_string,
        });
    }

    /**
     * Отправить результаты прохождения обучения
     * @param data
     * @returns {Promise<unknown>}
     */
    static setFlow = (flow_uuid ,data) => {
        const formData = new FormData();

        for (let keyData in data) {
            if (keyData === 'history') data[keyData] = JSON.stringify(data[keyData]);
            data[keyData] instanceof Blob
                ? formData.append(keyData, data[keyData], `${keyData}.jpg`)
                : formData.append(keyData, data[keyData]);
        }

        return this.req({
            method: 'post',
            action: `flows/${flow_uuid}`,
            data: formData
        });
    }

    /**
     * Получить все доп обучения пользователя
     * @param user_uuid
     * @returns {Promise<unknown>}
     */

    static getExtraEducationsAll = (user_id) => this.req({
            method: 'get',
            action: `extra-educations`,
            data: {
                employee_id: user_id,
                get_all: true
            }
        });

    static getElectronicCourses = (uuid_employee) => this.req({
            method: 'get',
            action: `electronicCourses/getAppointsByEmployee?uuid=${uuid_employee}`,
        });

    static getOneElectronicCourse = (appoint_uuid) => this.req({
            method: 'get',
            action: `electronicCourses/getAppointWithCourse?appoint=${appoint_uuid}`,
        });

    static getSessionsStatus = (class_uuid) => this.req({
            method: 'get',
            action: `learning_class/sessions/status/${class_uuid}`,
        });

    static getStudentsList = (class_uuid) => this.req({
            method: 'get',
            action: `learning_class/sessions/students_list/${class_uuid}`,
        });

    static setStudent = (class_uuid, student, course_uuid = '', session) => {
        const formData = new FormData();
        formData.append('student', JSON.stringify(student));
        formData.append('session', session);
        course_uuid && formData.append('course', course_uuid);
        return this.req({
            method: 'post',
            action: `learning_class/sessions/addStudyToSession/${class_uuid}`,
            data: formData
        });
    }

    static getPositionsOrStructures = (type, text) => this.req({
            method: 'get',
            action: `${type}?search=${text}&limit=10`,  // type = 'structures' | 'positions';
        });

    static getStudentByNameOrTabel = (type, text) => this.req({
            method: 'get',
            action: `students/?${type}=${text}`,  // type = 'title' | 'tabel';
        });

    static getCoursesForChoice = () => this.req({
            method: 'get',
            action: `electronicCourses`,
        });

}

export default apiPreshift;