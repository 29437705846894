import React from 'react';
import parse from "html-react-parser";
import {Row, Col} from "react-bootstrap";
import Content from "../../blocks/Content";

export default function DemoQuestions(props) {
    let modules = null;
    if (props.modules) modules = props.modules.map(el => el);

    return <div className={"Screen ScreenCabinet container"}>
            <Content>
                <Row className={"ScreenCabinet-Row"}>
                    {modules && modules.length > 0
                        ? modules.map(module =>
                            <Col xs={"12"} lg={"6"} className={"ScreenCabinet-Col ModuleCard cursor-pointer"} key={module.uuid}>
                                <div onClick={() => props.toQuestionModule(module.uuid)}
                                        className={"ModuleCard-Link d-flex align-items-center justify-content-center Btn Btn_darken text-white text-center p-5"}>
                                    <div className="ModuleCard-Text d-flex" >                                                
                                        {parse(module.title)}
                                    </div>
                                </div>
                            </Col>)
                        : <div className="d-flex align-items-center justify-content-center h-100 container">
                            <h4 className={`preshift__text text-center Text_success`}>{props.t("Empty list of demo tasks")}</h4>
                          </div>}
                </Row>
            </Content>
           </div>
}