import configApp from '../config/config';
import { reloadElectron, setInfoLog, isFileInstalled } from '../helpers/functions';
import appManage from '../reducers/app-manager';
import store from '../localStorage';
import { notify } from '../components/blocks/Notify';

const version = configApp.device_settings.version;
const platform = configApp.platform;
const updateFolderPath = configApp.updateFolderPath;
const pass = configApp.pass ? configApp.pass : '@WSX2wsx';
const ext = platform === 'win32' ? 'exe' : 'snap';

const autoUpdate = async (updateState, isUpdateFromHeader) => {
    try {
        const {url_string, new_version, app_name, can_update, update_app_url_string, updater_version, app_file_hash, update_file_hash} = updateState;
        if (can_update &&
            url_string &&
            app_name &&
            new_version &&
            update_app_url_string &&
            updater_version &&
            version !== new_version &&
            app_file_hash &&
            update_file_hash) {
            const electron = require('electron');
            const crypto = require("crypto");
            const fs = require('fs');
            const http = require('http');
            const { exec } = require("child_process");
            const updaterAppPath = `${updateFolderPath}/Complex_updater_${updater_version}.${ext}`;
            const isFolder = fs.existsSync(updateFolderPath);
            const setupFilePath = `${updateFolderPath}/${app_name}`;
            const isSetupFile = isFileInstalled(setupFilePath);
            const isUpdateApp = isFileInstalled(updaterAppPath);
            const isCanUpdate = store.getState().appState.isCanUpdate;
            if (isSetupFile && app_file_hash === getFileHash(crypto, fs, setupFilePath)) {
                if (isUpdateApp && update_file_hash === getFileHash(crypto, fs, updaterAppPath)) {
                    if (!isCanUpdate) appManage.setIsCanUpdated(true);
                    if (platform !== 'win32' || (platform === 'win32' && isUpdateFromHeader)) {
                        startUpdate(exec, electron, updaterAppPath);
                    }
                } else {
                    if (isUpdateApp) {
                        deleteFile(fs, 'update_file_hash не совпали. удаление файла для нового скачивания', updaterAppPath);
                    };
                    clearUpdateStatus();
                    download(http, fs, updaterAppPath, update_app_url_string)
                    .then(() => {
                        electron.ipcRenderer.send('save_updater_version', updater_version);
                        if (platform !== 'win32') {
                            exec(`echo ${pass} | sudo -S snap install --dangerous --classic ${updaterAppPath}`, (err, stdout, stderr) => {
                                if (!err) {
                                    if (isSetupFile && !isCanUpdate) appManage.setIsCanUpdated(true);
                                    setInfoLog(`Complex_updater_${updater_version} установлен`);
                                } else {
                                    deleteFile(fs, err, updaterAppPath);
                                }
                            });
                        } else {
                            setInfoLog(`Файл Complex_updater_${updater_version} сохранен`);
                        }
                    })
                    .catch(setInfoLog);
                };
            } else {
                if (isFolder) {
                    if (isSetupFile) {
                        deleteFile(fs, 'app_file_hash не совпали. удаление файла для нового скачивания', setupFilePath);
                    };
                    clearUpdateStatus();
                    download(http, fs, setupFilePath, url_string, new_version)
                    .then(() => {
                        if (isUpdateApp && !isCanUpdate) appManage.setIsCanUpdated(true);
                        electron.ipcRenderer.send('save_setup_file_path', setupFilePath);
                    })
                    .catch(setInfoLog);
                } else {
                    reloadElectron();
                };
            };
        };
    } catch (e) {
        setInfoLog(e);
    }
};

const download = (http, fs, filePath, url_string, new_version) => {
    return new Promise((resolve, reject) => {
        http.get(url_string, (res) => {
            if (res.statusCode === 200) {
                const file = fs.createWriteStream(filePath);
                res.pipe(file);
                file.on("error", (e) => {
                    file.close();
                    fs.unlink(filePath, ()=>{});
                    const info = new_version
                        ? `Ошибка сохранения файла новой версии: ${e}`
                        : `Ошибка сохранения файла Complex_updater: ${e}`;
                    reject(info);
                });
                file.on("finish", () => {
                    file.close();
                    new_version && setInfoLog(`Файл новой версии ${new_version} сохранен`);
                    resolve();
                });
            } else {
                const info = new_version
                    ? `Ошибка загрузки файла новой версии с кодом: ${res.statusCode}`
                    : `Ошибка загрузки Complex_updater с кодом: ${res.statusCode}`;
                reject(info);
            }
        });
    });
};

const startUpdate = (exec, electron, updaterAppPath) => {
    const command = platform === 'win32'
        ? `powershell -command "& {$Env:COMPLEX_UPDATE=$true; & '${updaterAppPath}'}"`
        : `complexupdater  --update`;
    notify('Обновление началось. Ждите!', 'success');
    setInfoLog(`Запуск обновления`);
    appManage.setUpdateStatus('update');
    exec(command, (error, stdout, stderr) => {
        if (!error) {
            clearUpdateStatus();
            electron.ipcRenderer.send('exit');
        } else {
            notify('Ошибка обновления. Проверьте консоль.', 'error');
            setInfoLog(error);
            appManage.setUpdateStatus('error');
            setTimeout(() => clearUpdateStatus(), 10000);
        }
    });
};

const clearUpdateStatus = () => {
    appManage.setIsCanUpdated(false);
    appManage.setUpdateStatus(null);
};

const getFileHash = (crypto, fs, filePath) => {
    const buff = fs.readFileSync(filePath);
    const hash = crypto.createHash("md5").update(buff).digest("hex");
    return hash
};

const deleteFile = (fs, error, filePath) => {
    setInfoLog(error);
    fs.unlinkSync(filePath);
};

export {autoUpdate};