import React, { useEffect, useState } from "react";
import {Button, FormGroup, FormControl, Container} from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard";
import { isElectron } from "react-device-detect";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../blocks/Loader";
import ScrollBlock from "../../blocks/ScrollBlock";
import configApp from "../../../config/config";
import {isTrueProp, focusInput} from "../../../helpers/functions";

export default function SearchEmp(props) {
    document.title = props.t('Search by name');
    let nodeEmployees = null;
    const [showKeyboard, setShowKeyboard] = useState(true);
    if (props.data instanceof Array && props.data.length > 0) {
        nodeEmployees = props.data.map((employee, index) =>
            <Button key={index} className={"search-emp__emp"} variant="link" onClick={() => props.handleSelectEmployee(employee.uuid)}>
                <div className={"search-emp__emp-name"}>
                    {employee.title}
                </div>
                {employee.position?.length > 0 &&
                    <div className={"search-emp__emp-division"}>
                        {employee.position[0].title}
                    </div>}
            </Button>)
    } else if (props.formErrors?.hasOwnProperty('detail')) {
        nodeEmployees =
            <h4 className={`search-emp__notice text-cot-green text-center ${props.formErrors.detail ? "Text_success" : "Text_danger"}`}>
                {props.formErrors.detail ? props.formErrors.detail[0] : props.t("Network Error")}
            </h4>
    };

    useEffect(() => {
        focusInput("controlName");
    }, []);

    return <div className="Screen">
            <Container className="p-0 h-100">
                <Loader isLoading={props.load.isLoading} loadId={"screen"} className="h-100" justifyContent="center">
                    <div className="search-emp">
                        <Loader isLoading={props.load.isLoading} loadId={"listEmployee"} justifyContent="center" style={{height: '100%'}}>
                            <ScrollBlock style={{overflowY: 'auto'}} className="mx-4">
                                {nodeEmployees}
                            </ScrollBlock>
                        </Loader>
                        <FormGroup className="search-emp__name-container text-center ml-4 mr-4 mt-2 d-flex">
                            <FormControl
                                type="text"
                                required
                                onChange={props.onInputName}
                                id="controlName"
                                name="name"
                                className={`search-emp__name-field mx-auto border-secondary form-control`}
                                placeholder={props.t('Enter name')}
                                value={props.formValues.name ? props.formValues.name : ''} />
                            {props.formValues.name &&
                                <Button onClick={props.onClearName} className="search-emp__name-searchButton Btn Btn_happy m-2 display-5">
                                    {props.t('Reset')}
                                </Button>}
                            <Button onClick={props.onSubmit} disabled={(!props.formValid || props.load.isLoading && props.load.idsLoad.includes('listEmployee'))}
                                    className="search-emp__name-searchButton Btn Btn_success m-2 display-5">
                                {props.t('Find')}
                            </Button>
                        </FormGroup>
                        {(isTrueProp(configApp.device_settings, 'keyboard') || isElectron) &&
                            <>
                                <div className="keyboardShowBtn">
                                    <button type="button" className="keyboardShowButtton" onClick={() => setShowKeyboard(!showKeyboard)}>
                                        <FontAwesomeIcon icon={showKeyboard ? faChevronDown : faChevronUp} />
                                    </button>
                                </div>
                                <div className={`mx-4 keywoard-container search-emp_keywoard-container ${showKeyboard ? null : "unShowKeyboardClass"}`}>
                                    <Keyboard className="px-4" {...props.keyboardConfig} />
                                </div>
                            </>}
                    </div>
                </Loader>
            </Container>
           </div>
};