import { faArrowLeft, faCheck, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ExitTimer from "../../blocks/ExitTimer";
import MainNav from "../../blocks/MainNav";

export default function EndCourse(props) {
  const [out, setOut] = useState(false);
  const timer = useRef();

  const exitCourse = () =>
    props.location.pathname === "/electronic-courses-class"
    ? props.handleChangeContent("Course", props.activeCourse.appoint.uuid)
    : props.handleChangeContent("AllCourses");

  useEffect(() => {
    timer.current = setTimeout(() => setOut(true), 30000);
    return () => clearTimeout(timer.current);
  }, []);
  
  return <>
      <div className="Screen container d-flex flex-column justify-content-center align-items-center">
        <h1 className="preshift__text mb-4">{props.t("Training on the course is completed")}</h1>
        <FontAwesomeIcon icon={faCheck} className="Success-Ok mb-5"/>
        <span className="text-center display-8 mb-4">{`${props.t('Solved training tasks')} ${props.activeCourse.result.count_questions}`}</span>
        <span className="text-center display-8 mb-4">{`${props.t('Tasks without errors')} ${props.activeCourse.result.count_question_without_error}`}</span>
        <span className="text-center display-8 mb-4">{`${props.t('Viewed a video')} ${props.activeCourse.result.viewed_video}`}</span>
        <span className="text-center display-8 mb-4">{`${props.t('Total time spent on completing the course')} ${props.activeCourse.result.total_spent_time}`}</span>
        <span className="text-center display-8">{`${props.t('Your competence based on the course results')} ${props.activeCourse.result.competence}%`}</span>
      </div>
      <MainNav className={`main-navi`}>
        <Container className={`d-flex justify-content-end`}>
          <Button onClick={() => {if (props.is_exit_timer) exitCourse()}} className={`Btn Btn_success MuiButton mr-4`}>
            <span>{props.t('Exit the course')}</span>
          </Button>
          {props.location.pathname !== "/electronic-courses-class" &&
            <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton mr-4`}>
              <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
              <span>{props.t('Personal area')}</span>
            </NavLink>}
          <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
            <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
            <span>{props.t('Exit')}</span>
            {out && <ExitTimer theme_name={"danger"} goOut={props.handleExitElCourses} handleExitTimer={props.handleExitTimer}/>}
          </NavLink>
        </Container>
       </MainNav>
    </>
}