const initialState = {
    show: false,
    idsModal: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'MODAL_SHOW':
            return {...state, idsModal: state.idsModal.concat(action.modalId), show: true};
        case 'MODAL_CLOSE':
            let idsModal = state.idsModal.filter(obj => action.modalId !== obj);
            let isShow = true;
            if (!idsModal.length) isShow = false;
            return {...state, idsModal: idsModal, show: isShow};
        case 'MODAL_DEFAULT':
            return initialState;
        default:
            return state;
    }
}