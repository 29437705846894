import {Dropdown, Form} from "react-bootstrap";
import React, {Component} from "react";
import {isMobile} from "react-device-detect";

export default class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textOption: '',
            value: '',
        }

        if (props.children.length > 0) {
            this.state.textOption = props.children[0].props.children;
            this.state.value = props.children[0].props.value;
            //this.props.onSelectCallback(this.state.value);
        }

        props.children.forEach((option) => {
            if (option.props.selected) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.textOption = option.props.children;
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.value = option.props.value;
            }
        });
    }

    prepareChange = (event) => {
        event.preventDefault();
        event.persist();
        this.change(event.target.value);
    }

    change = (value) => {
        this.props.children.forEach(option =>
            value === option.props.value &&
                this.setState({
                    textOption: option.props.children,
                    value: option.props.value
                }));
        this.props.onSelectCallback && this.props.onSelectCallback(value);
    }

    render() {
        let select;
        if (isMobile) {
            select = <Form.Group className={`Select ${this.props.className} mb-0`}>
                        <Form.Label className={`Select-Text`}>{this.props.textLabel}</Form.Label>
                        <Form.Control as="select" onChange={this.prepareChange} className={`Select-Menu p-4`} >
                            {this.props.children}
                        </Form.Control>
                     </Form.Group>
        } else {
            select = <Dropdown className={`Select ${(this.props?.hasOwnProperty('className')) ? this.props.className : ''}`}>
                        <Dropdown.ItemText className={`Select-Text mr-3`}>{this.props.textLabel}</Dropdown.ItemText>
                        <Dropdown.Toggle className={`Select-Toggle p-4 w-100 display-7 LibraryText`}>
                            {this.state.textOption}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`Select-Menu mw-100`}>
                            {this.props.children.map((option, i) =>
                                <Dropdown.Item className={`Select-Item LibraryText`} eventKey={option.props.value} key={i} onSelect={this.change}>
                                    {option.props.children}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                     </Dropdown>
        }
        return select;
    }
}
