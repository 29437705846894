import {combineReducers} from 'redux';
import userReduser from './user';
import loadReduser from './load';
import modalReduser from './modal';
import preshiftReduser from './preshift';
import appReducer from "./application";
import localeReduser from "./locale";
import breambReduser from "./breams";
import networkAnswerReduser from './network-answer';

let redusers = {
    userState: userReduser,
    loadState: loadReduser,
    modalState: modalReduser,
    preshiftState: preshiftReduser,
    localeState: localeReduser,
    breambState: breambReduser,
    appState: appReducer,
    networkAnswer: networkAnswerReduser,
}

const allRedusers = combineReducers(redusers);

export default allRedusers;
