import React, {Component} from "react";
import { isElectron } from 'react-device-detect'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo, faForward} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import Fail from "../../screens/questions/Fail";
import FailVisual from "../../screens/questions/FailVisual";
import FailVisualAndInjurie from "../../screens/questions/FailVisualAndInjurie";
import SuccessVisual from "../../screens/questions/SuccessVisual";
import {addBreamb, goBackStep} from "../../../reducers/breams-manage";
import preshiftManage from "../../../reducers/preshift-manager";
import ExitTimer from "../../blocks/ExitTimer";
import store from "../../../localStorage";
import Question from "../../screens/questions/flow/Question";
import { notify } from "../../blocks/Notify";

class SimpleQuestContainer extends Component {
    inititialState = {
        question: null,
        answers: [],
        content: Question,
        select_answer: null,
        event_dt: new Date(),
        is_exit_timer: true,
        show_exit_timer: false,
    }

    constructor(props) {
        super(props);
        this.state = {...this.inititialState};
        store.dispatch({type: 'PAGE_LOADED', loadId: 'item'});
    }

    skipListener = (event) => {
        if (isElectron && event.ctrlKey && event.key === 'm') {
            this.props.handleNextStep()
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.skipListener)
        if (this.state.content === Question && this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip) {
            this.props.renderAdditionalButton(
                <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                    <FontAwesomeIcon className="mr-4" icon={faForward}/>
                    {this.props.t('Skip')}
                </Button>);
        } else {
            this.props.renderAdditionalButton(null);
        }
    }

    componentWillUnmount = () => {
        this.setState({ ...this.inititialState });
        document.removeEventListener('keydown', this.skipListener)
    }

    handleExitTimer = () => this.setState({ is_exit_timer: false });
    
    renderButtons = (is_right, selected_answer_or_array) => {
        const selected_answer = selected_answer_or_array instanceof Array ? selected_answer_or_array[0] : selected_answer_or_array;
        const quote_from_document = this.props.question?.quote_from_document;
        const came_from = preshiftManage.getCameFrom();
        const sec = 10;
        const exitSec = quote_from_document ? 20 : sec;
        if (is_right) {
            if (came_from !== 'startup' && this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1 &&
                this.props.activeEducation === this.props.listEducation.length - 1) {
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        <Button className="MuiButton Btn Btn_success"
                                onClick={() => {
                                    if (this.state.is_exit_timer) {
                                        this.props.handleNextStep();
                                        this.props.renderAdditionalButton(null); }}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.t('Done')}
                            {came_from === "electronic-courses" &&
                                <ExitTimer theme_name={this.props.theme_name} goOut={this.props.handleNextStep} handleExitTimer={this.handleExitTimer} sec={sec} />}
                        </Button>
                        {came_from !== "electronic-courses" &&
                            <Button className="MuiButton Btn Btn_success ml-3"
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.props.handleFinish();
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Finish and exit')}
                                <ExitTimer theme_name={this.props.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer} sec={sec} />
                            </Button>}
                    </div>);
            } else {
                const goOut =
                    ((this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1) &&
                     (this.props.activeEducation === this.props.listEducation.length - 1))
                        ? this.props.handleFinish
                        : this.props.handleNextStep;
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        <Button className="MuiButton Btn Btn_success"
                                onClick={() => {
                                    if (this.state.is_exit_timer) {
                                        this.props.handleNextStep();
                                        this.props.renderAdditionalButton(null); }}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.activeStep !== this.props.listEducation[this.props.activeEducation].listStep.length - 1
                                    ? this.props.t('Next')
                                    : this.props.activeEducation !== this.props.listEducation.length - 1
                                        ? this.props.t('Proceed')
                                        : this.props.t('Finish and exit')}
                            <ExitTimer theme_name={this.props.theme_name} goOut={goOut} handleExitTimer={this.handleExitTimer} sec={sec} />
                        </Button>
                    </div>);
            }
        } else {
            if (!this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].params.mandatory_answer) {
                this.props.renderAdditionalButton(
                    <Button className="MuiButton Btn Btn_success" onClick={() => {if (this.state.is_exit_timer) this.repeatQuestion()}}>
                        <FontAwesomeIcon className="mr-4" icon={faRedo}/>
                        {this.props.t('Try again')}
                        {(!selected_answer?.hasOwnProperty('injurie') || this.state.show_exit_timer) &&
                            <ExitTimer theme_name={this.props.theme_name} goOut={this.repeatQuestion} handleExitTimer={this.handleExitTimer} sec={exitSec}/>}
                    </Button>);
            } else {
                if (came_from !== 'startup' && this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1 &&
                    this.props.activeEducation === this.props.listEducation.length - 1) {
                    this.props.renderAdditionalButton(
                        <div className="d-flex w-100 justify-content-end">
                            <Button className="MuiButton Btn Btn_success"
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.props.handleNextStep();
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Done')}
                                {came_from === "electronic-courses" &&
                                    <ExitTimer
                                        theme_name={this.props.theme_name}
                                        goOut={this.props.handleNextStep}
                                        handleExitTimer={this.handleExitTimer}
                                        sec={exitSec}/>}
                            </Button>
                            {came_from !== "electronic-courses" &&
                                <Button className="MuiButton Btn Btn_success ml-3"
                                        onClick={() => {
                                            if (this.state.is_exit_timer) {
                                                this.props.handleFinish();
                                                this.props.renderAdditionalButton(null); }}}>
                                    <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                    {this.props.t('Finish and exit')}
                                    {(!selected_answer?.hasOwnProperty('injurie') || this.state.show_exit_timer) &&
                                        <ExitTimer
                                            theme_name={this.props.theme_name}
                                            goOut={this.props.handleFinish}
                                            handleExitTimer={this.handleExitTimer}
                                            sec={exitSec}/>}
                                </Button>}
                        </div>);
                } else {
                    const goOut =
                        ((this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1) &&
                         (this.props.activeEducation === this.props.listEducation.length - 1))
                            ? this.props.handleFinish
                            : this.props.handleNextStep;
                    this.props.renderAdditionalButton(
                        <div className="d-flex w-100 justify-content-end">
                            <Button className="MuiButton Btn Btn_success"
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.props.handleNextStep();
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.activeStep !== this.props.listEducation[this.props.activeEducation].listStep.length - 1
                                        ? this.props.t('Next')
                                        : this.props.activeEducation !== this.props.listEducation.length - 1
                                            ? this.props.t('Proceed')
                                            : this.props.t('Finish and exit')}
                                {(!selected_answer?.hasOwnProperty('injurie') || this.state.show_exit_timer) &&
                                    <ExitTimer theme_name={this.props.theme_name} goOut={goOut} handleExitTimer={this.handleExitTimer} sec={exitSec}/>}
                            </Button>
                        </div>);
                }
            }
        }
    };

    onSelectAnswer = (answer_id) => {
        let statePreshift = this.props.preshift,
            stepUuid = this.props.stepData.uuid,
            stepName = this.props.stepData.name,
            error_count = statePreshift.error_count,
            question_uuid = this.props.question.id,
            selected_answer = {},
            is_right = false,
            question_text = this.props.stepData.data.question.text,
            selected_answer_text = '';

        if (answer_id instanceof Array) {
            const all_answers = statePreshift.answers;
            let right_answers_count = 0;
            all_answers.forEach(el => el.is_right && right_answers_count++);
            let selected_right_answers_count = 0;
            const selected_answers = [];
            const selected_answers_texts = [];
            answer_id.forEach(id =>
                all_answers.forEach(answer => {
                    if (answer.id === id) {
                        selected_answers.push(answer);
                        selected_answers_texts.push(answer.text);
                        if (answer.is_right) selected_right_answers_count++;
                    };
                }));
            is_right = right_answers_count === selected_right_answers_count && right_answers_count === answer_id.length;
            selected_answer = selected_answers;
            selected_answer_text = selected_answers_texts;
        } else {
            statePreshift.answers.forEach(answer => {
                if (answer.id === answer_id) {
                    is_right = answer.is_right;
                    selected_answer = answer;
                    selected_answer_text = answer.text;
                };
            });
        }

        this.setState({
            is_right: is_right,
            select_answer: selected_answer,
            show_exit_timer: false,
            is_exit_timer: true });

        const current_selected_answer = selected_answer instanceof Array ? selected_answer[0] : selected_answer;

        if (is_right) {
            let datumUhrzeit = new Date(),
                event_end_dt = '@' + Math.round(datumUhrzeit.getTime() / 1000),
                start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
                data = {
                    uuid: stepUuid,
                    name: stepName,
                    question_uuid: question_uuid,
                    answer_id: answer_id,
                    start_date_time: start_time,
                    elapsed_time: event_end_dt,
                    error_count: error_count,
                    selected_answer_text: selected_answer_text,
                    question_text: question_text,
                },
                flow_history = statePreshift.flow.history.steps;
                flow_history.push(data);

            preshiftManage.saveFlow({ history: {...statePreshift.flow.history} })

            this.setState({
                content: SuccessVisual,
                event_dt: new Date() });

            addBreamb({title: this.props.t('Answered correctly')});

            this.renderButtons(is_right, current_selected_answer);
        } else {
            if (!is_right && this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].params.mandatory_answer) {
                let datumUhrzeit = new Date(),
                    event_end_dt = '@' + Math.round(datumUhrzeit.getTime() / 1000),
                    start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
                    data = {
                        uuid: stepUuid,
                        name: stepName,
                        question_uuid: question_uuid,
                        answer_id: answer_id,
                        start_date_time: start_time,
                        elapsed_time: event_end_dt,
                        error_count: error_count + 1,
                        selected_answer_text: selected_answer_text,
                        question_text: question_text,
                    },
                    flow_history = statePreshift.flow.history.steps;
                    flow_history.push(data);
    
                preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
            };

            store.dispatch({type: 'PRESHIFT_FAIL'});

            current_selected_answer?.hasOwnProperty('visualisation')
                ? this.setState({
                    content: (current_selected_answer?.hasOwnProperty('injurie')) ? FailVisualAndInjurie : FailVisual,
                    event_dt: new Date() })
                : this.setState({
                    content: Fail,
                    event_dt: new Date() });

            addBreamb({title: this.props.t('Answered incorrectly')});

            this.renderButtons(is_right, current_selected_answer);
        }
    }

    handleTimeExpired = () => this.props.history.push('/');

    repeatQuestion = () => {
        this.setState({
            content: Question,
            event_dt: new Date(),
            show_exit_timer: false,
            is_exit_timer: true },
            () => {
                if (this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip) {
                    this.props.renderAdditionalButton(
                        <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.t('Skip')}
                        </Button>);
                } else {
                    this.props.renderAdditionalButton(null);
                };
        });
        goBackStep();
        notify(this.props.t('Attention!! The order of answers to the question has changed!!'), 'warning');
    }

    handleShowTimer = () => this.setState({show_exit_timer: true}, ()=>this.renderButtons(this.state.is_right, this.state.select_answer));

    render() {
        let Content = this.state.content;
        return <Content onSelectAnswer={this.onSelectAnswer}
                    handleTimeExpired={this.handleTimeExpired}
                    load={this.props.load} {...this.state} {...this.props}
                    repeatQuestion={this.repeatQuestion}
                    renderAdditionalButton={this.props.renderAdditionalButton}
                    handleShowTimer={this.handleShowTimer}
                    handleExitTimer={this.handleExitTimer} />
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        preshift: store.preshiftState,
        theme_name: store.appState.theme_name,
    }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(SimpleQuestContainer)));