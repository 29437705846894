import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import apiPreshift from '../../../api/apiPreshift';
import { errorNotify } from '../../../helpers/functions';
import { addBreamb, resetBreamb } from '../../../reducers/breams-manage';
import {pageLoadManageActions as loader} from "../../../reducers/load";
import preshiftManage from '../../../reducers/preshift-manager';
import Breams from '../../blocks/Breams';
import Loader from '../../blocks/Loader';
import { notify } from '../../blocks/Notify';
import AllCourses from '../../screens/electronicCourses/AllCourses';
import Course from '../../screens/electronicCourses/Course';
import EndCourse from '../../screens/electronicCourses/EndCourse';
import EndLesson from '../../screens/electronicCourses/EndLesson';
import EndTheme from '../../screens/electronicCourses/EndTheme';
import QuestionStepper from '../../steppers/QuestionStepper';

class ElectronicCoursesContainer extends Component {
    constructor(props) {
        super(props);

        this.state ={
          content: AllCourses,
          courses: [],
          activeCourse: [],
          activeLessons: [],
          activeTopic: {},
          appoint: '',
          course_title: '',
          is_exit_timer: true
        };

        this.handlePrepereData();
    };

    handleExitTimer = () => this.setState({is_exit_timer: false});

    handlePrepereData = () => {
      if (this.props.user.data.is_student) {
        document.title = this.props.t('Electronic courses');
        resetBreamb();
        addBreamb({title: this.props.t('Electronic courses')});
        this.getAllCourses();
        preshiftManage.setCameFrom('electronic-courses');
      } else if (this.props.location.pathname === "/electronic-courses-class") {
        preshiftManage.setCameFrom('electronic-courses');
        this.handleChangeContent("Course", this.props.user.data.appoint_uuid, this.props.user.data.course_title);
      } else {
        this.props.history.push("/cabinet");
      }
    };

    handleChangeContent = (new_content, id, course_title) => {
      switch (new_content) {
        case "AllCourses":
          this.getAllCourses();
          resetBreamb();
          addBreamb({title: this.props.t('Electronic courses')});
          this.setState({content: AllCourses});
          break;
        case "Course":
          this.getCourse(id)
          .then(() => {
            resetBreamb();
            addBreamb({title: this.props.t('Electronic courses')});
            addBreamb({title: course_title ? course_title : this.state.course_title});
            this.setState({content: Course});
          })
          break;
        case "EndTheme":
          resetBreamb();
          addBreamb({title: this.props.t('Electronic courses')});
          addBreamb({title: this.state.course_title});
          addBreamb({title: this.props.t('End of theme')});
          this.setState({content: EndTheme});
          break;
        case "EndLesson":
          resetBreamb();
          addBreamb({title: this.props.t('Electronic courses')});
          addBreamb({title: this.state.course_title});
          addBreamb({title: this.props.t('End of lesson')});
          this.setState({content: EndLesson});
          break;
        case "EndCourse":
          resetBreamb();
          addBreamb({title: this.props.t('Electronic courses')});
          addBreamb({title: this.state.course_title});
          addBreamb({title: this.props.t('End of course')});
          this.setState({content: EndCourse});
          break;
        case "QuestionStepper":
          this.setState({content: QuestionStepper});
          break;
        default:
            return this.state.content;
      }
    };

    getAllCourses = () => {
      return new Promise((resolve, reject) => {
        loader.start("El-courses");
        apiPreshift.getElectronicCourses(this.props.user.data.uuid)
        .then(response => {
          this.setState({courses: response.data});
          resolve();
        })
        .catch(e => {
          const isAuth = this.props.user.isAuth && !this.props.app.use_to_learning_class ? true : false;
          errorNotify(this.props.t('Error loading the e-courses'), e, this.props.history.push, isAuth);
        })
        .finally(() => loader.stop("El-courses"))
      })
  };

  getCourse = (id) => {
    return new Promise((resolve, reject) => {
        loader.start("El-courses");
        apiPreshift.getOneElectronicCourse(id)
        .then(res => {
          this.setState({
            activeCourse: res.data,
            activeLessons: res.data.lessons,
            activeTopic: this.handleSetActiveTopic(res.data.lessons),
            appoint: res.data.appoint.uuid,
            course_title: res.data.title,
          });
          resolve();
        })
        .catch(() => {
          notify(this.props.t('Error loading the e-course'), 'error');
          this.props.user.isAuth && this.props.app.use_to_learning_class && this.props.history.push('/');
        })
        .finally(() => loader.stop("El-courses"))
      })
  };

  handleSetActiveTopic = (lessons) => {
    let topic = {};
    let topics = [];
    lessons.forEach(el =>
      (!el.result || !el.result.complete) && el.topics.forEach(el =>
        (!el.result || !el.result.complete) && topics.push(el)));
    topic = topics[0];
    return topic;
  };

  handleNextStep = () => {
    return new Promise((resolve, reject) => {
      let lessons = this.state.activeLessons;
      let not_complete_lessons = [];
      lessons.forEach(el => !el.result || !el.result.complete && not_complete_lessons.push(el));
      let not_complete_lesson = not_complete_lessons[0];
      if (!not_complete_lesson) {
        resolve("EndCourse");
      } else {
        let not_complete_topics = not_complete_lesson.topics.map(el => !el.result || !el.result.complete && el);
        let not_complete_topic = not_complete_topics[0];
        if (!not_complete_topic) {
          resolve("EndTheme");
        } else {
          resolve("EndLesson");
        }
      }
    })
  };

  handleNextStepElCourse = (step) => {
    this.handleChangeContent("EndTheme");
    this.getCourse(this.state.appoint)
    .then(()=> {
      this.handleNextStep()
      .then((res) => {
        let next_step = step ? step : res;
        preshiftManage.setCameFrom('electronic-courses');
        this.handleChangeContent(next_step)})
    });
  };

  handleExitElCourses = () => this.props.history.push('/');

    render() {
        let Content = this.state.content;

        return <Loader isLoading={this.props.load.isLoading} loadId={"El-courses"}
                      justifyContent="center" className="Screen h-100">
                  {Content !== QuestionStepper && <Breams/>}
                  <Content {...this.state} {...this.props}
                            flow_uuid={this.state.activeTopic?.flow}
                            appointment={this.state.appoint}
                            handleChangeContent={this.handleChangeContent}
                            handleNextStepElCourse={this.handleNextStepElCourse}
                            handleExitElCourses={this.handleExitElCourses}
                            handleExitTimer={this.handleExitTimer} />
                </Loader>
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState,
        user: store.userState
    };
};

export default connect(mapStateToProps)(withTranslation()(ElectronicCoursesContainer));