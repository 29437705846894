import { faArrowLeft, faCheck, faForward, faSignOutAlt, faUserClock, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import Content from "../../blocks/Content";
import MainNav from "../../blocks/MainNav";
import courseImg from "../../../assets/course_img/default_course_cover.png";

export default function Course(props) {
  const [openModal, setOpenModal] = useState(false);
  const [modalTopics, setModalTopics] = useState([]);
  let content = <></>;
  let lessons = props.activeLessons;
  let course_img = props.activeCourse.file ? props.activeCourse.file : courseImg;
  let progress = props.activeCourse.result.progress;
  let bgColor = "";
  if (progress === 0) {
    bgColor = "#dc3545"; // красный
  } else if (progress === 100) {
    bgColor = "#1E874A"; // зеленый
  } else {
    bgColor = "#ffc107"; // желтый
  };

  const showTopics = (topics) => {
    if (topics.length > 0) {
      setModalTopics(topics);
      setOpenModal(true);
    }
  };

  const closeModal = (event) => event.target.id === "course_modal" && setOpenModal(false);

  if (lessons?.length > 0) {
      content = (
        <>
          <div className="d-flex flex-row align-items-center justify-content-around mb-4">
            <img src={course_img} alt={props.activeCourse.title} className="w-20"/>
            <span className="display-7 w-25">{props.activeCourse.title}</span>
            <div className="table_siz_num display-9" style={{backgroundColor: bgColor}}>{props.activeCourse.appoint.status}</div>
            <div className="display-7">
              <FontAwesomeIcon className="mr-2" icon={faUserClock} />
              {`${props.activeCourse.result.total_spent_time}/${props.activeCourse.passage_time}`}
            </div>
          </div>
          <h1 className="display-9 m-4">{props.activeCourse.description}</h1>
          <div className="d-flex align-items-center flex-column justify-content-center h-100">
            <table className="table_siz display-9 w-100">
              <thead>
                <tr>
                  <th className="text-center" scope="col">{props.t("Lesson name")}</th>
                  <th className="text-center" scope="col">{props.t("Number of topics")}</th>
                  <th className="text-center" scope="col">{props.t("Completed topics")}</th>
                  <th className="text-center" scope="col">{props.t("Total time")}</th>
                  <th className="text-center" scope="col">{props.t("Spent")}</th>
                </tr>
              </thead>
              <tbody>
                {lessons.map((el) => {
                  return (
                    <tr className="table_siz_tr cursor-pointer" key={el.uuid} id={el.uuid} onClick={() => showTopics(el.topics)}>
                      <td className="text-center table_siz_text">{el.title}</td>
                      <td className="text-center table_siz_text">{el.topics.length}</td>
                      <td className="text-center table_siz_text">{el.result.count_complete_topics}</td>
                      <td className="text-center table_siz_text">{el.passage_time}</td>
                      <td className="text-center table_siz_text">{el.result.spent_time}</td>
                    </tr>) })}
              </tbody>
            </table>
          </div>
        </>);
  } else {
      content = (
        <div className="d-flex align-items-center flex-column justify-content-center h-100">
          <h4 className={`preshift__text text-center ${props.data ? "Text_danger" : "Text_success"}`}>
            {props.t(`${props.data ? props.data : "No courses"}`)}
          </h4>
        </div>);
  };

  return <>
      <div className="Screen container d-flex flex-column justify-content-center align-items-center">
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Content>
            {content}
          </Content>
        </div>
        {openModal &&
          <div className="course_modal" id="course_modal" onClick={closeModal} >
            <div className="course_modal_div">
              <div className="course_modal_head">
                <h1 className="text-center">{props.t("List of topics")}</h1>
                <div className="course_modal_x display-9 cursor-pointer" onClick={() => setOpenModal(false)}>
                  <FontAwesomeIcon className="m-2" icon={faClose} />
                </div>
              </div>
              <Content>
              <table className="table_course display-9 m-5">
                <thead>
                  <tr>
                    <th className="text-center" scope="col">№</th>
                    <th className="text-center" scope="col">{props.t("Theme name")}</th>
                    <th className="text-center" scope="col">{props.t("Duration/Total")}</th>
                    <th className="text-center" scope="col">{props.t("Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {modalTopics.map((el, index) => {
                    let spent_time = el.result ? el.result.spent_time : 0;
                    let passage_time = el.passage_time ? el.passage_time : 0;
                    let status = el.result ? el.result.complete : false;
                    return (
                      <tr className="table_siz_tr" key={el.uuid} id={el.uuid}>
                        <td className="text-center table_siz_text">{index + 1}</td>
                        <td className="text-center table_siz_text">{el.title}</td>
                        <td className="text-center table_siz_text">{spent_time}/{passage_time}</td>
                        <td className="text-center table_siz_text">{status && <FontAwesomeIcon icon={faCheck}/>}</td>
                      </tr>) })}
                </tbody>
              </table>
              </Content>
            </div>
          </div>}
      </div>
      <MainNav className={`main-navi w-100`}>
        <Container className={`d-flex justify-content-between`}>
          {!props.activeCourse.result.complete && props.location.pathname === "/electronic-courses-class" &&
            <NavLink className={`Btn Btn_danger MuiButton`} to={'/'} >
              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt} />
              <span>{props.t('Exit')}</span>
            </NavLink>}
          {props.location.pathname !== "/electronic-courses-class" &&
            <Button onClick={() => props.handleChangeContent("AllCourses")} className={`Btn Btn_boring MuiButton`}>
                <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                 <span>{props.t('Back')}</span>
            </Button>}
          {!props.activeCourse.result.complete && !props.data &&
            <Button className={`Btn Btn_success MuiButton`}
                  onClick={() => props.handleChangeContent("QuestionStepper")} >
              <FontAwesomeIcon className="mr-4" icon={faForward} />
              <span>{props.t(progress === 0 ? "Start training" : "Continue training")}</span>
            </Button>}
          {props.activeCourse.result.complete && props.location.pathname === "/electronic-courses-class" &&
            <NavLink className={`Btn Btn_danger MuiButton`} to={'/'} >
              <FontAwesomeIcon className="mr-4" icon={faSignOutAlt} />
              <span>{props.t('Exit')}</span>
            </NavLink>}
        </Container>
      </MainNav>
    </>
}