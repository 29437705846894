import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import $ from 'jquery';
import {connect} from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import withFormHanlding from "../../hoc/FormHandling";
import apiEmployee from "../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../reducers/load";
import { errorNotify } from '../../helpers/functions';
import userManage from "../../reducers/user-manage";
import PinCode from '../screens/startup/PinCode';
import MainNav from '../blocks/MainNav';
import { notify } from '../blocks/Notify';
const CryptoJS = require("crypto-js");

class PinCodeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyboardConfig: {
        layoutName: "default",
        layout: {
          default: ["1 2 3", "4 5 6", "7 8 9", "0 {backspace}"],
        },
        display: {
          "{backspace}": "<",
        },
        theme: "theme_cot",
        onKeyReleased: this.onKeyRelease,
        keyboardRef: r => (this.keyboard = r),
      },
      state_pin_code: null,
      errCount: 0,
      inputValue: '',
    };
    let formState = {
      formErrors: {
        tabNum: [],
      },
      formValues: { tabNum: "" },
      formValid: false,
    };
    props.prepareFormState(formState);
  }

  onInputTabelNum = (event) => this.onKeyRelease(event.target.value, false);

  componentDidMount = () => loader.stop("screenStartup");

  componentDidUpdate(prevState) {
    this.state.errCount === 5 && setTimeout(() => { this.props.history.push("/") }, 500);
  }

  componentWillUnmount() {
    this.keyboard.destroy();
  };

  /**
   * @param input
   */

  onKeyRelease = (pressedKey, bool) => {
    if (!bool) {
      this.setState({inputValue: pressedKey}, () => this.handleSetResult(this.state.inputValue));
    } else if (pressedKey !== "{backspace}") {
      this.setState((prevState) => ({inputValue: prevState.inputValue + pressedKey}), () => this.handleSetResult(this.state.inputValue));
    } else {
      this.setState((prevState) => ({inputValue: prevState.inputValue.slice(0, -1)}), () => this.handleSetResult(this.state.inputValue));
    }
  };

  handleSetResult = (inputValue) => {
    const tabNum = document.querySelector("#pin_code_table");
    tabNum.value = inputValue;
    this.props.validateField(tabNum);
    this.handleSubmit(inputValue);
  };

  handleSubmit = (value) => {
    const user_pin_code = this.props.user.pin_code !== null && this.props.user.pin_code.includes(" ")
      ? this.props.user.pin_code.replaceAll(" ", "+")
      : this.props.user.pin_code;
    const dec_pin_code = this.props.user.pin_code !== null
      ? CryptoJS.DES.decrypt(user_pin_code, "key")
      : "null";
    const pin_code = dec_pin_code.toString(CryptoJS.enc.Utf8);
    if (value == pin_code) {
      setTimeout(userManage.pinCodeAuthorized, 100);
    } else if (this.props.user.pin_code === null && this.state.state_pin_code === null && value.length === this.props.app.pin_code_count) {
      this.setState({ state_pin_code: value });
      this.handleClearInput();
    } else if (value === this.state.state_pin_code && this.props.user.pin_code === null) {
      const enc_pin_code = CryptoJS.DES.encrypt(this.state.state_pin_code, "key");
      const pass = enc_pin_code.toString();
      this.setState({ errCount: 0, inputValue: '' });
      setTimeout(() => this.setPinCode(this.props.user.uuid, pass), 100);
    } else if (value.length >= this.props.app.pin_code_count &&
              ((this.state.state_pin_code !== null && value !== this.state.state_pin_code) ||
              (this.props.user.pin_code !== null && value !== pin_code))) {
      this.props.prepareFormState({
        formErrors: {
          tabNum: [this.props.t("Invalid pin code")],
        },
        formValid: false,
      });
      this.setState({ errCount: this.state.errCount + 1 }, () => this.state.errCount !== 5 && this.handleClearInput());
    };
  };

  handleClearInput = () => {
    const tabNum = document.querySelector("#pin_code_table");
    tabNum.value = "";
    this.setState({inputValue: ''});
    this.keyboard.clearInput();
  };

  handleBack = () => {
    this.setState({ state_pin_code: null, errCount: 0 });
    this.handleClearInput();
    this.props.prepareFormState({
      formErrors: {
        tabNum: [],
      },
    });
  };

  setPinCode = (id, pin_code) => {
    loader.start("screenStartup");
    return new Promise((resolve, reject) => {
      apiEmployee.setPinCode(id, pin_code)
        .then(() => {
          notify(this.props.t('PIN code is set'), 'success');
          userManage.setPinCode(pin_code);
          resolve();
        })
        .catch(e => errorNotify(this.props.t('PIN installation error'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop("screenStartup"));
    });
  };

  componentWillUpdate() {
    // TODO: fix double keyboards
    let keyboards = $(".startup__keyboard .hg-rows");

    if (keyboards.length > 1) {
      keyboards.last().remove();
    }
  }

  render() {
    return <>
            <PinCode {...this.props} {...this.state} onInputTabelNum={this.onInputTabelNum} errorClass={this.props.errorClass} />
            <MainNav>
              <Container>
                <div className="w-100">
                  <div className="my-auto pl-0 mx-auto row justify-content-between align-items-center">
                    <div className="d-flex justify-content-start">
                      {this.state.state_pin_code !== null && this.props.user.pin_code === null &&
                        <Button className="MuiButton Btn Btn_boring" onClick={this.handleBack} >
                          <FontAwesomeIcon className="mr-4" icon={faArrowAltCircleLeft} />
                          {this.props.t("Back")}
                        </Button>}
                    </div>
                    <div className="d-flex justify-content-end">
                      <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt} />
                        <span>{this.props.t("Exit")}</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </Container>
            </MainNav>
          </>
  };
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState,
        user: store.userState.data
    };
};

export default withRouter(connect(mapStateToProps)(withFormHanlding(PinCodeContainer)));