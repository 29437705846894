import React, { useState } from 'react';
import { Container, FormGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Content from "../../blocks/Content";
import Select from '../../blocks/Select';

export default function ExtraEducationsTab(props) {
    let type_keys = [props.t('All'), props.t('New educacions'), props.t('Passed')];
    const [type, setType] = useState(type_keys[0]);
    const [showPopover, setShowPopover] = useState(false);

    const onSelectType = (value) => setType(value);
    const handleShowPopover = () => {
        setShowPopover(true);
        setTimeout(()=> {setShowPopover(false)}, 5000);
        showPopover && setShowPopover(false);
    };
    const setSatusColor = (status) => {
        switch (status) {
            case "assigned-awaiting": return 'Btn_success'
            case "passed": return 'Btn_boring'
            case "training-started": return 'btn-primary'
            default: return 'Btn_danger'
        }
    };

    let data;

    if (type === type_keys[0] && props.state[0]?.appointment) data = Object.values(props.state);
    if (type === type_keys[1]) {
        let new_data = Object.values(props.state);
        data = new_data.filter(el => el.appointment.enable === true);
    };
    if (type === type_keys[2]) {
        let new_data = Object.values(props.state);
        data = new_data.filter(el => el.appointment.enable !== true);
    };

    return <div className={"Screen container"}>
            {data &&
             <div className={"Screen-Panel pt-4"}>
                <Container>
                    <FormGroup className="d-flex align-items-center justify-content-between">
                        <Select onSelectCallback={onSelectType} className="FeedbackAdd-SelectType"
                                name="theme_id" textLabel={props.t('Extra education')}>
                            {type_keys.map(key => <option value={key} key={key}>{key}</option>)}
                        </Select>
                    </FormGroup>
                </Container>
             </div>}
            <div className={"Screen container"}>
                <Content>
                    <Container>
                        <div className="h-100">
                            {data
                             ? <table className="table_siz display-9 w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center" scope="col">{props.t('Siz_Title')}</th>
                                        <th className="text-center" scope="col">{props.t('Days left')}</th>
                                        <th className="text-center" scope="col">{props.t('Attempts used')}</th>
                                        <th className="text-center" scope="col">{props.t('Status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {data.length > 0
                                    ? data.map((el) => {
                                        const days_left = Math.floor((Date.parse(el.appointment.date_delay) - new Date()) / 86400000);
                                        const days_left_render = days_left > 0 ? days_left : 0;
                                        const new_date = new Date().getTime() - new Date(el.appointment.start_dt).getTime();
                                        const end_date = new Date().getTime() - new Date(el.appointment.passage_dt).getTime();
                                        const status = setSatusColor(el.appointment.status);
                                        const attempts = el.appointment.attempts_used + props.t('of') + el.number_of_attempts;
                                        const enable = el.appointment.enable;
                                        let days_left_color = '';
                                        if (enable && days_left <= 1) days_left_color = 'Text_danger';
                                        if (enable && days_left > 1 && days_left <= 3) days_left_color = 'Text_happy';
                                        return <tr className={`${enable && "cursor-pointer"} table_siz_tr`} key={el.appointment.uuid}
                                                    onClick={() => {
                                                        enable && props.goToExtraEducation(el.flow, el.appointment.uuid, el.threshold_of_passage);
                                                        !el.flow && handleShowPopover(); }}>
                                                {!el.flow &&
                                                    <OverlayTrigger
                                                        show={showPopover}
                                                        placement="bottom"
                                                        overlay={<Popover className={"Text_danger display-9 text-center p-2"}>
                                                                    {props.t('There are no questions')}
                                                                 </Popover>}>
                                                     <td className="text-center red_text">
                                                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                                                     </td>
                                                    </OverlayTrigger>}
                                                <td className="text-center table_siz_text">{el.title}</td>
                                                <td className={`text-center table_siz_text ${days_left_color}`}>{days_left_render}</td>
                                                <td className={`text-center`}>{attempts}</td>
                                                <td className="text-center"><div className={`Educ_Status ${status}`}>{el.appointment.status_title}</div></td>
                                                <td className="text-center">
                                                   {(enable && new_date < 86400000)
                                                    ? <div className="Educ_Status Btn_happy">{props.t('New')}</div>
                                                    : (enable && el.appointment.passage_dt && end_date > 0) &&
                                                        <div className="Educ_Status Btn_danger">{props.t('Expired')}</div>}
                                                </td>
                                               </tr> })
                                    : <tr>
                                        <td className="table_siz_text text-center Text_success">
                                            {type === type_keys[1] && props.t('No assigned')}
                                            {type === type_keys[2] && props.t('No completed training')}
                                        </td>
                                      </tr>}
                                </tbody>
                               </table>
                             : <div className="preshift d-flex align-items-center flex-column justify-content-center h-100">
                                <h4 className={`preshift__text text-center ${props.state === "Network Error" ? "Text_danger" : "Text_success"}`}>
                                    {props.t(props.state === "Network Error" ? "Network Error" : "No assigned")}
                                </h4>
                               </div>}
                        </div>
                    </Container>
                </Content>
            </div>
           </div>
}