import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export default function Presentation({url, t, handleWatched, onError}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showButtons, setShowButtons] = useState(false);
  const [isWatch, setIsWatch] = useState(false);

  let parent = document.querySelector(".react-pdf__Document");
  let width = parent !== null ? parent.clientWidth : null;

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setShowButtons(true);
  };
  const pageLeft = () => pageNumber > 1 ? setPageNumber(pageNumber - 1) : setPageNumber(numPages);
  const pageRight = () => pageNumber < numPages ? setPageNumber(pageNumber + 1) : setPageNumber(1);
  const onLoadError = (e) => {
    setNumPages(1);
    onError(e);
  };

  useEffect(() => {
    if (handleWatched && pageNumber === numPages && !isWatch) {
      handleWatched();
      setIsWatch(true);
    };
  }, [pageNumber]);

  return (
    <>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} className={`d-flex justify-content-center align-items-center p-5`} onLoadError={onLoadError} >
        <Page pageNumber={pageNumber} width={width} />
      </Document>
      {showButtons &&
        <div className={`d-flex flex-row justify-content-center align-items-center overflow-auto`}>
          {pageNumber !== 1 &&
            <Button onClick={pageLeft} className={`Btn Btn_success MuiButton mr-3`}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>}
          <h1 className={`text-center`}>{t("Page")} {pageNumber} {t("of")} {numPages}</h1>
          {pageNumber !== numPages &&
            <Button onClick={pageRight} className={`Btn Btn_success MuiButton ml-3`}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>}
        </div>}
    </>
  )
};
