import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import animationData from '../../data/icons/repair.json';

export default React.memo(function TechWork() {
    const { t } = useTranslation()
    document.title = t('Competence control complex');
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
            <h1 className="display-3 mb-1 font-weight-bold">{t('Technical work is underway')}</h1>
            <h1 className="display-3">{t('The app is temporarily down')}</h1>
            <Lottie className="mb-4" {...defaultOptions} style={{height: '40rem', width: '40rem'}}/>
            <h1 className="display-4">{t('We apologize')}!</h1>
           </div>
});