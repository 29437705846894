import React from "react";
import Lottie from 'lottie-react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import appManage from "../../reducers/app-manager";
import animationData from "../../data/icons/ghost.json";
import store from "../../localStorage";

function FatalError(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    let external_desc_error = '';

    if (props.app.errors) {
        if (props.app.errors?.hasOwnProperty('typeError')) {
            if (props.app.errors.typeError === 'RFID_FAIL') {
                external_desc_error = props.t('Failed to connect rfid reader');
            } else if (props.app.errors.typeError === 'NETWORK_ERROR') {
                external_desc_error = props.t('Network Error');
            } else if (props.app.errors.typeError === 'CAMERA_FAIL') {
                external_desc_error = props.t('Failed to connect camera');
            } else if (props.app.errors.typeError === 'ALCO_FAIL') {
                external_desc_error = props.t('Failed to connect breathalyzer');
            }
        }
    }

    const addNotFatalError = () => appManage.notFatalError(external_desc_error);

    return <div className="d-flex flex-column flex-md-row align-items-center justify-content-center h-100 px-4">
            <div className="mb-4 mr-5">
                <Lottie className="mb-4" {...defaultOptions} style={{height: '20rem', width: '20rem'}} />
            </div>
            <div className="text-center text-md-left">
                <h4 className="display-7 mb-4 font-weight-bold">
                    {props.t('Sorry, something went wrong')}.<br/>
                    {props.t('Tell the administrator')}.
                </h4>
                {external_desc_error && <p className="display-6 mb-4">{props.t('Status')}: <b className="text-danger">{external_desc_error}.</b></p>}
                {props.userState.isAuth
                    ? <NavLink onClick={addNotFatalError} to="/cabinet" className="Btn Btn_success text-center d-inline-block display-7 py-3 px-5 font-weight-bold">
                        {props.t('Return to personal area')}
                      </NavLink>
                    : <NavLink onClick={addNotFatalError} to="/startup" className="Btn Btn_success text-center d-inline-block display-7 py-3 px-5 font-weight-bold">
                        {props.t('Return to start screen')}
                      </NavLink>
                    }
            </div>
           </div>
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        userState: store.userState
    };
};

export default connect(mapStateToProps)(withTranslation()(FatalError));