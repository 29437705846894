import React from 'react';
import {connect} from "react-redux";
import {FormControl, FormGroup, FormLabel, Tooltip, OverlayTrigger} from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import {withTranslation} from "react-i18next";
import Content from "../../blocks/Content";
import Loader from "../../blocks/Loader";
import { isWebApp } from '../../../helpers/functions';

class PinCode extends React.Component {
    constructor(props) {
        super(props);
        document.title = props.t("Enter pin-code");
    }

    textTitle = () => {
        let title = "";
        if (this.props.state_pin_code === null && this.props.user.pin_code === null) {
            title = "Come up with a pin-code";
        } else if (this.props.state_pin_code !== null && this.props.user.pin_code === null) {
            title = "Repeat the pin-code";
        } else {
            title = "Enter pin-code";
        };

        return title;
    }

    render(props) {
        const auto_focus = isWebApp();
        let hasError = false;
        let errors = [];
        let messageError = '';
        let formErrors = this.props.formErrors;

        if (formErrors?.hasOwnProperty('error') && formErrors.error.length > 0) {
            messageError = formErrors.error[0];
            errors = formErrors.error;
            hasError = true;
        } else if (formErrors?.hasOwnProperty('tabNum') && formErrors.tabNum.length > 0) {
            messageError = formErrors.tabNum[0];
            errors = formErrors.tabNum;
            hasError = true;
        }

        return <>
                <div className={"Screen startup"}>
                    <Content className="container">
                        <div className="overflow-hidden">
                            <div className="d-flex flex-row h-100">
                                <Loader isLoading={this.props.load.isLoading} loadId={"screenStartup"} justifyContent="center" className="mt-5">
                                    <div className={"startup__container startup__container_bn1 d"}>
                                        <div className={"startup__navi"}>
                                            <FormGroup className="text-center">
                                                <FormLabel className={"startup__tabel-label mb-0"}>
                                                    {this.props.t(this.textTitle())}
                                                    {(this.props.state_pin_code === null && this.props.user.pin_code === null) && this.props.app.pin_code_count}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip" className={"tooltip error"}>
                                                                    <i className="fas fa-exclamation-triangle mr-3"> </i> {(hasError) ? messageError : null}
                                                                </Tooltip>}
                                                        show={hasError} >
                                                        <FormControl
                                                            type="password"
                                                            minLength="1"
                                                            required
                                                            autoFocus={auto_focus}
                                                            name="tabNum"
                                                            onChange={this.props.onInputTabelNum}
                                                            className={`startup__tabel-field mx-auto mt-3 ${(hasError) ? this.props.errorClass(errors) : null}`}
                                                            id="pin_code_table" />
                                                    </OverlayTrigger>
                                                </FormLabel>
                                            </FormGroup>
                                            <div className={"startup__keyboard mb-4"}>
                                                <Keyboard {...this.props.keyboardConfig} />
                                            </div>
                                        </div>                                    
                                    </div>
                                </Loader>
                            </div>
                        </div>
                    </Content>
                </div>
               </>
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState.data
    };
};

export default connect(mapStateToProps)(withTranslation()(PinCode));