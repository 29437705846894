import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import FeedbackList from "../../screens/feedback/FeedbackList";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import apiFeedback from "../../../api/apiFeedback";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import { errorNotify } from '../../../helpers/functions';

class FeedbackListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogs: [],
            themes: {},
            filter_dialogs: 'All',
        }

        resetBreamb();
    }

    componentDidMount() {
        this.fieldMessage = document.querySelector('.feedback__message');
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Feedback')});
        this.getDialogs();
    }

    onSelectFilter = (value) => this.setState({filter_dialogs: value});

    getDialogs = () => {
        loader.start('content_list');
        let user_id = this.props.user.data.id;
        apiFeedback.getDialogs(user_id)
        .then(response => this.setState({...response.data}))
        .catch(e => errorNotify(this.props.t('Dialogs loading error'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('content_list'));
    }

    render() {
        return <FeedbackList {...this.state} {...this.props} onSelectFilter={this.onSelectFilter} />;
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(withTranslation()(FeedbackListContainer));