import apiHandling from "./apiHandling";

class apiFeedback extends apiHandling {

    static getThemes = () => this.req({
            method: 'get',
            action: `feedback/themes`,
        });

    static getStructures = () => this.req({
            method: 'get',
            action: `structures`,
        });

    static getUsers = () => this.req({
            method: 'get',
            action: `users`,
        });

    static getDialogs = (employee_id) => this.req({
            method: 'get',
            action: 'feedback/dialogs',
            data: {
                employee_id: employee_id
            }
        });

    static getDialog = (uuid) => this.req({
            method: 'get',
            action: 'feedback/dialogs',
            data: {
                uuid: uuid
            }
        });

    static createDialog = (employee_id, text_message, theme_uuid, recipient) => {
        const formData = new FormData();
        formData.append('employee_id', employee_id);
        formData.append('text_message', text_message);
        formData.append('theme_uuid', theme_uuid);
        formData.append('recipient', JSON.stringify(recipient));

        return this.req({
                method: 'post',
                action: 'feedback/dialogs',
                data: formData,
            });
    }

    static sendMessage = (employee_id, text_message, uuid) => {
        const formData = new FormData();
        formData.append('employee_id', employee_id);
        formData.append('text_message', text_message);
        formData.append('uuid', uuid);

        return this.req({
                method: 'post',
                action: 'feedback/dialogs',
                data: formData,
            });
    }

    static setMarkRead = (uuid_message) => {
        const formData = new FormData();
        formData.append('uuid_message', uuid_message);

        return this.req({
                method: 'post',
                action: 'feedback/dialogs/mark-read',
                data: formData,
            });
    }
}

export default apiFeedback;