import {Component} from "react";
import Highcharts from "highcharts";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {addBreamb, resetBreamb} from "../../reducers/breams-manage";
import userManage from "../../reducers/user-manage";
import apiRisks from "../../api/apiRisks";
import {pageLoadManageActions as loader} from "../../reducers/load";
import EmpLvl from "../screens/EmpLvl";
import { errorNotify } from "../../helpers/functions";
import img21 from "../../assets/risk/21m.png";
import img41 from "../../assets/risk/41m.png";
import img4 from "../../assets/risk/4m.png";

class EmpLvlContainer extends Component {
    constructor(props) {
        super(props);
        document.title = props.t('Analytics');
        resetBreamb();
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Analytics')});
        this.state = {
            data: {
                risks: []
            }
        }
        this.chartConfig = {
            chart: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                        [0, '#ededed'],
                        [1, '#ededed']
                    ]
                },
                height: 270,
                type: 'line',
            },
            title: false,
            legend: false,
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    states: null,
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontWeight: 'bold',
                            fontSize: '15',
                        },
                    },
                    animation: false,
                    pointWidth: 60,
                    pointStart: Date.UTC(new Date().getFullYear(), 1, 4),
                    pointInterval: 24 * 3600 * 1000, // one day
                },
            },
            yAxis: {
                title: {
                    text: props.t('Risk levels'),
                    style: {
                        color: '#606060',
                        fontWeight: 'bold',
                        fontSize: 18,
                    }
                },
                offset: 20,
                align: 'right',
                minColor: '#FFFFFF',
                maxColor: '#ff7f7f',
                tickPixelInterval: 1,
                labels: {
                    enabled: false,
                },
                gridLineWidth: 0,
                min: 70,
                minRange: 30,
                maxPadding: 0,
                plotBands: [
                    {
                        from: 70,
                        to: 80,
                        color: '#ff69b3',
                        label: {
                            //text: 'Высокий риск',
                            style: {
                                color: '#606060'
                            }
                        }
                    },
                    {
                        from: 80,
                        to: 90,
                        color: '#ffff7f',
                        label: {
                            //text: 'Средний риск',
                            style: {
                                color: '#606060'
                            }
                        }
                    },
                    {
                        from: 90,
                        to: 100,
                        color: '#7fff7f',
                        label: {
                            //text: 'Низкий риск',
                            style: {
                                color: '#606060'
                            }
                        }
                    },
                    {
                        from: 90,
                        to: 100,
                        color: 'lightgreen',
                        label: {
                            text: props.t('Low'),
                            rotation: 270,
                            style: {
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#606060'
                            },
                            opposite: true,
                            x: -10,
                            y: 30
                        }
                    },
                    {
                        from: 80,
                        to: 90,
                        color: 'khaki',
                        label: {
                            text: props.t('Medium'),
                            rotation: 270,
                            style: {
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#606060'
                            },
                            x: -10,
                            y: 30
                        }
                    },
                    {
                        from: 70,
                        to: 80,
                        color: '#e83e8c',
                        label: {
                            text: props.t('High'),
                            rotation: 270,
                            style: {
                                fontSize: 12,
                                color: '#606060',
                                fontWeight: 'bold',
                            },
                            x: -10,
                            y: 30
                        }
                    },
                    {
                        from: 90,
                        to: 90.5,
                        color: '#3dc5f5',
                        label: {
                            text: '',
                            style: {
                                color: '#606060'
                            }
                        }
                    },
                ],
            },
            xAxis: {
                type: 'datetime',
                tickmarkPlacement: 'on',
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                dateTimeLabelFormats: {
                    day: '%d. %b',
                    month: '%b \'%y',
                    year: '%Y',
                    hour: '%H:%M',
                },
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%b %y', this.value)
                    },
                    step: 1,
                    style: {
                        fontSize: 15,
                        fontWeight: 'bold',
                    }
                },
                lineWidth: 1,
                minorGridLineWidth: 4,
            },
            series: []
        };
        let offset = new Date().getTimezoneOffset();
        Highcharts.setOptions({
            lang: {
                months: [
                    props.t('January'), props.t('February'), props.t('March'), props.t('April'),
                    props.t('May'), props.t('June'), props.t('July'), props.t('August'),
                    props.t('September'), props.t('October'), props.t('November'), props.t('December')
                ],
                weekdays: [
                    props.t('Sunday'), props.t('Monday'), props.t('Tuesday'), props.t('Wednesday'),
                    props.t('Thursday'), props.t('Friday'), props.t('Saturday')
                ],
                shortMonths: [props.t('Jan'), props.t('Feb'), props.t('March'), props.t('Apr'), props.t('May'), props.t('June'), props.t('July'), props.t('Aug'), props.t('Sept'), props.t('Oct'), props.t('Nov'), props.t('Dec')],
            },
            time: {
                timezoneOffset: offset,
                useUTC: false
            }
        });
    }

    componentDidMount() {
        const user_uuid = this.props.user.data.uuid;
        let start_dt_new = new Date();
        start_dt_new.setFullYear(start_dt_new.getFullYear() - 1);
        let start_dt = start_dt_new.toISOString().slice(0,19);
        let end_dt = new Date().toISOString().slice(0,19);  // было new Date().getTime();
        let data = {
            start: start_dt,
            end: end_dt,
            unit: 'month',
        };
        loader.start('emplvl_screen');
        apiRisks.getStatistic(user_uuid, data)
        .then(response => {
            this.prepareDataChart(response.data);
            this.setState({data: response.data})
        })
        .catch(e => errorNotify(this.props.t('Analytics Loading Error'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('emplvl_screen'))
    }

    handleReviewedRisks = () => {
        loader.start('emplvl_screen');
        apiRisks.reviewedRisks(this.props.user.data.uuid)
        .catch(()=>{}) //* игнорируем
        .finally(async () => {
            const user = userManage.getData();
            const new_user = {
                ...user,
                viewRisks: false,
            };
            await userManage.authorized(new_user)
            .then(userManage.setIntervalGetExtData);
            loader.stop('emplvl_screen');
        })
    };

    prepareDataChart = (data) => {
        let imageData = [];
        if (data.risks.length > 0) {
            data.risks.forEach(function (item) {
                const competence = (item.averageCompetence > 70) ? item.averageCompetence : 70;
                let img;

                if (competence >= 90) {
                    img = `url(${img21})`;
                } else if (competence < 90 && competence >= 80) {
                    img = `url(${img41})`;
                } else if (competence < 80 && competence >= 70) {
                    img = `url(${img4})`;
                } else {
                    img = null;
                }

                imageData.push({
                    x: +new Date(item.date_start).getTime(),
                    y: +competence,
                    marker: {
                        symbol: img
                    }
                });
            });
        } else {
            imageData.push({
                x: +new Date(data.start_dt).getTime(),
                y: 0,
                marker: {
                    enabled: false,
                }
            });
        }
        this.chartConfig.series.push({
            line: {
                label: {
                    style: {
                        minFontSize: 15,
                        fontWeight: "bold"
                    }
                }
            },
            name: this.props.t('Level of risk'),
            color: "#004d80",
            lineWidth: 5,
            data: imageData
        });
    }

    render() {
        return <EmpLvl {...this.props} {...this.state}
                Highcharts={Highcharts}
                options={this.chartConfig}
                handleReviewedRisks={this.handleReviewedRisks} />;
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(withTranslation()(EmpLvlContainer));