import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Col} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import React from "react";
import {hasIsProp} from "../../../helpers/functions";

export default function Module(props) {
    const color = props?.hasOwnProperty('color') ? props.color : 'secondary';
    const disabled = props?.hasOwnProperty('disable') && props.disable ? 'disabled' : '';

    return <Col xs={"12"} lg={"6"} className={"ScreenCabinet-Col ModuleCard"}>
            <NavLink to={props.route} onClick={(hasIsProp(props, 'onClick')) ? props.onClick : null}
                     className={`ModuleCard-Link d-flex align-items-center ${disabled} ` + `justify-content-between Btn Btn_${color} text-white text-left p-5`} >
                <div className="ModuleCard-Text d-flex">
                    <FontAwesomeIcon icon={props.icon} className="mr-4"/>{props.title}
                </div>
                {props?.hasOwnProperty('unread') && props.unread > 0 && <Badge className={"ModuleCard-Count"} variant={"warning"}>+{props.unread}</Badge>}
            </NavLink>
           </Col>
}