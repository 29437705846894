import React from 'react';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

export default function ConfirmStudentModal(props) {
  return (
    <div className={`${props.theme}`}>
      <div className={`screensaver_div active`}>
        <div className='screensaver_modal'>
          <h1 className='display-7' style={{ color: 'white' }}>{props.t('Confirm the selection')}</h1>
          <div className='screensaver_text'>
            <h1>{props.title}</h1>
          </div>
          <div className='d-flex justify-content-between w-100'>
            <Button className='MuiButton Btn Btn_danger ml-3' onClick={() => props.selectStudent({})}>
              <FontAwesomeIcon className='m-4' icon={faBan} />
            </Button>
            <Button className='MuiButton Btn Btn_success ml-3' onClick={props.toElectronicCourses}>
              <FontAwesomeIcon className='m-4' icon={faCheck} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
