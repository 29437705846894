import React from 'react';
import {Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";

function Preloader(props) {
    return <div className="preloader preloader_cot d-flex align-items-center justify-content-center">
            {props.network_error
                ? <h1 className='preshift__text text-center text-danger'>{props.t(props.network_error)}</h1>
                : <><Spinner className="preloader__spinner" animation="grow" size="lg" as="span"/><span className="preloader__text">{props.t('Loading')}...</span></>}
           </div>
}

export default withTranslation()(Preloader);