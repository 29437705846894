import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {isElectron} from "react-device-detect";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Rfid from "../screens/startup/Rfid";
import apiEmployee from "../../api/apiEmployee";
import {listenerRfid} from "../../index";
import {pageLoadManageActions as loader} from "../../reducers/load";
import userManage from "../../reducers/user-manage";
import configApp from "../../config/config";

class RfidContainer extends Component {
    constructor(props) {
        super(props);

        userManage.logout();
        loader.stop('item');

        this.state = {
            rfidWord: '',
            message: '',
        };

        this.initRfid();
    }

    handleKeyUp = (e) => this.setState({rfidWord: this.state.rfidWord += e.key});

    componentDidMount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        this.props.app.is_auth_only_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.addEventListener('keyup', this.handleKeyUp);
        }
    };
    
    componentWillUnmount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        this.props.app.is_auth_only_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.removeEventListener('keyup', this.handleKeyUp);
        }
    };

    componentWillUpdate(nextProps, nextState) {
        if (isElectron && nextState.rfidWord.toLowerCase().includes('enter')) {
            const word = nextState.rfidWord.slice(0, -5);
            if (word) this.handleResult(word);
            this.setState({rfidWord: ''});
        }
    };

    handleResult = (result) => {
        return new Promise((resolve, reject) => {
            loader.start('item');

            apiEmployee.authRfid(result)
            .then(response => {
                userManage.authorized(response.data[0])
                .then(isAuth => {
                    if (isAuth) {
                        userManage.pinCodeAuthorized();
                        this.props.history.push('/cabinet');
                    }
                    userManage.setIntervalGetExtData();
                    resolve(isAuth);
                });
            }).catch(e => {
                this.setState({message: e.message[0]});
                setTimeout(() => {
                    this.setState({message: ''});
                    this.initRfid();
                }, 2000);
            }).finally(() => loader.stop('item'));
        });
    }

    initRfid = () => {
        if (isElectron &&
        this.props.app.is_rfid &&
        this.props.app.is_auth_only_rfid &&
        !configApp.device_settings.rfid.isHid) {
            listenerRfid.start()
            .then(result => this.handleResult(result))
            .catch(()=>{})
        }
    }

    render() {
        return <Rfid t={this.props.t} message={this.state.message} isLoading={this.props.load.isLoading} />
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(RfidContainer)));