import React, { useState, useEffect } from 'react';
import {Button, Image} from "react-bootstrap";
import {isElectron} from 'react-device-detect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faCheck, faForward} from '@fortawesome/free-solid-svg-icons';
import Loader from "../../../blocks/Loader";
import Timer from "../../../blocks/Timer";
import configApp from '../../../../config/config';
import Modal from '../../../modals/Modal';
import BootstrapModal from '../../../modals/BootstrapModal';
import modalManage from '../../../../reducers/modal-manage';
import ExitTimer from '../../../blocks/ExitTimer';
import { isMultipleQuestion } from '../../../../helpers/functions';
import { notify } from '../../../blocks/Notify';
import { getFilePath } from '../../../../helpers/cacheFiles';

export default function Question(props) {
    let answers = props.answers;
    const isMultiple = isMultipleQuestion(answers);
    const [nodeAnswers, setNodeAnswers] = useState(<></>);
    const [file, setFile] = useState(<></>);
    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [selectedAnswersArray, setSelectedAnswersArray] = useState([]);
    const [showTimer, setShowTimer] = useState(true);
    const devMode = configApp.device_settings?.devMode ? true : false;
    let notifyText = '';

    const shuffle = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    const handleChoiceMultipleAnswer = (answer) => {
        let new_answers = [];
        const selected_ids = selectedAnswersArray.map(el => el.id);
        new_answers = selected_ids.includes(answer.id)
            ? selectedAnswersArray.filter(el => el.id !== answer.id)
            : [...selectedAnswersArray, answer];
        setSelectedAnswersArray(new_answers);
    };

    const showAnswerModal = (answer) => {
        if (isMultiple) {
            handleChoiceMultipleAnswer(answer);
        } else {
            if (isElectron && props.app.confirm_answer) {
                setSelectedAnswer(answer);
                setShowTimer(false);
                modalManage.show('question_modal');
            } else {
                props.onSelectAnswer(answer.id);
            };
        }
    };

    const showAnswerModalWithMultiple = (answers) => {
        if (isElectron && props.app.confirm_answer) {
            setShowTimer(false);
            modalManage.show('question_modal');
        } else {
            props.onSelectAnswer(answers.map(el => el.id));
        };
    };

    const closeAnswerModal = (answer) => {
        modalManage.close('question_modal');
        if (answer) {
            props.onSelectAnswer(isMultiple ? answer.map(el => el.id) : answer.id);
        } else {
            setShowTimer(true);
            isMultiple
                ? setSelectedAnswersArray([])
                : setSelectedAnswer({});
        }
    };

    const handleNodeAnswers = (answers) => {
        let new_nodeAnswers;
        if (props.answers instanceof Array) {
            if (props.question?.type && props.question.type === 'collage') {
                new_nodeAnswers = answers.map((answer, index) => {
                    const selected_ids = selectedAnswersArray.map(el => el.id);
                    const isSelected = (isMultiple && selected_ids.includes(answer.id)) ? true : false;
                    return (
                        <div key={index} onClick={() => showAnswerModal(answer)} className={`m-3 d-flex flex-column justify-content-center align-items-center`}>
                            <Image src={answer.file} // src={getFilePath(answer.file)}
                                className={`collage_img ${isSelected && 'collage_img_multiple_selected'}`}/>
                            <span className={`collage_text text-center mt-2 ${devMode && answer.is_right ? "Btn_success" : ""}`}>{answer.text}</span>
                        </div>)});
            } else {
                new_nodeAnswers = answers.map((answer, index) => {
                    const selected_ids = selectedAnswersArray.map(el => el.id);
                    const isSelected = (isMultiple && selected_ids.includes(answer.id)) ? true : false;
                    return (
                        <div key={index} onClick={() => showAnswerModal(answer)} className={`quest__answer answer align-items-center justify-content-left`}>
                            <span className={`answer__num mr-4 ${isSelected && 'selected_multiple_answer'}`}>{index + 1}</span>
                            <span className={`answer__text ${devMode && answer.is_right ? "Btn_success" : ""}`}>{answer.text}</span>
                        </div>)});
            }
        };
        setNodeAnswers(new_nodeAnswers);
    };

    useEffect(() => {
        answers = shuffle(answers);
        handleNodeAnswers(answers);
    }, [props.answers]);

    useEffect(() => {
        isMultiple && handleNodeAnswers(answers);
    }, [selectedAnswersArray]);

    useEffect(() => {
        if (isMultiple) {
            props.renderAdditionalButton(
                <>
                    {props.listEducation[props.activeEducation].listStep[props.activeStep].can_skip &&
                        <Button className="MuiButton Btn Btn_success mr-3" onClick={props.handleNextStep}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {props.t('Skip')}
                        </Button>}
                    <Button
                        className="MuiButton Btn Btn_success"
                        onClick={() => showAnswerModalWithMultiple(selectedAnswersArray)}
                        disabled={selectedAnswersArray.length < 1} >
                        {props.t('To answer')}
                    </Button>
                </>
            )
        }
    }, [isMultiple, selectedAnswersArray]);

    const handleFile = () => {
        let file = <></>;

        if (props.question) {
            let filePath = props.question.file; //getFilePath(props.question.file);
            if (props.question?.type && props.question.type === 'photo') {
                file = <div className={"quest__picture"}>
                        <Image src={filePath}/>
                    </div>
            } else if (props.question?.type && (props.question.type === 'video' || props.question.type === 'combine-video')) {
                file = <div className={"quest__picture"}>
                        <video className={""} src={filePath} autoPlay={true} loop="loop">
                            <source src={filePath}/>
                        </video>
                    </div>
            } else if (props.question?.type && props.question.type === 'interactive_photo') {
                let map_name;
                let coords = "";            
                let right_answer_id;
                let wrong_answer_id;
                let shape;
                props.answers.forEach(answer => {
                    if (answer?.params?.hasOwnProperty("map")) {
                        let height = answer.params.map.height;
                        let width = answer.params.map.width;
                        let img_heigth = document.documentElement.clientHeight  * 0.5;
                        let img_width = img_heigth * (width/height);
                        let coef_Y = 100 / (height/img_heigth) / 100;
                        let coef_X = 100 / (width/img_width) / 100;
                        map_name = answer.params.map.name;
                        right_answer_id = answer.id;
                        shape = answer.params.map.areas[0].shape;
                        answer.params.map.areas[0].coords.forEach(point => {
                            coords += `${point.x * coef_X},${point.y * coef_Y},`;
                        })
                    } else {
                        wrong_answer_id = answer.id;
                    }
                });
                file = <div className={"quest__picture"}>
                        <Image useMap={`#${map_name}`} src={filePath} className={"cursor-pointer max-vh-50"} onClick={()=>props.onSelectAnswer(wrong_answer_id)}/>
                        <map name={map_name}>
                            <area alt="Изображение с ответом"
                                className={"cursor-pointer"}
                                shape={shape}
                                coords={coords}
                                onClick={()=>props.onSelectAnswer(right_answer_id)} />
                        </map>
                    </div>
            }
        }
        setFile(file);
    };

    useEffect(() => {
        handleFile();
    }, [props.question]);

    let question_content;

    if (props.question?.type && (props.question.type === 'collage' || props.question.type === 'interactive_photo')) {
        notifyText = props.t(isMultiple
            ? "Select several images and click the 'Reply' button"
            : 'Click on the picture to choose the answer');
        question_content = <div className={"h-100 d-flex flex-column justify-content-center align-items-center"}>
                            {props.question.type === 'interactive_photo' && file}
                            <div className={"quest__quest"}>
                                {props.question.type === 'collage' &&
                                    <div className={"quest_collage_answer"}>
                                        {nodeAnswers}
                                    </div>}
                                <div className="d-flex justify-content-center w-100">
                                    <div className={"quest__container mt-4"}>
                                        {showTimer && <Timer handleTimeExpired={props.handleTimeExpired}/>}
                                        <h2 className={"quest__title text-center"}>
                                            {props.question && props.question.text}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                           </div>
    } else {
        if (isMultiple) notifyText = props.t('Select several response options and click reply');
        question_content = <div className={"h-100 d-flex flex-column justify-content-center align-items-center"}>
                            {file}
                            <div className="d-flex justify-content-center w-100">
                                <div className={"quest__quest"}>
                                    <div className={"quest__container"}>
                                        {showTimer && <Timer handleTimeExpired={props.handleTimeExpired}/>}
                                        <h2 className={"quest__title"}>
                                            {props.question && props.question.text}
                                        </h2>
                                    </div>
                                    <div className={"quest__answers"}>
                                        {nodeAnswers}
                                    </div>
                                </div>
                            </div>
                           </div>
    };

    useEffect(() => {
        notifyText && notify(notifyText, 'warning');
    }, [notifyText])

    return <div className={"Screen quest container"}>
            <Loader isLoading={props.load.isLoading} loadId={"item"} justifyContent="center">
                {question_content}
            </Loader>
            <Modal>
                <BootstrapModal modalId="question_modal" dialogClassName={"startup__modal-lang"} btns={[]} >
                    <div className="d-flex flex-column justify-content-between" style={{width: '50vw'}}>
                        <h1 className="text-center pb-2">{props.t('Your answer')}:</h1>
                        {isMultiple
                            ? selectedAnswersArray.map(el => <h1 key={el.id} className="text-center mb-1"><b>{el.text}</b></h1>)
                            : <h1 className="text-center"><b>{selectedAnswer.text}</b></h1>}
                        <h1 className="text-center pt-2">{props.t('Confirm your choice')}?</h1>
                    </div>
                    <div className="d-flex justify-content-between">
                    <Button onClick={() => props.is_exit_timer && closeAnswerModal()} className={`Btn Btn_danger MuiButton mt-4`}>
                        <FontAwesomeIcon icon={faBan} className="mr-4"/>
                        {props.t('No')}
                    </Button>
                    <Button
                        onClick={() => props.is_exit_timer && closeAnswerModal(isMultiple ? selectedAnswersArray : selectedAnswer)}
                        className={`Btn Btn_success MuiButton mt-4`}>
                        <FontAwesomeIcon icon={faCheck} className="mr-4"/>
                        {props.t('Yes')}
                        <ExitTimer
                            theme_name={props.theme_name}
                            goOut={() => closeAnswerModal(isMultiple ? selectedAnswersArray : selectedAnswer)}
                            handleExitTimer={props.handleExitTimer} />
                    </Button>
                    </div>
                </BootstrapModal>
            </Modal>
           </div>
};