import * as process from "process";
import configApp from '../config/config';
import {getLocaleId} from "../reducers/locale-selector";
const store = require('../localStorage').default;

let backendHost;
const apiVersion = 'v1';

configApp.server_host ? backendHost = configApp.server_host : backendHost = process.env.REACT_APP_SERVER_URL || 'http://localhost:8080';

let reqDefaultConfig = {
    headers: {
        'X-VERSION': '1.4.0',
        'X-HOSTNAME': 'browser',
        'X-IP': '192.168.1.0',
        'X-PLATFORM': 'browser',
    }
}

if (configApp.hasOwnProperty('device_settings')) {
    if (configApp.device_settings.hasOwnProperty('version')) {
        reqDefaultConfig.headers['X-VERSION'] = configApp.device_settings.version
    }
    if (configApp.device_settings.hasOwnProperty('name')) {
        reqDefaultConfig.headers['X-HOSTNAME'] = configApp.device_settings.name
    }
    if (configApp.device_settings.hasOwnProperty('ip')) {
        reqDefaultConfig.headers['X-IP'] = configApp.device_settings.ip
    }
}
if (configApp.hasOwnProperty('platform')) {
    reqDefaultConfig.headers['X-PLATFORM'] = configApp.platform
}

store.subscribe(() => reqDefaultConfig.headers['X-LANGUAGE'] = getLocaleId(store.getState()));

export const API_ROOT = `${backendHost}/api/${apiVersion}`;
export const BACKEND_HOST = backendHost;
export const API_DEFAULT_CONFIG = reqDefaultConfig;