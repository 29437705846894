import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import Content from "../../blocks/Content";

function Success(props) {
    return <div className={"Screen w-100 container Success"}>
            <Content>
                <div className="Success-Answer">
                    <FontAwesomeIcon icon={faCheck} className="Success-Ok mb-5"/>
                    <div className={"Success-Success"}>
                        {props.t('You answered correctly')}.<br/>
                        {props.t('You have avoided injury through competence')}!
                    </div>
                </div>
            </Content>
           </div>
}

const mapStateToProps = (store) => {
    return {};
};

export default connect(mapStateToProps)(withTranslation()(Success));