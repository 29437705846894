import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ru from "../../locales/ru/translation.json";
import en from "../../locales/en/translation.json";
import kk from "../../locales/kk/translation.json";
import ky from "../../locales/ky/translation.json";
import store from "../../localStorage";
const locale = store.getState().localeState.locale;

i18next
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    // we init with resources
    resources: {
        ru: {
            translations: {
                ...ru
            }
        },
        en: {
            translations: {
                ...en
            }
        },
        kk: {
            translations: {
                ...kk
            }
        },
        ky: {
            translations: {
                ...ky
            }
        },
    },
    fallbackLng: locale ? locale : 'ru',
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false
    }
});

export default i18next;