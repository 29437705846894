import React from 'react';
import {NavLink} from 'react-router-dom';

export default function FeedbackItem(props) {
    let text_status;
    const un_ms_count = props.unread_message_count;
    const date = new Date(props.last_message_dt.slice(0, -6));
    const slice_time = new Date().getDate() === date.getDate() ? 12 : 0;
    const last_message_dt = `${date.toLocaleString().slice(slice_time, -3)}`;

    if (props.status === 'in_work') {
        text_status = props.t('In work');
    } else if (props.status === 'unread') {
        text_status = props.t('Unread');
    } else if (props.status === 'resolved') {
        text_status = props.t('Resolved');
    } else {
        text_status = props.t('In archive');
    }


    return <div className={"FeedbackItem"}>
            <NavLink to={`/feedback/dialog/${props.uuid}`} className="FeedbackItem-Link d-flex justify-content-between w-100">
                <div className='d-flex justify-content-start'>
                    {un_ms_count > 0 && <div className={"FeedbackItem-Column FeedbackItem-Status FeedbackItem-Status_warning"}>+{un_ms_count}</div>}
                    <div className={"FeedbackItem-Column FeedbackItem-Status FeedbackItem-Status_success"}>{text_status}</div>
                </div>
                <div className={"FeedbackItem-Column FeedbackItem-Message LibraryText"}>{props.short_text}</div>
                <div className='d-flex justify-content-end'>
                    <div className={"FeedbackItem-Column FeedbackItem-Status FeedbackItem-Status_success"}>{props.theme_title}</div>
                    <div className={"FeedbackItem-Column FeedbackItem-Date"}>{last_message_dt}</div>
                </div>
            </NavLink>
           </div>
};