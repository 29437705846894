import { faArrowLeft, faForward, faRedo, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import { Button, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { addBreamb, goBackStep, resetBreamb } from '../../reducers/breams-manage';
import {pageLoadManageActions as loader} from "../../reducers/load";
import userManage from '../../reducers/user-manage';
import Breams from '../blocks/Breams';
import Loader from '../blocks/Loader';
import Fail from '../screens/questions/Fail';
import FailVisual from '../screens/questions/FailVisual';
import FailVisualAndInjurie from '../screens/questions/FailVisualAndInjurie';
import Prolog from '../screens/questions/Prolog';
import Question from '../screens/questions/flow/Question';
import SuccessVisual from '../screens/questions/SuccessVisual';
import Epilog from '../screens/questions/Epilog';
import HighQuestAnswer from '../screens/questions/HighQuestAnswer';
import { setLog } from '../../helpers/functions';
import apiDemoQuestions from '../../api/apiDemoQuestions';

class DemoQuestionPageContainer extends Component {
    constructor(props) {
        super(props);

        this.state ={
          load: true,
          content: Question,
          pre_content: null,
          modules: null,
          module_questions: null,
          question: null,
          high_question: null,
          high_question_step: 0,
          file: null,
        }

        userManage.logout();
        document.title = props.t('Demonstration question');
        this.getDemoQuest();
        resetBreamb();
        addBreamb({title: props.t('Demonstration question')});
    }

    componentWillUnmount() {
      loader.stop("demo-question");
      clearTimeout(this.timeout_1);
      clearTimeout(this.timeout_2);
      clearTimeout(this.timeout_3);
    };

    getDemoQuestion = async () => {
      let url = window.location.hash;
      let json = decodeURI(url.replace("#/demo-question/", ""));
      let obj = {};
      try {
        obj = JSON.parse(json);
      } catch (e) {
        await apiDemoQuestions.getDemoQuestion(json)
        .then(res => obj = res.data)
        .catch(e => setLog('Ошибка парсинга JSON на демо вопросе', e));
      }

      let notice = [];
      let not_quest_video = "http://192.168.1.71/upload/system/3ga/d5w.webm";
      let not_quest = "Вопрос не указан";
      let not_false_answer = "Неправильный ответ не указан";
      let not_true_answer = "Правильный ответ не указан";
      let not_description = "Описание или комментарий не указаны";

      if (obj.question?.type === "combine-video") {
        let child_questions_from_json = [];
        obj.question?.child_questions
          ? obj.question.child_questions.forEach((quest) => {
              child_questions_from_json.push({
                question: {
                  id: quest.question?.id ? quest.question?.id : "cd9cac75-e54a-4398-a007-777518fe99ba",
                  text: quest.question?.text ? quest.question?.text : not_quest,
                  type: quest.question?.type,
                  file: quest.question?.file ? quest.question?.file : not_quest_video,
                  sort: 1,
                  quote_from_document: quest.question?.quote_from_document,
                },
                answers: [
                  {
                    id: quest.answers[0]?.id ? quest.answers[0]?.id : 2870,
                    uuid: quest.answers[0]?.uuid ? quest.answers[0]?.uuid : "d80b41f4-31a9-42c9-be39-3c39def82e11",
                    text: quest.answers[0]?.text ? quest.answers[0]?.text : not_false_answer,
                    sort: quest.answers[0]?.sort ? quest.answers[0]?.sort : 1,
                    is_right: quest.answers[0]?.hasOwnProperty('is_right') ? quest.answers[0]?.is_right : false,
                    comment: "",
                    file: quest.answers[0]?.file ? quest.answers[0]?.file : not_quest_video,
                  },
                  {
                    id: quest.answers[1]?.id ? quest.answers[1]?.id : 2869,
                    uuid: quest.answers[1]?.uuid ? quest.answers[1]?.uuid : "9128fc00-e634-4710-8d29-948008d71c1b",
                    text: quest.answers[1]?.text ? quest.answers[1]?.text : not_true_answer,
                    sort: quest.answers[1]?.sort ? quest.answers[1]?.sort : 2,
                    is_right: quest.answers[1]?.hasOwnProperty('is_right') ? quest.answers[1]?.is_right : true,
                    comment: "",
                    file: quest.answers[1]?.file ? quest.answers[1]?.file : not_quest_video,
                  },
                ],
              });
            })
          : child_questions_from_json.push({
                question: {
                  id: "cd9cac75-e54a-4398-a007-777518fe99ba",
                  text: not_quest,
                  type: "combine-video",
                  file: not_quest_video,
                  sort: 1,
                },
                answers: [
                  {
                    id: 2870,
                    uuid: "d80b41f4-31a9-42c9-be39-3c39def82e11",
                    text: not_false_answer,
                    sort: 1,
                    is_right: false,
                    question_id: 958,
                    comment: "",
                    file: not_quest_video,
                  },
                  {
                    id: 2869,
                    uuid: "9128fc00-e634-4710-8d29-948008d71c1b",
                    text: not_true_answer,
                    sort: 2,
                    is_right: true,
                    question_id: 958,
                    comment: "",
                    file: not_quest_video,
                  },
                ],
              });
        notice = {
          question: {
            id: "462f8f6d-5a25-4744-8695-0f1c726dafac",
            text: obj.question?.text ? obj.text : not_quest,
            type: obj.question.type,
            sort: 1,
            prolog: obj.question?.prolog ? obj.question?.prolog : not_quest_video,
            epilog: obj.question?.epilog ? obj.question?.epilog : not_quest_video,
            child_questions: child_questions_from_json,
            quote_from_document: obj.question?.quote_from_document,
          }
        };
      } else {
        let answers_from_json = [];
        let answers = [{
          id: 8,
          uuid: "8cb2953e-60ac-4f4f-832b-ccee510c5c35",
          text: not_false_answer,
          sort: 2,
          is_right: false,
          question_id: 3,
          comment: not_description,
          file: not_quest_video,
          visual_uuid: "e34243c5-0d36-4f59-94ad-c0566ceca3af",
          factor_risk: [{
            name: "otkrytoe-plama",
            uuid: "b64cfe92-7da7-49e7-b979-d56dbde04984",
            title: not_description
          }],
        },
        {
          id: 9,
          uuid: "a1165bc0-551c-4b34-a74b-80b33240ea2c",
          text: not_false_answer,
          sort: 3,
          is_right: false,
          question_id: 3,
          comment: not_description,
          file: not_quest_video,
          visual_uuid: "fca79313-c4eb-41ca-ad72-a6cb2128a214",
          factor_risk: [{
            name: "otkrytoe-plama",
            uuid: "b64cfe92-7da7-49e7-b979-d56dbde04984",
            title: not_description
          }],
          },
          {
            id: 7,
            uuid: "5f905e2c-97e4-4d60-8da3-047a97c603db",
            text: not_true_answer,
            sort: 1,
            is_right: true,
            question_id: 3,
            comment: "",
            file: null
          }];
        obj.answers?.length >= 1 ? answers_from_json = obj.answers : answers_from_json = answers;
        notice = {
          question: {
            id: "ed16fd96-5c58-436e-a544-d7a3865578c7",
            text: obj.question?.text ? obj.question?.text : not_quest,
            type: obj.question?.type ? obj.question?.type : "video",
            file: obj.question?.file ? obj.question?.file : (obj.question?.type === "photo" ? "photo" : not_quest_video),
            sort: 1,
            quote_from_document: obj.question?.quote_from_document,
          },
          answers: answers_from_json
        }
      }

      return new Promise((resolve, reject) => resolve(notice))
    };

    getDemoQuest = () => {
      return new Promise((resolve, reject) => {
        loader.start("demo-question");

        this.getDemoQuestion()
        .then(response => {
          if (response.question.type === "combine-video") {
            this.setState({
              high_question: response,
              question: response },
              () => {
                this.setState({ content: Prolog });
                addBreamb({title: this.props.t('Prolog')});
            });
          } else {
            this.setState({ question: response },
              () => this.setState({ content: Question }));
          }
          resolve();
        })
        .finally(() => {
          this.timeout_1 = setTimeout(() => {
            this.setState({load: false});
            loader.stop("demo-question");
          }, 1000);
          this.timeout_2 = setTimeout(() => this.endVideo(Prolog), 1001);
        })
      })
    };

    toQuestionModule = () => {
      return
    };

    goBack = () => this.props.history.push('/');

    onSelectAnswer = (answer_id) => {
      let question = this.state.question,
          selected_answer = {},
          is_right = false;

      question.answers.forEach(answer => {
        if (answer.id === answer_id) {
            is_right = answer.is_right;
            selected_answer = answer;
        }
      });

      this.setState({
        select_answer: selected_answer,
        file: selected_answer.file
      });

      if (is_right) {
          this.setState({ content: question.question.type === "combine-video" ? HighQuestAnswer : SuccessVisual });
          addBreamb({title: this.props.t('Answered correctly')});
      } else {
        this.setState({ content: question.question.type === "combine-video"
          ? HighQuestAnswer
          : (selected_answer?.hasOwnProperty('visualisation')
            ? (selected_answer?.hasOwnProperty('injurie') ? FailVisualAndInjurie : FailVisual)
            : Fail)
      });
          addBreamb({title: this.props.t('Answered incorrectly')});
      }
  };

  repeatQuestion = () => this.setState({ content: Question }, goBackStep);

  highQuestionsStep = () => {
    let steps = this.state.high_question.question.child_questions.length;
    let active_step = this.state.high_question_step;
    if (active_step === 0) {
      goBackStep();
      addBreamb({ title: this.props.t("Question") + (active_step + 1) + this.props.t("of") + steps });
      this.setState({
        question: this.state.high_question.question.child_questions[active_step],
        high_question_step: active_step + 1 },
        () => {this.setState({ content: Question })});
    } else if (active_step !== 0 && active_step < steps) {
      goBackStep();
      addBreamb({ title: this.props.t("Question") + (active_step + 1) + this.props.t("of") + steps });
      this.setState({
        question: this.state.high_question.question.child_questions[active_step],
        high_question_step: active_step + 1 },
        () => {this.setState({ content: Question })});
    } else if (active_step === steps) {
      goBackStep();
      addBreamb({ title: this.props.t("Epilog") });
      this.setState({
        question: this.state.high_question,
        high_question_step: active_step + 1 },
        () => {
          this.setState({ content: Epilog });
          this.endVideo(Epilog); });
    } else {
      this.setState({ high_question_step: 0 });
      goBackStep();
      this.goBack();
    };
  };

  endVideo = (content) => {
    let selector;
    if (content === Prolog) selector = "#prolog_video";
    if (content === Epilog) selector = "#epilog_video";
    this.timeout_3 = setTimeout(() => {
          let nodeVideo = document.querySelector(selector);
          if (nodeVideo) {
            nodeVideo.autoplay = 'autoplay';
            nodeVideo.addEventListener('ended', () => {
                nodeVideo.pause();
                this.highQuestionsStep();
              });
          };
      }, 1);
  };

  backToQuestion = () => {
    goBackStep();
    let Content = Question;
    if (this.state.question.question.type === 'combine-video') {
      Content = Prolog;
      addBreamb({title: this.props.t('Prolog')});
      this.timeout_2 = setTimeout(() => this.endVideo(Prolog), 500);
    };
    this.setState({ high_question_step: 0, content: Content });
  };

    render() {
        let Content = this.state.content;
        return <div className="Screen">
                <Loader isLoading={this.state.load} loadId={"demo-question"} justifyContent="center">
                    <Breams/>
                    <Content {...this.state} {...this.props}
                            toQuestionModule={this.toQuestionModule}
                            goBack={this.goBack}
                            getDemoQuest={this.getDemoQuest}
                            answers={this.state.question?.answers || []}
                            question={this.state.question?.question}
                            handleTimeExpired={this.goBack}
                            onSelectAnswer={this.onSelectAnswer}
                            select_answer={this.state.select_answer}
                            file={this.state.file}
                            toNextChildQuest={this.highQuestionsStep}
                            goBackStep={goBackStep} />
                      {(this.state.content === SuccessVisual ||
                        this.state.content === Epilog) &&
                          <div className='MuiStepContainer'>
                            <Container className="pb-1">
                              <div className="w-100">
                                <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                  <Button className="Btn Btn_boring MuiButton" onClick={this.backToQuestion}>
                                    <FontAwesomeIcon className="mr-4" icon={faRedo}/>
                                    {this.props.t('Try again')}
                                  </Button>
                                  <NavLink to="/" className="Btn Btn_danger MuiButton">
                                    <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>{this.props.t('Exit')}
                                  </NavLink>
                                </div>
                              </div>
                            </Container>
                          </div>}
                      {(this.state.content === Fail ||
                        this.state.content === FailVisual ||
                        this.state.content === FailVisualAndInjurie) &&
                          <div className='MuiStepContainer'>
                            <Container className="pb-1">
                              <div className="w-100">
                                <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                  <NavLink to={"/"} className="Btn Btn_danger MuiButton cursor-pointer">
                                    <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                    {this.props.t('Done')} {this.props.t('Question')}
                                  </NavLink>
                                  <Button className="Btn Btn_boring MuiButton cursor-pointer" onClick={this.repeatQuestion}>
                                    <FontAwesomeIcon className="mr-4" icon={faRedo}/>
                                    {this.props.t('Try again')}
                                  </Button>
                                </div>
                              </div>
                            </Container>
                          </div>}
                      {this.state.content === Prolog &&
                        <div className='MuiStepContainer'>
                          <Container className="pb-1">
                            <div className="w-100">
                              <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                <NavLink to={"/"} className="Btn Btn_danger MuiButton cursor-pointer">
                                  <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                  {this.props.t('Done')} {this.props.t('Question')}
                                </NavLink>
                                <Button className="MuiButton Btn Btn_success" onClick={this.highQuestionsStep}>
                                  <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                  {this.props.t('Proceed')}
                                  </Button>
                              </div>
                            </div>
                          </Container>
                        </div>}
                      {this.state.content === HighQuestAnswer &&
                        <div className='MuiStepContainer'>
                          <Container className="pb-1">
                            <div className="w-100">
                              <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                <NavLink to={"/"} className="Btn Btn_danger MuiButton cursor-pointer">
                                  <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                    {this.props.t('Done')} {this.props.t('Question')}
                                </NavLink>
                                <Button className="MuiButton Btn Btn_success" onClick={() => { goBackStep(); this.highQuestionsStep(); }}>
                                  <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                  {this.state.high_question_step < this.state.high_question.question.child_questions.length
                                    ? this.props.t('Next')
                                    : this.props.t('Proceed')}
                                </Button>
                              </div>
                            </div>
                          </Container>
                        </div>}
                      {this.state.content === Question &&
                        <div className='MuiStepContainer'>
                          <Container className="pb-1">
                            <div className="w-100">
                              <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                <NavLink to={"/"} className="Btn Btn_danger MuiButton cursor-pointer">
                                  <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                  {this.props.t('Done')} {this.props.t('Question')}
                                </NavLink>
                              </div>
                            </div>
                          </Container>
                        </div>}
                </Loader>
              </div>
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.appState,
    };
};

export default connect(mapStateToProps)(withTranslation()(DemoQuestionPageContainer));