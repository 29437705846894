import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import apiPreshift from '../../../api/apiPreshift';
import { setLog } from '../../../helpers/functions';
import {pageLoadManageActions as loader} from "../../../reducers/load";
import Loader from '../../blocks/Loader';
import { notify } from '../../blocks/Notify';
import ElectronicClass from '../../screens/electronicCourses/ElectronicClass';
import userManage from '../../../reducers/user-manage';

class ElectronicClassContainer extends Component {
    constructor(props) {
        super(props);
        userManage.logout();

        this.state = {
          students: [],
          selected_student: {},
          selected_course_uuid: '',
          isSessionStart: false,
          session: '',
          free_course: false,
          course: null,
          courses_for_choice: [],
        };

        this.timeOutID = () => {};

        document.title = this.props.t('Electronic courses');
        this.handlePrepareData();
    };

    handlePrepareData = () => {
      this.getSessionStatus()
      .then(res => res
        ? this.getStudents().then(() => this.timeOutID = setTimeout(this.handlePrepareData, 300000))
        : this.timeOutID = setTimeout(this.handlePrepareData, 30000))
    };

    componentWillUnmount() {
      this.handleClearTimeout();
    };

    handleClearTimeout = () => clearTimeout(this.timeOutID);

    handleSetTimeOutID = () => {
      const time = this.state.isSessionStart ? 300000 : 30000;
      this.timeOutID = setTimeout(this.handlePrepareData, time);
    };

    getSessionStatus = () => {
      return new Promise((resolve, reject) => {
        loader.start("El-class");
        apiPreshift.getSessionsStatus(this.props.app.learning_class_uuid)
        .then(res => {
          const data = res.data;
          this.setState({
            isSessionStart: data.status,
            free_course: data.free_course,
            session: data.session,
            course: data.course,
          });
          if (data.free_course) this.handleGetCoursesForChoice();
          resolve(data.status);
        })
        .catch(e => {
          this.setState({network: "Network Error"});
          setLog('Ошибка загрузки статуса сессии', e);
          resolve(false);
        })
        .finally(() => !this.state.isSessionStart && loader.stop("El-class"))
      })
    };

    getStudents = () => {
      return new Promise((resolve, reject) => {
        loader.start("El-class");
        apiPreshift.getStudentsList(this.props.app.learning_class_uuid)
        .then(res => {
          this.setState({students: res.data});
          resolve();
        })
        .catch(() => {
          this.setState({network: "Network Error"});
          notify(this.props.t('Error loading the list of students'), 'error');
        })
        .finally(() => loader.stop("El-class"))
      })
    };

    setStudent = (new_student) => {
      return new Promise((resolve, reject) => {
        loader.start("El-class");
        apiPreshift.setStudent(this.props.app.learning_class_uuid, new_student, this.state.selected_course_uuid, this.state.session)
        .then(res => {
          this.setState({selected_student: res.data}, () => this.toElectronicCourses());
          resolve();
        })
        .catch(() => notify(this.props.t('Error adding a student'), 'error'))
        .finally(() => loader.stop("El-class"))
      })
    };

    handleSelectStudent = (student) => this.setState({selected_student: student});

    handleSelectCourse = (uuid) => this.setState({selected_course_uuid: uuid});

    toElectronicCourses = () => {
      let user_data = {};
      user_data.uuid = this.state.selected_student.student.uuid;
      user_data.appoint_uuid = this.state.selected_student.course.appoint.uuid;
      user_data.course_title = this.state.selected_student.course.title;
      user_data.title = this.state.selected_student.student.title;
      userManage.authorized(user_data).then(() => this.props.history.push('/electronic-courses-class'));
    };

    handleGetPositionsOrStructures = (type, text, setSpinner, setArray) => {
      setSpinner(true);
      apiPreshift.getPositionsOrStructures(type, text)
      .then(res => setArray(res.data))
      .catch(e => setLog('Ошибка загрузки getPositionsOrStructures', e))
      .finally(() => setSpinner(false));
    };

    handleGetStudentByNameOrTabel = (type, text, setSpinner, setArray, setError) => {
      setError('');
      setSpinner(true);
      apiPreshift.getStudentByNameOrTabel(type, text)
      .then(res => setArray(res.data))
      .catch(e => {
        setError(e.message[0]);
        setLog('Ошибка загрузки getStudentByNameOrTabel', e);
      })
      .finally(() => setSpinner(false));
    };

    handleGetCoursesForChoice = () => {
      apiPreshift.getCoursesForChoice()
      .then(res => this.setState({courses_for_choice: res.data}))
      .catch(e => setLog('Ошибка загрузки getCoursesForChoice', e))
    };

    render() {
      return (
        <Loader isLoading={this.props.load.isLoading} loadId={"El-class"} justifyContent="center" className="Screen h-100">
          <ElectronicClass {...this.state} {...this.props}
            handleSelectStudent={this.handleSelectStudent}
            toElectronicCourses={this.toElectronicCourses}
            handleGetPositionsOrStructures={this.handleGetPositionsOrStructures}
            handleGetStudentByNameOrTabel={this.handleGetStudentByNameOrTabel}
            handleSelectCourse={this.handleSelectCourse}
            handleClearTimeout={this.handleClearTimeout}
            handleSetTimeOutID={this.handleSetTimeOutID}
            setStudent={this.setStudent} />
        </Loader>
      )
    };
}

const mapStateToProps = (store) => {
  return {
    app: store.appState,
    load: store.loadState,
    user: store.userState,
  };
};

export default connect(mapStateToProps)(withTranslation()(ElectronicClassContainer));