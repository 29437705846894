import React, { useEffect, useState } from 'react';
import { faBan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import Content from '../../../blocks/Content';

let positionSearchTimer;
let structureSearchTimer;

export default function AddStudentModal(props) {
  const [fullName, setFullName] = useState('');
  const [bDay, setBDay] = useState('2000-01-01');
  const [tabel, setTabel] = useState('');
  const [position, setPosition] = useState('');
  const [structure, setStructure] = useState('');
  const [course, setCourse] = useState('');
  const [positionArray, setPositionArray] = useState([]);
  const [structureArray, setStructureArray] = useState([]);
  const [positionSelected, setPositionSelected] = useState({});
  const [structureSelected, setStructureSelected] = useState({});
  const [positionSpinner, setPositionSpinner] = useState(false);
  const [structureSpinner, setStructureSpinner] = useState(false);
  const [isInvalidFIO, setIsInvalidFIO] = useState(true);
  const [isInvalidBDay, setIsInvalidBDay] = useState(true);
  const [isInvalidCourse, setIsInvalidCourse] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const courses = props.courses_for_choice;

  const MySpinner = <Spinner className='ml-3' size='lg' animation='border' as='span' variant='light' />;

  useEffect(() => {
    let bool = false;
    if (props.free_course && !props.course?.uuid && !course) bool = true;
    setIsInvalidCourse(bool);
  }, [course]);

  const handleAddFullName = (e) => {
    const value = e.target.value;
    const bool = value !== '' ? false : true;
    setFullName(value);
    setIsInvalidFIO(bool);
  };

  const handleAddBDay = (e) => {
    const value = e.target.value;
    const today_100 = new Date().setFullYear(new Date().getFullYear() - 100);
    const today = new Date().getTime();
    const new_bDay_ms = new Date(value).getTime();
    const bool = new_bDay_ms > today_100 && new_bDay_ms < today ? false : true;
    setBDay(value);
    setIsInvalidBDay(bool);
  };

  const handleAddTabel = (e) => {
    const value = +e.target.value;
    if (value > 0) setTabel(value);
    if (value === 0) setTabel('');
  };

  const handleAddPosition = (e) => {
    const value = e.target.value;
    clearTimeout(positionSearchTimer);
    setPosition(value);
    setPositionSpinner(false);
    if (value !== '') {
      positionSearchTimer = setTimeout(() => {
        props.handleGetPositionsOrStructures('positions', value, setPositionSpinner, setPositionArray);
      }, 400);
    } else {
      setPositionArray([]);
      setPositionSelected({});
    }
  };

  const handleAddStructure = (e) => {
    const value = e.target.value;
    clearTimeout(structureSearchTimer);
    setStructure(value);
    setStructureSpinner(false);
    if (value !== '') {
      structureSearchTimer = setTimeout(() => {
        props.handleGetPositionsOrStructures('structures', value, setStructureSpinner, setStructureArray);
      }, 400);
    } else {
      setStructureArray([]);
      setStructureSelected({});
    }
  };

  const handleSelectPosition = (el) => {
    setPositionSelected(el);
    setPosition(el.title);
    setPositionArray([]);
  };

  const handleSelectStructure = (el) => {
    setStructureSelected(el);
    setStructure(el.title);
    setStructureArray([]);
  };

  const handleSelectCourse = (el) => {
    props.handleSelectCourse(el.uuid);
    setCourse(el.title);
    setShowCourses(false);
    setIsInvalidCourse(false);
  };

  const handleAddStudent = () => {
    if (!isInvalidFIO && !isInvalidBDay && !isInvalidCourse) {
      const date = '@' + new Date(bDay).getTime() / 1000;
      const new_position = positionSelected.title ? positionSelected : position;
      const new_structure = structureSelected.title ? structureSelected : structure;
      const new_student = {
        title: fullName,
        birthday_dt: date,
        tabel: tabel,
        position: new_position,
        structure: new_structure,
      };
      props.setStudent(new_student);
    }
  };

  return (
    <div className={`${props.theme}`}>
      <div className={`screensaver_div active`}>
        <div className='screensaver_modal w-75' style={{ maxWidth: '700px', maxHeight: '95%' }}>
          <Content>
            <FormGroup className='text-center m-4 w-auto d-flex flex-column'>
        {/* ФИО */}
              <FormLabel className='display-7' style={{ color: 'white', border: 'none' }}>{props.t('Add a student')}</FormLabel>
              <FormControl
                type='text'
                required
                onChange={handleAddFullName}
                id='controlFIO'
                name='FIO'
                autoComplete='off'
                className={`search-emp__name-field mx-auto form-control mb-4`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                placeholder={props.t('Enter your full name')}
                value={fullName}
                isInvalid={isInvalidFIO} />
        {/* Дата рождения */}
              <FormLabel className='display-7' style={{ color: 'white' }}>{props.t('Date of birth')}</FormLabel>
              <FormControl
                type='date'
                required
                onChange={handleAddBDay}
                id='controlBD'
                name='bDay'
                autoComplete='off'
                className={`search-emp__name-field mx-auto form-control mb-4`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                value={bDay}
                isInvalid={isInvalidBDay} />
        {/* Табельный */}
              <FormLabel className='display-7' style={{ color: 'white' }}>{props.t('Entry of personnel')}</FormLabel>
              <FormControl
                type='text'
                required
                onChange={handleAddTabel}
                id='controlTabel'
                name='Tabel'
                autoComplete='off'
                placeholder={props.t('Entry of personnel')}
                className={`search-emp__name-field mx-auto form-control mb-4`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                value={tabel} />
        {/* Должность */}
              <div>
                <FormLabel className='display-7' style={{ color: 'white' }}>{props.t('Position')}</FormLabel>
                {positionSpinner && MySpinner}
              </div>
              <FormControl
                type='text'
                required
                onChange={handleAddPosition}
                id='controlPosition'
                name='Position'
                autoComplete='off'
                placeholder={props.t('Enter position')}
                className={`search-emp__name-field mx-auto form-control`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                value={position} />
              {positionArray.map(el =>
                <span className={`display-9 border p-3 dropDownStyle`} key={el.uuid} onClick={() => handleSelectPosition(el)} >
                  {el.title}
                </span>)}
        {/* Структура */}
              <div>
                <FormLabel className='display-7 mt-4' style={{ color: 'white' }}>{props.t('Division')}</FormLabel>
                {structureSpinner && MySpinner}
              </div>
              <FormControl
                type='text'
                required
                onChange={handleAddStructure}
                id='controlStructure'
                name='Structure'
                autoComplete='off'
                placeholder={props.t('Enter structure')}
                className={`search-emp__name-field mx-auto form-control`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                value={structure} />
              {structureArray.map(el =>
                <span className={`display-9 border p-3 dropDownStyle`} key={el.id} onClick={() => handleSelectStructure(el)} >
                  {el.title}
                </span>)}
        {/* Курс */}
              {courses.length > 0 &&
                <>
                  <FormLabel className='display-7 mt-4' style={{ color: 'white' }}>{props.t('Course name')}</FormLabel>
                  <FormControl
                    type='text'
                    required
                    onChange={()=>{}}
                    onClick={() => setShowCourses(!showCourses)}
                    id='controlCourse'
                    name='Course'
                    autoComplete='off'
                    isInvalid={isInvalidCourse}
                    placeholder={props.t('Course name')}
                    className={`search-emp__name-field mx-auto form-control`}
                    style={{ backgroundColor: 'white', height: '5rem' }}
                    value={course} />
                  {showCourses && courses.map(el =>
                    <span className={`display-9 border p-3 dropDownStyle`} key={el.uuid} onClick={() => handleSelectCourse(el)} >
                      {el.title}
                    </span>)}
                </>}
            </FormGroup>
          </Content>
          <div className='d-flex justify-content-between w-100'>
            <Button className='MuiButton Btn Btn_danger ml-3' onClick={props.handleCloseAddStudentModal}>
              <FontAwesomeIcon className='m-4' icon={faBan} />
            </Button>
            <Button className='MuiButton Btn Btn_success ml-3' onClick={handleAddStudent}>
              <FontAwesomeIcon className='m-4' icon={faPlus} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
