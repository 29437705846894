import {Breadcrumb, Container} from "react-bootstrap";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function Breams(props) {
    return <div className="Breams">
            <Container className={"pb-0"}>
                <Breadcrumb className={"Breams-Nav"}>
                    {props.breamb.breambs.map((breamb, key) => {
                            return <Breadcrumb.Item key={key} className={"Breams-Item"} active={breamb?.hasOwnProperty('active') && breamb.active} linkAs="span">
                                {breamb.title}</Breadcrumb.Item>
                        })}
                </Breadcrumb>
            </Container>
           </div>
}

const mapStateToProps = (store) => {
    return {
        breamb: store.breambState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(Breams)));