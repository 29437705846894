import React, { useEffect } from 'react';
import { FormGroup, FormControl, FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faSuitcase, faUser } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../blocks/Loader';
import Content from '../../blocks/Content';
import BootstrapModal from '../../modals/BootstrapModal';
import { focusInput } from '../../../helpers/functions';

export default function Ldap(props) {
  document.title = props.t('Enter Login and Password');
  const modules = props.app.modules;
  useEffect(() => {
    focusInput('ldapLoginInput');
  }, []);

  let messageError = '',
    passwordError = '',
    classDisable = ' disabled',
    formErrors = props.formErrors;

  if (props.formValid) classDisable = '';
  if (formErrors?.hasOwnProperty('error') && formErrors.error.length > 0) messageError = formErrors.error[0];
  if (formErrors?.hasOwnProperty('login') && formErrors.login.length > 0) messageError = formErrors.login[0];
  if (formErrors?.hasOwnProperty('password') && formErrors.password.length > 0) passwordError = formErrors.password[0];

  return (
    <>
      <div className={'Screen startup'}>
        <Content className='container'>
          <div className='overflow-hidden'>
            <div className='d-flex flex-row h-100'>
              <Loader isLoading={props.load.isLoading} loadId={'item'} justifyContent='center'>
                <div className={'startup__container startup__container_bn1 d'}>
                  <div className={'startup__navi'}>
                    <FormGroup className='text-center'>
                      <FormLabel className={'startup__tabel-label mb-0'}>
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='button-tooltip' className={'tooltip error'}>
                              <i className='fas fa-exclamation-triangle mr-3'> </i>{' '}
                              {messageError ? props.t(messageError) : null}
                            </Tooltip>
                          }
                          show={!!messageError}
                        >
                          <FormControl
                            type='text'
                            minLength='1'
                            required
                            name='login'
                            id='ldapLoginInput'
                            placeholder={props.t('Login')}
                            onInput={(event) => props.onInputLogin(event.currentTarget.value)}
                            className={`startup__tabel-field mx-auto mt-3`}
                          />
                        </OverlayTrigger>
                      </FormLabel>
                    </FormGroup>
                    <FormGroup className='text-center'>
                      <FormLabel className={'startup__tabel-label mb-0'}>
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='button-tooltip' className={'tooltip error'}>
                              <i className='fas fa-exclamation-triangle mr-3'> </i>{' '}
                              {passwordError ? passwordError : null}
                            </Tooltip>
                          }
                          show={!!passwordError}
                        >
                          <FormControl
                            type='password'
                            minLength='1'
                            required
                            name='password'
                            placeholder={props.t('Password')}
                            onInput={(event) => props.onInputPassword(event.currentTarget.value)}
                            className={`startup__tabel-field mx-auto mt-3`}
                          />
                        </OverlayTrigger>
                      </FormLabel>
                    </FormGroup>
                    <FormGroup className='text-center'>
                      {modules.includes('study') &&
                        <NavLink
                          to={`/preshift`}
                          onClick={props.routeGuider}
                          linkto={`/preshift`}
                          className={`startup__submit btn Btn_success ${classDisable} mx-auto mb-3`}
                        >
                          {props.t('Preshift')}
                        </NavLink>}
                      <NavLink
                        to={`/cabinet`}
                        onClick={props.routeGuider}
                        linkto={`/cabinet`}
                        className={`startup__submit btn Btn_boring ${classDisable} mx-auto`}
                      >
                        {props.t('Cabinet')}
                      </NavLink>
                    </FormGroup>
                    <div className='mb-0 text-center'>
                      <span onClick={props.toStartupStep} className='startup__link'>
                        <FontAwesomeIcon className='mr-3' icon={faHome} />
                        {props.t('Login by personnel number')}
                      </span>
                      <br />
                      <span onClick={() => props.teleporToStep('search_employee')} className='startup__link'>
                        <FontAwesomeIcon className='mr-3' icon={faSearch} />
                        {props.t('Search by name')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'startup__container startup__container_bn2'}>
                  <div className={'startup__navi'}>
                    {modules.includes('study') &&
                        <NavLink
                          to={`/preshift`}
                          onClick={props.routeGuider}
                          linkto={`/preshift`}
                          exact
                          className={`startup__navi-link btn Btn_success ${classDisable}`}
                        >
                          <FontAwesomeIcon icon={faSuitcase} className='mr-4' />
                          {props.t('Pre-shift express training')}
                        </NavLink>}
                    <NavLink
                      to={`/cabinet`}
                      onClick={props.routeGuider}
                      linkto={`/cabinet`}
                      exact
                      className={`startup__navi-link btn Btn_boring ${classDisable}`}
                    >
                      <FontAwesomeIcon icon={faUser} className='mr-4' />
                      {props.t('Personal Area')}
                    </NavLink>
                  </div>
                </div>
              </Loader>
            </div>
          </div>
        </Content>
      </div>
      {props.data && props.data.length > 0 && (
        <BootstrapModal
          modalId='selectEmployees'
          dialogClassName={'startup__modal-emp'}
          title={props.t('Select yourself')}
          btns={[]}
          handleClearStateEmployes={props.handleClearStateEmployes}
        >
          <div className='list-group startup__emplist'>
            {props.employes.map((emp, i) => (
              <button key={i} onClick={() => props.handleSelectEmployee(emp.uuid)} className='startup__emplist-item list-group-item list-group-item-action'>
                <div className='startup__emplist-itemname'>{emp.title}</div>
              </button>
            ))}
          </div>
        </BootstrapModal>
      )}
    </>
  );
};