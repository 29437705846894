import store from "../localStorage";

const initialState = {
    question: null,
    answers: [],
    error_count: 0,
    event_dt: null,
    isTakeFrontPhoto: false,
    isTakeOverviewPhoto: false,
    came_from: 'startup',
    flow: {
        history: {}
    },
}

export const SAVE_STATE_PRESHIFT = 'SAVE_STATE_PRESHIFT',
    CLEAR_STATE_PRESHIFT = 'CLEAR_STATE_PRESHIFT',
    PRESHIFT_SUCCESS = 'PRESHIFT_SUCCESS',
    PRESHIFT_FAIL = 'PRESHIFT_FAIL',
    CLEAR_PRESHIFT_FAIL = 'CLEAR_PRESHIFT_FAIL',
    SAVE_FLOW = 'SAVE_FLOW',
    TAKE_FRONT_PHOTO = 'TAKE_FRONT_PHOTO',
    TAKE_OVERVIEW_PHOTO = 'TAKE_OVERVIEW_PHOTO',
    CLEAR_FLOW = 'CLEAR_FLOW',
    SET_CAME_FROM = 'SET_CAME_FROM';

export default function preshiftReduser(state = initialState, action) {
    switch (action.type) {
        case SAVE_STATE_PRESHIFT:
            let datumUhrzeit = new Date();
            let event_dt = '@' + Math.round(datumUhrzeit.getTime() / 1000);
            let action_new = action;
            action_new.event_dt = event_dt;
            return {...state, ...action_new};
        case CLEAR_STATE_PRESHIFT:
            localStorage.removeItem('ComplexAppStorage');
            return initialState;
        case PRESHIFT_SUCCESS:
            localStorage.removeItem('ComplexAppStorage');
            return initialState;
        case PRESHIFT_FAIL:
            return {...state, error_count: state.error_count += 1};
        case CLEAR_PRESHIFT_FAIL:
            return {...state, error_count: state.error_count = 0};
        case SAVE_FLOW:
            return {...state, flow: {...state.flow, ...action.data}};
        case CLEAR_FLOW:
            return {...state, flow: {}};
        case TAKE_FRONT_PHOTO:
            return {...state, isTakeFrontPhoto: action.isTakeFrontPhoto};
        case TAKE_OVERVIEW_PHOTO:
            return {...state, isTakeOverviewPhoto: action.isTakeOverviewPhoto};
        case SET_CAME_FROM:
            return {...state, came_from: action.came_from}
        default:
            return state;
    }
}

const typeClaerPreshift = () => ({type: CLEAR_STATE_PRESHIFT});

export const clearStatePreshift = () => store.dispatch(typeClaerPreshift());