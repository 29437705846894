import React from "react";
import {Button} from "react-bootstrap";
import Content from "../../../blocks/Content";

export default React.memo(function QuestionnairePage(props) {
    const title = props.survey.survey.title;
    const description = props.survey.survey.description;
    const start_dt = props.survey.assignment.start_dt;
    const end_dt = props.survey.assignment.end_dt;
    const start_date = start_dt && `${new Date(start_dt.slice(0, -6)).toLocaleString().slice(0, -10)}`;
    const end_date = end_dt && `${new Date(end_dt.slice(0, -6)).toLocaleString().slice(0, -10)}`;
    const attempts_count = props.survey.assignment.number_of_attempts;
    const author = props.survey.survey.author;
    const results = props.survey.results;

    return (
        <div className="Screen container d-flex flex-column justify-content-center align-items-center">
            <div className="w-100">
                <Content>
                    <div className="w-100 p-5 border d-flex flex-column justify-content-between flex-md-row">
                        <div className='d-flex flex-column justify-content-between align-items-start'>
                            <p className='display-4'>{title}</p>
                            <p className='display-9'>{description}</p>
                            <p className='display-9 mt-5'>{props.t('Author')}: {author}</p>
                        </div>
                        <div className='d-flex flex-column justify-content-between align-items-end'>
                            {start_dt && <p className='display-9'>{props.t('Start date')}: {start_date}</p>}
                            {end_dt && <p className='display-9'>{props.t('End date')}: {end_date}</p>}
                            {attempts_count > 0 && <p className='display-9'>{props.t('Number of attempts')}: {attempts_count}</p>}
                            <Button onClick={props.goToSurvey} className={`Btn Btn_success MuiButton mt-5`} disabled={attempts_count <= results.length} >
                                <span>{props.t('Start the survey')}</span>
                            </Button>
                        </div>
                    </div>
                    {results.map((el, i) =>
                        <div className="w-100 p-4 border mt-4" key={el.uuid}>
                            <h1 className='display-7'>{i + 1} {props.t('attempt')} {new Date(el.start_dt.slice(0, -6)).toLocaleString().slice(0, -10)}</h1>
                        </div>)}
                </Content>
            </div>
        </div>
    )
});