import apiHandling from "./apiHandling";

class apiDemoQuestions extends apiHandling {
    static getDemoQuestions = () => this.req({
            method: 'get',
            action: 'demo'
        });

    static getDemoQuestion = (uuid) => this.req({
            method: 'get',
            action: `questions/${uuid}`
        });
}

export default apiDemoQuestions;