import apiHandling from "./apiHandling";

class apiScreensavers extends apiHandling {
    static getScreensavers = (uuid) => this.req({
            method: 'get',
            action: `screensavers/${uuid}`
        });
}

export default apiScreensavers;
