import React, {Component} from "react";
import {Image} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import withFormHanlding from "../../../hoc/FormHandling";
import apiFeedback from "../../../api/apiFeedback";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import modalManage from "../../../reducers/modal-manage";
import BootstrapModal from "../../modals/BootstrapModal";
import Modal from "../../modals/Modal";
import FeedbackAnswer from "../../screens/feedback/FeedbackAnswer";
import {notify} from "../../blocks/Notify";
import {errorNotify} from "../../../helpers/functions";

class FeedbackAnswerContainer extends Component {
    constructor(props) {
        super(props);

        loader.stop('content');

        this.state = {
            localeKeyboard: props.locale.locale,
            keyboardConfig: {
                layoutName: props.locale.locale,
                layout: {
                    'ru_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'ru': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'en': [
                        'Q W E R T Y U I O P {backspace}',
                        '{lock} A S D F G H J K L -',
                        '{lang} {numbers} Z X C V B N M',
                        '{space}'
                    ],
                    'en_lower': [
                        'q w e r t y u i o p {backspace}',
                        '{lock} a s d f g h j k l -',
                        '{lang} {numbers} z x c v b n m',
                        '{space}'
                    ],
                    'ky_lower': [
                        'ё й ц у к е н г ш щ з х ъ ө {backspace}',
                        '{lock} ф ы в а п р о л д ж э ү -',
                        '{lang} {numbers} я ч с м и т ь б ю ң',
                        '{space}'
                    ],
                    'ky': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ Ө {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э Ү -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю Ң',
                        '{space}'
                    ],
                    'symbols': [
                        '[ ] { } # % ^ * - + =',
                        '_ \/ | ~ < > $ € •',
                        '{lang} {abc} {numbers} . , ? ! \'',
                        '{space}'
                    ],
                    'numbers': [
                        '1 2 3 4 5 6 7 8 9 0',
                        '- \/ : ; ( ) ₽ & @ "',
                        '{lang} {abc} {symbols} . , ? ! \'',
                        '{space}'
                    ]
                },
                display: {
                    "{backspace}": '<',
                    "{space}": props.t('Space'),
                    "{lock}": "A->a", // String.fromCharCode(55358, 56389), // "&#129093", // 🡅,
                    "{lang}": props.locale.locale.toUpperCase(),
                    "{symbols}": '#+=',
                    "{abc}": props.t('ABC'),
                    "{numbers}": '123'
                },
                theme: 'theme_cot',
                onChange: this.onPressKey,
                onKeyPress: this.onKeyPress,
                keyboardRef: r => (this.keyboard = r),
            },
            input: "",
            themes: {},
            structures: [],
            selected_structure_id: '',
            users: [],
            selected_user_id: '',
            recipient: 'structure',
        };
        let formState = {
            formErrors: {
                message: [],
            },
            formValues: {message: ''},
            formValid: false,
            onSubmit: this.handleSubmit
        };
        props.prepareFormState(formState);

        resetBreamb();

        this.allPromise();
    }

    componentDidMount() {
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Feedback')});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.locale.locale !== this.props.locale.locale)) {
            this.setState({
                keyboardConfig: {
                    ...this.state.keyboardConfig,
                    layoutName: this.props.locale.locale
                }
            })
        }
    }

    handleSelectLang = (lang) => {
        let keyboardConfig = this.state.keyboardConfig;
        keyboardConfig.display = {...keyboardConfig.display, "{lang}": lang.toUpperCase()}
        keyboardConfig.layoutName = lang;
        this.setState({localeKeyboard: lang, keyboardConfig: keyboardConfig});
        modalManage.close('selectLangKeyboard');
    }

    allPromise = () => {
        loader.start('content');
        Promise.all([this.getThemes(), this.getStructures(), this.getUsers()])
        .catch(e => errorNotify(this.props.t('Error loading data to create a message'), e, this.props.history.push, this.props.user.isAuth, '/feedback'))
        .finally(() => loader.stop('content'))
    };

    getThemes = () => new Promise((resolve, reject) => {
        apiFeedback.getThemes()
        .then(response => {
            this.setState({themes: response.data});
            this.onSelectTheme(Object.keys(response.data)[0]);
            resolve();
        })
        
        .catch(e => reject(e))
        .finally(() => this.onInputMessage(''))
    });

    getStructures = () => new Promise((resolve, reject) => {
        apiFeedback.getStructures()
        .then(res => {
            const user_structure = res.data.filter(el=> el.id === this.props.user.data.structure_id);
            this.setState({
                structures: res.data,
                selected_structure_id: user_structure.length > 0 ? user_structure[0].id : ''});
            resolve();
        })
        .catch(e => reject(e))
    });

    getUsers = () => new Promise((resolve, reject) => {
        apiFeedback.getUsers()
        .then(res => {
            this.setState({
                users: res.data,
                selected_user_id: res.data.length > 0 ? res.data[0].id : ''});
            resolve();
        })
        .catch(e => reject(e))
    });

    handleRecipient = (value) => {
        const new_value = value === 'user' ? this.state.selected_user_id : this.state.selected_structure_id;
        this.setState({recipient: value},
            () => this.onSelectRecipient(new_value.toString()));
    };

    onKeyPress = (button) => {
        let keyboardConfig = this.state.keyboardConfig;
        if (button === "{lock}") {
            keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard ? `${this.state.localeKeyboard}_lower` : this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{lang}") {
            modalManage.show('selectLangKeyboard');
        } else if (button === "{symbols}") {
            keyboardConfig.layoutName = 'symbols';
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{abc}") {
            keyboardConfig.layoutName = this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{numbers}") {
            keyboardConfig.layoutName = 'numbers';
            this.setState({keyboardConfig: keyboardConfig});
        }
    };

    onInputMessage = (value) => {
        let message = document.createElement('input');
        message.value = value;
        message.type = 'text';
        message.name = 'message';
        message.required = true;
        this.onChange(message);
    }

    onSelectTheme = (value) => {
        let theme = document.createElement('input');
        theme.value = value;
        theme.type = 'text';
        theme.name = 'theme_id';
        this.onChange(theme);
    }

    onSelectRecipient = (value) => {
        let recipient = document.createElement('input');
        recipient.value = value;
        recipient.type = 'text';
        recipient.name = 'recipient_id';
        this.state.recipient === 'user' ? this.setState({selected_user_id: value}) : this.setState({selected_structure_id: value});
        this.onChange(recipient);
    }

    showSelectRecipientModal = () => {
        const value = this.state.recipient === 'user' ? this.state.selected_user_id : this.state.selected_structure_id;
        this.onSelectRecipient(value.toString());
        modalManage.show('selectRecipient');
    };

    handleSubmit = (event, handleRecipient, handleId) => {
        if (this.props.formValid) {
            let formValues = this.props.formValues;
            modalManage.close('selectRecipient');

            return new Promise((resolve, reject) => {
                loader.start('content');
                let text_message = formValues.message,
                    theme_id = formValues.theme_id,
                    employee_id = this.props.user.data.id,
                    recipient = {
                        type: handleRecipient ? handleRecipient : this.state.recipient,
                        id: handleId
                                ? handleId
                                : formValues.recipient_id
                                    ? formValues.recipient_id
                                    : this.props.user.data.structure_id
                    };

                apiFeedback.createDialog(employee_id, text_message, theme_id, recipient)
                .then(response => {
                    resolve(response);
                    notify(this.props.t('The message has been sent'), 'success');
                    this.props.history.push('/feedback');
                })
                .catch(e => errorNotify(this.props.t('Error sending the message'), e, this.props.history.push, this.props.user.isAuth, '/feedback'))
                .finally(() => loader.stop('content'));
            });
        }
    }

    onChange = (input) => this.props.validateField(input);

    onPressKey = (input) => {
        let message = document.querySelector('.MessageField');
        message.value = input;
        this.props.validateField(message);
    }

    render() {
        return <>
                <FeedbackAnswer
                    onSubmit={this.onSubmit}
                    onClearName={this.onClearName}
                    onSelectTheme={this.onSelectTheme}
                    onInputMessage={this.onInputMessage}
                    handleSubmit={this.handleSubmit}
                    handleRecipient={this.handleRecipient}
                    onSelectRecipient={this.onSelectRecipient}
                    showSelectRecipientModal={this.showSelectRecipientModal}
                    keyboard={this.keyboard}
                    {...this.state} {...this.props} />
                <Modal>
                    <BootstrapModal modalId="selectLangKeyboard" dialogClassName={"startup__modal-lang"} btns={[]}>
                        <div className="ModalLang list-group d-flex flex-row justify-content-between">
                            {this.props.locale.languages &&
                                this.props.locale.languages.map(lang =>
                                    <button key={lang.id} className="ModalLang-Btn d-flex flex-column text-center" onClick={() => this.handleSelectLang(lang.id)}>
                                        <Image width="64" className="mx-auto mb-4" src={lang.icon}/>
                                        <span className="display-6 text-uppercase font-weight-bold">{lang.title}</span>
                                    </button>)}
                        </div>
                    </BootstrapModal>
                </Modal>
               </>
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        locale: store.localeState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withTranslation()(withFormHanlding(FeedbackAnswerContainer)));