import store from "../localStorage";

const initialState = {
    isAuth: false,
    isPinCode: false,
    isInterval: false,
    data: {},
}

export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const PIN_CODE_SUCCESS = 'PIN_CODE_SUCCESS';
export const SET_PIN_CODE = 'SET_PIN_CODE';
export const SET_USER_INTERVAL = 'SET_USER_INTERVAL';
export const CLEAR_USER_INTERVAL = 'CLEAR_USER_INTERVAL';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export default function userReduser(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_DATA:
            return {
                ...state,
                isAuth: true,
                data: {
                    ...state.data,
                    ...action.data,
                }
            };
        case PIN_CODE_SUCCESS:
            return {
                ...state,
                isPinCode: true,
            };
        case SET_USER_INTERVAL:
            return {
                ...state,
                isInterval: true,
            };
        case CLEAR_USER_INTERVAL:
            return {
                ...state,
                isInterval: false,
            };
        case SET_PIN_CODE:
            return {
                ...state,
                data: {
                    ...state.data, pin_code: action.data
                }
            };
        case CLEAR_USER_DATA:
            localStorage.clear();
            return initialState;
        case CLEAR_NOTIFICATIONS:
            return {
                ...state,
                data: {
                    ...state.data, block_notifications: []
                }
            };
        default:
            return state;
    }
}

export const saveUserData = (data) => ({type: SAVE_USER_DATA, data: data});
export const clearUserData = () => ({type: CLEAR_USER_DATA});
export const pinCodeSuccess = () => ({type: PIN_CODE_SUCCESS});
export const setPinCode = () => ({type: SET_PIN_CODE, data: data});
export const authorized = (data) => new Promise((resolve, reject) => {
        store.dispatch({type: SAVE_USER_DATA, data: data});
        resolve(true);
    });
export const logout = () => store.dispatch(clearUserData());
export const clearNotifications = () => store.dispatch({type: CLEAR_NOTIFICATIONS});