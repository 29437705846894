import React, { useEffect, useState } from "react";
import {withTranslation} from "react-i18next";
import {Button, Container, FormControl, FormGroup, } from "react-bootstrap";
import { isElectron } from "react-device-detect";
import {NavLink} from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faChevronDown, faChevronUp, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import MainNav from "../../blocks/MainNav";
import ListContainer from "../../containers/ListContainer";
import FeedbackMessage from "../../blocks/Feedback/FeedbackMessage";
import Loader from "../../blocks/Loader";
import {isTrueProp, isWebApp} from "../../../helpers/functions";
import configApp from "../../../config/config";
import Breams from "../../blocks/Breams";


function FeedbackDialog(props) {
    const [showKeyboard, setShowKeyboard] = useState(true);
    const auto_focus = isWebApp();
    document.title = props.t('Dialog');
    let letMessages = [];
    let ListMessages = null;
    for (let key in props.messages) {
        let message = props.messages[key];
        letMessages.push(message);
    };
    if (letMessages.length > 0) ListMessages = <ListContainer listItems={letMessages} templateItem={FeedbackMessage}/>;

    useEffect(() => {
        const feedbackDialogMessages = document.getElementById('FeedbackDialogMessages');
        if (!props.load.isLoading && ListMessages !== null && feedbackDialogMessages) {
            const scrollDiv = feedbackDialogMessages.firstChild;
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
        if (props.messages?.hasOwnProperty('new_message') && feedbackDialogMessages) {
            const scrollDiv = feedbackDialogMessages.firstChild;
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    }, [props.load.isLoading]);

    return <>
            <Breams/>
            <div className="Screen FeedbackAdd container pb-1">
                <Loader className="h-100" isLoading={props.load.isLoading} loadId={"content"} justifyContent="center">
                    <div className="d-flex flex-column justify-content-end h-100" id="FeedbackDialogMessages">
                        {ListMessages}
                    </div>
                </Loader>
            </div>
            <Container>
                <FormGroup className="search-emp__name-container text-center my-1 d-flex">
                    <FormControl onChange={props.onInputMessage}
                                name="message"
                                maxLength="1024"
                                autoFocus={auto_focus}
                                required
                                className={`Field MessageField mx-auto border-secondary form-control`}
                                placeholder={props.t('Enter message')}
                                value={props.formValues.message ? props.formValues.message : ''}
                                type="text" />
                    {props.formValues?.hasOwnProperty('message') && props.formValues.message.length > 0 &&
                        <Button onClick={props.onClear} className="search-emp__name-searchButton Btn_happy m-2 display-5">
                            {props.t('Reset')}
                        </Button>}
                    <Button onClick={props.handleSubmit} disabled={(props.formValid !== true)}
                            className={`search-emp__name-searchButton Btn_success m-2 display-5`}>
                        {props.t('Send')}
                    </Button>
                </FormGroup>
                {(isTrueProp(configApp.device_settings, 'keyboard') || isElectron) &&
                    <>
                        <div className="keyboardShowBtn">
                            <button type="button" className="keyboardShowButtton" onClick={() => setShowKeyboard(!showKeyboard)}>
                                <FontAwesomeIcon icon={showKeyboard ? faChevronDown : faChevronUp} />
                            </button>
                        </div>
                        <div className={`keywoard-container ${showKeyboard ? null : "unShowKeyboardClass"}`}>
                            <Keyboard className="px-4" {...props.keyboardConfig} />
                        </div>
                    </>}
            </Container>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <NavLink to={"/feedback"} className={`Btn Btn_boring MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Back')}</span>
                    </NavLink>
                    <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                        <span>{props.t('Exit')}</span>
                    </NavLink>
                </Container>
            </MainNav>
        </>
};

export default withTranslation()(FeedbackDialog);