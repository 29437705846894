import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import Notices from "../screens/notices/Notices";
import apiHandling from "../../api/apiHandling";
import { errorNotify } from "../../helpers/functions";
import {BACKEND_HOST} from "../../api/api-config";


class NoticesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentNotice: props.notifications[props.activeStep],
            colorActions: {
                put_aside: 'warning',
                close: 'secondary',
                set_read: 'primary',
                agree: 'success',
                refused: 'danger',
            }
        }
    }

    componentDidMount() {
        if (this.state.currentNotice.content['content-type'] === 'video') {
            setTimeout(() => {
                let materialContent = document.querySelector('.Material-FileViewer video');
                if (materialContent) {
                    materialContent.muted = true;
                    materialContent.play();
                }
            }, 1);
        }
    }

    onSelectAction = (action_id) => {
        this.state.currentNotice.actions[action_id].callbacks.forEach(async (callback_link) => {
            try {
                 await apiHandling.reqByLink(BACKEND_HOST + callback_link, 'post');
            } catch (e) {
                const info = `${this.props.t('Function execution error')} "${this.state.currentNotice.actions[action_id].title}"`;
                errorNotify(info, e, this.props.history.push, this.props.user.isAuth);
            }
        });
        this.props.handleNextStep();
    }

    render() {
        if (!this.state.currentNotice.blocked) {
            this.props.renderAdditionalButton(
                <Button className="MuiButton Btn Btn_boring btn btn-primary" onClick={this.props.handleEndStepper}>
                    <FontAwesomeIcon className="mr-4" icon={faArrowAltCircleLeft}/>
                    {this.props.t('Back')}
                </Button>)
        }
        return <Notices onSelectAction={this.onSelectAction} notice={this.state.currentNotice} {...this.state} {...this.props}/>
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
    }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(NoticesContainer)));