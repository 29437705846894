export const getLanguages = (state) => state.localeState.languages;
export const getLocaleId = (state) => state.localeState.locale;
export const getLocale = (state) => {
    let locale = null;
    let is_has_locale = false;
    state.localeState.languages.forEach((lang) => {
        if (state.localeState.locale === lang.id) {
            locale = lang;
            is_has_locale = true;
        }
    });
    if (!is_has_locale) locale = state.localeState.languages[0];

    return locale;
}