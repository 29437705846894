import React from 'react';
import { isElectron } from 'react-device-detect';
import {Container, Image, OverlayTrigger, Popover} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle, faUser} from '@fortawesome/free-solid-svg-icons';
import {withTranslation} from "react-i18next";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import Progress from "./Progress";
import Notifications from './Notifications';
import modalManage from "../../reducers/modal-manage";
import {getLocale, getLocaleId} from "../../reducers/locale-selector";
import configApp from "../../config/config";
import { autoUpdate } from '../../update/autoUpdate';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopoverComp: false,
            showPopoverRisk: false,
            showNotifications: false,
            showComplexInfo: false,
        }
    }

    handleClickLang = () => modalManage.show('selectLang');

    handleShowPopoverComp = () => {
        this.setState({showPopoverComp: !this.state.showPopoverComp});
        setTimeout(()=> {this.setState({showPopoverComp: false})}, 5000);
    }

    handleShowPopoverRisk = () => {
        this.setState({showPopoverRisk: !this.state.showPopoverRisk});
        setTimeout(()=> {this.setState({showPopoverRisk: false})}, 5000);
    }

    handleShowPopoverComplexInfo = () => {
        this.setState({showComplexInfo: !this.state.showComplexInfo});
        setTimeout(()=> {this.setState({showComplexInfo: false})}, 10000);
    }

    handleShowNotifications = () => {
        this.setState({showNotifications: !this.state.showNotifications});
        setTimeout(()=> {this.setState({showNotifications: false})}, 10000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        prevProps.localeId !== this.props.localeId && this.setState({keyRender: Math.random()});
    }

    render() {
        let user = this.props.user.data;
        let locale = this.props.locale;

        return <>
                <header className={"Header d-flex align-items-center w-100"}>
                    <Container className={"pb-0"}>
                        <div className={"d-flex Header-Row align-items-center"}>
                            {(this.props.user.isAuth &&
                             (this.props.user.isPinCode ||
                              !this.props.app.modules.includes("pin_code") ||
                              !this.props.app.use_pin_code ||
                              this.props.location.pathname === "/electronic-courses-class"))
                                ? <>
                                    <div style={{flex: ".65"}} className={"Header-Col d-flex justify-content-start align-items-baseline pt-1"}>
                                        <FontAwesomeIcon icon={faUser} className="mr-2"/>
                                        <span className={"Header-Text"}>
                                            <b>{user.title}</b><br/>
                                            {user.number}
                                        </span>
                                    </div>
                                    <div style={{flex: ".65"}} className={"Header-Col d-flex justify-content-center"}>
                                        {user?.hasOwnProperty('count_new_notification') &&
                                            <OverlayTrigger
                                                    trigger="click"
                                                    show={this.state.showNotifications}
                                                    onToggle={this.handleShowNotifications}
                                                    placement="bottom"
                                                    overlay={<Popover className={"p-3 mw-50"}>
                                                                <Notifications notifications={this.props.user.data.unread_notifications}
                                                                            handleShowNotifications={this.handleShowNotifications}
                                                                            t={this.props.t}/>
                                                             </Popover>}>
                                                <div className="mr-4">
                                                    <div className={`p-1 Header-Notices cursor-pointer ${user.count_new_notification > 0 ? 'Header-Notices_active' : null}`}>
                                                        <FontAwesomeIcon className="Header-NoticesIcon" icon={faBell}/>
                                                        <span className="Header-NoticesCount">{user.count_new_notification}</span>
                                                    </div>
                                                </div>
                                            </OverlayTrigger>}
                                        {user?.hasOwnProperty('position') && user.position.length > 0 &&
                                            <span className={"Header-Text"}>
                                                {this.props.t('Position')}<br/>
                                                <b>{user.position[0].title}</b>
                                            </span>}
                                    </div>
                                    {this.props.app?.hasOwnProperty('competence_state') &&
                                     this.props.app.competence_state &&
                                     this.props.location.pathname !== "/electronic-courses-class" &&
                                        <div style={{flex: ".4"}} className={"Header-Col justify-content-center align-items-center text-right"}>
                                            <OverlayTrigger
                                                    trigger="click"
                                                    show={this.state.showPopoverComp}
                                                    onToggle={this.handleShowPopoverComp}
                                                    placement="bottom"
                                                    overlay={<Popover className={"Text_success display-9 text-center p-2"}>{this.props.t('Competence txt')}</Popover>}>
                                                <div className="d-flex flex-row justify-content-center cursor-pointer" >
                                                    <span className={"Header-Text d-none d-sm-block"}>{this.props.t('Competence')}<br/>{this.props.t('month')}</span>
                                                    <Progress className={"Header-Progress"} color={"#1E874A"} persent={user.competence}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>}
                                    {this.props.app?.hasOwnProperty('risks_state') &&
                                     this.props.app.risks_state &&
                                     this.props.location.pathname !== "/electronic-courses-class" &&
                                        <div style={{flex: ".4"}} className={"Header-Col justify-content-end align-items-center text-right"}>
                                            <OverlayTrigger
                                                    trigger="click"
                                                    show={this.state.showPopoverRisk}
                                                    onToggle={this.handleShowPopoverRisk}
                                                    placement="bottom"
                                                    overlay={<Popover className={"Text_danger display-9 text-center p-2"}>{this.props.t('Risk txt')}</Popover>}>
                                                <div className="d-flex flex-row justify-content-end cursor-pointer" >
                                                    <span className={"Header-Text d-none d-sm-block"}>{this.props.t('Risk')}<br/>{this.props.t('month')}</span>
                                                    <Progress className={"Header-Progress"} color={"#DC3545"} persent={user.risks}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>}
                                  </>
                                : <>
                                    <div className={"my-auto d-flex justify-content-between align-items-center w-100"}>
                                        <Image src={this.props.app.logo_negative} className={"Header-Logo"} alt='Logo'/>
                                        <span className={"Header-Brand"}>{this.props.t('Competence control complex')}</span>
                                        <div className='d-flex align-items-center '>
                                            <div onClick={this.handleClickLang} className={`Header-Langs d-flex align-items-center ${isElectron && 'mr-5'}`}>
                                                <span className="text-uppercase mr-3 font-weight-bold">{locale.id}</span>
                                                <Image width="32" src={locale.icon}/>
                                            </div>
                                            {isElectron &&
                                                <OverlayTrigger
                                                    trigger="click"
                                                    show={this.state.showComplexInfo}
                                                    onToggle={this.handleShowPopoverComplexInfo}
                                                    placement="bottom"
                                                    overlay={<Popover className={"display-9 p-2"}>
                                                            <p><b>{this.props.t('Name')}:</b> {configApp.device_settings.name}</p>
                                                            <p><b>{this.props.t('Device ip')}:</b> {configApp.device_settings.ip}</p>
                                                            <p><b>{this.props.t('Server ip')}:</b> {configApp.server_host}</p>
                                                            <p><b>{this.props.t('Version')}:</b> {configApp.device_settings.version}</p>
                                                            <p><b>{this.props.t('Date')}:</b> {new Date().toLocaleString()}</p>
                                                            {configApp.platform === 'win32' && this.props.app?.updateState?.can_update &&
                                                            this.props.app.updateState.new_version !== configApp.device_settings.version &&
                                                            this.props.app.isCanUpdate &&
                                                                <p>
                                                                    {this.props.app.update_status === 'update' && <b>{this.props.t('Update start. Wait!')}</b>}
                                                                    {this.props.app.update_status === 'error' && <b>{this.props.t('Update error. Look to console.')}</b>}
                                                                    {!this.props.app.update_status &&
                                                                        <button type='button' onClick={() => autoUpdate(this.props.app.updateState, true)}>
                                                                            {this.props.t('Update to')} {this.props.app.updateState.new_version}
                                                                        </button>}
                                                                </p>}
                                                        </Popover>}>
                                                    <FontAwesomeIcon icon={faQuestionCircle} className='cursor-pointer' />
                                                </OverlayTrigger>}
                                        </div>
                                    </div>
                                  </>}
                        </div>
                    </Container>
                </header>
            </>
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        localeId: getLocaleId(store),
        locale: getLocale(store),
        app: store.appState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(Header)));