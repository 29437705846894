import {isElectron} from "react-device-detect";
import { notify } from "../../components/blocks/Notify";
import { setLog } from "../../helpers/functions";

const WAIT = "WAIT";
const STANBY = "STANBY";
const BREATH = "BREATH";
const RESULT = "RESULT";
const END = "END";
const ALCO_FAIL = 'ALCO_FAIL';

class moduleAlcotester {
	/**
	 *    option = {
	 *	baudRate: 4800,
	 *	port: 'COM3', -port
	 *	delimiter: '$' - разделитель
	 *	}
	 *
	 *    callbackResult - f(String) куда вернуть результат алкотестера
	 *    callbackStatus - f(String) статус состояния алкотестера (WAIT,STANBY,BREATH,RESULT,END)
	 *    callbackError - f(String) обработка ошибки
	 *
	 *    после создания обьекта, следует использовать команду start()
	 */

	constructor(option, callbackStatus) {
		if (!isElectron) return;

		this.baudRate = (option.baudRate === undefined) ? 4800 : option.baudRate;
		this.delimiterSimbol = (option.delimiter === undefined) ? '$' : option.delimiter;
		this.path = (option.port === undefined) ? 'COM3' : option.port;

		try {
			this.SerialPort = require('serialport');
			const DelimiterParser = require('@serialport/parser-delimiter');
			this.callbackStatus = (callbackStatus === undefined) ? (result) => {} : callbackStatus;

			this.port = new this.SerialPort(this.path, {baudRate: this.baudRate, autoOpen: false});
			this.parser = this.port.pipe(new DelimiterParser({delimiter: this.delimiterSimbol}));
		} catch (e) {
			e.typeError = 'ALCO_FAIL';
			let info = "Ошибка в moduleAlcotester";
			setLog(info, e);
			notify(info, 'error');
		}
	}


	/**
	 * Начать выполнение
	 * @returns {Promise.Promise}
	 */
	start() {
		return  new Promise((resolve, reject) => {
			this.parser.on('data', (data) => {
				let str = data.toString();
				let pos = str.indexOf(RESULT);

				if (pos !== -1) {
					let lastAlco = this.parseResult(str);
					str = RESULT;

					resolve(lastAlco);
				}
				if (str !== this.status) {
					this.status = str;
					this.callbackStatus(this.status);
				}
			});

			// this.port.on('data', console.log)

			this.port.on('close', () => {});

			if (this.port.isOpen === false) {
				this.port.open((error) => {
					if (error === `Error: Opening ${this.port.path}: Access denied`) {
						reject(error);
					} else {
						this.port.write('$START\r\n', 'ascii', (error) => {
							if (error) reject(error);
						});
					}
				});
			}
		});
	}

	close() {
		return  new Promise((resolve, reject) => {
			if (this.port.isOpen === true) {
				this.port.close((error) => {
					if (error) {
						reject(error);
					} else {
						this.port.write('$CLOSE\r\n', 'ascii', (error) => {
							if (error) {
								error.typeError = ALCO_FAIL;
								reject(error);
							}
						});
					}
				});
			}
		});
	}

	reset() {
		return  new Promise((resolve, reject) => {
			if (this.port.isOpen === true) {
				this.port.write('$RESET\r\n', 'ascii', (error) => {
					if (error) reject(error);
				});
			}
		});
	}


	parseResult(str) {
		const regex = /\w+\,(?<value>[0-9]+\.[0-9]+)-(?<level>\w+)/;
		const found = str.match(regex);

		return parseFloat(found.groups.value);
	}

	checkStatus() {
		let isData = [];
		return  new Promise((resolve, reject) => {
			try {
				this.port.on('data', (data) => {
					isData.push(data);
					if (isData.length > 0) {
						resolve(true);
						this.port.on('close', () => {});
					}
				})

				setTimeout(() => {
					if (isData.length === 0) resolve(false);
				}, 2000)

				if (this.port.isOpen === false) {
					this.port.open((error) => {
						if (error === `Error: Opening ${this.port.path}: Access denied`) {
							reject(error);
						} else {
							this.port.write('$CLOSE\r\n', 'ascii', (error) => {
								if (error) reject(error);
							});
						}
					});
				}
			} catch (e) {
				reject(e);
			}
		})
	}
	
}

export default moduleAlcotester;