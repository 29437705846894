import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faForward} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import preshiftManage from '../../../reducers/preshift-manager';
import ExitTimer from '../../blocks/ExitTimer';
import Epilog from "../../screens/questions/Epilog";

class EpilogContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTimer: false,
            is_exit_timer: true
        };

        this.timeoutID = () => {};
    }

    componentDidMount() {
        setTimeout(() => {
            let nodeVideo = document.querySelector('#epilog_video');
            nodeVideo.autoplay = 'autoplay';
            nodeVideo.addEventListener('ended', () => {
                nodeVideo.pause();
                this.returnAction(); });
            if (this.props.question.epilog === null) this.timeoutID = setTimeout(() => this.returnAction(), 10000);
        }, 1);
        this.renderButtons();
    }

    componentWillUnmount = () => clearTimeout(this.timeoutID);

    handleExitTimer = () => this.setState({is_exit_timer: false});

    returnAction() {
        ((this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1) &&
         (this.props.activeEducation === this.props.listEducation.length - 1))
            ? this.setState({ showTimer: true })
            : this.props.theEnd();
    }

    componentDidUpdate(pverState) {
        if (pverState.showTimer !== this.state.showTimer) this.renderButtons();
    }

    renderButtons() {
        let came_from = preshiftManage.getCameFrom();
        if (came_from !== 'startup' && this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1 &&
            this.props.activeEducation === this.props.listEducation.length - 1) {
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        <Button className="MuiButton Btn Btn_success" onClick={() => {if (this.state.is_exit_timer) this.props.theEnd()}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.t('Done')}
                        </Button>
                        {came_from !== "electronic-courses" &&
                            <Button className="MuiButton Btn Btn_success ml-3"
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.props.handleFinish();
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Finish and exit')}
                                {this.state.showTimer && came_from !== "electronic-courses" &&
                                    <ExitTimer theme_name={this.props.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer}/>}
                            </Button>}
                    </div>);
                } else {
                    this.props.renderAdditionalButton(
                        <div className="d-flex w-100 justify-content-end">
                            <Button className="MuiButton Btn Btn_success" onClick={() => {if (this.state.is_exit_timer) this.props.theEnd()}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {((this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1) &&
                                  (this.props.activeEducation === this.props.listEducation.length - 1))
                                    ? this.props.t('Finish and exit')
                                    : this.props.t('Proceed')}
                                {this.state.showTimer &&
                                    <ExitTimer theme_name={this.props.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer}/>}
                            </Button>
                        </div>);
                };
    };

    render() {
        return <Epilog {...this.props} {...this.state} />
    }
}

export default withTranslation()(EpilogContainer);