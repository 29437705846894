import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import preshiftManage from "../../../reducers/preshift-manager";
import Loader from "../../blocks/Loader";
import Breams from "../../blocks/Breams";
import ExtraEducationsTab from "../../screens/questions/ExtraEducationsTab";
import apiPreshift from "../../../api/apiPreshift";
import QuestionStepper from "../../steppers/QuestionStepper";
import store from "../../../localStorage";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import { errorNotify } from "../../../helpers/functions";

class ExtraEducationsContainer extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            flow_uuid: null,
            appointment: null,
            top: 0
        };

        store.dispatch({type: "PAGE_LOADED", loadId: "extra_education"});
        document.title = props.t("Extra education");
        this.getQestions();
    };

    getQestions = () => {
        resetBreamb();
        addBreamb({title: this.props.t("Personal Area")});
        addBreamb({title: this.props.t("Extra education")});
        return new Promise((resolve, reject) => {
            loader.start("extra_education");
            apiPreshift.getExtraEducationsAll(this.props.user.data.id)
            .then(response => {
              this.setState({data: response.data});
              resolve();
            })
            .catch(e => errorNotify(this.props.t('Error loading additional training'), e, this.props.history.push, this.props.user.isAuth))
            .finally(() => loader.stop("extra_education"))
          })
    }

    handleTimeExpired = () => this.props.history.push("/");

    goToExtraEducation = (flow_uuid, appointment, top) => {
        this.setState({
            flow_uuid: flow_uuid,
            appointment: appointment,
            top: top,
        });
        preshiftManage.setCameFrom('extra-education');
    }

    clearResponseFlow = () => {
        this.setState({
            flow_uuid: null,
            appointment: null,
            top: 0,
        });
        this.getQestions();
    }

    render() {
        return <>
                {this.state.flow_uuid 
                    ? <QuestionStepper clearResponseFlow={this.clearResponseFlow} flow_uuid={this.state.flow_uuid} appointment={this.state.appointment} top={this.state.top}/>
                    : <div className="Screen">
                        <Loader isLoading={this.props.load.isLoading} loadId={"extra_education"} justifyContent="center">
                            <Breams />
                            <ExtraEducationsTab state={this.state.data} t={this.props.t} goToExtraEducation={this.goToExtraEducation}/>
                            <div className='MuiStepContainer'>
                                <Container className="pb-1">
                                    <div className="w-100">
                                        <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                                            <NavLink to="/cabinet" className="Btn Btn_success MuiButton">
                                                <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                                {this.props.t('Personal Area')}
                                            </NavLink>
                                            <NavLink to="/" className="Btn Btn_danger MuiButton">
                                                <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                                                {this.props.t('Exit')}
                                            </NavLink>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </Loader>
                     </div>}
               </>
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        preshift: store.preshiftState,
        theme_name: store.appState.theme_name,
    }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(ExtraEducationsContainer)));