import React from "react";
import { withTranslation } from "react-i18next";

function SetDisplaySettings(props) {
    return <div  className="w-75">
            <div className="d-flex justify-content-start align-items-center mb-4">
                <input type="checkbox"
                    defaultChecked={props.device_settings.fullscreen}
                    name="fullscreen"
                    id="fullscreen"
                    onChange={props.onInputChange}
                    className="set_settings_input mr-4" />
                <label className="set_settings_label" htmlFor="fullscreen">{props.t("Run in fullscreen mode")}</label>
            </div>
            <div className="d-flex justify-content-start align-items-center">
                <input type="checkbox"
                    defaultChecked={props.device_settings.cursor}
                    name="cursor"
                    id="cursor"
                    onChange={props.onInputChange}
                    className="set_settings_input mr-4" />
                <label className="set_settings_label" htmlFor="cursor">{props.t("Display the cursor")}</label>
            </div>
          </div>
}

export default withTranslation()(SetDisplaySettings);