import React from 'react';
import Lottie from "lottie-react";
import {withTranslation} from "react-i18next";
import animationData from "../../../../data/icons/camera_test.json";

function FrontCamera(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className="preshift d-flex align-items-center flex-column justify-content-center h-100 px-4 text-center">
            <div className="preshift__lottie mb-3">
                <Lottie className="preshift__lottie mb-3" {...defaultOptions} style={{height: '20rem', width: '20rem'}} />
            </div>
            <h4 className="preshift__text text-cot-green">{props.t('Look into the camera lens')}</h4>
            <div className="preshift__timer"/>
           </div>
};

export default withTranslation()(FrontCamera);