import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import ExitTimer from "../ExitTimer";
import ScreensaverParams from "./ScreensaverParams";

export default function ScreensaverModal(props) {
    const [showScreensaver, setShowScreensaver] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [count, setCount] = useState(0);
    const timer = useRef();
    const { t } = useTranslation();

    const windowOnClick = () => {
        clearTimeout(timer.current);
        setSeconds(props.time);
        setCount(count + 1);
    };

    useEffect(() => {
        setSeconds(props.time);
        window.onclick = windowOnClick;
        return () => {window.onclick = null};
    }, []);
    
    useEffect(() => {
        if (seconds > 5 && seconds < props.time) { timer.current = setTimeout(() => setSeconds(seconds - 1), 1000) };
        if (seconds === 5){
            setSeconds(0);
            window.onclick = null;
            setOpenModal(true);
        };
        if (seconds === props.time) {
            setCount(0);
            timer.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        };
        return () => clearTimeout(timer.current);
    }, [seconds, count]);

    const goOut= () => {
        props.handleTimeExpired();
        setShowScreensaver(true);
        setOpenModal(false);
        setSeconds(0);
        window.onclick = null;
    };

    const closeScreensaverModal = () => {
        setOpenModal(false);
        setShowScreensaver(false);
        window.onclick = windowOnClick;
    };

    const preModal = <div className={`${props.theme_name}`}>
                        <div className={`screensaver_div ${(openModal) ? "active" : ""}`}>
                            <div className="screensaver_modal">
                                <div className="screensaver_text">
                                    <h1>{t("Are you still here?")}</h1>
                                </div>
                                <Button className="MuiButton Btn Btn_success ml-3" onClick={() => closeScreensaverModal()}>
                                    <FontAwesomeIcon className="mr-4" icon={faUser}/>
                                    {t("Yes")}
                                    <ExitTimer theme_name={props.theme_name} goOut={goOut} />
                                </Button>
                            </div>
                        </div>
                    </div>;

    const screensaverElement = <div className={`${props.theme_name}`}>
                                <div className={`screensaver_data ${(showScreensaver) ? "active" : ""}`} onClick={() => closeScreensaverModal()}>
                                    <div className={`screensaver_text ${props.type === "template" && "m-0"}`}>
                                        <h1>{t("To unlock, tap on the screen")}</h1>
                                    </div>
                                    {props.type === "template"
                                        ? <ScreensaverParams params={props.params} t={t} theme_name={props.theme_name} />
                                        : props.type === "video"
                                            ? <div className="screensaver_video">
                                                <ReactPlayer
                                                    id="FileViewer"
                                                    url={props.file}
                                                    playing={true}
                                                    loop={true}
                                                    muted={true}
                                                    width="100%"
                                                    height="100%"/>
                                              </div>
                                            : <div className="screensaver_img">
                                                <Image src={props.file} className="notice_img" />
                                              </div>}
                                </div>
                             </div>;

    return openModal
        ? preModal
        : props.type
            ? screensaverElement
            : <></>
}