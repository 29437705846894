import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import apiEmployee from '../../api/apiEmployee';
import { errorNotify } from '../../helpers/functions';
import {pageLoadManageActions as loader} from "../../reducers/load";
import Siz from '../screens/Siz';
import { addBreamb, resetBreamb } from '../../reducers/breams-manage';
import Breams from '../blocks/Breams';
import Loader from '../blocks/Loader';
import MainNav from '../blocks/MainNav';


class SizContainer extends Component {
    constructor(props) {
        super(props);
        this.getSizs();
        this.state = null;
        document.title = props.t('Personal protective equipment');
        resetBreamb();
        addBreamb({title: props.t('Personal Area')});
        addBreamb({title: props.t('Personal protective equipment')});
    }

    getSizs = () => {
        loader.start('siz');
        apiEmployee.getSiz(this.props.user.data.uuid)
        .then(response => this.setState({...response.data}))
        .catch(e => errorNotify(this.props.t('Error loading PPE'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('siz'));
    }

    viewedSizs = (id) => apiEmployee.viewedSiz(id);

    render() {
        return  <>
                    <Breams/>
                    <div className="Screen quest">
                        <Loader className="h-100" isLoading={this.props.load.isLoading} loadId={"siz"} justifyContent="center">
                            <Siz {...this.state} t={this.props.t} viewedSizs={this.viewedSizs} />
                        </Loader>
                    </div>
                    <MainNav className={`main-navi`}>
                        <Container className={`d-flex justify-content-between`}>
                            <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                                <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                <span>{this.props.t('Personal Area')}</span>
                            </NavLink>
                        </Container>
                    </MainNav>
                </>
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(withTranslation()(SizContainer));