import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function FeedbackMessage(props) {
    const { t } = useTranslation();
    const date = new Date(props.create_dt.slice(0, -6));
    const create_dt = props.create_dt ? `${date.toLocaleString()}` : t('Sending');
    const isAdmin = !props.author_employee ? true : false;
    const classes = isAdmin ? '' : 'justify-content-end';
    const user_title = props.user_title;
    const theme = useSelector(store => store.appState.theme_name);
    const check_color = !props.status
        ? ''
        : theme === 'default_theme'
            ? '#1E874A'
            : '#ed7817';

    return <div className={`d-flex ${classes}`}>
            <div className="FeedbackMessage  mb-4">
                <div className="FeedbackMessage-Text">
                    {props.text}
                </div>
                <div className="FeedbackMessage-Date">
                    <>{create_dt} | {isAdmin && user_title && user_title} {!isAdmin && <FontAwesomeIcon icon={props.create_dt ? faCheckDouble : faCheck} color={check_color} />}</>
                </div>
            </div>
           </div>
}