import store from "../localStorage";
import configApp from "../config/config";

const initialState = {
    locale: 'ru',
    languages: [{
        id: 'ru',
        code: 'ru_RU',
        title: 'Russian',
        icon: `${configApp.app_dir}assets/img/lang/ru.svg`
    },
    {
        id: 'en',
        code: 'en_US',
        title: 'English',
        icon: `${configApp.app_dir}assets/img/lang/en.svg`
    },
    {
        id: 'kk',
        code: 'kk_KZ',
        title: 'Қазақ',
        icon: `${configApp.app_dir}assets/img/lang/kk.svg`
    },
    {
        id: 'ky',
        code: 'ky_KY',
        title: 'Кыргыз',
        icon: `${configApp.app_dir}assets/img/lang/ky.svg`
    }],
}

const SET_LOCALE = 'SET_LOCALE';
const SET_LANGUAGES = 'SET_LANGUAGES';

export default function localeReduser(state = initialState, action) {
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            }
        default:
            return state;
    }
}

/**
 * TODO: Вынеси в отдельный файл-класс locale-manager
 * @param locale
 * @returns {{type: string, locale}}
 */
const typeSetLocale = (locale) => ({type: SET_LOCALE, locale: locale,});
const typeSetLanguages = (languages) => ({type: SET_LANGUAGES, languages: languages});

export const setLocaleState = (locale) => {
    document.querySelector('html').lang = locale;
    store.dispatch(typeSetLocale(locale));
}
export const setLanguages = (languages) => store.dispatch(typeSetLanguages(languages));