import isCordova from "is-cordova";

const {isElectron} = require('react-device-detect');

class ConfigApp {
    constructor(config) {
        for (let [key, value] of Object.entries(config)) {
            this[key] = value;
        }
    }
}

let settings = {};

if (isElectron) {
    try {
        const electron = require('electron');
        settings = electron.ipcRenderer.sendSync('get_conf');
        if (!settings.device_settings.use_terminal_name) {
            settings.device_settings.name = settings.device_settings.appName;
        };
    } catch (e) {
        console.error(e);
    }
} else if (isCordova) {
    appSettings.app_dir = './';
    let device_settings = {
        version: process.env.REACT_APP_VERSION,
        name: device.model,
        ip: '192.168.1.1',
        platform: 'cordova',
    }

    appSettings.device_settings = {...device_settings, ...appSettings.device_settings};

    settings = appSettings;
} else {
    appSettings.app_dir = './';
    let device_settings = {
        version: process.env.REACT_APP_VERSION,
        name: appSettings.device_settings?.appName ? appSettings.device_settings.appName : 'browser',
        ip: '192.168.1.1',
        platform: 'browser',
    }

    appSettings.device_settings = {...device_settings, ...appSettings.device_settings};

    settings = appSettings;
}

let configApp = new ConfigApp(settings);

export default configApp;
