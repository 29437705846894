import store from "../localStorage";

const ADD_BREAMB = 'ADD_BREAMB';
const RESET_BREAMB = 'RESET_BREAMB';
const GO_BACK_ONE_STEP = 'GO_BACK_ONE_STEP';

const add = (breamb) => ({type: ADD_BREAMB, breamb: breamb});
const reset = () => ({type: RESET_BREAMB});
const backStep = () => ({type: GO_BACK_ONE_STEP});

export const addBreamb = (breamb) => store.dispatch(add(breamb));
export const resetBreamb = () => store.dispatch(reset());
export const goBackStep = () => store.dispatch(backStep());