import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faForward} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Prolog from "../../screens/questions/Prolog";

class PrologContainer extends Component {
    constructor(props) {
        super(props);
        this.timeoutID = () => {};
    }

    componentDidMount() {
        setTimeout(() => {
            let nodeVideo = document.querySelector('#prolog_video');
            nodeVideo.autoplay = 'autoplay';
            nodeVideo.addEventListener('ended', () => {
                nodeVideo.pause();
                this.props.toQuestions();
                this.props.renderAdditionalButton(null);
            });
        }, 1);

        if (this.props.question.prolog === null) {
            this.timeoutID = setTimeout(() => {
                this.props.toQuestions();
                this.props.renderAdditionalButton(null);
            }, 10000);
        }

        if (this.props.can_skip) {
            this.props.renderAdditionalButton(
                <div className="d-flex w-100 justify-content-end">
                    <Button className="MuiButton Btn Btn_success mr-4" onClick={this.props.handleNextStep}>
                        <FontAwesomeIcon className="mr-4" icon={faForward}/>
                        {this.props.t('Skip')}
                    </Button>
                    <Button className="MuiButton Btn Btn_success" onClick={this.props.toQuestions}>
                        <FontAwesomeIcon className="mr-4" icon={faForward}/>
                        {this.props.t('Proceed')}
                    </Button>
                </div>);
        } else {
            this.props.renderAdditionalButton(
                <Button className="MuiButton Btn Btn_success" onClick={this.props.toQuestions}>
                    <FontAwesomeIcon className="mr-4" icon={faForward}/>
                    {this.props.t('Proceed')}
                </Button>);
        }
    }

    componentWillUnmount = () => clearTimeout(this.timeoutID);

    render() {
        return <Prolog {...this.props} {...this.state}/>
    }
}


export default withTranslation()(PrologContainer);