import React, {Component} from "react";
import {connect} from "react-redux";
import {Container} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import apiEmployee from "../../api/apiEmployee";
import { errorNotify } from "../../helpers/functions";
import {pageLoadManageActions as loader} from "../../reducers/load";
import {addBreamb, resetBreamb} from "../../reducers/breams-manage";
import NoticeList from "../screens/notices/NoticeList";
import Notices from "../screens/notices/Notices";
import apiHandling from "../../api/apiHandling";
import {BACKEND_HOST} from "../../api/api-config";
import Loader from "../blocks/Loader";
import Breams from "../blocks/Breams";
import MainNav from "../blocks/MainNav";

class NoticeListContainer extends Component {
    constructor(props) {
        super(props);
        this.getNotices();
        this.state = {
            notifications: [],
            colorActions: {
                put_aside: 'warning',
                close: 'secondary',
                set_read: 'primary',
                agree: 'success',
                refused: 'danger',
            },
            sort_by_date: "Descending order",
            selected_types: []
        }

        document.title = props.t('Notices');
        resetBreamb();
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Notices')});
    }

    navLink = (notice_uuid) => this.props.history.push(`/notice-list/${notice_uuid}`);

    getNotices = () => {
        loader.start('notice-list');
        let user_uuid = this.props.user.data.uuid;
        apiEmployee.getNotifications(user_uuid)
        .then(response => this.setState({notifications: response.data.notifications}))
        .catch(e => errorNotify(this.props.t('Error loading notifications'), e, this.props.history.push, this.props.user.isAuth))
        .finally(() => loader.stop('notice-list'))
    }

    onSelectAction = (action_id) => {
        let notice = this.getCurrentNotify();
        notice.actions[action_id].callbacks.forEach(async (callback_link) => {
            try {
                await apiHandling.reqByLink(BACKEND_HOST + callback_link, 'post');
            } catch (e) {
                const info = `${this.props.t('Function execution error')} "${notice.actions[action_id].title}"`;
                errorNotify(info, e, this.props.history.push, this.props.user.isAuth, `/notice-list`);
            }
        });
        this.props.history.push(`/notice-list`);
        this.getNotices();
    }

    getCurrentNotify = () => {
        let notice = {};
        for (let i = 0; i < this.state.notifications.length; i++) {
            if (this.state.notifications[i].uuid === this.props.match.params.id) {
                notice = this.state.notifications[i];
                break;
            }
        }
        return notice;
    }

    onSelectSortByDate = (sort) => this.setState({sort_by_date: sort});

    onSelectTypes = (event) => {
        let value = event.target.id;
        if (this.state.selected_types.includes(value)) {
            let new_types = this.state.selected_types.filter(el => el !== value);
            this.setState({selected_types: new_types});
        } else {
            this.setState({selected_types: [...this.state.selected_types, value]});
        }
    }

    render() {
        let content = null;
        if (this.props.match.params.id && this.state.notifications.length > 0) {
            let notice = this.getCurrentNotify();
            if (notice) {
                content = <>
                            <Breams/>
                            <Loader isLoading={this.props.load.isLoading} loadId={"notice-list"} justifyContent="center">
                                <Notices onSelectAction={this.onSelectAction} {...this.state} {...this.props} notice={notice}/>
                            </Loader>
                            <MainNav>
                                <Container className={`d-flex justify-content-between`}>
                                    <NavLink to={"/notice-list"} className={`Btn Btn_boring MuiButton`}>
                                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                                        <span>{this.props.t('Back')}</span>
                                    </NavLink>
                                </Container>
                            </MainNav>
                        </>;
            } else {
                content = <NoticeList navLink={this.navLink} {...this.props} {...this.state}
                            onSelectSortByDate={this.onSelectSortByDate}
                            onSelectTypes={this.onSelectTypes} />;
            }
        } else {
            content = <NoticeList navLink={this.navLink} {...this.props} {...this.state}
                        onSelectSortByDate={this.onSelectSortByDate}
                        onSelectTypes={this.onSelectTypes} />;
        }
        return content;
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withTranslation()(NoticeListContainer));