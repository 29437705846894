import configApp from "../config/config";

const initialState = {
    initialized: false,
    errors: null,
    not_fatal_errors: [],
    theme_name: 'default_theme',
    logo: `${configApp.app_dir}assets/img/logo.png`,
    logo_negative: `${configApp.app_dir}assets/img/logo.png`,
    favicon: `${configApp.app_dir}favicon.ico`,
    modules: [],
    isElectronStartAppError: false,
    isRfidWork: true,
    isCanUpdate: false,
    update_status: null, // null | 'update' | 'error'
}

export const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';
export const FATAL_ERROR = 'FATAL_ERROR';
export const NOT_FATAL_ERROR = 'NOT_FATAL_ERROR';
export const DEFAULT_STATE_APP = 'DEFAULT_STATE_APP';
export const SET_THEME = 'SET_THEME';
export const SET_LOGO = 'SET_LOGO';
export const SET_LOGO_NEGATIVE = 'SET_LOGO_NEGATIVE';
export const SET_FAVICON = 'SET_FAVICON';
export const SET_MODULES = 'SET_MODULES';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const SET_ELECTRON_START_APP_ERROR = 'SET_ELECTRON_START_APP_ERROR';
export const DELETE_ELECTRON_START_APP_ERROR = 'DELETE_ELECTRON_START_APP_ERROR';
export const RFID_WORK = 'RFID_WORK';
export const IS_CAN_UPDATE = 'IS_CAN_UPDATE';
export const UPDATE_STATUS = 'UPDATE_STATUS';

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZED_SUCCESS:
            return {
                ...state,
                initialized: true
            };
        case FATAL_ERROR:
            return {
                ...state,
                initialized: true,
                errors: action.errors
            };
        case NOT_FATAL_ERROR:
            return {
                ...state,
                initialized: true,
                not_fatal_errors: action.errors
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                errors: null,
                not_fatal_errors: []
            };
        case SET_THEME:
            return {
                ...state,
                theme_name: action.theme_name,
            };
        case SET_LOGO:
            return {
                ...state,
                logo: action.logo,
            };
        case SET_FAVICON:
            return {
                ...state,
                favicon: action.favicon,
            };
        case SET_MODULES:
            return {
                ...state,
                modules: action.modules,
            }
        case SET_LOGO_NEGATIVE:
            return {
                ...state,
                logo_negative: action.logo_negative,
            };
        case SAVE_SETTINGS:
            return {
                ...state,
                ...action.settings,
            }
        case SET_ELECTRON_START_APP_ERROR:
            return {
                ...state,
                isElectronStartAppError: true,
            }
        case DELETE_ELECTRON_START_APP_ERROR:
            return {
                ...state,
                isElectronStartAppError: false,
            }
        case RFID_WORK:
            return {
                ...state,
                isRfidWork: action.isRfidWork,
            }
        case IS_CAN_UPDATE:
            return {
                ...state,
                isCanUpdate: action.isCanUpdate,
            }
        case UPDATE_STATUS:
            return {
                ...state,
                update_status: action.update_status,
            }
        case DEFAULT_STATE_APP:
            return {
                ...initialState,
                theme_name: state.theme_name,
                logo: state.logo,
                logo_negative: state.logo_negative,
                favicon: state.favicon
            };
        default:
            return state;
    }
}