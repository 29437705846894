import axios from 'axios'
import apiHandling from './apiHandling'
import { settingsSource } from '../helpers/source'

class apiApp extends apiHandling {
    constructor() {
        super();
    }

    static getSettings = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source()
        settingsSource.setSource(source)

        return this.req({
            method: 'get',
            action: 'settings',
            cancelToken: source.token,
        });
    }
}

export default apiApp;
