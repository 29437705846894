import React, {Component} from 'react';
import {connect} from "react-redux";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import Breathalyze from "../../../screens/questions/flow/Breathalyze";
import moduleAlcotester from "../../../../externalHardware/moduleAlcotester/moduleAlcotester";
import configApp from "../../../../config/config";
import { setLog } from '../../../../helpers/functions';
import preshiftManage from "../../../../reducers/preshift-manager";

let settingsBreathalyze = {...configApp.device_settings.breathalyzer};
const ALCO_FAIL = 'ALCO_FAIL';

class BreathalyzeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            content: Breathalyze,
            event_dt: new Date(),
            can_skip: false,
            devMode: configApp.device_settings?.devMode ? true : false,
        };
        this.moduleAlcotester = new moduleAlcotester(settingsBreathalyze, (status) => this.handleChangeStatus(status));

        this.moduleAlcotester.start()
        .then(result => this.handleResult(result))
        .catch(e => this.handleError(e));
    }

    skipListener = (event) => {
        if (event.ctrlKey && event.key === 'm') {
            this.props.handleNextStep()
        }
    };

    componentDidMount() {
        this.notReadyTimer = setTimeout(()=> {
            const error = {err: 'Ошибка запуска алкотестера'};
            this.handleError(error);
        }, 300000);
    }

    componentDidUpdate() {
        if (this.state.status.includes('STANBY')) document.addEventListener('keydown', this.skipListener);
        if ((this.state.can_skip || this.state.devMode) && this.state.status.includes('STANBY')) {
            if (this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip || this.state.devMode) {
                this.props.renderAdditionalButton(
                    <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                        <FontAwesomeIcon className="mr-4" icon={faForward}/>
                        {this.props.t('Skip')}
                    </Button>);
            } else {
                this.props.renderAdditionalButton(null);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.status.includes('STANBY')) this.moduleAlcotester.reset().catch(console.log);
        clearTimeout(this.notReadyTimer);
        clearTimeout(this.isReadyTimer);
        document.removeEventListener('keydown', this.skipListener);
    }
    

    handleChangeStatus = (status) => {
        if (status.includes('STANBY')) {
            this.setState({status: status, can_skip: true});
            clearTimeout(this.notReadyTimer);
            this.isReadyTimer = setTimeout(()=> {
                this.props.history.push('/');
            }, 20000);
        } else if (status.includes('END')) {
            this.moduleAlcotester.close();
        } else {
            this.setState({status: status});
        }
    }

    handleResult = (result) => {
        let statePreshift = this.props.preshift;
        let datumUhrzeit = new Date(),
            event_end_dt = "@" + Math.round(datumUhrzeit.getTime() / 1000),
            stepUuid = this.props.stepData.uuid,
            stepName = this.props.stepData.name,
            start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
            data = {
                uuid: stepUuid,
                name: stepName,
                start_date_time: start_time,
                elapsed_time: event_end_dt,
                value: result
            },
            flow_history = statePreshift.flow.history.steps;
            flow_history.push(data);

        preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
        this.props.handleNextStep();
    }

    handleError = (error) => {
        error.typeError = ALCO_FAIL;
        setLog('Ошибка алкотестера', error);
        this.handleResult(null);
    }

    render = () => {
        let Content = this.state.content;

        return <Content {...this.props} {...this.state}/>;
    }
}

const mapStateToProps = (store) => {
    return {
        preshift: store.preshiftState,
    }
};

export default connect(mapStateToProps)(BreathalyzeContainer);