import React, {Component} from 'react';
import {isElectron} from "react-device-detect";
import {withRouter} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import $ from 'jquery';
import {connect} from 'react-redux';
import Ldap from "../screens/startup/Ldap";
import withFormHanlding from "../../hoc/FormHandling";
import modalManage from "../../reducers/modal-manage";
import apiEmployee from "../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../reducers/load";
import configApp from "../../config/config";
import {listenerRfid} from "../../index";
import userManage from "../../reducers/user-manage";
import { reloadElectron } from '../../helpers/functions';

class LdapContainer extends Component {
    constructor(props) {
        super(props);
        userManage.logout();
        loader.stop('item');

        this.state = {
            rfidWord: '',
            employes: [],
            to_link: "/cabinet",
        };

        let formState = {
            formErrors: {
                login: [],
                password: [],
            },
            formValues: {login: '', password: ''},
            formValid: false,
            onSubmit: this.handleSubmit
        };
        props.prepareFormState(formState);

        this.initRfid();
    }

    handleKeyUp = (e) => this.setState({rfidWord: this.state.rfidWord += e.key});

    componentDidMount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.addEventListener('keyup', this.handleKeyUp);
        }
    };

    componentWillUnmount() {
        if (isElectron &&
        this.props.app.is_rfid &&
        configApp.device_settings.rfid.isHid) {
            document.body.removeEventListener('keyup', this.handleKeyUp);
        }
    };

    onInputTabelNum = (event) => this.onChange(event.target.value);

    onInputLogin = () => {
        let login = document.querySelector('[name=login]');
        this.onChange(login);
    }

    onInputPassword = () => {
        let password = document.querySelector('[name=password]');
        this.onChange(password);
    }

    onChange = (input) => this.props.validateField(input);

    handleClearStateEmployes = () => this.setState({employes: []});

    /**
     * @param event
     * @returns {boolean}
     */
    routeGuider = (event) => {
        event.preventDefault();
        event.persist();

        this.setState({
            to_link: event.target.getAttribute('linkto'),
        }, () => this.handleSubmit());

        return false;
    }

    handleError = (e) => {
        const errors = e.hasOwnProperty('message') ? [e.message] : [e.error];
        this.props.prepareFormState({
            formValues: {...this.props.formValues, login: '', password: ''},
            formErrors: {...this.props.formErrors, error: errors},
            formValid: false
        });
    };

    auth = (id) => {
        return new Promise((resolve, reject) => {
            apiEmployee.auth(id)
            .then(response => {
                userManage.authorized(response.data[0])
                .then(isAuth => {
                    if (isAuth) {
                        userManage.pinCodeAuthorized();
                        this.props.history.push(this.state.to_link);
                    }
                    userManage.setIntervalGetExtData();
                    resolve(isAuth);
                });
            })
            .catch(e => this.handleError(e));
        });
    }

    authByRfid = (result) => {
        return new Promise((resolve, reject) => {
            loader.start('item');
            let to_link = this.state.to_link;

            apiEmployee.authRfid(result)
            .then(response => {
                userManage.authorized(response.data[0])
                .then(isAuth => {
                    if (isAuth) {
                        userManage.pinCodeAuthorized();
                        this.props.history.push(to_link);
                    }
                    userManage.setIntervalGetExtData();
                    resolve(isAuth);
                });
            })
            .catch(e => {
                setTimeout(this.initRfid, 1000);
                this.handleError(e);
            })
            .finally(() => loader.stop('item'));
        });
    }

    initRfid = () => {
        if (isElectron && !configApp.server_settings) {
            reloadElectron();
        } else if (isElectron &&
                configApp.server_settings.is_rfid &&
                !configApp.device_settings.rfid.isHid) {
            listenerRfid.start()
            .then(result => this.authByRfid(result))
            .catch(()=>{});
        }
    }

    /**
     * @param formValues
     * @returns {Promise<unknown>}
     */
    handleSubmit = () => {
        return new Promise((resolve, reject) => {
            loader.start('item');
            let login = this.props.formValues.login;
            let password = this.props.formValues.password;
            apiEmployee.authLdap(login, password)
            .then(response => {
                if (response.data.length > 1) {
                    response.data.forEach(emp => this.state.employes.push(emp));
                    loader.stop('item');
                    modalManage.show('selectEmployees');
                } else if (response.data.length === 1) {
                    this.auth(response.data[0].uuid)
                    .then(response => resolve(response))
                    .catch(()=>{})
                    .finally(() => loader.stop('item'));
                }
                resolve(response);
            })
            .catch(e => {
                this.handleError(e);
                loader.stop('item');
            });
        });
    }

    componentWillUpdate(nextProps, nextState) {
        // TODO: fix double keyboards
        let keyboards = $('.startup__keyboard .hg-rows');
        if (keyboards.length > 1) keyboards.last().remove();
        if (isElectron && nextState.rfidWord.toLowerCase().includes('enter')) {
            const word = nextState.rfidWord.slice(0, -5);
            if (word) this.authByRfid(word);
            this.setState({rfidWord: ''});
        }
    }

    render() {
        return <Ldap {...this.props} {...this.state}
                routeGuider={this.routeGuider}
                onInputLogin={this.onInputLogin}
                onInputPassword={this.onInputPassword}
                errorClass={this.props.errorClass}
                handleClearStateEmployes={this.handleClearStateEmployes} />
    }
}

const configHocForm = {};

const mapStateToProps = (store) => {
    return {
        app: store.appState,
        load: store.loadState
    };
};

/**
 * @param message
 * @constructor
 */
function NotHasTabNum(message) {
    this.message = message;
    this.name = 'Not has tab num';
}

export default withRouter(connect(mapStateToProps)(withTranslation()(withFormHanlding(LdapContainer, configHocForm))));