import React, {Component} from "react";
import { isElectron } from "react-device-detect";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Image} from "react-bootstrap";
import withFormHanlding from "../../../hoc/FormHandling";
import FeedbackDialog from "../../screens/feedback/FeedbackDialog";
import apiFeedback from "../../../api/apiFeedback";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import { errorNotify, isTrueProp, setLog } from "../../../helpers/functions";
import configApp from "../../../config/config";
import modalManage from "../../../reducers/modal-manage";
import Modal from "../../modals/Modal";
import BootstrapModal from "../../modals/BootstrapModal";
import { notify } from "../../blocks/Notify";

class FeedbackDialogContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localeKeyboard: props.locale.locale,
      keyboardConfig: {
        layoutName: props.locale.locale,
        layout: {
          'ru_lower': [
            'ё й ц у к е н г ш щ з х ъ {backspace}',
            '{lock} ф ы в а п р о л д ж э -',
            '{lang} {numbers} я ч с м и т ь б ю',
            '{space}'
        ],
        'ru': [
            'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
            '{lock} Ф Ы В А П Р О Л Д Ж Э -',
            '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
            '{space}'
        ],
        'kk': [
            'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
            '{lock} Ф Ы В А П Р О Л Д Ж Э -',
            '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
            '{space}'
        ],
        'kk_lower': [
            'ё й ц у к е н г ш щ з х ъ {backspace}',
            '{lock} ф ы в а п р о л д ж э -',
            '{lang} {numbers} я ч с м и т ь б ю',
            '{space}'
        ],
        'en': [
            'Q W E R T Y U I O P {backspace}',
            '{lock} A S D F G H J K L -',
            '{lang} {numbers} Z X C V B N M',
            '{space}'
        ],
        'en_lower': [
            'q w e r t y u i o p {backspace}',
            '{lock} a s d f g h j k l -',
            '{lang} {numbers} z x c v b n m',
            '{space}'
        ],
        'ky_lower': [
          'ё й ц у к е н г ш щ з х ъ ө {backspace}',
          '{lock} ф ы в а п р о л д ж э ү -',
          '{lang} {numbers} я ч с м и т ь б ю ң',
          '{space}'
        ],
        'ky': [
            'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ Ө {backspace}',
            '{lock} Ф Ы В А П Р О Л Д Ж Э Ү -',
            '{lang} {numbers} Я Ч С М И Т Ь Б Ю Ң',
            '{space}'
        ],
        'symbols': [
            '[ ] { } # % ^ * - + =',
            '_ \/ | ~ < > $ € •',
            '{lang} {abc} {numbers} . , ? ! \'',
            '{space}'
        ],
        'numbers': [
            '1 2 3 4 5 6 7 8 9 0',
            '- \/ : ; ( ) ₽ & @ "',
            '{lang} {abc} {symbols} . , ? ! \'',
            '{space}'
        ]
        },
        display: {
          "{backspace}": '<',
          "{space}": props.t('Space'),
          "{lock}": "A->a", // String.fromCharCode(55358, 56389), //"&#129093", // 🡅,
          "{lang}": props.locale.locale.toUpperCase(),
          "{symbols}": '#+=',
          "{abc}": props.t('ABC'),
          "{numbers}": '123'
      },
        theme: "theme_cot",
        onChange: this.onPressKey,
        onKeyPress: this.onKeyPress,
        keyboardRef: (r) => (this.keyboard = r),
      },
      messages: {},
      input: "",
    };

    resetBreamb();
  }

  componentDidMount() {
    addBreamb({ title: this.props.t("Personal Area") });
    addBreamb({ title: this.props.t("Feedback") });
    loader.start("content");
    this.getDialog(this.props.match.params.id)
    .then(response => this.setMarkRead())
    .finally(() => loader.stop("content"));
    this.onInputMessage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.locale.locale !== this.props.locale.locale) this.setState({keyboardConfig: {...this.state.keyboardConfig, layoutName: this.props.locale.locale }});
  }

  getDialog = (uuid) => {
    return new Promise((resolve, reject) => {
      apiFeedback.getDialog(uuid)
        .then(response => this.setState({ ...response.data }, () => resolve(response)))
        .catch(e => {
          errorNotify(this.props.t('Dialog loading error'), e, this.props.history.push, this.props.user.isAuth, '/feedback');
          loader.stop("content");
          loader.stop("sendMassage");
        });
    });
  };

  onInputMessage = () => {
    let message = document.querySelector(".MessageField");
    this.setState({ input: message.value });
    this.hasOwnProperty("keyboard") && this.keyboard.setInput(message.value);
    this.onChange(message);
  };

  onClear = (event) => {
    let message = document.querySelector(".MessageField");
    message.value = "";
    this.props.validateField(message);
    if (isTrueProp(configApp.device_settings, "keyboard") || isElectron) this.keyboard.clearInput();
  };

  handleSubmit = () => {
      return new Promise((resolve, reject) => {
        loader.start("sendMassage");
        let employee_id = this.props.user.data.id,
            text_message = this.props.formValues.message,
            dialog_uuid = this.props.match.params.id;
        const new_message = {
          isSending: true,
          text: text_message,
          author_employee: employee_id,
          create_dt: '',
          author_user: null,
          dialog: dialog_uuid,
          user_title: null,
          uuid: "isSending"
        };
        this.setState({messages: {...this.state.messages, new_message}});
        apiFeedback.sendMessage(employee_id, text_message, dialog_uuid)
          .then(response => {
            this.getDialog(dialog_uuid)
              .then(response => {
                this.setMarkRead();
                resolve(response);
              });
          })
          .catch(e => errorNotify(this.props.t('Error sending the message'), e, this.props.history.push, this.props.user.isAuth, '/feedback'))
          .finally(() => loader.stop("sendMassage"));
          this.onClear();
      });
  };

  onChange = (input) => this.props.validateField(input);

  onPressKey = (input) => {
    let message = document.querySelector(".MessageField");
    message.value = input;
    this.props.validateField(message);
  };

  onKeyPress = (button) => {
    let keyboardConfig = this.state.keyboardConfig;
    if (button === "{lock}") {
        keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard ? `${this.state.localeKeyboard}_lower` : this.state.localeKeyboard;
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{lang}") {
        modalManage.show('selectLangKeyboard');
    } else if (button === "{symbols}") {
        keyboardConfig.layoutName = 'symbols';
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{abc}") {
        keyboardConfig.layoutName = this.state.localeKeyboard;
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{numbers}") {
        keyboardConfig.layoutName = 'numbers';
        this.setState({keyboardConfig: keyboardConfig});
    }
  };

  handleSelectLang = (lang) => {
    let keyboardConfig = this.state.keyboardConfig;
    keyboardConfig.display = {...keyboardConfig.display, "{lang}": lang.toUpperCase()}
    keyboardConfig.layoutName = lang;
    this.setState({localeKeyboard: lang, keyboardConfig: keyboardConfig});
    modalManage.close('selectLangKeyboard');
  };

  setMarkRead = () => {
    for (let uuid_message in this.state.messages) {
      if (this.state.messages[uuid_message].author_user) apiFeedback.setMarkRead(uuid_message);
    }
  };

  render() {
    return <>
            <FeedbackDialog {...this.state} {...this.props}
              handleSubmit={this.handleSubmit}
              onClear={this.onClear}
              onClearName={this.onClearName}
              onInputMessage={this.onInputMessage}
              keyboard={this.keyboard} />
            <Modal>
              <BootstrapModal modalId="selectLangKeyboard" dialogClassName={"startup__modal-lang"}btns={[]}>
                <div className="ModalLang list-group d-flex flex-row justify-content-between">
                  {this.props.locale.languages && this.props.locale.languages.map(lang =>
                    <button key={lang.id} className="ModalLang-Btn d-flex flex-column text-center" onClick={() => {this.handleSelectLang(lang.id)}}>
                      <Image width="64" className="mx-auto mb-4" src={lang.icon}/>
                      <span className="display-6 text-uppercase font-weight-bold">{lang.title}</span>
                    </button>)}
                </div>
              </BootstrapModal>
            </Modal>
           </>
  };
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        locale: store.localeState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withTranslation()(withFormHanlding(FeedbackDialogContainer, {})));