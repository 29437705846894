import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import allReducers from "./reducers";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('ComplexAppStorage');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('ComplexAppStorage', serializedState);
    } catch (err) {
        // Игнорируем
    }
};
const middleware = [thunk];
const composeEnhancers = composeWithDevTools({thunk});
const persistedState = loadState();
const store = createStore(
    allReducers,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => saveState(store.getState()));

export default store;