import React from 'react';
import Content from '../../../blocks/Content';
import ListContainer from '../../../containers/ListContainer';
import QuestionnaireItem from './QuestionnaireItem';

export default React.memo(function QuestionnaireList(props) {
    return (
        <div className='Screen container d-flex flex-column justify-content-center align-items-center'>
            <div className='w-100'>
                <Content>
                    {props.surveys.length > 0
                        ? <ListContainer
                            listItems={props.surveys}
                            templateItem={QuestionnaireItem}
                            t={props.t}
                            className='h-100'
                            getOneSurveys={props.getOneSurveys} />
                        : <div className='d-flex align-items-center flex-column justify-content-center h-100'>
                            <h4 className='preshift__text text-center Text_success'>
                                {props.t('No surveys')}
                            </h4>
                        </div>}
                </Content>
            </div>
        </div>
    )
});