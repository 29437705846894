import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import SimpleQuestContainer from "../SimpleQuestContainer";
import HighQuestContainer from "../HighQuestContainer";
import store from "../../../../localStorage";

class QuestionContainer extends Component {
    inititialState = {
        question: null,
        answers: [],
        content: SimpleQuestContainer
    }

    constructor(props) {
        super(props);
        this.state = {...this.inititialState};
        store.dispatch({type: 'PAGE_LOADED', loadId: 'item'});
    }

    componentDidMount() {
        this.getQuestion();
    }

    getQuestion = () => {
        return new Promise((resolve, reject) => {
            let data = this.props.stepData ? this.props.stepData.data : null;
            if (data?.question?.type === "combine-video") {
                this.setState({
                    question: data.question,
                    content: HighQuestContainer},
                    () => resolve());
            } else if (data?.question && data?.answers) {
                this.setState({
                    question: data.question,
                    answers: data.answers,
                    content: SimpleQuestContainer},
                    () => resolve());
            };
            data && store.dispatch({type: 'SAVE_STATE_PRESHIFT', ...data});
        })
    }

    render() {
        let Content = this.state.content;
        return <Content {...this.state} {...this.props} />
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        preshift: store.preshiftState,
        theme_name: store.appState.theme_name,
    }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(QuestionContainer)));