import React from 'react';
import {connect} from "react-redux";
import {Nav} from "react-bootstrap";

function MainNav(props) {
    return <Nav className={`Panel py-3 ${props?.hasOwnProperty('className') ? props.className : ''}`}>
            {props.children}
           </Nav>
    }

const mapStateToProps = (store) => {
    return {};
};

export default connect(mapStateToProps)(MainNav);