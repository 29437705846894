import React from 'react';
import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faArrowLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import MainNav from "../../blocks/MainNav";
import Breams from "../../blocks/Breams";
import Content from "../../blocks/Content";
import FeedbackItem from "../../blocks/Feedback/FeedbackItem/FeedbackItem";
import ListContainer from "../../containers/ListContainer";
import Loader from "../../blocks/Loader";
import Select from '../../blocks/Select';

export default function FeedbackList(props) {
    document.title = props.t('Feedback');
    const themes = Object.keys(props.themes);
    const keys = ['All', ...themes];
    const filter = props.filter_dialogs;
    const isLoading = props.load.isLoading;
    const dialogs = props.dialogs;
    const show_filter = (!isLoading && dialogs.length > 0) ? true : false;
    let letDialogs = dialogs;
    let ListDialogs = null;

    if (props.themes[filter]) letDialogs = dialogs.filter(el => el.theme_uuid === filter);
    
    if (letDialogs.length > 0) {
        ListDialogs = <ListContainer listItems={letDialogs} templateItem={FeedbackItem} t={props.t} className="h-100"/>;
    } else {
        ListDialogs = <h4 className="Text_success font-weight-medium display-8 text-center">{props.t('You have no dialogues')}. {props.t('You can start a new one')}.</h4>;
    };

    return <>
            <Breams/>
            <Container className={`Screen-Panel pt-4 pl-4 pr-4 d-flex justify-content-${show_filter ? 'between' : 'end'}`}>
                {show_filter &&
                    <Select onSelectCallback={props.onSelectFilter} className="FeedbackAdd-SelectType max-content flex-column w-50"
                            name="theme_id" >
                        {keys.map(key => {
                            const text = props.themes[key] ? props.themes[key] : props.t(key);
                            return <option value={key} key={key}>{text}</option>
                        })}
                    </Select>}
                <NavLink to={'/feedback/add'} className="Btn Btn_success MuiButton">
                    <FontAwesomeIcon className="mr-4" icon={faComment}/>
                    {props.t('New message')}
                </NavLink>
            </Container>
            <div className="Screen feedback container">
                <Content>
                    <Loader isLoading={isLoading} loadId={"content_list"} justifyContent="center">
                        {ListDialogs}
                    </Loader>
                </Content>
            </div>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Personal Area')}</span>
                    </NavLink>
                    <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                        <span>{props.t('Exit')}</span>
                    </NavLink>
                </Container>
            </MainNav>
        </>
};