import React from 'react';
import {Container, Button} from "react-bootstrap";

export default function SetRfidCard(props) {
    return (
        <Container className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className={`d-flex flex-column display-3 font-weight-normal `}>
                <div className={`d-flex align-items-center justify-content-left mb-3`}>
                    <span className={"number_setRfid"}>1</span>
                    <span className={`${props.rfid_ready ? "text_setRfid" : ""}`}>{props.t('Click on record button')}</span>
                </div>
                <div className={`d-flex align-items-center justify-content-left mb-3`}>
                    <span className={"number_setRfid"}>2</span>
                    <span className={`${props.rfid ? "text_setRfid" : ""}`}>{props.t('Lean card against reader')}</span>
                </div>
                <div className={`d-flex align-items-center justify-content-left mb-5`}>
                    <span className={"number_setRfid"}>3</span>
                    <span className=''>{props.t('Click on save button')}</span>
                </div>
            </div>
            {!props.rfid_ready &&
                <Button onClick={props.handleStartRfid} className={`Btn Btn_success MuiButton mt-5`}>
                    {props.t('Write it down')}
                </Button>}
            {props.rfid_ready &&
                <div className="d-flex">
                    <Button onClick={props.handleStopRfid} className={`Btn Btn_danger MuiButton mt-5 mr-5`}>
                        {props.t('Cancel')}
                    </Button>
                    {props.rfid && 
                        <Button onClick={props.handleResult} className={`Btn Btn_success MuiButton mt-5 ml-5`}>
                            {props.t('Save')}
                        </Button>}
                </div>}
        </Container>
    )
};