import React, { useEffect, useState } from "react";

export default function ScreensaverParams(props) {
    const [clock, setClock] = useState(new Date().toLocaleString());
    const getDays = (date) => {
        const days = Math.trunc((new Date() - new Date(Date.parse(date))) / 60000 / 60 / 24);
        return days > 0 ? days : 0;
    };
    const params = props.params;
    const text_header = params.text_header.toUpperCase();
    const logo_url = params.logo_header;
    const date = clock.slice(0, 10);
    const time = clock.slice(12, 17);
    const injuryDays = getDays(params.date_last_injury);
    const incidentDays = getDays(params.date_last_incident);
    const injury_free_days = props.t("Injury-free days").toUpperCase();
    const incident_free_days = props.t("Days without incident").toUpperCase();
    let bgColor = "#F7A400"; //! в рамках cv2-917 для Восточного порта
    // if (props.theme_name === "default_theme") bgColor = "#1E874A";
    // if (props.theme_name === "evraz_theme") bgColor = "#ed7817";

    useEffect(() => {
        const interval = setInterval(() => setClock(new Date().toLocaleString()), 1000);
        return () => clearInterval(interval)
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <div className="d-flex justify-content-around align-items-center h-50 w-100 bg-white px-4">
                <h1 className="display-3 screensaver_text_line-clamp">{text_header}</h1>
                {logo_url && <img src={logo_url} alt={"logo"} className="mw-25 mh-100" />}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center h-20 w-100 text-white" style={{background: "black"}}>
                <h1 className="display-4 screensaver_text_line-clamp">{params.company_name}</h1>
            </div>
            <div className="d-flex justify-content-around align-items-center h-30 w-100 bg-white">
                <h1 className="display-3 font-weight-bold">{date}</h1>
                <h1 className="display-3 font-weight-bold">{time}</h1>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100" style={{background: bgColor}}>
                <div className="d-flex justify-content-between align-items-center h-100 w-50 text-white">
                    <h1 className="display-3 font-weight-bold">{injury_free_days}</h1>
                    <h1 className="display-1 font-weight-bold">{injuryDays}</h1>
                </div>
                <div className="d-flex justify-content-between align-items-center h-100 w-50 text-white">
                    <h1 className="display-3 font-weight-bold">{incident_free_days}</h1>
                    <h1 className="display-1 font-weight-bold">{incidentDays}</h1>
                </div>
            </div>
        </div>
    )
}