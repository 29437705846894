import React, {Component} from 'react';
import { isElectron } from 'react-device-detect';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Image} from "react-bootstrap";
import SearchEmp from "../screens/startup/SearchEmp";
import apiEmployee from "../../api/apiEmployee";
import {pageLoadManageActions as loader} from "../../reducers/load";
import withFormHanlding from "../../hoc/FormHandling";
import userManage from "../../reducers/user-manage";
import modalManage from "../../reducers/modal-manage";
import Modal from "../modals/Modal";
import BootstrapModal from "../modals/BootstrapModal";
import { errorNotify, isTrueProp } from '../../helpers/functions';
import configApp from '../../config/config';

class SearchEmpContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            localeKeyboard: props.locale.locale,
            keyboardConfig: {
                layoutName: props.locale.locale,
                layout: {
                    'ru_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'ru': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю',
                        '{space}'
                    ],
                    'kk_lower': [
                        'ё й ц у к е н г ш щ з х ъ {backspace}',
                        '{lock} ф ы в а п р о л д ж э -',
                        '{lang} {numbers} я ч с м и т ь б ю',
                        '{space}'
                    ],
                    'en': [
                        'Q W E R T Y U I O P {backspace}',
                        '{lock} A S D F G H J K L -',
                        '{lang} {numbers} Z X C V B N M',
                        '{space}'
                    ],
                    'en_lower': [
                        'q w e r t y u i o p {backspace}',
                        '{lock} a s d f g h j k l -',
                        '{lang} {numbers} z x c v b n m',
                        '{space}'
                    ],
                    'ky_lower': [
                        'ё й ц у к е н г ш щ з х ъ ө {backspace}',
                        '{lock} ф ы в а п р о л д ж э ү -',
                        '{lang} {numbers} я ч с м и т ь б ю ң',
                        '{space}'
                    ],
                    'ky': [
                        'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ Ө {backspace}',
                        '{lock} Ф Ы В А П Р О Л Д Ж Э Ү -',
                        '{lang} {numbers} Я Ч С М И Т Ь Б Ю Ң',
                        '{space}'
                    ],
                    'symbols': [
                        '[ ] { } # % ^ * - + =',
                        '_ \/ | ~ < > $ € •',
                        '{lang} {abc} {numbers} . , ? ! \'',
                        '{space}'
                    ],
                    'numbers': [
                        '1 2 3 4 5 6 7 8 9 0',
                        '- \/ : ; ( ) ₽ & @ "',
                        '{lang} {abc} {symbols} . , ? ! \'',
                        '{space}'
                    ]
                },
                display: {
                    "{backspace}": '<',
                    "{enter}": props.t('Search'),
                    "{space}": props.t('Space'),
                    "{lock}":  "A->a", // String.fromCharCode(55358, 56389), // "U+1F845", // "&#129093", // 🡅,
                    "{lang}": props.locale.locale.toUpperCase(),
                    "{symbols}": '#+=',
                    "{abc}": props.t('ABC'),
                    "{numbers}": '123'
                },
                theme: 'theme_cot theme_cot_search-emp',
                onChange: this.onChangeName,
                onKeyPress: this.onKeyPress,
                keyboardRef: r => (this.keyboard = r),
            },
            data: [],
            input: ""
        };

        let formState = {
            formErrors: {
                name: [],
            },
            formValues: {name: ''},
            formValid: false,
            onSubmit: this.onSubmit
        };

        props.prepareFormState(formState);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.locale.locale !== this.props.locale.locale)) {
            this.setState({
                keyboardConfig: {
                    ...this.state.keyboardConfig,
                    layoutName: this.props.locale.locale
                }
            });
        }
    }

    componentWillUnmount() {
        loader.stop('listEmployee');
    }

    onInputName = (e) => {
        if (isTrueProp(configApp.device_settings, 'keyboard')) {
            const input = e.target.value;
            this.setState({input: input});
            this.keyboard.setInput(input);
        }
        this.props.validateField(e.target);
    }

    onChangeName = (val) => {
        let searchName = document.querySelector('.search-emp__name-field');
        searchName.value = val;
        this.props.validateField(searchName);
    }

    onKeyPress = (button) => {
        let keyboardConfig = this.state.keyboardConfig;
        if (button === "{lock}") {
            keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard
                ? `${this.state.localeKeyboard}_lower`
                : this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{lang}") {
            modalManage.show('selectLangKeyboard');
        } else if (button === "{symbols}") {
            keyboardConfig.layoutName = 'symbols';
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{abc}") {
            keyboardConfig.layoutName = this.state.localeKeyboard;
            this.setState({keyboardConfig: keyboardConfig});
        } else if (button === "{numbers}") {
            keyboardConfig.layoutName = 'numbers';
            this.setState({keyboardConfig: keyboardConfig});
        }
    };

    handleSelectLang = (lang) => {
        let keyboardConfig = this.state.keyboardConfig;
        keyboardConfig.display = {...keyboardConfig.display, "{lang}": lang.toUpperCase()}
        keyboardConfig.layoutName = lang;
        this.setState({
            localeKeyboard: lang,
            keyboardConfig: keyboardConfig,
        });
        modalManage.close('selectLangKeyboard');
    }

    getEmployees = () => {
        let name = this.props.formValues.name;
        if (name.length > 0) {
            loader.start('listEmployee');
            apiEmployee.getListByName(name)
            .then(response => this.props.prepareFormState({data: response.data}))
            .catch(e => {
                let formErrors = this.props.formErrors;
                this.props.prepareFormState({
                    formErrors: {...formErrors, detail: e.message},
                    data: [],
                });
            }).finally(() => loader.stop('listEmployee'));
        } else {
            this.props.prepareFormState({data: []});
        }
    }

    onClearName = (event) => {
        this.props.prepareFormState({formValues: {name: ''}})
        .then(() => {
            this.onChangeName('');
            if (isTrueProp(configApp.device_settings, 'keyboard') || isElectron) this.keyboard.clearInput();
        });
    }

    onSubmit = () => {
        this.getEmployees();
        return false;
    }

    handleSelectEmployee = (employee_id) => {
        return new Promise((resolve, reject) => {
            loader.start('screen');
            apiEmployee.auth(employee_id)
            .then(response => {
                userManage.authorized(response.data[0])
                .then(isAuth => {
                    if (isAuth) this.props.history.push('/cabinet');
                    userManage.setIntervalGetExtData();
                    resolve()
                });
            })
            .catch(e => errorNotify(this.props.t('Authorization error by full name'), e, this.props.history.push, this.props.user.isAuth))
            .finally(() => loader.stop('screen'));
        });
    }

    render() {
        return <>
                <SearchEmp keyboardConfig={this.state.keyboardConfig}
                           onSubmit={this.onSubmit}
                           onClearName={this.onClearName}
                           onInputName={this.onInputName}
                           handleSelectEmployee={this.handleSelectEmployee}
                           keyboard={this.keyboard}
                           {...this.state} {...this.props} />
                <Modal>
                    <BootstrapModal modalId="selectLangKeyboard" dialogClassName={"startup__modal-lang"} btns={[]}>
                        <div className="ModalLang list-group d-flex flex-row justify-content-between">
                        {this.props.locale.languages &&
                            this.props.locale.languages.map(lang => 
                                <button key={lang.id} className="ModalLang-Btn d-flex flex-column text-center" onClick={() => this.handleSelectLang(lang.id)}>
                                    <Image width="64" className="mx-auto mb-4" src={lang.icon}/>
                                    <span className="display-6 text-uppercase font-weight-bold">{lang.title}</span>
                                </button>)}
                        </div>
                    </BootstrapModal>
                </Modal>
               </>
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        locale: store.localeState,
        user: store.userState,
    };
};

export default connect(mapStateToProps)(withRouter(withFormHanlding(withTranslation()(SearchEmpContainer))));