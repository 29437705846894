import React, {Component} from 'react';
import {isElectron} from "react-device-detect";
import {isValidHttpUrl} from "../helpers/functions";

const CAMERA_FAIL = 'CAMERA_FAIL';

export default function withCameraHandling(WrappedComponent) {
    class WithCameraHandling extends Component {
        constructor() {
            super();
        }

        /**
         * @param interface_camera
         * @returns {Promise<unknown>}
         */
        getScreenShotBase64 = (interface_camera) => {
            return new Promise((resolve, reject) => {
                let screenshot = '';

                if (interface_camera === 'internal') {
                    navigator.mediaDevices.enumerateDevices()
                    .then(response => {
                        let inputs = [];
                        response.forEach(el => inputs.push(el.kind));
                        if (inputs.includes("videoinput")) {
                            navigator.mediaDevices.getUserMedia({video: true, audio: false})
                            .then(async stream => {
                                const mediaStreamTrack = stream.getVideoTracks()[0];
                                const imageCapture = new ImageCapture(mediaStreamTrack);
                                await imageCapture.takePhoto()
                                .then(blob => {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(blob);
                                    reader.onloadend = () => {
                                        screenshot = reader.result;
                                        resolve(screenshot);
                                    };
                                })
                                .catch(e => reject(e))
                                .finally(() => {
                                    mediaStreamTrack.stop();
                                    stream.removeTrack(mediaStreamTrack);
                                });
                            })
                            .catch(e => reject(e));
                        } else {
                            reject({
                                typeError: CAMERA_FAIL,
                                error: 'Failed to take photo. Make sure the camera is connected to the com port and try again'
                            });
                        }
                    })
                    .catch(e => reject(e));
                } else if (interface_camera === 'overview') {
                    resolve('');
                } else if (isValidHttpUrl(interface_camera)) {
                    try {
                        const http = require("http");
                        http.get(interface_camera, (res) => {
                            let data = [];
                            res.on('data', (chunk) => data.push(chunk))
                            .on('end', () => {
                                screenshot = 'data:image/jpg;base64,' + Buffer.concat(data).toString('base64');
                                resolve(screenshot);
                            })
                            .on('error', error => {
                                throw new Error(error);
                            });
                        });
                    } catch (error) {

                    }
                } else {
                    let error = new UnknownInterfaceCamera('Unknown interface camera');

                    if (isElectron) {
                        try {
                            const logger = require('electron-log');
                            logger.error({error: error});
                        } catch (e) {

                        }
                    }
                    error.typeError = CAMERA_FAIL;
                    reject(error);
                }
            });
        }

        /**
         * @param screen_base64
         * @param name
         */
        getFile(screen_base64, name, camera) {
            const ext = camera === 'internal' ? 'png' : 'jpg';
            return new Promise((resolve, reject) => {
                if (screen_base64) {
                    fetch(screen_base64)
                    .then(res => res.blob())
                    .then(blob => resolve(new File([blob], `${name}.${ext}`, blob)))
                    .catch(e => reject(e));
                }
            });
        }

        shot = (camera, file_name) => {
            return new Promise((resolve, reject) => {
                this.getScreenShotBase64(camera)
                .then(screenshot => {
                    const file = this.getFile(screenshot, file_name, camera);
                    resolve(file);
                })
                .catch(e => reject(e));
            })
        }

        render() {
            return <WrappedComponent getFile={this.getFile} shot={this.shot} {...this.props} />;
        }
    }

    WithCameraHandling.displayName = `WithSubscription(${getDisplayName(WrappedComponent)})`;

    return WithCameraHandling
}

/**
 * @param WrappedComponent
 * @returns {*|string}
 */
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * @param message
 * @constructor
 */
const UnknownInterfaceCamera = (message) => {
    this.message = message;
    this.name = 'Unknown interface camera';
}