import { faCircleCheck, faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Content from './Content';

export default function Notifications(props) {
    let notifications = props.notifications?.length > 0 ? props.notifications.slice(0, 5) : [];
    let notifications_count = props.notifications?.length > 5 ? `(+${props.notifications.length - 5})` : "";
    return <Content className='d-flex flex-column justify-content-center align-items-center'>
            {notifications.length > 0
                ? notifications.map(el => {
                    let nodeIcon;
                    if (el.type === 'success') {
                        nodeIcon = <FontAwesomeIcon icon={faCircleCheck} className="display-9 text-success mr-2"/>
                    } else if (el.type === 'error') {
                        nodeIcon = <FontAwesomeIcon icon={faCircleExclamation} className="display-9 text-danger mr-2"/>
                    } else if (el.type === 'warning') {
                        nodeIcon = <FontAwesomeIcon icon={faCircleExclamation} className="display-9 text-warning mr-2"/>
                    } else {
                        nodeIcon = <FontAwesomeIcon icon={faCircleInfo} className="display-9 text-info mr-2"/>
                    };
                    return <NavLink to={`/notice-list/${el.uuid}`} onClick={props.handleShowNotifications} style={{textDecoration: "none" }} key={el.uuid}>
                            <Card className={'mb-2 p-2'}>
                                <Card.Body className={'d-flex flex-row text-dark'}>
                                    {nodeIcon}
                                    <Card.Text className='display-9'>{el.description}</Card.Text>
                                </Card.Body>
                            </Card>
                           </NavLink>})
                : <Card className={'mb-2 p-2'}>
                    <Card.Body>
                        <Card.Text className='display-9'>{props.t('You have no new notifications')}</Card.Text>
                    </Card.Body>
                </Card>}
            <NavLink to={"/notice-list"} onClick={props.handleShowNotifications} style={{textDecoration: "none"}}>
                <Button className={"MuiButton Btn Btn_boring"}>
                    {props.t('All notifications')} {notifications_count}
                </Button>
            </NavLink>
           </Content>
}