import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export default React.memo(function ScaleTable({answers, result_answer, question, handleClick, lang}) {
    const { t } = useTranslation()

    return (
        <table className="display-7 w-100">
            <thead>
                <tr>
                    <th className="text-center questionarieTableBorder py-3 w-5" scope="col">№</th>
                    <th className="text-center questionarieTableBorder py-3" scope="col">{t('Criteria')}</th>
                    {answers.params.variants.map(el => {
                        return <th className="text-center questionarieTableBorder py-3 text-wrap w-10" scope="col" key={el[lang] || el}>{el[lang] || el}</th>
                        })}
                </tr>
            </thead>
            <tbody>
                {answers.params.criteria.map((criter, i) => {
                    const current_criteria = criter[lang];
                    return (
                        <tr key={`${criter}${i}`}>
                            <td className="text-center questionarieTableBorder py-3">{i+1}</td>
                            <td className="text-center questionarieTableBorder py-3">{current_criteria}</td>
                            {answers.params.variants.map((el, index) => {
                                let variant = '';
                                result_answer.forEach(el => {
                                    if (el && el.criteria === current_criteria) variant = el.variant;
                                });
                                return (
                                    <td className='text-center questionarieTableBorder py-3 cursor-pointer' key={`${current_criteria}${index}`}
                                        onClick={() => handleClick(question.uuid, current_criteria, el)}>
                                        {variant === el && <FontAwesomeIcon className='ScaleTableCustomStyle' style={{borderRadius: '50%'}} icon={faCheck}/>}
                                    </td>
                                )})}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
})