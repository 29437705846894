import appManage from "../../reducers/app-manager";
import { notify } from "../../components/blocks/Notify";
import { setLog } from "../../helpers/functions";

const RFID_FAIL = "RFID_FAIL";

class moduleRfid {
    /**
     *    let option - массив имеющий следующие параметры
     *    baudRate: 9600,
     *    port: 'COM5', - port
     *
     *    callbackResult - функция куда будет возвращен результат, с аргументом типа String f(String)
     *
     *    после создания объекта, следует использовать команду start()
     */
    constructor(option) {
        this.baudRate = (option.baudRate === undefined) ? 9600 : option.baudRate;
        this.path = (option.port === undefined) ? 'COM4' : option.port;
        this.isHid = option.isHid ? true : false;
        this.isPort = false;

        try {
            if (!this.isHid) {
                const RegexParser = require('@serialport/parser-regex');
                this.SerialPort = require('serialport');
                this.port = new this.SerialPort(this.path, {baudRate: this.baudRate, autoOpen: false});
                this.parser = this.port.pipe(new RegexParser({ regex:/[\r\n]+/}));
                this.checkStatus().then(res => {
                    this.isPort = res;
                    appManage.isRfidWork(res);
                });
            } else {
                appManage.isRfidWork(true);
            }
        } catch (e) {
            e.typeError = RFID_FAIL;
            let info = "Ошибка в moduleRfid";
            setLog(info, e);
            notify(info, 'error');
            appManage.isRfidWork(false);
        }
    }

    /**
     * Начать выполнение
     * @returns {Promise.Promise}
     */
    start() {
        return new Promise((resolve, reject) => {
            try {
                this.parser.on('data', (a) => this.isPort && a !== 'Err5: Syntax' && resolve(a));

                if (this.port.isOpen === false) {
                    this.port.open((error) => {
                        if (error) {
                            error.typeError = RFID_FAIL;
                            reject(error);
                        }
                    });
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    close() {
		return  new Promise((resolve, reject) => {
			if (this.port.isOpen === true) {
				this.port.close((error) => {
					if (error) {
						reject(error);
					} else {
						this.port.write('$CLOSE\r\n', 'ascii', (error) => {
							if (error) reject(error);
						});
					}
				});
			}
		});
	}

    reset() {
		return  new Promise((resolve, reject) => {
			if (this.port.isOpen === true) {
				this.port.write('$RESET\r\n', 'ascii', (error) => {
					if (error) reject(error);
				});
			}
		});
	}

    checkStatus() {
		let isData = [];
		return  new Promise((resolve, reject) => {
			try {
                this.port.write('test_text\r\n');
                this.port.on('data', (data) => {
                    isData.push(data);
                    if (isData.length > 0) resolve(true);
                });
                if (this.port.isOpen === false) {
                    this.port.open((error) => {
                        if (error) {
                            error.typeError = RFID_FAIL;
                            setLog('Ошибка порта при подключении к RFID', error);
                            resolve(false);
                        }
                    });
                };

                setTimeout(() => {
                    if (isData.length === 0) {
                        notify('Ошибка подключения RFID', 'error');
                        resolve(false);
                    }
                }, 2000)
            } catch (e) {
                resolve(false);
            }
		})
	}
}

export default moduleRfid;