import React from 'react';
import Lottie from "lottie-react";
import {withTranslation} from "react-i18next";
import animationData from "../../../../data/icons/alco_test.json";

function Breathalyze(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    let textStatus = props.t('Wait');
    let classStatus = 'Text_dark';

    if (props.status.includes('STANBY')) {
        textStatus = props.t('Blow into the breathalyzer');
        classStatus = 'Text_success';
    } else if (props.status.includes('TRIGGER') ||
        props.status.includes('BREATH') ||
        props.status.includes('RESULT')) {
        textStatus = props.t('Analysis');
        classStatus = 'Text_happy';
    } else if (props.status.includes('END')) {
        textStatus = props.t('Analysis completed');
        classStatus = 'Text_success';
    }

    return <div className="preshift d-flex align-items-center flex-column justify-content-center h-100">
            <div className="preshift__lottie mb-3">
                <Lottie className="preshift__lottie mb-3" {...defaultOptions} style={{height: '20rem', width: '20rem'}} />
            </div>
            <h4 className={`preshift__text text-center ${classStatus}`}>{textStatus}</h4>
            <div className="preshift__timer"/>
           </div>
}

export default withTranslation()(Breathalyze);