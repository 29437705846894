import apiHandling from "./apiHandling";

class apiEmployee extends apiHandling {
    /**
     * Get list employee by tabelnum
     * @param tabNum
     * @returns {Promise<unknown>}
     */
    static checkTabel = (tabNum) => {
        if (!tabNum) throw new InvalidTabNum('Invalid tabel number');
        this.stopSettingsReq()

        return this.req({
            method: 'get',
            action: `employees/getForNumber`,
            data: {
                number: tabNum
            }
        });
    }

    /**
     * Get list employee by name
     * @param name
     * @returns {Promise<unknown>}
     */
    static getListByName = (name) => {
        if (!name) throw new InvalidName('Invalid name');
        this.stopSettingsReq()

        return this.req({
            method: 'get',
            action: `employees/getForFamily`,
            data: {
                family: name
            }
        });
    }

    /**
     * Auth
     * @param id
     * @returns {Promise<unknown>}
     */
    static auth = (id, second_auth = false) => {
        if (!id) throw new InvalidTabNum('Invalid identity number');
        this.stopSettingsReq()
        const formData = new FormData();
        formData.append('uuid', id);
        formData.append('second_auth', second_auth);

        return this.req({
            method: 'post',
            action: `employees/auth`,
            data: formData
        });
    }

    /**
     * Auth
     * @param id
     * @returns {Promise<unknown>}
     */
    static authRfid = (id) => {
        if (!id) throw new InvalidTabNum('Invalid identity number');
        this.stopSettingsReq()
        const formData = new FormData();
        formData.append('rfid', id);

        return this.req({
            method: 'post',
            action: `employees/auth`,
            data: formData
        });
    }

    static authLdap = (login, password) => {
        this.stopSettingsReq()
        const formData = new FormData();
        formData.append('login', login);
        formData.append('password', password);

        return this.req({
            method: 'post',
            action: `ldap/auth`,
            data: formData
        });
    }

    static authElCourses = (login, password) => {
        this.stopSettingsReq()
        const formData = new FormData();
        formData.append('login', login);
        formData.append('password', password);

        return this.req({
            method: 'post',
            action: `students/auth`,
            data: formData
        });
    }

    static getNotifications = (id) => this.req({
            method: 'get',
            action: 'employees/getNotifications',
            data: {
                uuid: id,
            }
        });

    static getSiz = (uuid) => this.req({
            method: 'get',
            action: `ipm/needs/${uuid}?mode=only_new` //'employees/getSiz' || `ipm/needs/${uuid}?mode=only_new`
        });

    static viewedSiz = (siz_id) => this.req({
            method: 'post',
            action: `ipm/needs/${siz_id}/apply`
        });

    static setPinCode = (id, pin_code) => {
        if (!pin_code) throw new InvalidTabNum('Invalid pin code');

        return this.req({
            method: 'post',
            action: `pin_code/?employee_uuid=${id}&pin_code=${pin_code}`
        });
    }

    static setRfidCard = (uuid, rfid) => {
        const formData = new FormData();
        formData.append('employee', uuid);
        formData.append('rfid', rfid);

        return this.req({
            method: 'post',
            action: `rfid/joinToEmployee`,
            data: formData
        });
    }
}

export default apiEmployee;

/**
 * @param message
 * @constructor
 */
function InvalidTabNum(message) {
    this.message = message;
    this.name = 'Invalid tabel number';
}

/**
 * @param message
 * @constructor
 */
function InvalidName(message) {
    this.message = message;
    this.name = 'Invalid name';
}
