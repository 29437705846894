import React from 'react';
import {connect} from "react-redux";
import {Spinner} from "react-bootstrap";

function Loader(props) {
    let textNode = props.children;

    if (props.isLoading) {
        for (var i in props.load.idsLoad) {
            if (props.load.idsLoad[i] === props.loadId) {
                textNode = <div className={'preloader_cot loadedContent ' + props.className} style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: props.justifyContent,
                                    alignItems: 'center',
                                    ...props.style
                                }}>
                                <Spinner className="preloader__spinner" animation="grow" size="lg" as="span"/>
                            </div>;
                break;
            }
        }
    }

    return textNode;
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(Loader);
