import React from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NoticeListItem({notify, handleSetNodeIcon, navLink}) {
    const classRow = (new Date() > new Date(Date.parse(notify.end_dt.slice(0, -6))) && notify.read) ? "opacity-5" : "cursor-pointer";
    const to_day = new Date().toLocaleString().slice(0, -10);
    const start_dt = new Date(Date.parse(notify.start_dt.slice(0, -6))).toLocaleString().slice(0, -3);
    const time = (to_day === start_dt.slice(0, -7)) ? start_dt.slice(12) : start_dt;
    const nodeIcon = handleSetNodeIcon(notify.type);

    return <div key={notify.uuid} onClick={() => navLink(notify.uuid)} className={`m-1 ${classRow} w-75 d-flex flex-row align-items-center`}>
            <FontAwesomeIcon icon={faCircle} className={`display-9 text-dark mr-1 ${notify.read && "invisible"}`} />
            <div className={`m-1 p-2 ${classRow} w-100 border d-flex flex-column`}>
                <div className={`w-100 d-flex flex-row align-items-center justify-content-between display-7`}>
                    <div>
                        {nodeIcon}
                        {notify.title && notify.title.slice(0, 30)}{notify.title && notify.title.length > 30 && "..."}
                    </div>
                    <div className={`display-9`}>
                        {time}
                    </div>
                </div>
                <div className={`m-1 w-100 display-9`}>
                    {notify.description && notify.description.slice(0, 50)}{notify.description && notify.description.length > 50 && "..."}
                </div>
            </div>
           </div>
}