import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faX } from "@fortawesome/free-solid-svg-icons";
import Content from "../../blocks/Content";

export default function ExtraEducationsResultsTab(props) {
    const steps = props.steps;
    const persent = props.persent_right_answers;
    const complite = props.test_complite;
    const text = props.t(complite ? 'Competent' : 'Not competent');
    const text_color = complite ? 'Text_success' : 'Text_danger';
    const icon = complite ? faCircleCheck : faX;

    return (
        <div className='Screen container'>
          <div className='d-flex justify-content-between mt-2'>
            <h1 className="display-7">{props.t('Score for the test')}<FontAwesomeIcon className={`mr-2 ${text_color}`} icon={icon}/><b>{text}</b></h1>
            <h1 className="display-7">{props.t('Percentage of correct answers')} <b className={text_color}>{persent}%</b></h1>
          </div>
          <Content>
              <table className='table_siz display-9 w-100 mw-100'>
                  <thead>
                      <tr>
                          <th className='text-center' scope='col'>№</th>
                          <th className='text-center' scope='col'>{props.t('Question')}</th>
                          <th className='text-center' scope='col'>{props.t('Answer')}</th>
                      </tr>
                  </thead>
                  <tbody>
                      {steps.map((el, index) => {
                          const question_number = index + 1;
                          const is_error = el.error_count > 0;
                          const text_style = `text-center table_siz_text display-7 ${is_error && 'Text_danger'}`;
                          const question = el.question_text;
                          let answer = '';
                          if (el.selected_answer_text) {
                              answer = el.selected_answer_text;
                          } else {
                              const all_count = el.answer_id.length;
                              const right_count = all_count - el.error_count;
                              answer = `${right_count}${props.t('of')}${all_count}`;
                          };
                          return <tr className={`table_siz_tr`} key={question_number} >
                                  <td className={text_style}>{question_number}</td>
                                  <td className={text_style}>{question}</td>
                                  <td className={text_style}>{answer}</td>
                                 </tr>
                          })}
                  </tbody>
              </table>
          </Content>
        </div>
      );
}