import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Animated } from 'react-animated-css';
import $ from 'jquery';
import {isElectron} from "react-device-detect";
import {withRouter} from "react-router-dom";
import { Button } from 'react-bootstrap';
import withFormHanlding from "../../hoc/FormHandling";
import store from '../../localStorage';
import SetApiAndTerminalName from '../screens/setSettings/SetApiAndTerminalName';
import SetDevicesSettings from '../screens/setSettings/SetDevicesSettings';
import SetDisplaySettings from '../screens/setSettings/SetDisplaySettings';
import StepperNav from '../blocks/StepperNav';
import Breams from '../blocks/Breams';
import { addBreamb, goBackStep, resetBreamb } from '../../reducers/breams-manage';
import { DELETE_ELECTRON_START_APP_ERROR } from '../../reducers/application';

class SetElectronSettingsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      listStep: [
        {title: props.t('Server address and terminal name')},
        {title: props.t('Configuring Display Settings')},
        {title: props.t('Configuring peripheral devices')}
      ],
      content: [
        SetApiAndTerminalName,
        SetDisplaySettings,
        SetDevicesSettings
      ],
      platform: '',
      active_input: '',
      ip_adress: '',
      pass: '',
      isVisible: true,
      device_settings: {},
      localeKeyboard: 'en',
      keyboardConfig: {
          layoutName: 'en_lower',
          layout: {
            'en': [
              'Q W E R T Y U I O P {backspace}',
              '{lock} A S D F G H J K L -',
              '{numbers} Z X C V B N M',
              '{space}'
            ],
            'en_lower': [
              'q w e r t y u i o p {backspace}',
              '{lock} a s d f g h j k l -',
              '{numbers} z x c v b n m',
              '{space}'
            ],
            'symbols': [
              '[ ] { } # % ^ * - + =',
              '_ \/ | ~ < > $ € •',
              '{abc} {numbers} . , ? ! \'',
              '{space}'
            ],
            'numbers': [
              '1 2 3 4 5 6 7 8 9 0',
              '- \/ : ; ( ) ₽ & @ "',
              '{abc} {symbols} . , ? ! \'',
              '{space}'
            ]
          },
          display: {
            "{backspace}": '<',
            "{space}": props.t('Space'),
            "{lock}": "A->a", // String.fromCharCode(55358, 56389), // "&#129093", // 🡅,
            "{symbols}": '#+=',
            "{abc}": props.t('ABC'),
            "{numbers}": '123'
          },
          theme: 'theme_cot',
          onChange: this.onPressKey,
          onKeyPress: this.onKeyPress,
          keyboardRef: r => (this.keyboard = r),
      },
    };

    resetBreamb();
  }

  componentDidMount() {
    addBreamb({title: this.props.t('Initial setup')});
    addBreamb({title: this.state.listStep[this.state.activeStep].title});
    if (isElectron) {
      try {
        const electron = require("electron");
        const settings = electron.ipcRenderer.sendSync('get_conf');
        this.setState({
          device_settings: settings.device_settings,
          ip_adress: settings.server_host,
          pass: settings.pass,
          platform: settings.platform,
        });
      } catch (e) {
        console.log(e);
      }
    };
  };

  componentWillUnmount() {
    resetBreamb();
  }

  /**
   * @param input
   */

  handleActiveInput = (e) => {
    let name = e.target.name;
    if (name === "ip_adress") this.keyboard.setInput(this.state.ip_adress);
    if (name === "pass") this.keyboard.setInput(this.state.pass);
    if (name === "appName") this.keyboard.setInput(this.state.device_settings.appName);
    if (name === "breathalyzer_port") this.keyboard.setInput(this.state.device_settings.breathalyzer.port);
    if (name === "breathalyzer_baudRate") this.keyboard.setInput(this.state.device_settings.breathalyzer.baudRate.toString());
    if (name === "breathalyzer_delimiter") this.keyboard.setInput(this.state.device_settings.breathalyzer.delimiter);
    if (name === "rfid_port") this.keyboard.setInput(this.state.device_settings.rfid.port);
    if (name === "rfid_baudRate") this.keyboard.setInput(this.state.device_settings.rfid.baudRate.toString());
    this.setState({active_input: name});
  };

  onInputChange = (event, data) => {
    let name = event.target.name;
    let value = event.target.value;
    let checked = event.target.checked;
    if (data) {
      name = data.name;
      value = data.value;
    };
    if (name === "ip_adress") this.onChangeIpAdress(value);
    if (name === "pass") this.onChangePass(value);
    if (name === "appName") this.onChangeAppname(value);
    if (name === "cursor") this.onChangeCheckboxInputs(name, checked);
    if (name === "fullscreen") this.onChangeCheckboxInputs(name, checked);
    if (name === "use_terminal_name") this.onChangeCheckboxInputs(name, checked);
    if (name === "breathalyzer_port") this.onChangeBreathalyzerSettings(name, value);
    if (name === "breathalyzer_baudRate") this.onChangeBreathalyzerSettings(name, value);
    if (name === "breathalyzer_delimiter") this.onChangeBreathalyzerSettings(name ,value);
    if (name === "rfid_port") this.onChangeRFIDSettings(name ,value);
    if (name === "rfid_baudRate") this.onChangeRFIDSettings(name ,value);
    if (name === "isHid") this.onChangeRFIDSettings(name ,checked);
    this.keyboard.setInput(value.toString());
  };

  onChangeIpAdress = (value) => this.setState({ip_adress: value});

  onChangePass = (value) => this.setState({pass: value});

  onChangeAppname = (value) => this.setState({device_settings: {...this.state.device_settings, appName: value}});

  onChangeCheckboxInputs = (name, checked) => this.setState({device_settings: {...this.state.device_settings, [name]: checked}});

  onChangeBreathalyzerSettings = (name, value) => {
    let breathalyzer = this.state.device_settings.breathalyzer;
    if (name === "breathalyzer_port") breathalyzer.port = value;
    if (name === "breathalyzer_baudRate") breathalyzer.baudRate = +value;
    if (name === "breathalyzer_delimiter") breathalyzer.delimiter = value;
    this.setState({device_settings: {...this.state.device_settings, breathalyzer: breathalyzer}});
  };

  onChangeRFIDSettings = (name, value) => {
    let rfid = this.state.device_settings.rfid;
    if (name === "rfid_port") rfid.port = value;
    if (name === "rfid_baudRate") rfid.baudRate = +value;
    if (name === "isHid") rfid.isHid = value;
    this.setState({device_settings: {...this.state.device_settings, rfid: rfid}});
  };

  onPressKey = (input) => {
    let active_input = this.state.active_input;
    if (active_input) {
      let data = {
        name: active_input,
        value: input,
      };
      if (active_input === "breathalyzer_baudRate" || active_input === "rfid_baudRate") {
        if (!isNaN(input)) {
          data.value = input;
        } else {
          if (active_input === "breathalyzer_baudRate") {
            data.value = this.state.device_settings.breathalyzer.baudRate;
            this.keyboard.setInput(this.state.device_settings.breathalyzer.baudRate);
          };
          if (active_input === "rfid_baudRate") {
            data.value = this.state.device_settings.rfid.baudRate;
            this.keyboard.setInput(this.state.device_settings.rfid.baudRate);
          };
        };
      };
      this.onInputChange(event, data);
    };
  };

  onKeyPress = (button) => {
    let keyboardConfig = this.state.keyboardConfig;
    if (button === "{lock}") {
        keyboardConfig.layoutName = keyboardConfig.layoutName === this.state.localeKeyboard ? `${this.state.localeKeyboard}_lower` : this.state.localeKeyboard;
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{symbols}") {
        keyboardConfig.layoutName = 'symbols';
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{abc}") {
        keyboardConfig.layoutName = `${this.state.localeKeyboard}_lower`;
        this.setState({keyboardConfig: keyboardConfig});
    } else if (button === "{numbers}") {
        keyboardConfig.layoutName = 'numbers';
        this.setState({keyboardConfig: keyboardConfig});
    }
  };

  nextStep = () => {
    if (this.state.activeStep < this.state.listStep.length - 1) {
      this.setState({isVisible: false, active_input: ""},
        () => setTimeout(()=>{
            this.setState({activeStep: this.state.activeStep + 1, isVisible: true}),
            goBackStep();
            addBreamb({title: this.state.listStep[this.state.activeStep].title});
        }, 500));
    } else {
      let device_settings = this.state.device_settings;
      let server_host = this.state.ip_adress;
      let pass = this.state.pass;
      try {
        const electron = require("electron");
        electron.ipcRenderer.send('save_device_settings', {...device_settings});
        electron.ipcRenderer.send('save_server_host', server_host);
        electron.ipcRenderer.send('save_pass', pass);
        store.dispatch({type: DELETE_ELECTRON_START_APP_ERROR});
        electron.ipcRenderer.send('relaunch');
        electron.ipcRenderer.send('exit');
      } catch (e) {
        console.log(e);
      }
    }
  };

  previousStep = () => {
    this.setState({isVisible: false, active_input: ""},
      () => setTimeout(()=>{
          this.setState({activeStep: this.state.activeStep - 1, isVisible: true}),
          goBackStep();
          addBreamb({title: this.state.listStep[this.state.activeStep].title});
      }, 500));
  };

  handleCloseApp = () => window.close();

  handleAdditionalButton = () => {
    return (
      <div className="d-flex w-100 justify-content-end">
        <Button className="MuiButton Btn Btn_danger mr-3" onClick={this.handleCloseApp} >
          {this.props.t("Close")}
        </Button>
        {this.state.activeStep > 0 && 
          <Button className="MuiButton Btn Btn_boring mr-3" onClick={this.previousStep} >
            {this.props.t("Back")}
          </Button>}
        <Button className="MuiButton Btn Btn_success" onClick={this.nextStep} >
          {this.state.activeStep < this.state.listStep.length - 1 ? this.props.t("Next") : this.props.t("Done")}
        </Button>
      </div>
    )
  };

  componentWillUpdate() {
    // TODO: fix double keyboards
    let keyboards = $(".startup__keyboard .hg-rows");
    if (keyboards.length > 1) keyboards.last().remove();
  }

  render() {
    const activeStep = this.state.activeStep;
    const Content = this.state.content[activeStep];
    const additionalButton = this.handleAdditionalButton();
    return <>
            <Breams />
            <Animated
                className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                animationInDuration={500}
                animationOutDuration={500}
                animationIn="slideInRight"
                animationOut="slideOutLeft"
                isVisible={this.state.isVisible}>
              <Content {...this.props} {...this.state}
                onInputChange={this.onInputChange}
                handleActiveInput={this.handleActiveInput}
                nextStep={this.nextStep} />
            </Animated>
            <StepperNav activeStep={this.state.activeStep} listStep={this.state.listStep} additionalButton={additionalButton} />
           </>
  };
}

const mapStateToProps = (store) => {
    return {
      locale: store.localeState,
    };
};

export default withRouter(connect(mapStateToProps)(withFormHanlding(SetElectronSettingsContainer)));