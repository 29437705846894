const initialState = {
    breambs: [],
}

export default function breambReduser(state = initialState, action) {
    switch (action.type) {
        case 'ADD_BREAMB':
            let newBreamb = action.breamb;
            let breams = state.breambs;

            breams.map((breamb) => {
                breamb.active = false;
                return breamb;
            })

            newBreamb.active = true;

            return {...state, breambs: breams.concat([newBreamb])};
        case 'GO_BACK_ONE_STEP':
            let breams_ = state.breambs;

            if (breams_.length > 0) {
                breams_ = breams_.slice(0, -1);
                breams_.map((breamb) => {
                    breamb.active = false;
                    return breamb;
                })

                breams_[breams_.length - 1].active = true;
            }

            return {...state, breambs: breams_};
        case 'RESET_BREAMB':
            return initialState;
        default:
            return state;
    }
}