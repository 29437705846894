import React from 'react';
import { isElectron } from 'react-device-detect';
import {NavLink} from 'react-router-dom';
import {Container, Row, Breadcrumb} from "react-bootstrap";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faSignOutAlt,
    faChartColumn,
    faFile,
    faVideo,
    faComment,
    faUniversity,
    faSuitcase,
    faBell,
    faHelmetSafety,
    faDesktop,
    faShieldBlank,
    faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import Module from "../../blocks/Cabinet/Module";
import Content from "../../blocks/Content";
import preshiftManage from "../../../reducers/preshift-manager";

export default function Cabinet(props) {
    document.title = props.t('Personal Area');
    const modules = props.app.modules;
    const render_modules = [];

    if (modules.includes('study')) {
        render_modules.push({
            title: props.t('Pre-shift express training-testing'),
            onClick: () => preshiftManage.setCameFrom('cabinet'),
            route: './preshift',
            color: 'success',
            icon: faSuitcase,
            disable: false,
        });
    };

    if (modules.includes('extra_education')) {
        render_modules.push({
            title: props.t('Additional express training-testing'),
            onClick: () => preshiftManage.setCameFrom('cabinet'),
            route: './extra-education',
            icon: faUniversity,
            disable: false,
        });
    };

    render_modules.push({
        title: props.t('Notices'),
        route: './notice-list',
        icon: faBell,
        unread: (props.user.data?.hasOwnProperty('count_new_notification')) ? props.user.data.count_new_notification : 0
    });

    if (modules.includes('electronic_courses') && props.user.data.is_student) {
        render_modules.push({
            title: props.t('Electronic courses'),
            color: 'success',
            route: './electronic-courses',
            icon: faDesktop,
        });
    };

    if (modules.includes('surveys')) {
        render_modules.push({
            title: props.t('Surveys'),
            route: '/questionary',
            icon: faListCheck,
            unread: (props.user.data?.hasOwnProperty('surveys_unread_count')) ? props.user.data.surveys_unread_count : 0,
        });
    };

    if (modules.includes('feedback')) {
        render_modules.push({
            title: props.t('Feedback'),
            route: '/feedback',
            icon: faComment,
            color: 'darken',
            unread: (props.user?.data?.hasOwnProperty('feedback_unread_count')) ? props.user.data.feedback_unread_count : 0,
        });
    };

    if (modules.includes('library')) {
        let unread_video = 0
        let unread_text = 0;
        if (props.user?.data?.hasOwnProperty('library_unread_count')) {
            if (props.user?.data?.library_unread_count?.hasOwnProperty('simulated_situation')) {
                unread_video += props.user.data.library_unread_count.simulated_situation;
            }
            if (props.user?.data?.library_unread_count?.hasOwnProperty('video_tutorial')) {
                unread_video += props.user.data.library_unread_count.video_tutorial;
            }
            if (props.user?.data?.library_unread_count?.hasOwnProperty('text_document')) {
                unread_text += props.user.data.library_unread_count.text_document;
            }
        }

        render_modules.push({
            title: props.t('Regulations'),
            route: './library/text_document',
            icon: faFile,
            unread: unread_text,
        });
        render_modules.push({
            title: props.t('Video instructions'),
            route: './library/video_tutorial,simulated_situation',
            icon: faVideo,
            unread: unread_video,
        });
    };

    render_modules.push({
        title: props.t('Analytics'),
        route: './emp-lvl',
        icon: faChartColumn,
    });

    if (modules.includes('ipm')) {
        render_modules.push({
            title: props.t('Personal protective equipment'),
            route: './siz',
            icon: faHelmetSafety,
        });
    };

    if (isElectron && modules.includes('rfid') && props.app.is_rfid && props.app.can_write_rfid) {
        render_modules.push({
            title: props.t('Link RFID card'),
            route: './set-rfid',
            icon: faShieldBlank,
            disable: !props.app.isRfidWork,
        });
    };

    return <>
            <div className="Breams">
                <Container className={"pb-0"}>
                    <Breadcrumb className={"Breams-Nav"}>
                        <Breadcrumb.Item className={"Breams-Item"} active>{props.t('Personal Area')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>
            <div className={"Screen ScreenCabinet container"}>
                <Content>
                    <Row className={"ScreenCabinet-Row"}>
                        {render_modules.map((module) => <Module {...module} key={module.title} />)}
                    </Row>
                </Content>
            </div>
            <div className='MuiStepContainer'>
                <Container className="pb-1">
                    <div className="w-100">
                        <div className="my-auto pl-0 mx-auto d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-row">
                                <Stepper activeStep={0}>
                                    <Step key="0" connector={null} classes={{ root: 'MuiStep-root_cot' }}>
                                        <StepLabel completed={false}/>
                                    </Step>
                                </Stepper>
                                <span className="MuiTypography-caption">{props.t('Personal area')}</span>
                            </div>
                            <NavLink to="/" className="Btn Btn_danger MuiButton">
                                <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                                {props.t('Exit')}
                            </NavLink>
                        </div>
                    </div>
                </Container>
            </div>
        </>
};