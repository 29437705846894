import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faForward} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import ExitTimer from "../../blocks/ExitTimer";
import ExtraEducationsResultsTab from "../../screens/questions/ExtraEducationsResultsTab";

class ExtraEducationsResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_exit_timer: true,
      steps: [],
      persent_right_answers: 0,
      test_complite: false,
    };

    this.renderButtons();
  }

  componentDidMount() {
    this.prepareState();
  };

  handleExitTimer = () => this.setState({ is_exit_timer: false });

  renderButtons = () => {
    this.props.renderAdditionalButton(
      <div className='d-flex w-100 justify-content-end'>
        <Button className='MuiButton Btn Btn_success'
          onClick={() => {
            if (this.state.is_exit_timer) {
              this.props.handleNextStep();
              this.props.renderAdditionalButton(null);
            }}}>
          <FontAwesomeIcon className='mr-4' icon={faForward} />
          {this.props.t('Done')}
        </Button>
        <Button className='MuiButton Btn Btn_success ml-3'
          onClick={() => {
            if (this.state.is_exit_timer) {
              this.props.handleFinish();
              this.props.renderAdditionalButton(null);
            }}}>
          <FontAwesomeIcon className='mr-4' icon={faForward} />
          {this.props.t('Finish and exit')}
          <ExitTimer theme_name={this.props.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer} sec={20} />
        </Button>
      </div>
    );
  };

  prepareState = () => {
    const flow_steps = this.props.preshift.flow.history.steps.filter((step) => step.name === 'question');
    const persent = this.rightAnswwersPersent(flow_steps);
    const complite = persent >= +this.props.top;
    this.setState({
      steps: flow_steps,
      persent_right_answers: persent,
      test_complite: complite,
    });
  };

  rightAnswwersPersent = (data) => {
    const question_count = data.length;
    let errors = 0;
    data.forEach((el) => {
        if (el.error_count > 0) errors += 1;
    });
    const persent = Math.round(((question_count - errors) / question_count) * 100);
    return persent;
  };

  render() {
    return <ExtraEducationsResultsTab {...this.state} t={this.props.t} />
  }
}

const mapStateToProps = (store) => {
    return {
        preshift: store.preshiftState,
        theme_name: store.appState.theme_name,
    }
};

export default connect(mapStateToProps)(withTranslation()(ExtraEducationsResults));