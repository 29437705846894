import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export default React.memo(function RadioCheckboxTable({answers, question, type, result_answer, handleClick}) {
    const { t } = useTranslation()

    return (
        <table className="display-7 w-100">
            <thead>
                <tr>
                    <th className="text-center questionarieTableBorder py-3 w-5" scope="col">№</th>
                    <th className="text-center questionarieTableBorder py-3" scope="col">{t('Variants')}</th>
                    <th className="text-center questionarieTableBorder py-3 text-wrap w-20" scope="col">{t(type === 'radio' ? 'Select one' : 'Select one or more')}</th>
                </tr>
            </thead>
            <tbody>
                {answers.map((el, i) => {
                    let showIcon
                    let style
                    if (type === 'radio') {
                        showIcon = el.uuid === result_answer
                        style = {borderRadius: '50%'}
                    }
                    if (type === 'checkbox') {
                        showIcon = result_answer.includes(el.uuid)
                        style = {borderRadius: 4, paddingInline: .5}
                    }
                    return (
                        <tr className="cursor-pointer" key={el.uuid} onClick={() => handleClick(question.uuid, el.uuid)}>
                            <td className="text-center questionarieTableBorder py-3">{i+1}</td>
                            <td className="text-center questionarieTableBorder py-3">{el.params.text}</td>
                            <td className='text-center questionarieTableBorder py-3'>
                                {showIcon && <FontAwesomeIcon className='ScaleTableCustomStyle' style={style} icon={faCheck}/>}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
})