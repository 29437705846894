import {isElectron} from "react-device-detect";
import { setInfoLog } from './functions';

const folderPath = (folderName) => {
    let folderPath = '';
    try {
        const os = require("os");
        const platform = os.platform();
        const username = os.userInfo().username;
        const startPath = platform === 'win32' ? 'C:/Users' : '/home';
        const mediaFolderPath = `${startPath}/${username}/complexMedia`;
        chekOrCreateFolder(mediaFolderPath);
        folderPath = `${mediaFolderPath}/${folderName}`;
        chekOrCreateFolder(folderPath);
    } catch (e) {
        //игнорируем
    }
    return folderPath;
};

const chekOrCreateFolder = (folderPath) => {
    try {
        const fs = require('fs');
        const isFolder = fs.existsSync(folderPath);
        !isFolder && fs.mkdirSync(folderPath);
    } catch (e) {
        //игнорируем
    }
};

const isFileInstalled = (filePath) => {
    let flag = false;
    try {
        const fs = require('fs');
        fs.accessSync(filePath, fs.constants.F_OK);
        flag = true;
    } catch (e) {
        //игнорируем
    };
    return flag;
};

const readFile = (url) => {
    let str = '';
    try {
        const fs = require('fs');
        const isFile = isFileInstalled(url);
        if (isFile) str = JSON.parse(fs.readFileSync(url));
    } catch (e) {
        //игнорируем
    }
    return str;
};

const getNames = (string) => {
    const str = string.split('/');
    const folderName = str[str.length -2];
    const name = str[str.length -1].split('.');
    const fileName = name[0];
    const fileExt = name[1];
    return [folderName, fileName, fileExt];
};

const downloadFile = async (url, fileName, fileExt, jsonFilePath) => {
    try {
        const http = require('http');
        const fs = require('fs');
        return new Promise((resolve, reject) => {
            http.get(url, (res) => {
                if (res.statusCode === 200) {
                    res.responseType = "arraybuffer";
                    const rawData = [];
                    res.on('data', (chunk) => rawData.push(chunk));
                    res.on('end', () => {
                        try {
                            if (rawData.length > 0) {
                                const ext = fileExt.toLowerCase();
                                const type = (ext === 'webm' || ext === 'mp4')
                                    ? 'video'
                                    : (ext === 'pdf')
                                        ? 'application'
                                        : 'image';
                                const text = `data:${type}/${ext};base64,` + Buffer.concat(rawData).toString('base64');
                                fs.writeFileSync(jsonFilePath, JSON.stringify(text));
                            } else {
                                setInfoLog(`Ошибка загрузки данных c ${url}`);
                            }
                        } catch (e) {
                            setInfoLog(e);
                        }
                    });
                    res.on('error', (err) => setInfoLog(err));
                } else {
                    setInfoLog(`Ошибка загрузки файла ${fileName}.${fileExt}`);
                }
            });
        });
    } catch (e) {
        setInfoLog(e);
    }
};

const saveFileInCache = (url) => {
    if (isElectron) {
        const [folderName, fileName, fileExt] = getNames(url);
        const folder = folderPath(folderName);
        const jsonFilePath = `${folder}/${fileName}.json`;
        const isFile = isFileInstalled(jsonFilePath);
        !isFile && downloadFile(url, fileName, fileExt, jsonFilePath);
    };
};

const getFilePath = (url) => {
    let path = url;
    if (isElectron) {
        const [folderName, fileName, fileExt] = getNames(url);
        const folder = folderPath(folderName);
        const jsonFilePath = `${folder}/${fileName}.json`;
        const isFile = isFileInstalled(jsonFilePath);
        if (isFile) {
            path = readFile(jsonFilePath);
        } else {
            downloadFile(url, fileName, fileExt, jsonFilePath);
        }
    };
    return path;
};

export {saveFileInCache, getFilePath};